import React from 'react';
import { DealsAndDiscountProvider } from './DealsAndDiscountContext';
import DealsAndDiscountPageLayout from './DealsAndDiscountPageLayout';

export default function AddAndEditDealsAndDiscountPage(
  props: React.PropsWithChildren<any>
): React.FunctionComponentElement<any> {
  return (
    <DealsAndDiscountProvider>
      <DealsAndDiscountPageLayout />
    </DealsAndDiscountProvider>
  );
}
