import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from "@components/hook/OnClickOutside";

export interface IDropdownTimepickerProps {
  selected: string;
  invalid?: boolean;
  elementID: string;
  disable?: boolean;
  onChange: (val: string) => void;
}

export const defaultTime = (time: string): string => {
  return (time.length < 2) ? `0${time}` : time;
}

export function DropdownTimepicker(
  props: React.PropsWithChildren<IDropdownTimepickerProps>
): React.FunctionComponentElement<IDropdownTimepickerProps> {
  const { selected, invalid, elementID, disable, onChange } = props;

  const generateTime = (max: number): string[] => {
    let k = [];
    for (let i = 0; i < max; i++) {
      if (i < 10) {
        k.push('0' + i);
      } else {
        k.push('' + i);
      }
    }
    return k;
  }

  const ref = useRef(null)
  const [show, setShow] = useState<boolean>(false);
  const [hourList] = useState<string[]>(generateTime(24));
  const [minuteList] = useState<string[]>(generateTime(60));
  const [hour, setHour] = useState<string>();
  const [minute, setMinute] = useState<string>();
  const [selectH, setSelectH] = useState<boolean>(false);
  const [selectM, setSelectM] = useState<boolean>(false);

  useEffect(() => {
    if (selected) {
      let temp = selected.split(':');
      if (temp.length == 2) {
        setHour(defaultTime(temp[0]));
        setMinute(defaultTime(temp[1]));
      } else {
        setHour('00');
        setMinute('00');
      }
    } else {
      setHour('00');
      setMinute('00');
    }
  }, [])

  useEffect(() => {
    if (show) {
      if (selected) {
        setSelectH(false);
        setSelectM(false);
        let temp = selected.split(':');
        if (temp.length == 2) {
          setHour(defaultTime(temp[0]));
          setMinute(defaultTime(temp[1]));
        } else {
          setHour('00');
          setMinute('00');
        }
      } else {
        setHour('00');
        setMinute('00');
      }
    }
  }, [show])

  useEffect(() => {
    if (hour && minute && selectH && selectM) {
      onChange(hour + ':' + minute);
      setShow(false);
      setSelectH(false);
      setSelectM(false);
    }
  }, [hour, minute, selectH, selectM])

  const onSelect = (type: 'hour' | 'minute', value: string) => {
    if (type === 'hour') {
      setSelectH(true);
      setHour(value);
    } else if (type === 'minute') {
      setSelectM(true);
      setMinute(value);
    }
  }

  const onShowDropdownItem = () => {
    if (!disable) {
      setSelectH(false);
      setSelectM(false);
      setShow(true);
    }
  }

  const handleClickOutside = () => {
    if (hour && minute && (selectH || selectM)) {
      onChange(hour + ':' + minute);
      setSelectH(false);
      setSelectM(false);
    }
    setShow(false);
  }

  useOnClickOutside(ref, handleClickOutside);

  const renderItem = (value: string, index: number, type: 'hour' | 'minute') => {
    let className = '';
    if ((type === 'hour' && value === hour) || (type === 'minute' && value === minute)) {
      className += ' active'
    }
    return (<a key={`dropdownItem-${type + index}`}
      id={`a-${elementID}-time-dropdownItem-${type + index}`}
      className={`dropdown-item${className}`}
      onClick={() => onSelect(type, value)}>{value}</a>);
  }

  return (<>
    <div className="dropdown dropdown-time" id={`ddl-${elementID}-time`}>
      <div id={`txt-${elementID}-input-time`} data-toggle="dropdown" className={`input-group icon input-time ${invalid ? 'is-invalid' : ''}`}>
        <input id={`txt-${elementID}-time`} type="text" placeholder="HH:MM" disabled={disable} value={selected} onChange={() => { }}
          className="form-control" onClick={onShowDropdownItem} />
        <button id={`btn-${elementID}-time`} type="button" className="btn" onClick={onShowDropdownItem}>
          <i id={`i-${elementID}-time`} className="icon-history">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </button>
      </div>
      <div id={`div-${elementID}-time-dropdown-menu`} ref={ref} className={`dropdown-menu${show ? ' show' : ''}`}>
        <div className="d-flex">
          <div id={`div-${elementID}-time-hour`} className="time-hour">
            {hourList.map((h, index) => {
              return renderItem(h, index, 'hour');
            })}
          </div>
          <div id={`div-${elementID}-time-minute`} className="time-sec">
            {minuteList.map((h, index) => {
              return renderItem(h, index, 'minute');
            })}
          </div>
        </div>
      </div>
    </div>
  </>
  );
}
