import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalSize } from '../../utils/constants/StyleConstant';
import { ModalStackPropsBase } from './ModalStackProvider';
export interface IImageAlertModalData {
  modalSize?: ModalSize;
  title?: string;
  image?: string;
}


interface IProps extends ModalStackPropsBase<unknown, unknown> {
  data: IImageAlertModalData;
  elementID?: string;
}

export function ImageAlertModal(
  props: React.PropsWithChildren<IProps>
): React.FunctionComponentElement<IProps> {
  const { data, elementID, show, onClose } = props;

  return (
    <>
      <Modal
        show={show}
        size={data.modalSize === ModalSize.MD ? undefined : data.modalSize}
        dialogClassName={'modal-dialog modal-540'}
      >
        {data.title &&
          <Modal.Header>
            <Modal.Title id={`${elementID}-title`}>{data.title}</Modal.Title>
            <button id={`btn-${elementID}-close`}
              type='button'
              data-dismiss='modal'
              className='close'
              onClick={() => onClose && onClose(undefined)}>
              <i id={`i-${elementID}-close`} className='icon-cancel'></i>
            </button>
          </Modal.Header>
        }

        <Modal.Body>
          <div className="modal-body text-center">
            <img src={data?.image} width="492" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
