import NotFoundItem from '@assets/images/frontend/not-found.png';
import { IconType, SCIcon } from '@components/icon/SCIcon';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalStackPropsBase } from './ModalStackProvider';

interface IProps extends ModalStackPropsBase<unknown, unknown> {
  title?: string;
  disableTitle?: boolean;
  description?: string;
  cardIcon?: any;
  buttonText?: string;
  show?: boolean;
  buttonIcon?: false | IconType;
  errMessage?: string;
  errorCode?: string;
  onSubmit: () => void;
}

export function SomethingWentWrongModal(
  props: React.PropsWithChildren<IProps>
): React.FunctionComponentElement<IProps> {
  const { t } = useTranslation();
  const { show, errorCode, errMessage, onSubmit, title, disableTitle, description, cardIcon, buttonIcon = 'reload', buttonText } = props;

  const elementID = {
    title: 'something-wrong-popup-title',
    btnPrimary: 'something-wrong-popup-btn-yes',
  };

  const onOpenError = (errorMessage: string) => {
    var newWindow = window.open();
    if (newWindow?.document) {
      newWindow?.document?.write(errorMessage);
    }
  }

  return (
    <>
      <Modal
        show={show}
        dialogClassName={'modal-dialog modal-md'}
      >
        <Modal.Body className="text-center">
          <img src={cardIcon || NotFoundItem} className="card-icon" />
          {!disableTitle && <div className="title">{t(title || 'Common.Modal.SomethingWentWrong.Title')}</div>}
          <p>{t(description || "Common.Modal.SomethingWentWrong.Description")}</p>
          {errMessage && errorCode && <>
            <div className="float-left"><a className="btn-link"
              onClick={() => onOpenError(errMessage)}
            >{t("Common.Button.ViewError")}
            </a>
            </div>
            <div className="float-right">
              <p>Code:{errorCode}</p>
            </div>
          </>
          }
          {errMessage && !errorCode &&
            <a className="btn-link"
              onClick={() => onOpenError(errMessage)}
            >{t("Common.Button.ViewError")}
            </a>
          }
        </Modal.Body>

        <Modal.Footer className="text-center">
          <Button variant="primary" id={elementID?.btnPrimary} onClick={() => onSubmit()}>
            {typeof buttonIcon === 'string' && <SCIcon icon={buttonIcon} className="mr-8px" />}
            {t(buttonText || "Common.Modal.SomethingWentWrong.TryAgainButton")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
