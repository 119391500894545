import { UploadCompanyDocumentResponse, UploadFileResponse } from './response/FileResponse';
import { PTVNResponse } from './response/BaseResponse';
import { HeaderTypes, HttpClientHelper, PTVNClient } from '@ptvn-react/authentication';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import fileDownload from 'js-file-download';
import axios from 'axios-observable';
import APIConstant from '@utils/constants/APIConstant';
import { AxiosRequestConfig } from 'axios';
import { EditProductImageRequest } from './request/ProductRequest';
import { EditProductImageResponse } from './response/ProductResponse';
export class FileClient {
  public static downloadFile(url: string, fileName: string): Observable<boolean> {
    return axios.get(url, { responseType: 'blob' }).pipe(
      map((response) => {
        fileDownload(response.data, fileName);
        return true;
      }),
      catchError((err) => throwError(err))
    );
  }

  public static uploadProductFile(
    file: File,
    type: string,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<PTVNResponse<UploadFileResponse>> {
    const url = APIConstant.product.uploadProductFile.replace('{type}', type);
    const payload = new FormData();
    payload.append('attachment', file);
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.post(url, payload, options).pipe(map((m) => m.data));
  }

  public static editProductImage(
    request: EditProductImageRequest,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<EditProductImageResponse> {
    const url = APIConstant.product.uploadProductFile.replace('{type}', 'image');
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.put<PTVNResponse<EditProductImageResponse>>(url, request, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static uploadCompanyDocument(
    file: File
  ): Observable<PTVNResponse<UploadCompanyDocumentResponse>> {
    const url = APIConstant.company.companyDocument;
    const payload = new FormData();
    payload.append('attachment', file);
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options };
    return PTVNClient.post(url, payload, options).pipe(map((m) => m.data));
  }

  public static uploadCompanyDocumentFile(
    file: File
  ): Observable<PTVNResponse<UploadCompanyDocumentResponse>> {
    const url = APIConstant.company.companyDocumentFile;
    const payload = new FormData();
    payload.append('attachment', file);
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options };
    return PTVNClient.post(url, payload, options).pipe(map((m) => m.data));
  }

  public static downCompanyDocument(uuid: string): Observable<File> {
    const url = `${APIConstant.company.companyDocument}?UUID=${uuid}`;
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, responseType: 'blob' };
    return PTVNClient.get(url, options).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  public static uploadCompanyImage(
    file: File,
    type: string,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<PTVNResponse<UploadFileResponse>> {
    const url = APIConstant.company.companyAttachment.replace('{type}', type);
    const payload = new FormData();
    payload.append('attachment', file);
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.post(url, payload, options).pipe(map((m) => m.data));
  }

  public static editCompanyImage(
    request: EditProductImageRequest,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<EditProductImageResponse> {
    const url = APIConstant.company.companyAttachment.replace('{type}', 'image');
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.put<PTVNResponse<EditProductImageResponse>>(url, request, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static uploadPromotionImage(
    file: File,
    type: string,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<PTVNResponse<UploadFileResponse>> {
    const url = APIConstant.product.uploadPromotionFile.replace('{type}', type);
    const payload = new FormData();
    payload.append('attachment', file);
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.post(url, payload, options).pipe(map((m) => m.data));
  }

  public static editPromotionImage(
    request: EditProductImageRequest,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<EditProductImageResponse> {
    const url = APIConstant.product.uploadPromotionFile.replace('{type}', 'image');
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.put<PTVNResponse<EditProductImageResponse>>(url, request, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static downloadCompanyDocument(branchId: number, uuid?: string, attachmentUniqueName?: string): Observable<File> {
    let url = `${APIConstant.company.downloadCompanyDocument}?branchId=${branchId}`;
    if (uuid) {
      url += `&uuid=${uuid}`;
    }

    if (attachmentUniqueName) {
      url += `&attachmentUniqueName=${attachmentUniqueName}`;
    }

    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, responseType: 'blob' };
    return PTVNClient.get(url, options).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  public static uploadAutoAcceptPOFile(
    branchId: number,
    file: File,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress']
  ): Observable<PTVNResponse<UploadFileResponse>> {
    const url = APIConstant.company.autoAcceptPOAttachment;
    const payload = new FormData();
    payload.append('attachment', file);
    payload.append('branchId', branchId.toString());
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, onUploadProgress };
    return PTVNClient.post(url, payload, options).pipe(map((m) => m.data));
  }

  public static deleteAutoAcceptPOFile(
    branchId: number,
    fileId: number,
  ): Observable<PTVNResponse<UploadFileResponse>> {
    const url = APIConstant.company.deleteAutoAcceptPOAttachment;
    const request = {
      branchId: branchId,
      fileId: fileId
    }
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post(url, request, options).pipe(map((m) => m.data));
  }
}
