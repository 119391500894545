import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalSize } from '../../utils/constants/StyleConstant';
import { ModalStackPropsBase } from './ModalStackProvider';
export interface IDescriptionWithFooterModalData {
  modalSize?: ModalSize;
  title?: string;
  description?: string;
  actor?:string;
  date?:string;
}

interface IProps extends ModalStackPropsBase<unknown, unknown> {
  data: IDescriptionWithFooterModalData;
  elementID?: string;
}

export function DescriptionWithFooterModal(
  props: React.PropsWithChildren<IProps>
): React.FunctionComponentElement<IProps> {
  const { data, show, elementID, onClose } = props;

  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        size={data.modalSize === ModalSize.MD ? undefined : data.modalSize}
        dialogClassName={'modal-dialog modal-540'}
        backdropClassName={'z-index-1002'}
      >
        {data.title && (
          <Modal.Header>
            <Modal.Title id={`modal-${elementID}-title`}>
              {data.title}
              <button
                id={`btn-${elementID}-close`}
                type="button"
                data-dismiss="modal"
                className="close"
                onClick={() => onClose && onClose(undefined)}
              >
                <i id={`i-${elementID}-close`} className="icon-cancel"></i>
              </button>
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={'break-word'}>
          <p>{data.description}</p>
          <div className="mt-4">
            <small>{data.actor}<br/>{data.date}</small>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
