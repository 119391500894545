export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: number;
  DateTimestamp: string;
  Long: number;
  Short: number;
};

export type AddressMaster = {
  __typename?: 'AddressMaster';
  city?: Maybe<Scalars['String']>;
  cityLocal?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceTaxCode?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  stateNameLocal?: Maybe<Scalars['String']>;
  subDistrict?: Maybe<Scalars['String']>;
  subDistrictLocal?: Maybe<Scalars['String']>;
};

export type AddressRequestUpdate = {
  __typename?: 'AddressRequestUpdate';
  addressEn?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Long']>;
  addressLocal?: Maybe<Scalars['String']>;
  addressRequestUpdateId?: Maybe<Scalars['Long']>;
  country?: Maybe<MasterCountry>;
  districtEn?: Maybe<Scalars['String']>;
  districtLocal?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceEn?: Maybe<Scalars['String']>;
  provinceLocal?: Maybe<Scalars['String']>;
  requestBy?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['DateTimestamp']>;
  subDistrictEn?: Maybe<Scalars['String']>;
  subDistrictLocal?: Maybe<Scalars['String']>;
  swwReqId?: Maybe<Scalars['Int']>;
};

export type AdminPoUserInformation = {
  __typename?: 'AdminPOUserInformation';
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  faxCallingCode?: Maybe<Scalars['String']>;
  faxExt?: Maybe<Scalars['String']>;
  jobTitleId?: Maybe<Scalars['Int']>;
  jobTitleName?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobileCallingCode?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  otherJobTitleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCallingCode?: Maybe<Scalars['String']>;
  phoneExt?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['Long']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  documentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  uploadedBy?: Maybe<Scalars['String']>;
  uploadedDate?: Maybe<Scalars['DateTimestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

export type AuctionDetail = {
  __typename?: 'AuctionDetail';
  auctionID: Scalars['Int'];
  awardStatus?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['DateTimestamp']>;
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['DateTimestamp']>;
  originatorID?: Maybe<Scalars['Int']>;
  originatorNameEN?: Maybe<Scalars['String']>;
  originatorNameLocal?: Maybe<Scalars['String']>;
  originatorRegisterFor?: Maybe<Scalars['String']>;
  ownerDepartment?: Maybe<Scalars['String']>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerFullName?: Maybe<Scalars['String']>;
  ownerUserID?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTimestamp']>;
};

export type AuctionEvent = {
  __typename?: 'AuctionEvent';
  auctionID: Scalars['Int'];
  auctionItemList?: Maybe<Array<Maybe<AuctionItem>>>;
  awardStatus?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['DateTimestamp']>;
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['DateTimestamp']>;
  originatorID?: Maybe<Scalars['Int']>;
  originatorNameEN?: Maybe<Scalars['String']>;
  originatorNameLocal?: Maybe<Scalars['String']>;
  originatorRegisterFor?: Maybe<Scalars['String']>;
  ownerDepartment?: Maybe<Scalars['String']>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerFullName?: Maybe<Scalars['String']>;
  ownerUserID?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTimestamp']>;
};

export type AuctionEventAuctionItemListArgs = {
  itemIDList?: Maybe<Array<Scalars['String']>>;
};

export type AuctionItem = {
  __typename?: 'AuctionItem';
  VAT?: Maybe<Scalars['String']>;
  auctionItemAwarded?: Maybe<AuctionItemAwarded>;
  auctionItemBidderList?: Maybe<Array<Maybe<ItemBidder>>>;
  auctionItemID: Scalars['Int'];
  auctionType?: Maybe<Scalars['String']>;
  baseCurrencyCode?: Maybe<Scalars['String']>;
  basePrice?: Maybe<Scalars['String']>;
  bidDirection?: Maybe<Scalars['String']>;
  by?: Maybe<Scalars['String']>;
  categoryCode?: Maybe<Scalars['String']>;
  categoryFullNameEN?: Maybe<Scalars['String']>;
  categoryFullNameLocal?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  decimal?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  itemID: Scalars['String'];
  itemNumber?: Maybe<Scalars['Int']>;
  lastBid?: Maybe<BidHistoryLog>;
  name?: Maybe<Scalars['String']>;
  openingPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  reservePrice?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTimestamp']>;
};

export type AuctionItemAuctionItemBidderListArgs = {
  userIDList?: Maybe<Array<Scalars['Long']>>;
};

export type AuctionItemLastBidArgs = {
  userID: Scalars['Long'];
};

export type AuctionItemAwarded = {
  __typename?: 'AuctionItemAwarded';
  auctionItemID?: Maybe<Scalars['Int']>;
  bidAmountApproved?: Maybe<Scalars['BigDecimal']>;
  by?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  email?: Maybe<Scalars['String']>;
  organizationID?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['Int']>;
};

export type AuctionItemDetail = {
  __typename?: 'AuctionItemDetail';
  VAT?: Maybe<Scalars['String']>;
  auctionDetail?: Maybe<AuctionDetail>;
  auctionItemID: Scalars['Int'];
  auctionType?: Maybe<Scalars['String']>;
  baseCurrencyCode?: Maybe<Scalars['String']>;
  basePrice?: Maybe<Scalars['String']>;
  bidDirection?: Maybe<Scalars['String']>;
  bidHistory?: Maybe<Array<Maybe<BidHistory>>>;
  bidLogs?: Maybe<Array<Maybe<BidLog>>>;
  bidderRankings?: Maybe<Array<Maybe<BidderRanking>>>;
  by?: Maybe<Scalars['String']>;
  categoryCode?: Maybe<Scalars['String']>;
  categoryFullNameEN?: Maybe<Scalars['String']>;
  categoryFullNameLocal?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  currentCloseTime?: Maybe<Scalars['DateTimestamp']>;
  decimal?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  itemBidders?: Maybe<Array<Maybe<ItemBidder>>>;
  itemID: Scalars['String'];
  itemNumber?: Maybe<Scalars['Int']>;
  itemSettings?: Maybe<ItemSettings>;
  name?: Maybe<Scalars['String']>;
  openingPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  reservePrice?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalBids?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTimestamp']>;
};

export type BecomeABuyer = {
  __typename?: 'BecomeABuyer';
  contact?: Maybe<Array<Maybe<CompanyContact>>>;
  isBecomeABuyer?: Maybe<Scalars['Boolean']>;
};

export type BidConditionSetting = {
  __typename?: 'BidConditionSetting';
  active?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BidHistory = {
  __typename?: 'BidHistory';
  bidAmount?: Maybe<Scalars['String']>;
  bidDateTime?: Maybe<Scalars['DateTimestamp']>;
  bidQuantity?: Maybe<Scalars['Int']>;
  bidWeightAmount?: Maybe<Scalars['String']>;
  bidderDepartment?: Maybe<Scalars['String']>;
  bidderEmail?: Maybe<Scalars['String']>;
  bidderFullName?: Maybe<Scalars['String']>;
  bidderUserID?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  organizationID?: Maybe<Scalars['Int']>;
  organizationNameEN?: Maybe<Scalars['String']>;
  organizationNameLocal?: Maybe<Scalars['String']>;
  originalBidAmount?: Maybe<Scalars['String']>;
  originalBidCurrency?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type BidHistoryLog = {
  __typename?: 'BidHistoryLog';
  active?: Maybe<Scalars['Boolean']>;
  auctionID?: Maybe<Scalars['Int']>;
  bidAmount?: Maybe<Scalars['BigDecimal']>;
  bidAmountBaseCurrency?: Maybe<Scalars['BigDecimal']>;
  bidCurrency?: Maybe<Scalars['String']>;
  bidDateTime?: Maybe<Scalars['DateTimestamp']>;
  bidStatus?: Maybe<Scalars['String']>;
  bidderDepartment?: Maybe<Scalars['String']>;
  bidderEmail?: Maybe<Scalars['String']>;
  bidderFullName?: Maybe<Scalars['String']>;
  bidderOrganizationNameEN?: Maybe<Scalars['String']>;
  bidderOrganizationNameLocal?: Maybe<Scalars['String']>;
  biddingHistoryLogID?: Maybe<Scalars['Long']>;
  decimal?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['BigDecimal']>;
  note?: Maybe<Scalars['String']>;
  organizationID?: Maybe<Scalars['Int']>;
  originatorID?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['Long']>;
};

export type BidLog = {
  __typename?: 'BidLog';
  active?: Maybe<Scalars['Boolean']>;
  bidDateTime?: Maybe<Scalars['DateTimestamp']>;
  bidQuantity?: Maybe<Scalars['Int']>;
  bidStatus?: Maybe<Scalars['String']>;
  bidderDepartment?: Maybe<Scalars['String']>;
  bidderEmail?: Maybe<Scalars['String']>;
  bidderFullName?: Maybe<Scalars['String']>;
  bidderUserID?: Maybe<Scalars['Int']>;
  organizationID?: Maybe<Scalars['Int']>;
  organizationNameEN?: Maybe<Scalars['String']>;
  organizationNameLocal?: Maybe<Scalars['String']>;
  originalBidAmount?: Maybe<Scalars['String']>;
  originalBidCurrency?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  savingAmount?: Maybe<Scalars['String']>;
};

export type BidderRanking = {
  __typename?: 'BidderRanking';
  awardBidAmount?: Maybe<Scalars['String']>;
  awardBidDateTime?: Maybe<Scalars['DateTimestamp']>;
  awardBy?: Maybe<Scalars['String']>;
  bidderDepartment?: Maybe<Scalars['String']>;
  bidderEmail?: Maybe<Scalars['String']>;
  bidderFullName?: Maybe<Scalars['String']>;
  bidderUserID?: Maybe<Scalars['Int']>;
  lastBidAmount?: Maybe<Scalars['String']>;
  lastBidDateTime?: Maybe<Scalars['DateTimestamp']>;
  lastBidExchangeRate?: Maybe<Scalars['String']>;
  lastBidNote?: Maybe<Scalars['String']>;
  lastBidQuantity?: Maybe<Scalars['Int']>;
  lastBidWeightAmount?: Maybe<Scalars['String']>;
  openingPrice?: Maybe<Scalars['String']>;
  organizationID?: Maybe<Scalars['Int']>;
  organizationNameEN?: Maybe<Scalars['String']>;
  organizationNameLocal?: Maybe<Scalars['String']>;
  originalLastBidAmount?: Maybe<Scalars['String']>;
  originalLastBidCurrency?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  startingBidAmount?: Maybe<Scalars['String']>;
  startingBidDateTime?: Maybe<Scalars['DateTimestamp']>;
  weight?: Maybe<Scalars['String']>;
};

export type BoardOfDirector = {
  __typename?: 'BoardOfDirector';
  boardOfDirectorId?: Maybe<Scalars['Long']>;
  fullName?: Maybe<Scalars['String']>;
};

export type Branch = {
  __typename?: 'Branch';
  addressRequestUpdate?: Maybe<Array<Maybe<AddressRequestUpdate>>>;
  boardOfDirectors?: Maybe<Array<Maybe<BoardOfDirector>>>;
  branchAddressList: Array<CompanyAddress>;
  branchId?: Maybe<Scalars['Long']>;
  branchNameEn?: Maybe<Scalars['String']>;
  branchNameLocal?: Maybe<Scalars['String']>;
  branchNumber?: Maybe<Scalars['String']>;
  branchStatus?: Maybe<MasterBranchStatus>;
  branchType?: Maybe<MasterBranchType>;
  brochures?: Maybe<Array<Maybe<Brochure>>>;
  categoryList: Array<CompanyCategory>;
  certificates?: Maybe<Array<Maybe<Certificate>>>;
  companyDocumentHistory?: Maybe<Array<Maybe<CompanyDocumentHistory>>>;
  companyDocumentList?: Maybe<Array<Maybe<CompanyDocumentList>>>;
  companyDocumentRequestUpdate?: Maybe<Array<Maybe<CompanyDocumentRequestUpdate>>>;
  companyServiceRequestList: Array<CompanyServiceRequest>;
  contactList: Array<CompanyContact>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTimestamp']>;
  customerReferences?: Maybe<Array<Maybe<CustomerReference>>>;
  decisionMakers?: Maybe<Array<Maybe<DecisionMaker>>>;
  displayNameEN?: Maybe<Scalars['String']>;
  displayNameLocal?: Maybe<Scalars['String']>;
  documentList?: Maybe<Array<Maybe<Document>>>;
  dunsNumber?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<File>>>;
  isPTVNVerified?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Scalars['String']>;
  netProfit?: Maybe<Scalars['String']>;
  orgID?: Maybe<Scalars['String']>;
  paymentTerm?: Maybe<PaymentTermType>;
  shareholdersEquity?: Maybe<Scalars['String']>;
  totalAnnualRevenue?: Maybe<Scalars['String']>;
  totalAssets?: Maybe<Scalars['String']>;
  totalEmployee?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTimestamp']>;
  useDisplayName?: Maybe<Scalars['Boolean']>;
  videos?: Maybe<Array<Maybe<Video>>>;
};

export type Brand = {
  __typename?: 'Brand';
  name?: Maybe<Scalars['String']>;
};

export type Brochure = {
  __typename?: 'Brochure';
  brochureId: Scalars['Long'];
  brochureName: Scalars['String'];
  file: File;
};

export type Category = {
  __typename?: 'Category';
  categoryID: Scalars['Int'];
  categoryLevel?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  parentCategoryID?: Maybe<Scalars['Int']>;
};

export type Certificate = {
  __typename?: 'Certificate';
  certificateById?: Maybe<Scalars['Long']>;
  certificateByOtherName?: Maybe<Scalars['String']>;
  certificateId: Scalars['Long'];
  certificateName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expireDate?: Maybe<Scalars['DateTimestamp']>;
  files?: Maybe<Array<Maybe<File>>>;
  startDate: Scalars['DateTimestamp'];
};

export type Company = {
  __typename?: 'Company';
  branchList: Array<Branch>;
  businessEntity?: Maybe<MasterBusinessEntity>;
  companyId: Scalars['Long'];
  companyNameEn?: Maybe<Scalars['String']>;
  companyNameLocal?: Maybe<Scalars['String']>;
  companyStatus?: Maybe<MasterCompanyStatus>;
  country?: Maybe<MasterCountry>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTimestamp']>;
  identityType?: Maybe<MasterIdentityType>;
  isRDVerify?: Maybe<Scalars['Boolean']>;
  isVatRegistration?: Maybe<Scalars['Boolean']>;
  otherBusinessEntityName?: Maybe<Scalars['String']>;
  registeredCapital?: Maybe<Scalars['BigDecimal']>;
  taxFirstNameEn?: Maybe<Scalars['String']>;
  taxFirstNameLocal?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  taxLastNameEn?: Maybe<Scalars['String']>;
  taxLastNameLocal?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTimestamp']>;
  website?: Maybe<Scalars['String']>;
  yearEstablished?: Maybe<Scalars['String']>;
};

export type CompanyBranchListArgs = {
  branchIDs?: Maybe<Array<Scalars['Long']>>;
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  addressEn?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Long']>;
  addressLocal?: Maybe<Scalars['String']>;
  addressType?: Maybe<MasterAddressType>;
  country?: Maybe<MasterCountry>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTimestamp']>;
  district?: Maybe<Scalars['String']>;
  districtEn?: Maybe<Scalars['String']>;
  districtLocal?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  provinceEn?: Maybe<Scalars['String']>;
  provinceLocal?: Maybe<Scalars['String']>;
  subDistrict?: Maybe<Scalars['String']>;
  subDistrictEn?: Maybe<Scalars['String']>;
  subDistrictLocal?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTimestamp']>;
};

export type CompanyCategory = {
  __typename?: 'CompanyCategory';
  businessType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  categoryLevelId?: Maybe<Scalars['String']>;
};

export type CompanyContact = {
  __typename?: 'CompanyContact';
  contactId?: Maybe<Scalars['Int']>;
  contactPhoneNumberList: Array<CompanyContactPhoneNumber>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstNameEN?: Maybe<Scalars['String']>;
  firstNameLocal?: Maybe<Scalars['String']>;
  fullNameEn?: Maybe<Scalars['String']>;
  fullNameLocal?: Maybe<Scalars['String']>;
  lastNameEN?: Maybe<Scalars['String']>;
  lastNameLocal?: Maybe<Scalars['String']>;
  masterContactType: MasterContactType;
  masterJobTitle?: Maybe<MasterJobTitle>;
  masterTitleName?: Maybe<MasterTitleName>;
  otherJobTitle?: Maybe<Scalars['String']>;
};

export type CompanyContactPhoneNumber = {
  __typename?: 'CompanyContactPhoneNumber';
  callingCode?: Maybe<Scalars['String']>;
  contactPhoneNumberId?: Maybe<Scalars['Int']>;
  ext?: Maybe<Scalars['String']>;
  masterPhoneNumberType?: Maybe<MasterPhoneNumberType>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CompanyDocument = {
  __typename?: 'CompanyDocument';
  documentType?: Maybe<DocumentType>;
  mimeType?: Maybe<Scalars['String']>;
  originalFileName?: Maybe<Scalars['String']>;
  uploadByName?: Maybe<Scalars['String']>;
  uploadByNameEn?: Maybe<Scalars['String']>;
  uploadByNameLocal?: Maybe<Scalars['String']>;
  uploadByUserID?: Maybe<Scalars['Long']>;
  uploadDate?: Maybe<Scalars['DateTimestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

export type CompanyDocumentHistory = {
  __typename?: 'CompanyDocumentHistory';
  CompanyDocumentMappingId?: Maybe<Scalars['Long']>;
  companyDocumentHistoryId?: Maybe<Scalars['Long']>;
  createDate?: Maybe<Scalars['DateTimestamp']>;
  createName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  requestUpdateStatusId?: Maybe<Scalars['Long']>;
  swwReqId?: Maybe<Scalars['Int']>;
};

export type CompanyDocumentList = {
  __typename?: 'CompanyDocumentList';
  documentList?: Maybe<Array<Maybe<DocumentList>>>;
  section?: Maybe<Scalars['String']>;
};

export type CompanyDocumentRequestUpdate = {
  __typename?: 'CompanyDocumentRequestUpdate';
  CompanyDocumentMappingId?: Maybe<Scalars['Long']>;
  OtherName?: Maybe<Scalars['String']>;
  companyDocumentRequestUpdateId?: Maybe<Scalars['Long']>;
  companyDocumentTypeId?: Maybe<Scalars['Long']>;
  files?: Maybe<Array<Maybe<File>>>;
  swwReqId?: Maybe<Scalars['Int']>;
};

export type CompanyInformation = {
  __typename?: 'CompanyInformation';
  branchName?: Maybe<Scalars['String']>;
  branchNameEn?: Maybe<Scalars['String']>;
  branchNameLocal?: Maybe<Scalars['String']>;
  branchNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameEn?: Maybe<Scalars['String']>;
  companyNameLocal?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryNameCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  identityTypeCode?: Maybe<Scalars['String']>;
  isVatRegistration?: Maybe<Scalars['Boolean']>;
  numberOfEmployee?: Maybe<Scalars['String']>;
  otherBusinessEntityName?: Maybe<Scalars['String']>;
  registeredCapital?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  taxFirstName?: Maybe<Scalars['String']>;
  taxFirstNameEn?: Maybe<Scalars['String']>;
  taxFirstNameLocal?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  taxLastName?: Maybe<Scalars['String']>;
  taxLastNameEn?: Maybe<Scalars['String']>;
  taxLastNameLocal?: Maybe<Scalars['String']>;
  taxTitle?: Maybe<MasterBusinessEntity>;
  yearEstablished?: Maybe<Scalars['String']>;
};

export type CompanyInformationBranchNameArgs = {
  lang: LangType;
};

export type CompanyInformationCompanyNameArgs = {
  lang: LangType;
};

export type CompanyInformationTaxFirstNameArgs = {
  lang: LangType;
};

export type CompanyInformationTaxLastNameArgs = {
  lang: LangType;
};

export type CompanyProduct = {
  __typename?: 'CompanyProduct';
  branchID: Scalars['Int'];
  companyID: Scalars['Int'];
  order: Scalars['Int'];
};

export type CompanyProductInput = {
  branchIDList: Array<Scalars['Int']>;
  companyID: Scalars['Int'];
};

export type CompanyRequestUpdateAddressType = {
  __typename?: 'CompanyRequestUpdateAddressType';
  addressEn?: Maybe<Scalars['String']>;
  addressLocal?: Maybe<Scalars['String']>;
  addressTypeId?: Maybe<Scalars['Long']>;
  addressTypeName?: Maybe<Scalars['String']>;
  addressTypeNameCode?: Maybe<Scalars['String']>;
  country?: Maybe<MasterCountry>;
  districtEn?: Maybe<Scalars['String']>;
  districtLocal?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceEn?: Maybe<Scalars['String']>;
  provinceLocal?: Maybe<Scalars['String']>;
  subDistrictEn?: Maybe<Scalars['String']>;
  subDistrictLocal?: Maybe<Scalars['String']>;
};

export type CompanyRequestUpdateDate = {
  dateBy?: Maybe<CompanyRequestUpdateDateBy>;
  endDate?: Maybe<Scalars['Long']>;
  startDate?: Maybe<Scalars['Long']>;
  timeType?: Maybe<TimeRange>;
};

export enum CompanyRequestUpdateDateBy {
  ApprovedDate = 'APPROVED_DATE',
  RequestDate = 'REQUEST_DATE',
}

export type CompanyRequestUpdateDetail = {
  __typename?: 'CompanyRequestUpdateDetail';
  address?: Maybe<Array<Maybe<CompanyRequestUpdateAddressType>>>;
  adminPO?: Maybe<AdminPoUserInformation>;
  approvedByEmail?: Maybe<Scalars['String']>;
  approvedByName?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTimestamp']>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  becomeABuyer?: Maybe<BecomeABuyer>;
  billingContact?: Maybe<Array<Maybe<CompanyContact>>>;
  companyContact?: Maybe<Array<Maybe<CompanyContact>>>;
  companyInformation?: Maybe<CompanyInformation>;
  invitationCode?: Maybe<Scalars['String']>;
  productKeyword?: Maybe<Array<Maybe<Scalars['String']>>>;
  productServiceCategory?: Maybe<Array<Maybe<CompanyCategory>>>;
  refRequestNumber?: Maybe<Scalars['Int']>;
  refSAFRequestNumber?: Maybe<Scalars['String']>;
  remarkList?: Maybe<Array<Maybe<RemarkItem>>>;
  requestID?: Maybe<Scalars['Int']>;
  requestStatus?: Maybe<Scalars['String']>;
  safRequestNumber?: Maybe<Scalars['String']>;
  safStatus?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CompanyRequestUpdateFilter = {
  date?: Maybe<CompanyRequestUpdateDate>;
  requestStatus?: Maybe<Array<Maybe<CompanyRequestUpdateRequestStatus>>>;
};

export type CompanyRequestUpdateList = {
  __typename?: 'CompanyRequestUpdateList';
  items: Array<Maybe<CompanyRequestUpdateListItem>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalItem: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type CompanyRequestUpdateListInput = {
  filterBy?: Maybe<CompanyRequestUpdateFilter>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  searches?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<CompanyRequestUpdateSortByType>;
  sortDir?: Maybe<SortDirType>;
  sortNameBy?: Maybe<LangType>;
};

export type CompanyRequestUpdateListItem = {
  __typename?: 'CompanyRequestUpdateListItem';
  approvedByEmail?: Maybe<Scalars['String']>;
  approvedByName?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTimestamp']>;
  branchName?: Maybe<Scalars['String']>;
  branchNameEn?: Maybe<Scalars['String']>;
  branchNameLocal?: Maybe<Scalars['String']>;
  branchNumber?: Maybe<Scalars['String']>;
  businessEntity?: Maybe<MasterBusinessEntity>;
  companyName?: Maybe<Scalars['String']>;
  companyNameEn?: Maybe<Scalars['String']>;
  companyNameLocal?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryNameCode?: Maybe<Scalars['String']>;
  identityType?: Maybe<Scalars['String']>;
  orgID?: Maybe<Scalars['String']>;
  otherBusinessEntityName?: Maybe<Scalars['String']>;
  remarkList?: Maybe<Array<Maybe<RemarkItem>>>;
  requestByEmail?: Maybe<Scalars['String']>;
  requestByName?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['DateTimestamp']>;
  requestId?: Maybe<Scalars['Int']>;
  requestStatus?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<Scalars['String']>>>;
  taxID?: Maybe<Scalars['String']>;
};

export type CompanyRequestUpdateListItemBranchNameArgs = {
  lang: LangType;
};

export type CompanyRequestUpdateListItemCompanyNameArgs = {
  lang: LangType;
};

export enum CompanyRequestUpdateRequestStatus {
  Approved = 'APPROVED',
  Awaiting = 'AWAITING',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
}

export enum CompanyRequestUpdateSortByType {
  CompanyName = 'COMPANY_NAME',
  RequestDate = 'REQUEST_DATE',
}

export type CompanyServiceRequest = {
  __typename?: 'CompanyServiceRequest';
  addedBy?: Maybe<Scalars['String']>;
  addedDate?: Maybe<Scalars['DateTimestamp']>;
  adminPOUserID?: Maybe<Scalars['Int']>;
  adoptionReqId?: Maybe<Scalars['Int']>;
  documentList: Array<CompanyDocument>;
  masterInvitationCode?: Maybe<MasterInvitationCode>;
  masterServiceType?: Maybe<MasterServiceType>;
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTimestamp']>;
  remark?: Maybe<Scalars['String']>;
  serviceStatus?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  currencyCode?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['Int']>;
};

export type CurrencySetting = {
  __typename?: 'CurrencySetting';
  active?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['String']>;
};

export type CustomerReference = {
  __typename?: 'CustomerReference';
  countryId?: Maybe<Scalars['Long']>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<File>>>;
  productSupplyToCustomer?: Maybe<Scalars['String']>;
  projectOrCustomerName?: Maybe<Scalars['String']>;
};

export type DecisionMaker = {
  __typename?: 'DecisionMaker';
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DefaultSetting = {
  __typename?: 'DefaultSetting';
  active?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  countryType?: Maybe<Scalars['String']>;
  documentGroupName?: Maybe<Scalars['String']>;
  documentTypeId?: Maybe<Scalars['Long']>;
  documentTypeName?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<File>>>;
  identityTypeId?: Maybe<Scalars['Long']>;
  isVATRegistration?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  otherDocumentName?: Maybe<Scalars['String']>;
};

export type DocumentList = {
  __typename?: 'DocumentList';
  companyDocumentType?: Maybe<MasterCompanyDocumentType>;
  documentTypeId?: Maybe<Scalars['Int']>;
  documentTypeName?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<File>>>;
  otherDocumentName?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<Scalars['String']>;
};

export type DocumentRequest = {
  __typename?: 'DocumentRequest';
  createdAt: Scalars['DateTimestamp'];
  detail?: Maybe<Scalars['String']>;
  documentRequire: Scalars['String'];
  supplierSubscriptionID: Scalars['Int'];
  userRequest: Scalars['String'];
};

export type DocumentType = {
  __typename?: 'DocumentType';
  documentTypeId?: Maybe<Scalars['Int']>;
  masterInvitationCode?: Maybe<MasterInvitationCode>;
  nameEn?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  currencyCode: Scalars['String'];
  rateTHB: Scalars['BigDecimal'];
};

export type File = {
  __typename?: 'File';
  fileStatus?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
  fileUrl?: Maybe<Scalars['String']>;
  isDisplay?: Maybe<Scalars['Boolean']>;
  originalFileName: Scalars['String'];
  seq?: Maybe<Scalars['Long']>;
  uploadByName?: Maybe<Scalars['String']>;
  uploadByUserID?: Maybe<Scalars['Long']>;
  uploadDate?: Maybe<Scalars['DateTimestamp']>;
  uuid: Scalars['String'];
};

export type HammerAndRankingSetting = {
  __typename?: 'HammerAndRankingSetting';
  active?: Maybe<Scalars['Boolean']>;
  hideOnLastMinute?: Maybe<Scalars['Boolean']>;
  hideOnlyRanking?: Maybe<Scalars['Boolean']>;
  hideWhenNotReachReservePrice?: Maybe<Scalars['Boolean']>;
  lastMinutes?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type HistorySetting = {
  __typename?: 'HistorySetting';
  active?: Maybe<Scalars['Boolean']>;
  bidAmount?: Maybe<Scalars['Boolean']>;
  includeOtherAs?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type ItemBidder = {
  __typename?: 'ItemBidder';
  bidderDisplayName?: Maybe<Scalars['String']>;
  bidderEmail?: Maybe<Scalars['String']>;
  bidderFullName?: Maybe<Scalars['String']>;
  bidderOrganizationID?: Maybe<Scalars['Int']>;
  bidderOrganizationNameEN?: Maybe<Scalars['String']>;
  bidderOrganizationNameLocal?: Maybe<Scalars['String']>;
  bidderUserID?: Maybe<Scalars['Int']>;
  itemID?: Maybe<Scalars['String']>;
};

export type ItemSettings = {
  __typename?: 'ItemSettings';
  bidConditionSetting?: Maybe<BidConditionSetting>;
  multipleCurrencySetting?: Maybe<MultipleCurrencySetting>;
  showLeadingBidSetting?: Maybe<DefaultSetting>;
  showOpeningPriceSetting?: Maybe<DefaultSetting>;
  showQuantitySetting?: Maybe<DefaultSetting>;
};

export type Job = {
  __typename?: 'Job';
  allowConversation?: Maybe<Scalars['Boolean']>;
  canOfferSimilarItem?: Maybe<Scalars['Boolean']>;
  categoryDescriptionEN?: Maybe<Scalars['String']>;
  categoryDescriptionLocal?: Maybe<Scalars['String']>;
  categoryID?: Maybe<Scalars['Int']>;
  closeDate?: Maybe<Scalars['DateTimestamp']>;
  companyID: Scalars['Int'];
  createdAt: Scalars['DateTimestamp'];
  createdBy: Scalars['String'];
  createdByUserID: Scalars['Long'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  eRFxNo?: Maybe<Scalars['Int']>;
  eRFxStatus?: Maybe<Scalars['String']>;
  estimatedProjectValueID?: Maybe<Scalars['Int']>;
  estimatedProjectValueMax?: Maybe<Scalars['BigDecimal']>;
  estimatedProjectValueMin?: Maybe<Scalars['BigDecimal']>;
  expireDate: Scalars['DateTimestamp'];
  includeVat?: Maybe<Scalars['Boolean']>;
  jobAttachmentList?: Maybe<Array<Maybe<JobAttachment>>>;
  jobBrandList?: Maybe<Array<Maybe<Brand>>>;
  jobID: Scalars['Int'];
  jobItemList?: Maybe<Array<Maybe<JobItem>>>;
  jobKeyWordList?: Maybe<Array<Maybe<JobKeyWord>>>;
  jobResponseList?: Maybe<Array<Maybe<JobResponse>>>;
  jobShippingAreaList?: Maybe<Array<Maybe<ShippingArea>>>;
  jobType: Scalars['String'];
  jobViewList?: Maybe<Array<Maybe<JobView>>>;
  lastUpdateDate?: Maybe<Scalars['DateTimestamp']>;
  openDate: Scalars['DateTimestamp'];
  postStatus?: Maybe<Scalars['String']>;
  requireQuotation?: Maybe<Scalars['Boolean']>;
  shortlistStatus?: Maybe<Scalars['String']>;
  subCompanyAddress?: Maybe<Scalars['String']>;
  subCompanyID?: Maybe<Scalars['Int']>;
  subCompanyNameEN: Scalars['String'];
  subCompanyNameLocal: Scalars['String'];
  subCompanyPictureURL?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalView?: Maybe<Scalars['Int']>;
};

export type JobAttachment = {
  __typename?: 'JobAttachment';
  fileExtension?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
};

export type JobItem = {
  __typename?: 'JobItem';
  canOfferSimilarItem?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  eRFxItemNo?: Maybe<Scalars['Int']>;
  jobItemBrandList?: Maybe<Array<Maybe<Brand>>>;
  jobItemID?: Maybe<Scalars['Int']>;
  materialNo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['BigDecimal']>;
  unit?: Maybe<Scalars['String']>;
};

export type JobKeyWord = {
  __typename?: 'JobKeyWord';
  keyword?: Maybe<Scalars['String']>;
};

export type JobResponse = {
  __typename?: 'JobResponse';
  acceptCondition?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['BigDecimal']>;
  branchID?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jobResponseAttachmentList?: Maybe<Array<Maybe<JobResponseAttachment>>>;
  jobResponseID?: Maybe<Scalars['Int']>;
  jobResponseItemList?: Maybe<Array<Maybe<JobResponseItem>>>;
  questionnaireScore?: Maybe<Scalars['Float']>;
  respondedBy?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['Int']>;
  userID?: Maybe<Scalars['Long']>;
  userProviderID?: Maybe<Scalars['String']>;
};

export type JobResponseAttachment = {
  __typename?: 'JobResponseAttachment';
  fileExtension?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
};

export type JobResponseItem = {
  __typename?: 'JobResponseItem';
  jobResponseItemPK?: Maybe<JobResponseItemPk>;
  quote?: Maybe<Scalars['BigDecimal']>;
  reason?: Maybe<Scalars['String']>;
};

export type JobResponseItemPk = {
  __typename?: 'JobResponseItemPK';
  jobItemID?: Maybe<Scalars['Int']>;
  jobResponseID?: Maybe<Scalars['Int']>;
};

export type JobShippingArea = {
  __typename?: 'JobShippingArea';
  masterShippingArea?: Maybe<ShippingArea>;
  shippingAreaID?: Maybe<Scalars['Int']>;
};

export type JobView = {
  __typename?: 'JobView';
  createdAt?: Maybe<Scalars['DateTimestamp']>;
  jobViewPK?: Maybe<JobViewPk>;
  userFullName?: Maybe<Scalars['String']>;
  userProviderID?: Maybe<Scalars['String']>;
};

export type JobViewPk = {
  __typename?: 'JobViewPK';
  branchID?: Maybe<Scalars['Int']>;
  supplierID?: Maybe<Scalars['Int']>;
  userID?: Maybe<Scalars['Long']>;
};

export enum LangType {
  En = 'EN',
  Local = 'LOCAL',
}

export type MasterAddressType = {
  __typename?: 'MasterAddressType';
  description?: Maybe<Scalars['String']>;
  masterAddressTypeId?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
};

export type MasterBank = {
  __typename?: 'MasterBank';
  iconUrl: Scalars['String'];
  masterBankID: Scalars['String'];
  nameEN: Scalars['String'];
  nameTH: Scalars['String'];
  shortName: Scalars['String'];
};

export type MasterBranchStatus = {
  __typename?: 'MasterBranchStatus';
  description?: Maybe<Scalars['String']>;
  masterBranchStatusId?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
};

export type MasterBranchType = {
  __typename?: 'MasterBranchType';
  masterBranchTypeId?: Maybe<Scalars['Long']>;
  masterBranchTypeName?: Maybe<Scalars['String']>;
};

export type MasterBusinessEntity = {
  __typename?: 'MasterBusinessEntity';
  businessEntityCode?: Maybe<Scalars['String']>;
  businessEntityName?: Maybe<Scalars['String']>;
  identityType?: Maybe<MasterIdentityType>;
  masterBusinessEntityId?: Maybe<Scalars['Long']>;
};

export type MasterCategory = {
  __typename?: 'MasterCategory';
  categoryID: Scalars['Int'];
  categoryLevel?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  parentCategoryID?: Maybe<Scalars['Int']>;
  swwCategoryID?: Maybe<Scalars['Int']>;
};

export type MasterCompanyDocumentType = {
  __typename?: 'MasterCompanyDocumentType';
  countryType?: Maybe<Scalars['String']>;
  documentGroupName?: Maybe<Scalars['String']>;
  documentTypeId?: Maybe<Scalars['Long']>;
  documentTypeName?: Maybe<Scalars['String']>;
  isPISApprove?: Maybe<Scalars['Boolean']>;
  isVATRegistration?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  swwDocumentNameId?: Maybe<Scalars['Int']>;
};

export type MasterCompanyStatus = {
  __typename?: 'MasterCompanyStatus';
  description?: Maybe<Scalars['String']>;
  masterCompanyStatusId?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
};

export type MasterContactType = {
  __typename?: 'MasterContactType';
  description?: Maybe<Scalars['String']>;
  masterContactTypeId?: Maybe<Scalars['Short']>;
  name?: Maybe<Scalars['String']>;
};

export type MasterCountry = {
  __typename?: 'MasterCountry';
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  countryNameCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  isEnglishOfficial?: Maybe<Scalars['String']>;
  masterCountryId?: Maybe<Scalars['Long']>;
  phoneCode?: Maybe<Scalars['String']>;
};

export type MasterIdentityType = {
  __typename?: 'MasterIdentityType';
  description?: Maybe<Scalars['String']>;
  masterIdentityTypeId?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
};

export type MasterInvitationCode = {
  __typename?: 'MasterInvitationCode';
  invitationCode?: Maybe<Scalars['String']>;
  masterInvitationCodeId?: Maybe<Scalars['Short']>;
};

export type MasterJobTitle = {
  __typename?: 'MasterJobTitle';
  jobTitleCode?: Maybe<Scalars['String']>;
  jobTitleName?: Maybe<Scalars['String']>;
  masterJobTitleId?: Maybe<Scalars['Long']>;
};

export type MasterPhoneNumberType = {
  __typename?: 'MasterPhoneNumberType';
  masterPhoneNumberTypeId?: Maybe<Scalars['Long']>;
  phoneNumberType?: Maybe<Scalars['String']>;
};

export type MasterPromotionType = {
  __typename?: 'MasterPromotionType';
  name: Scalars['String'];
  promotionTypeID: Scalars['Int'];
};

export type MasterServiceType = {
  __typename?: 'MasterServiceType';
  description?: Maybe<Scalars['String']>;
  masterServiceTypeId?: Maybe<Scalars['Short']>;
  name?: Maybe<Scalars['String']>;
};

export type MasterTitleName = {
  __typename?: 'MasterTitleName';
  masterTitleNameId?: Maybe<Scalars['Long']>;
  nameTitleEN?: Maybe<Scalars['String']>;
  nameTitleLocal?: Maybe<Scalars['String']>;
};

export type MultipleCurrencySetting = {
  __typename?: 'MultipleCurrencySetting';
  active?: Maybe<Scalars['Boolean']>;
  currencies?: Maybe<Array<Maybe<CurrencySetting>>>;
  value?: Maybe<Scalars['String']>;
};

export type MultipleUnitPrice = {
  __typename?: 'MultipleUnitPrice';
  thb?: Maybe<Scalars['String']>;
  usd?: Maybe<Scalars['String']>;
  vnd?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  upsertProduct?: Maybe<Product>;
  upsertPromotion?: Maybe<Promotion>;
};

export type MutationUpsertProductArgs = {
  input: UpsertProductInput;
};

export type MutationUpsertPromotionArgs = {
  input: UpsertPromotionInput;
};

export type OriginatorMonitorAuctionItemDetail = {
  __typename?: 'OriginatorMonitorAuctionItemDetail';
  advanceBid?: Maybe<Scalars['Boolean']>;
  auctionType?: Maybe<Scalars['String']>;
  bidDirection?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  itemID?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  itemOpeningPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  totalBids?: Maybe<Scalars['Int']>;
};

export type PaymentTerm = {
  __typename?: 'PaymentTerm';
  paymentID: Scalars['Int'];
  paymentName: Scalars['String'];
};

export type PaymentTermType = {
  __typename?: 'PaymentTermType';
  masterPaymentTermID?: Maybe<Scalars['Long']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentTermCode?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  brand?: Maybe<Scalars['String']>;
  companyProductList: Array<CompanyProduct>;
  createByName?: Maybe<Scalars['String']>;
  createByUserID?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTimestamp']>;
  draft: Scalars['Boolean'];
  isRequiredImproveAttribute: Scalars['Boolean'];
  nameEn?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  paymentTerm?: Maybe<PaymentTerm>;
  productAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  productCategory: Array<Category>;
  productDescription?: Maybe<ProductDescription>;
  productDescriptionFileList: Array<ProductDescriptionFile>;
  productFileList: Array<ProductFile>;
  productHistoryList: Array<ProductHistory>;
  productID: Scalars['Int'];
  productKeywords: Array<Scalars['String']>;
  productOption?: Maybe<ProductOption>;
  productSize?: Maybe<ProductSize>;
  productStatus: Scalars['String'];
  productUOM?: Maybe<UnitOfMeasurement>;
  productWeight?: Maybe<ProductWeight>;
  promotion?: Maybe<ProductPromotion>;
  promotionProductItemList?: Maybe<Array<Maybe<PromotionProductItem>>>;
  runningNumber?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  updateByName?: Maybe<Scalars['String']>;
  updateByUserID?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['DateTimestamp']>;
};

export type ProductPromotionArgs = {
  date?: Maybe<Scalars['DateTimestamp']>;
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  attributeID: Scalars['Int'];
  attributeName: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  verifiedValue?: Maybe<Scalars['String']>;
};

export type ProductAttributeInput = {
  attributeID?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductContentInput = {
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ProductImageInput>>>;
};

export type ProductDescription = {
  __typename?: 'ProductDescription';
  description?: Maybe<Scalars['String']>;
};

export type ProductDescriptionFile = {
  __typename?: 'ProductDescriptionFile';
  extension: Scalars['String'];
  uuid: Scalars['String'];
};

export type ProductDescriptionInput = {
  productAttributes?: Maybe<Array<Maybe<ProductAttributeInput>>>;
  productContent?: Maybe<ProductContentInput>;
  productImages?: Maybe<Array<Maybe<ProductImageInput>>>;
};

export type ProductFile = {
  __typename?: 'ProductFile';
  extension: Scalars['String'];
  fileUrl: Scalars['String'];
  order: Scalars['Int'];
  originalFileName: Scalars['String'];
  originalFileUrl: Scalars['String'];
  uploadByName: Scalars['String'];
  uploadByUserID: Scalars['Int'];
  uploadDate: Scalars['DateTimestamp'];
  uuid: Scalars['String'];
};

export type ProductHistory = {
  __typename?: 'ProductHistory';
  action: Scalars['String'];
  productHistoryID: Scalars['Int'];
  updateByName: Scalars['String'];
  updateByUserID: Scalars['Int'];
  updateDate: Scalars['DateTimestamp'];
};

export type ProductImageInput = {
  extension?: Maybe<Scalars['String']>;
  originalFileName?: Maybe<Scalars['String']>;
  uploadByUserID?: Maybe<Scalars['Int']>;
  uploadByUserName?: Maybe<Scalars['String']>;
  uploadDate?: Maybe<Scalars['Long']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductInformationInput = {
  brand?: Maybe<Scalars['String']>;
  categoryID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height?: Maybe<Scalars['BigDecimal']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  length?: Maybe<Scalars['BigDecimal']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  paymentID?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  productUOMID?: Maybe<Scalars['String']>;
  sizeUOMID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['BigDecimal']>;
  weightUOMID?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['BigDecimal']>;
};

export type ProductListCategory = {
  __typename?: 'ProductListCategory';
  categoryID?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
};

export type ProductListCategoryNameArgs = {
  lang: LangType;
};

export type ProductListCompany = {
  __typename?: 'ProductListCompany';
  branch?: Maybe<Array<Maybe<ProductListCompanyBranch>>>;
  companyID?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
};

export type ProductListCompanyBranch = {
  __typename?: 'ProductListCompanyBranch';
  branchID?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type ProductListExcludeInput = {
  productID?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ProductListFilterInput = {
  productID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productPricingType?: Maybe<Array<Maybe<ProductListFilterPricingType>>>;
  productStatus?: Maybe<Array<Maybe<ProductListFilterStatusType>>>;
  uom?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ProductListFilterPricingType {
  AdjustableProductPrice = 'ADJUSTABLE_PRODUCT_PRICE',
  NonPriceProduct = 'NON_PRICE_PRODUCT',
}

export enum ProductListFilterStatusType {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
}

export type ProductListInput = {
  branchIDs?: Maybe<Array<Maybe<Scalars['Int']>>>;
  companyID: Scalars['Int'];
  excludeBy?: Maybe<ProductListExcludeInput>;
  filterBy?: Maybe<ProductListFilterInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searches?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy: ProductListSortByType;
  sortDir: SortDirType;
  sortNameBy?: Maybe<LangType>;
};

export type ProductListItem = {
  __typename?: 'ProductListItem';
  attributePercent?: Maybe<Scalars['BigDecimal']>;
  category?: Maybe<Array<Maybe<ProductListCategory>>>;
  company?: Maybe<ProductListCompany>;
  isNoAttribute?: Maybe<Scalars['Boolean']>;
  isRequiredImproveAttribute?: Maybe<Scalars['Boolean']>;
  lastUpdate?: Maybe<Scalars['DateTimestamp']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['Int']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productOption?: Maybe<ProductOption>;
  productUOM?: Maybe<ProductListUom>;
  promotion?: Maybe<Array<Maybe<Promotion>>>;
  promotionOverlap?: Maybe<Array<Maybe<Promotion>>>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ProductListItemNameArgs = {
  lang: LangType;
};

export type ProductListItemPromotionOverlapArgs = {
  endDate?: Maybe<Scalars['Long']>;
  promotionID?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Long']>;
};

export enum ProductListSortByType {
  LastUpdated = 'LAST_UPDATED',
  ProductName = 'PRODUCT_NAME',
  Sku = 'SKU',
}

export type ProductListUom = {
  __typename?: 'ProductListUOM';
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
};

export type ProductListUomNameArgs = {
  lang: LangType;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  currency?: Maybe<Currency>;
  maxQuantity?: Maybe<Scalars['Long']>;
  minQuantity?: Maybe<Scalars['Long']>;
  multipleUnitPrice?: Maybe<MultipleUnitPrice>;
  pricingType: Scalars['String'];
  productRefNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['BigDecimal']>;
  vat?: Maybe<Scalars['BigDecimal']>;
};

export type ProductOptionInput = {
  currencyCode?: Maybe<Scalars['String']>;
  maxQuantity?: Maybe<Scalars['Long']>;
  minQuantity?: Maybe<Scalars['Long']>;
  pricingType?: Maybe<Scalars['String']>;
  productRefNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['BigDecimal']>;
  vat?: Maybe<Scalars['BigDecimal']>;
};

export type ProductPromotion = {
  __typename?: 'ProductPromotion';
  bannerUrlEN?: Maybe<Scalars['String']>;
  bannerUrlLocal?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionLocal?: Maybe<Scalars['String']>;
  discountPercent?: Maybe<Scalars['Int']>;
  discountPrice?: Maybe<MultipleUnitPrice>;
  discountType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTimestamp']>;
  promotionID?: Maybe<Scalars['Int']>;
  promotionNameEN?: Maybe<Scalars['String']>;
  promotionNameLocal?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTimestamp']>;
};

export type ProductSize = {
  __typename?: 'ProductSize';
  height?: Maybe<Scalars['BigDecimal']>;
  length?: Maybe<Scalars['BigDecimal']>;
  uom?: Maybe<UnitOfMeasurement>;
  width?: Maybe<Scalars['BigDecimal']>;
};

export type ProductWeight = {
  __typename?: 'ProductWeight';
  uom?: Maybe<UnitOfMeasurement>;
  weight?: Maybe<Scalars['BigDecimal']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  createByName?: Maybe<Scalars['String']>;
  createByUserID?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTimestamp']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionLocal?: Maybe<Scalars['String']>;
  discountDetail?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTimestamp']>;
  noEndDate?: Maybe<Scalars['Boolean']>;
  promotionID?: Maybe<Scalars['Int']>;
  promotionNameEN?: Maybe<Scalars['String']>;
  promotionNameLocal?: Maybe<Scalars['String']>;
  promotionStatus?: Maybe<Scalars['String']>;
  promotionTypeID?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTimestamp']>;
  status?: Maybe<Scalars['String']>;
  updateByName?: Maybe<Scalars['String']>;
  updateByUserID?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['DateTimestamp']>;
};

export type PromotionDetail = {
  __typename?: 'PromotionDetail';
  createByName?: Maybe<Scalars['String']>;
  createByUserID?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTimestamp']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionLocal?: Maybe<Scalars['String']>;
  discountDetail?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTimestamp']>;
  noEndDate?: Maybe<Scalars['Boolean']>;
  promotionFileList: Array<PromotionFile>;
  promotionHistoryList: Array<PromotionHistory>;
  promotionID?: Maybe<Scalars['Int']>;
  promotionNameEN?: Maybe<Scalars['String']>;
  promotionNameLocal?: Maybe<Scalars['String']>;
  promotionProductItemList?: Maybe<PromotionProductItemList>;
  promotionStatus?: Maybe<Scalars['String']>;
  promotionType: MasterPromotionType;
  remark?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTimestamp']>;
  status?: Maybe<Scalars['String']>;
  updateByName?: Maybe<Scalars['String']>;
  updateByUserID?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['DateTimestamp']>;
};

export type PromotionDetailPromotionProductItemListArgs = {
  input?: Maybe<PromotionProductItemListInput>;
};

export type PromotionFile = {
  __typename?: 'PromotionFile';
  extension: Scalars['String'];
  fileUrl: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  originalFileName: Scalars['String'];
  originalFileUrl: Scalars['String'];
  promotionFileID?: Maybe<Scalars['Int']>;
  promotionFileType?: Maybe<Scalars['String']>;
  uploadByName: Scalars['String'];
  uploadByUserID: Scalars['Int'];
  uploadDate: Scalars['DateTimestamp'];
  uuid: Scalars['String'];
};

export type PromotionHistory = {
  __typename?: 'PromotionHistory';
  action: Scalars['String'];
  promotionHistoryID: Scalars['Int'];
  updateByName: Scalars['String'];
  updateByUserID: Scalars['Int'];
  updateDate: Scalars['DateTimestamp'];
};

export type PromotionImageInput = {
  language: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  originalFileName: Scalars['String'];
  promotionFileType?: Maybe<Scalars['String']>;
  uploadByUserID: Scalars['Int'];
  uploadByUserName: Scalars['String'];
  uploadDate: Scalars['Long'];
  uuid: Scalars['String'];
};

export type PromotionListFilterInput = {
  promotionStatus?: Maybe<Array<Maybe<PromotionListFilterStatusType>>>;
};

export enum PromotionListFilterStatusType {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Ongoing = 'ONGOING',
  Upcoming = 'UPCOMING',
}

export type PromotionListInput = {
  branchIDs?: Maybe<Array<Maybe<Scalars['Int']>>>;
  companyID: Scalars['Int'];
  filterBy?: Maybe<PromotionListFilterInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searches?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy: PromotionListSortByType;
  sortDir: SortDirType;
  sortNameBy?: Maybe<LangType>;
};

export type PromotionListItem = {
  __typename?: 'PromotionListItem';
  company?: Maybe<ProductListCompany>;
  createBy?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['DateTimestamp']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionLocal?: Maybe<Scalars['String']>;
  discountDetail?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTimestamp'];
  lastUpdate?: Maybe<Scalars['DateTimestamp']>;
  lastUpdateBy?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  product?: Maybe<Array<Maybe<PromotionListProduct>>>;
  promotionID?: Maybe<Scalars['Int']>;
  promotionStatus?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTimestamp'];
  status?: Maybe<Scalars['String']>;
};

export type PromotionListItemNameArgs = {
  lang: LangType;
};

export type PromotionListProduct = {
  __typename?: 'PromotionListProduct';
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['Int']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productUOM?: Maybe<ProductListUom>;
  sku?: Maybe<Scalars['String']>;
};

export type PromotionListProductNameArgs = {
  lang: LangType;
};

export enum PromotionListSortByType {
  EndDate = 'END_DATE',
  PromotionName = 'PROMOTION_NAME',
  StartDate = 'START_DATE',
}

export type PromotionProductInput = {
  discountValue?: Maybe<Scalars['String']>;
  productID: Scalars['Int'];
};

export type PromotionProductItem = {
  __typename?: 'PromotionProductItem';
  product?: Maybe<Product>;
  promotion?: Maybe<PromotionDetail>;
  promotionOverlap?: Maybe<Array<Maybe<PromotionDetail>>>;
  promotionProductItemID: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type PromotionProductItemList = {
  __typename?: 'PromotionProductItemList';
  items?: Maybe<Array<Maybe<PromotionProductItem>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalItem: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PromotionProductItemListInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searches?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy: PromotionProductItemSortByType;
  sortDir: SortDirType;
  sortNameBy?: Maybe<LangType>;
};

export enum PromotionProductItemSortByType {
  ProductName = 'PRODUCT_NAME',
}

export type Provider = {
  __typename?: 'Provider';
  providerGroup: Scalars['String'];
  providerID: Scalars['Int'];
  providerKey: Scalars['String'];
  tenantID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  uaaIdp?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  auctionEvent?: Maybe<AuctionEvent>;
  auctionItemBidderList?: Maybe<Array<Maybe<ItemBidder>>>;
  auctionItemDetail?: Maybe<AuctionItemDetail>;
  bankList?: Maybe<Array<Maybe<MasterBank>>>;
  category?: Maybe<MasterCategory>;
  categoryList?: Maybe<Array<Maybe<MasterCategory>>>;
  company?: Maybe<Company>;
  companyRequestUpdateDetail?: Maybe<CompanyRequestUpdateDetail>;
  companyRequestUpdateList?: Maybe<CompanyRequestUpdateList>;
  currencyList?: Maybe<Array<Maybe<ExchangeRate>>>;
  documentRequest?: Maybe<DocumentRequest>;
  job?: Maybe<Job>;
  originatorMonitorAuctionItemDetail?: Maybe<OriginatorMonitorAuctionItemDetail>;
  product?: Maybe<Product>;
  productList?: Maybe<Array<Maybe<Product>>>;
  promotion?: Maybe<PromotionDetail>;
  provider?: Maybe<Provider>;
  supplierProductList?: Maybe<SupplierProductList>;
  supplierPromotionList?: Maybe<SupplierPromotionList>;
  supplierSubscription?: Maybe<SupplierSubscription>;
  unitOfMeasurementList?: Maybe<Array<Maybe<UnitOfMeasurement>>>;
  userInformation?: Maybe<UserInformation>;
  userInformationByUsername?: Maybe<UserInformation>;
  userInformationList?: Maybe<Array<Maybe<UserInformation>>>;
  userInformationListByUsername?: Maybe<Array<Maybe<UserInformation>>>;
  userResetPassword?: Maybe<UserResetPassword>;
};

export type QueryAuctionEventArgs = {
  auctionID: Scalars['Int'];
};

export type QueryAuctionItemBidderListArgs = {
  itemIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryAuctionItemDetailArgs = {
  itemID: Scalars['String'];
};

export type QueryBankListArgs = {
  masterBankIDs?: Maybe<Array<Scalars['String']>>;
};

export type QueryCategoryArgs = {
  categoryID: Scalars['Int'];
};

export type QueryCategoryListArgs = {
  categoryIDList?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type QueryCompanyArgs = {
  companyID: Scalars['Long'];
};

export type QueryCompanyRequestUpdateDetailArgs = {
  requestID: Scalars['Int'];
};

export type QueryCompanyRequestUpdateListArgs = {
  input: CompanyRequestUpdateListInput;
};

export type QueryCurrencyListArgs = {
  currencyCode?: Maybe<Array<Scalars['String']>>;
};

export type QueryDocumentRequestArgs = {
  supplierSubscriptionID: Scalars['Int'];
};

export type QueryJobArgs = {
  jobID: Scalars['Int'];
};

export type QueryOriginatorMonitorAuctionItemDetailArgs = {
  auctionID: Scalars['Int'];
  itemID: Scalars['String'];
};

export type QueryProductArgs = {
  productID: Scalars['Int'];
};

export type QueryProductListArgs = {
  productIDList: Array<Maybe<Scalars['Int']>>;
};

export type QueryPromotionArgs = {
  promotionID: Scalars['Int'];
};

export type QueryProviderArgs = {
  providerID?: Maybe<Scalars['Int']>;
  tenantID?: Maybe<Scalars['String']>;
};

export type QuerySupplierProductListArgs = {
  input: ProductListInput;
};

export type QuerySupplierPromotionListArgs = {
  input: PromotionListInput;
};

export type QuerySupplierSubscriptionArgs = {
  supplierSubscriptionID: Scalars['Int'];
};

export type QueryUnitOfMeasurementListArgs = {
  scUOM?: Maybe<Scalars['Boolean']>;
  specificType?: Maybe<Scalars['String']>;
};

export type QueryUserInformationArgs = {
  userID?: Maybe<Scalars['Int']>;
};

export type QueryUserInformationByUsernameArgs = {
  username: Scalars['String'];
};

export type QueryUserInformationListArgs = {
  userIDs?: Maybe<Array<Maybe<Scalars['Long']>>>;
};

export type QueryUserInformationListByUsernameArgs = {
  usernameList: Array<Scalars['String']>;
};

export type QueryUserResetPasswordArgs = {
  userID: Scalars['Int'];
};

export type RemarkItem = {
  __typename?: 'RemarkItem';
  remark?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type ShippingArea = {
  __typename?: 'ShippingArea';
  countryCode?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionTH?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shippingAreaID?: Maybe<Scalars['Int']>;
};

export enum SortDirType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SupplierProductList = {
  __typename?: 'SupplierProductList';
  isRequiredImproveAttribute: Scalars['Boolean'];
  items: Array<Maybe<ProductListItem>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalItem: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type SupplierPromotionList = {
  __typename?: 'SupplierPromotionList';
  items: Array<Maybe<PromotionListItem>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalItem: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type SupplierSubscription = {
  __typename?: 'SupplierSubscription';
  analysisReport: Scalars['Boolean'];
  availableQuote?: Maybe<Scalars['Int']>;
  companyID: Scalars['Int'];
  createdAt: Scalars['DateTimestamp'];
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTimestamp']>;
  fullName?: Maybe<Scalars['String']>;
  maxJobValue?: Maybe<Scalars['Int']>;
  paymentStatus?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  productShowCase: Scalars['Int'];
  profileListing: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTimestamp']>;
  subscriptionPlanPrice?: Maybe<Scalars['BigDecimal']>;
  subscriptionPlanPriceDiscount?: Maybe<Scalars['BigDecimal']>;
  subscriptionPlanType?: Maybe<Scalars['String']>;
  subscriptionStatus: Scalars['String'];
  supplierSubscriptionID: Scalars['Int'];
  supplierSubscriptionPlan: SupplierSubscriptionPlan;
  title: Scalars['String'];
  totalPrice: Scalars['BigDecimal'];
  updatedAt: Scalars['DateTimestamp'];
  updatedBy?: Maybe<Scalars['String']>;
  userAccount?: Maybe<Scalars['Int']>;
};

export type SupplierSubscriptionPlan = {
  __typename?: 'SupplierSubscriptionPlan';
  analysisReport: Scalars['Boolean'];
  availableQuote?: Maybe<Scalars['Int']>;
  maxJobValue?: Maybe<Scalars['Int']>;
  price: Scalars['BigDecimal'];
  productShowCase: Scalars['Int'];
  profileListing: Scalars['Boolean'];
  recommended: Scalars['Boolean'];
  supplierSubscriptionPlanID: Scalars['Int'];
  title: Scalars['String'];
  userAccount?: Maybe<Scalars['Int']>;
};

export enum TimeRange {
  AnyTime = 'ANY_TIME',
  CustomRange = 'CUSTOM_RANGE',
  Last_7Day = 'LAST_7_DAY',
  Last_30Day = 'LAST_30_DAY',
  Last_90Day = 'LAST_90_DAY',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
}

export type UnitOfMeasurement = {
  __typename?: 'UnitOfMeasurement';
  active?: Maybe<Scalars['Boolean']>;
  buyerCode?: Maybe<Scalars['String']>;
  buyerDesc?: Maybe<Scalars['String']>;
  commerceDesc?: Maybe<Scalars['String']>;
  commerceOneCode?: Maybe<Scalars['String']>;
  descriptionTH?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  scUOMID?: Maybe<Scalars['String']>;
  specificType?: Maybe<Scalars['String']>;
  uomID: Scalars['String'];
};

export type UpsertProductInput = {
  companyProduct: CompanyProductInput;
  draft: Scalars['Boolean'];
  productDescription?: Maybe<ProductDescriptionInput>;
  productID?: Maybe<Scalars['Int']>;
  productInformation?: Maybe<ProductInformationInput>;
  productOption?: Maybe<ProductOptionInput>;
  status?: Maybe<Scalars['String']>;
};

export type UpsertPromotionInput = {
  branchID: Scalars['Int'];
  companyID: Scalars['Int'];
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionLocal?: Maybe<Scalars['String']>;
  discountDetail?: Maybe<Scalars['String']>;
  discountType: Scalars['String'];
  draft: Scalars['Boolean'];
  endDate?: Maybe<Scalars['Long']>;
  noEndDate?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Array<Maybe<PromotionProductInput>>>;
  promotionID?: Maybe<Scalars['Int']>;
  promotionImages?: Maybe<Array<Maybe<PromotionImageInput>>>;
  promotionNameEN: Scalars['String'];
  promotionNameLocal: Scalars['String'];
  promotionStatus: Scalars['String'];
  promotionTypeID: Scalars['Int'];
  remark?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Long']>;
};

export type UserInformation = {
  __typename?: 'UserInformation';
  countryCode?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['DateTimestamp']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  faxCallingCode?: Maybe<Scalars['String']>;
  faxExt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNameEN?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameEN?: Maybe<Scalars['String']>;
  localeName?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobileCallingCode?: Maybe<Scalars['String']>;
  otherTitleName?: Maybe<Scalars['String']>;
  passwordUpdateDate?: Maybe<Scalars['DateTimestamp']>;
  phone?: Maybe<Scalars['String']>;
  phoneCallingCode?: Maybe<Scalars['String']>;
  phoneExt?: Maybe<Scalars['String']>;
  registerFrom?: Maybe<Scalars['String']>;
  tempPassword?: Maybe<Scalars['String']>;
  timeZoneID?: Maybe<Scalars['String']>;
  titleID?: Maybe<Scalars['Long']>;
  updateDate?: Maybe<Scalars['DateTimestamp']>;
  userID: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type UserResetPassword = {
  __typename?: 'UserResetPassword';
  resetPasswordToken?: Maybe<Scalars['String']>;
  resetPasswordTokenExpire?: Maybe<Scalars['DateTimestamp']>;
  userID?: Maybe<Scalars['Int']>;
};

export type Video = {
  __typename?: 'Video';
  link: Scalars['String'];
  titleName: Scalars['String'];
  videoId: Scalars['Int'];
};
