import { useTranslation } from 'react-i18next';
import { useDealsAndDiscountDetailContext } from '../DealsAndDiscountDetailContext';
import { DateTimeFormat, Language } from '@utils/constants/CommonConstants';
import { PromotionImage } from '@models/DealsAndDiscount';
import { LangType, PromotionFile } from '@graphql/autogenerate/schemas';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import emptyFile from '@assets/images/thumbnail/empty-thumbnail-64.png';

interface IPromotionDetailProps {
  elementID: string;
  refBox: any;
}

export default function PromotionDetails(
  props: React.PropsWithChildren<IPromotionDetailProps>
): React.FunctionComponentElement<IPromotionDetailProps> {
  const { elementID, refBox } = props;
  const thisElementID = elementID + '-promotionDetail';
  const { t, i18n } = useTranslation();
  const { promotionDetail } = useDealsAndDiscountDetailContext();

  const renderPromotionBannerEn = () => {
    let promotionBanner = promotionDetail?.promotionFileList
      .filter((e) => e.language?.toLocaleLowerCase() == LangType.En.toLocaleLowerCase())
      .sort(function (a, b) {
        return b.promotionFileID! - a.promotionFileID!;
      });
    if (promotionBanner?.length) {
      return (
        <>
          <div className="mb-2">
            <img
              src={promotionBanner[0]?.fileUrl}
              onError={(ev: any) => {
                ev.target.src = emptyFile;
              }}
              width="475"
              height="95"
            />
          </div>
        </>
      );
    }
  };

  const renderPromotionBannerLocal = () => {
    let promotionBanner = promotionDetail?.promotionFileList
      .filter((e) => e.language?.toLocaleLowerCase() == LangType.Local.toLocaleLowerCase())
      .sort(function (a, b) {
        return b.promotionFileID! - a.promotionFileID!;
      });
    if (promotionBanner?.length) {
      return (
        <>
          <div>
            <img
              src={promotionBanner[0]?.fileUrl}
              onError={(ev: any) => {
                ev.target.src = emptyFile;
              }}
              width="475"
              height="95"
            />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div id="tab-1" className="tab-pane active">
        <div className="card">
          <div className="card-header">{t('DealsAndDiscount.Title.PromotionDetails')}</div>
          <div className="card-body">
            <div className="col-list">
              <div className="col-label label-lg">{t('DealsAndDiscount.Label.PromotionName')}</div>
              <div className="col-detail">
                <div className="mb-8px">{promotionDetail?.promotionNameEN}</div>
                <small>{promotionDetail?.promotionNameLocal}</small>
              </div>
            </div>
            <div className="col-list">
              <div className="col-label label-lg">{t('DealsAndDiscount.Label.Description')}</div>
              <div className="col-detail">
                <div className="mb-8px">{promotionDetail?.descriptionEN}</div>
                <small>{promotionDetail?.descriptionLocal}</small>
              </div>
            </div>
            <div className="col-list mb-8px">
              <div className="col-label label-lg">{t('DealsAndDiscount.Label.Banner')}</div>
            </div>
            <div className="view-banner mb-4">
              {renderPromotionBannerEn()}
              {renderPromotionBannerLocal()}
            </div>
            <div className="title">{t('DealsAndDiscount.Label.PromotionPeriod')}</div>
            <div className="col-list mb-4">
              <div className="col-label label-lg">{t('DealsAndDiscount.Label.Period')}</div>
              <div className="col-detail">
                <div className="box-period">
                  <div className="bl">{t('DealsAndDiscount.Label.Start')}</div>
                  <div className="br">
                    {CommonHelper.displayDate(
                      Number(promotionDetail?.startDate),
                      DateTimeFormat.DEFAULT_DATE_TIME_FORMAT
                    ) || '-'}
                  </div>
                </div>
                <div className="box-period mt-4px">
                  <div className="bl">{t('DealsAndDiscount.Label.End')}</div>
                  <div className="br">
                    {Number(promotionDetail?.endDate) != DateTimeFormat.MAX_DATE ? (
                      <>
                        {CommonHelper.displayDate(
                          Number(promotionDetail?.endDate),
                          DateTimeFormat.DEFAULT_DATE_TIME_FORMAT
                        ) || '-'}
                      </>
                    ) : (
                      <>{t('DealsAndDiscount.Table.Body.NoEndDate')}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="title">{t('DealsAndDiscount.Label.DiscountDetails')}</div>
            <div className="col-list">
              <span>{promotionDetail?.discountDetail}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
