import { DisplayValueMap } from './../../models/Model';
import { ISCProgressBarStep } from '../../components/progress-bar/SCProgressBar';
import { ProductListStatusEnum } from '@utils/enum/ProductEnum';

export enum ProductPageRoute {
  LIST = 'list',
  ADD = 'add',
  DETAIL = ':productID/detail',
  DRAFT = ':productID/draft',
  EDIT = ':productID/edit',
  SINGLE = 'single',
  SINGLE_EDIT = 'single/:productID/edit',
  LIST_SELECT = 'list/select',
}

export enum UOMEnum {
  SIZE = 'size',
  WEIGHT = 'weight',
}

export enum ProductStatusEnum {
  active = 'active',
  inactive = 'inactive',
}

export enum ProductOptionPricingEnum {
  adjustable_product_price,
  non_price_product,
}

export const AddProductStep: ISCProgressBarStep[] = [
  {
    step: 1,
    postElementID: 'generalDetails',
    title: 'ProductManagement.ProgressBar.GeneralDetails',
  },
  {
    step: 2,
    postElementID: 'descriptions',
    title: 'ProductManagement.ProgressBar.Description',
  },
  {
    step: 3,
    postElementID: 'variantsAndPricing',
    title: 'ProductManagement.ProgressBar.VariantsAndPricing',
  },
];

export class BusinessType {
  public static readonly MANUFACTURER = new DisplayValueMap(
    'Common.BusinessType.Manufacturer',
    'manufacturer'
  );
  public static readonly DISTRIBUTOR = new DisplayValueMap(
    'Common.BusinessType.Distributor',
    'distributor'
  );
  public static readonly DEALER = new DisplayValueMap('Common.BusinessType.Dealer', 'dealer');
  public static readonly SERVICE_PROVIDER = new DisplayValueMap(
    'Common.BusinessType.ServiceProvider',
    'service_provider'
  );

  public static readonly getAll = () => [
    BusinessType.MANUFACTURER,
    BusinessType.DISTRIBUTOR,
    BusinessType.DEALER,
    BusinessType.SERVICE_PROVIDER,
  ];

  public static findType(val: string) {
    return this.getAll().find((t) => t.value === val);
  }
}

export const AddProductVAT = [0, 7];

export class AddProductAPIErrors {
  public static readonly DUPLICATE_PRODUCT_NAME_LOCAL = '4011';
  public static readonly DUPLICATE_PRODUCT_NAME_EN = '4012';
}

export const productListStatusList = [
  ProductListStatusEnum.ACTIVE,
  ProductListStatusEnum.INACTIVE,
  ProductListStatusEnum.DRAFT,
];

export class ProductTypeMap {
  public static readonly PRODUCT = new DisplayValueMap(
    'ProductManagement.Label.Product',
    'product'
  );
  public static readonly SERVICE = new DisplayValueMap(
    'ProductManagement.Label.Service',
    'service'
  );
  private static readonly getAll = () => {
    return [ProductTypeMap.PRODUCT, ProductTypeMap.SERVICE];
  };

  public static findType(val: string) {
    return this.getAll().find((t) => t.value === val);
  }
}

export class ProductStatusMap {
  public static readonly ACTIVE = new DisplayValueMap(
    'Common.Label.Active',
    'active',
    'green'
  );
  public static readonly INACTIVE = new DisplayValueMap(
    'Common.Label.Inactive',
    'inactive',
    'red'
  );
  public static readonly DRAFT = new DisplayValueMap(
    'Common.Label.Draft',
    'draft',
    'gray'
  );
  private static readonly getAll = () => {
    return [ProductStatusMap.ACTIVE, ProductStatusMap.INACTIVE];
  };

  public static findType(val: string) {
    return this.getAll().find((t) => t.value === val);
  }
}
