export enum BranchConstant {
  HEAD_QUARTER = '00000'
}

export enum BranchType {
  HEAD_QUARTER = '1',
  SUB_BRANCH = '2',
  NOT_SPECIFY = '3'
}

export enum BranchStatus {
  AWAITING_ADD_NEW_BRANCH = 1,
  REJECTED_ADD_NEW_BRANCH = 2,
  AWAITING = 8,
  APPROVED = 9,
  REJECTED = 10
}

export enum UserBranchStatus {
  AWAITING = 1,
  APPROVED = 2,
  REJECTED = 3,
  REQUEST_TO_JOIN = 4,
  CANCEL_REQUEST_TO_JOIN = 5
}