import { CompanyResponse } from '@api/response/CompanyResponse';
import { SCButton } from '@components/button/SCButton';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { CompanyItem, usePTVNAuthentication } from '@ptvn-react/authentication';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import EmptyImg from '@assets/images/frontend/not-found.png';
import NoCreatedImg from '@assets/images/frontend/save-draft.png';
import {
  DealsAndDiscountListStatusEnum,
  DealsAndDiscountPageRoute,
} from '@utils/enum/DealsAndDiscountEnum';
import Pagination from '@components/pagination/Pagination';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { GatewayGraphQLClient } from '@api/GatewayGraphQLClient';
import { useSupplierPromotionListLazyQuery } from '@graphql/autogenerate/hooks';
import {
  LangType,
  PromotionListFilterStatusType,
  PromotionListInput,
  PromotionListSortByType,
  SortDirType,
} from '@graphql/autogenerate/schemas';
import PromotionListTable from './DealsAndDiscountListTable';
import { PageSizeDefaultValues } from '@utils/constants/CommonConstants';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import DropdownMultipleSelectWithSubmit from '@components/dropdown/DropdownMultipleSelectWithSubmit';
import useGoogleAnalytics from '@google/useGoogleAnalytics';
import { GoogleAnalyticsConstantEvent } from '@utils/constants/GoogleAnalyticsConstant';
import { EventArgs } from 'react-ga';
import { Subscription } from 'rxjs';

export default function DealsAndDiscountListPage(): React.FunctionComponentElement<any> {
  const elementID = 'dealsAndDiscountList';
  const history = useHistory();
  const { push } = useModalStack();
  const { t, i18n } = useTranslation();
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const { requestGAObservable } = useGoogleAnalytics();
  const [subscription, setSubscription] = useState<Subscription>();
  const [selectedFilterStatusList, setSelectedFilterStatusList] = useState<
    PromotionListFilterStatusType[]
  >([]);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');
  const [searches, setSearches] = useState<string[]>([]);
  const [searchBtn, setSearchBtn] = useState<boolean>(true);
  const [promotionIDSelected, setPromotionIDSelected] = useState<number[]>([]);
  const { extendedUserInformation } = usePTVNAuthentication();
  const companyProfile = useMemo<CompanyResponse>(() => {
    return extendedUserInformation?.companies && extendedUserInformation.companies[0];
  }, [extendedUserInformation]);
  const userBranchIDs = useMemo<number[]>(
    () => companyProfile?.branches && companyProfile.branches.map((c) => c.branchId),
    [companyProfile]
  );

  const popAddDealsAndDiscountModal = () => {
    setSubscription(requestGAObservable({
      suffixPathAction: GoogleAnalyticsConstantEvent.CREATE_PROMOTION,
      event: { action: GoogleAnalyticsConstantEvent.CREATE_PROMOTION } as EventArgs
    }).subscribe(() => {
      history.push(DealsAndDiscountPageRoute.ADD);
    }));
  };

  const defaultFilter = (): PromotionListInput => {
    return {
      sortBy: PromotionListSortByType.StartDate,
      sortDir: SortDirType.Desc,
      companyID: companyProfile?.companyId,
      branchIDs: userBranchIDs,
      page: 1,
      pageSize: 10,
      searches: [],
      filterBy: {
        promotionStatus: [],
      },
    };
  };

  const [filter, setFilter] = useState<PromotionListInput>(defaultFilter());

  const [
    loadPromotionList,
    { data: graphQLPromotionList, loading: isLoadingPromotionList, error: errorLoadPromotionList },
  ] = useSupplierPromotionListLazyQuery({
    client: GatewayGraphQLClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: filter!,
      lang: i18n.language.startsWith('en') ? LangType.En : LangType.Local,
    },
  });

  useEffect(() => {
    return () => {
      subscription && subscription.unsubscribe();
    }
  }, [subscription])

  useEffect(() => {
    if (filter) {
      setFilter({
        ...filter,
        page: 1,
        searches: searches,
        filterBy: {
          promotionStatus: selectedFilterStatusList,
        },
      });

      if (selectedFilterStatusList?.length) {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.SEARCH,
          event: { action: GoogleAnalyticsConstantEvent.SEARCH } as EventArgs
        }).subscribe(() => { }));
      }
    }
  }, [searches]);

  useEffect(() => {
    if (filter) {
      loadPromotionList();
    }
  }, [filter]);

  useEffect(() => {
    if (errorLoadPromotionList) {
      push(SomethingWentWrongModal, {
        onSubmit: () => {
          window.location.reload();
        },
      });
    }
  }, [errorLoadPromotionList]);

  const addSearch = (value: string) => {
    const trimmedValue = CommonHelper.trimValue(value);
    if (trimmedValue) {
      setSearches([trimmedValue]);
      setSearchBtn(false);
    } else {
      setSearches([]);
      setSearchBtn(true);
    }


  };

  const removeSearch = () => {
    setInputSearchValue('');
    setSearches([]);
    setSearchBtn(true);
  };

  const handleEnterSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setInputSearchValue(e.currentTarget.value);
      addSearch(e.currentTarget.value);
    }
  };

  const handleSortChange = (by: PromotionListSortByType, sortName: LangType, dir: SortDirType) => {
    filter &&
      setFilter((x) => {
        return x
          ? {
            ...x,
            sortBy: by,
            sortDir: dir,
            sortNameBy: sortName,
          }
          : defaultFilter();
      });
  };

  const submitFilterStatus = () => {
    if (filter) {
      setFilter({
        ...filter,
        page: 1,
        filterBy: {
          promotionStatus: selectedFilterStatusList,
        },
      });

      if (selectedFilterStatusList?.length) {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.FILTER,
          event: { action: GoogleAnalyticsConstantEvent.FILTER } as EventArgs
        }).subscribe(() => { }));
      }
    }
  };

  const submitClearFilterStatus = () => {
    if (filter) {
      setFilter({
        ...filter,
        page: 1,
        filterBy: {
          promotionStatus: [],
        },
      });
    }
  };


  const handleOnAction = () => {
    loadPromotionList();
  };

  const handelChangeSearchFilter = (value: string) => {
    const trimmedValue = CommonHelper.trimValue(value);
    if (!trimmedValue) {
      addSearch(value);
    }

    setInputSearchValue(value);
  }

  return (
    <>

      {currentCompany?.companyID && (
        <>
          <div className="card-content w-full">
            <h2 className="d-flex align-items-center p-hz-default">
              <span>{t('DealsAndDiscount.Title.DealsAndDiscount')}</span>
              <div className="button-inline">
                <SCButton
                  id={`btn-${elementID}-addDealsAndDiscount`}
                  type="primary"
                  className="clear-minwidth"
                  onClick={popAddDealsAndDiscountModal}
                >
                  {t('DealsAndDiscount.Button.CreatePromotion')}
                </SCButton>
              </div>
            </h2>
            <div className="card clear-border">
              <div className="card-body no-hz-space">
                <div className="row-search mb-12px p-hz-default">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6">
                      <div className="input-group icon">
                        <input
                          id={`txt-${elementID}-search`}
                          type="text"
                          placeholder={t('DealsAndDiscount.Label.SearchPlaceholder')}
                          className="form-control"
                          value={inputSearchValue}
                          onChange={(e) => handelChangeSearchFilter(e.target.value)}
                          onKeyDown={handleEnterSearchInput}
                        />
                        {searchBtn ? (
                          <>
                            <button
                              id={`btn-${elementID}-search`}
                              type="button"
                              className="btn"
                              onClick={() => addSearch(inputSearchValue)}
                            >
                              <i className="icon-search">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              id={`btn-${elementID}-search`}
                              type="button"
                              className="btn"
                              onClick={() => removeSearch()}
                            >
                              <i className="icon-cancel"></i>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 pd-0px">
                      <DropdownMultipleSelectWithSubmit
                        elementIdPrefix={elementID}
                        elementIdSuffix="filter-status"
                        name={t('DealsAndDiscount.Label.AllStatus')}
                        selectedName={t('DealsAndDiscount.Label.Status')}
                        options={[
                          {
                            label: t('DealsAndDiscount.Label.Ongoing'),
                            value: DealsAndDiscountListStatusEnum.ONGOING,
                          },
                          {
                            label: t('DealsAndDiscount.Label.Upcoming'),
                            value: DealsAndDiscountListStatusEnum.UPCOMING,
                          },
                          {
                            label: t('DealsAndDiscount.Label.Draft'),
                            value: DealsAndDiscountListStatusEnum.DRAFT,
                          },
                          {
                            label: t('DealsAndDiscount.Label.Cancelled'),
                            value: DealsAndDiscountListStatusEnum.CANCELLED,
                          },
                          {
                            label: t('DealsAndDiscount.Label.Expired'),
                            value: DealsAndDiscountListStatusEnum.EXPIRED,
                          },
                        ]}
                        selectedOptionList={selectedFilterStatusList}
                        onChange={setSelectedFilterStatusList}
                        submitFilter={submitFilterStatus}
                        clearFilter={submitClearFilterStatus}
                        className="dropdown-check ml-0"
                      />
                    </div>
                  </div>
                </div>

                {filter &&
                  graphQLPromotionList?.supplierPromotionList &&
                  graphQLPromotionList.supplierPromotionList.items.length > 0 ? (
                  <>
                    <PromotionListTable
                      elementIdPrefix={elementID}
                      promotionList={graphQLPromotionList.supplierPromotionList.items}
                      sortBy={filter.sortBy}
                      sortDir={filter.sortDir}
                      sortNameBy={filter.sortNameBy!}
                      onSortChange={handleSortChange}
                      onSelectItem={(productIDList: number[]) =>
                        setPromotionIDSelected(productIDList)
                      }
                      onAction={handleOnAction}
                    />
                    <Pagination
                      elementIdPrefix={elementID}
                      elementIdSuffix="product"
                      currentPage={graphQLPromotionList.supplierPromotionList.page}
                      totalPage={graphQLPromotionList.supplierPromotionList.totalPage}
                      totalItem={graphQLPromotionList.supplierPromotionList.totalItem}
                      pageSize={graphQLPromotionList.supplierPromotionList.pageSize}
                      pageSizes={PageSizeDefaultValues}
                      onSelectPageSize={(pageSize: number) => {
                        filter && setFilter({ ...filter, page: 1, pageSize });
                      }}
                      onSelectPageNumber={(pageNo: number) => {
                        filter && setFilter({ ...filter, page: pageNo });
                      }}
                    />
                  </>
                ) : (
                  <div className="result-found">
                    {isLoadingPromotionList ? (
                      <div className="loading" style={{ position: 'absolute' }}>
                        <i className="icon-loading spinner"></i>
                      </div>
                    ) : (
                      <>
                        {filter.filterBy?.promotionStatus?.length != 0 ||
                          filter.searches?.length != 0 ? (
                          <>
                            <img src={EmptyImg} className="img-result" />
                            <div className="title">{t('DealsAndDiscount.Label.NotFound')}</div>
                          </>
                        ) : (
                          <>
                            <img src={NoCreatedImg} className="img-result" />
                            <div className="title">{t('DealsAndDiscount.Label.NoPromotion')}</div>
                            <p>{t('DealsAndDiscount.Label.NoPromotionDescription')}</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
