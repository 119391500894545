import { DisplayValueMap } from '../../models/Model';
import FlagThai from '../../../assets/images/flags/TH.png';
import FlagVietnam from '../../../assets/images/flags/VN.png';

export class DateTimeFormat {
  public static readonly DDMMYYYY_SLASH = 'DD/MM/YYYY';
  public static readonly DDMMYYYY_HYPHEN = 'DD-MM-YYYY';
  public static readonly YYYYMMDD_HYPHEN = 'YYYY-MM-DD';
  public static readonly HHmm = "HH:mm";
  public static readonly hhmmA = "hh:mm A";
  public static readonly hhmmssA = "hh:mm:ss A";
  public static readonly DDMMYYY_SLASH_hhmmA = "DD/MM/YYYY, hh:mm A"
  public static readonly DDMMYYY_SLASH_hhmmss = 'DD/MM/YYYY, HH:mm:ss';
  public static readonly DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
  public static readonly YYYYMMDD_HYPHEN_hhmm = 'YYYY-MM-DD HH:mm';
  public static readonly MAX_DATE = 253402214400000;
}
export class TimeZone {
  public static readonly ASIA_BANGKOK = "Asia/Bangkok";
}

export class BankOfThai {
  public static readonly BBL = new DisplayValueMap("ธนาคารกรุงเทพ (BBL)", "BBL");
  public static readonly KBANK = new DisplayValueMap("ธนาคารกสิกรไทย (KBANK)", "KBANK");
  public static readonly KTB = new DisplayValueMap("ธนาคารกรุงไทย (KTB)", "KTB");
  public static readonly TMB = new DisplayValueMap("ธนาคารทหารไทย (TMB)", "TMB");
  public static readonly SCB = new DisplayValueMap("ธนาคารไทยพาณิชย์ (SCB)", "SCB");
  public static readonly BAY = new DisplayValueMap("ธนาคารกรุงศรีอยุทธยา (BAY)", "BAY");
  public static readonly getAll = () => (
    [
      BankOfThai.BBL,
      BankOfThai.KBANK,
      BankOfThai.KTB,
      BankOfThai.TMB,
      BankOfThai.SCB,
      BankOfThai.BAY,
    ]
  )
}

export class REGEX {
  public static readonly WEB_SITE = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/;
  public static readonly EMAIL = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
  public static readonly DATE = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
  public static readonly TIME = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  public static readonly PHONE = /^[0-9]{8,10}$/;
  public static readonly CALLING_CODE = /^(\+?\d{1,3}|\d{1,4})$/;
  public static readonly POSTAL_CODE = /^[0-9]{5}$/;
  public static readonly ENGLISH_LANGUAGE = /^[a-zA-Z]+$/;
  public static readonly THAI_LANGUAGE = /^[ก-๏]+$/;
  public static readonly VIETNAMESE_LANGUAGE = /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/;
  public static readonly ENGLISH_LAST_NAME = /^[a-zA-Z]+((\\s|\\.\\s|\\'\\s|\\’\\s|\\-)?[a-zA-Z]+)*$/;
  public static readonly THAI_LAST_NAME = /^[ก-๏]+(\\s[ก-๏]+)*$/;
  public static readonly VIETNAMESE_LAST_NAME = /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+(\\s[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+)*$/;
  public static readonly NUMBER_ONLY = /^[0-9]+$/;
  public static readonly PRODUCT_NAME_ENGLISH_LANGUAGE = /^[a-zA-Z0-9!@#$%^&*()\-_=+[\]{}\\|;:'",.<>/\s]+$/;
  public static readonly PRODUCT_NAME_THAI_LANGUAGE = /^[ก-๏0-9!@#$%^&*()\-_=+[\]{}\\|;:'",.<>/\s]+$/;
  public static readonly UPPER_CASE_LETTERS = /[A-Z]/g;
  public static readonly SPECIAL_CASE_LETTERS = /[ !'#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g;
  public static readonly NUMBER_CASE_LETTERS = /[0-9]/g;
}

export enum SortDirectionEnum { ASC = 'ASC', DESC = 'DESC' };

export const PageSizeDefaultValues = [10, 25, 50, 100];
export type PageSizeDefaultType = typeof PageSizeDefaultValues[number];

export const KB = 1024;

export interface LanguageItem {
  key: string;
  title: string;
  icon: string;
}

export class Flags {
  public static readonly ENGLISH = FlagVietnam;
  public static readonly VIETNAM = FlagVietnam;
  public static readonly THAI = FlagThai;
}
export class Language {
  public static readonly en: LanguageItem = { key: 'en', title: 'English', icon: Flags.ENGLISH };
  public static readonly vn: LanguageItem = { key: 'vn', title: 'tiếng Việt', icon: Flags.VIETNAM };
  public static readonly th: LanguageItem = { key: 'th', title: 'ภาษาไทย', icon: Flags.THAI };

  public static getLangByKey(key: string) {
    switch (key) {
      case Language.en.key:
        return Language.en;
      case Language.vn.key:
        return Language.vn;
      case Language.th.key:
        return Language.th;
      default:
        return undefined;
    }
  }

}
export class Config {
  // GroupName
  public static readonly Group_Maintenance = 'maintenance';
  public static readonly Group_User = 'user';

  // Name
  public static readonly Maintenance_Enable = 'maintenance';
  public static readonly Maintenance_StartTime = 'maintenance_startTime';
  public static readonly Maintenance_EndTime = 'maintenance_endTime';
  public static readonly Maintenance_Date = 'maintenance_date';
  public static readonly Maintenance_User = 'maintenance_user';
  public static readonly Enable_Modal_Broadcast_User = 'enable_popup_user_broadcast';
  public static readonly Enable_Popup_Self_Assessment = 'enable_popup_invite_self_assessment_buyer_true';
  public static readonly Enable_Popup_Update_Product_Attribute = 'enable_popup_update_product_attribute';
  public static readonly Enable_Popup_Announcement = 'enable_popup_announcement_buyer_true';
}

export class HttpStatus {
  public static readonly Unauthorized = 401;
}

export class AlertBarTimeOut {
  public static readonly EIGHT_THOUSAND_MILLISECONDE = 8000;
}

export class TemplateType {
  public static readonly NEW_TEMPLATE_PRODUCT = "new_template_product";
  public static readonly UPDATE_TEMPLATE_PRODUCT = "update_template_product";
}