import { GatewayGraphQLClient } from '@api/GatewayGraphQLClient';
import { usePromotionDetailLazyQuery } from '@graphql/autogenerate/hooks';
import { PromotionDetail, PromotionProductItemSortByType, SortDirType } from '@graphql/autogenerate/schemas';
import { PromotionProduct } from '@models/DealsAndDiscount';
import { DealsAndDiscountAction, DealsAndDiscountActionType, ErrorCodeEnum, PromotionStatusEnum } from '@utils/enum/DealsAndDiscountEnum';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { match, useRouteMatch } from 'react-router';
import useGoogleAnalytics from '@google/useGoogleAnalytics';
import { GoogleAnalyticsConstantEvent } from '@utils/constants/GoogleAnalyticsConstant';
import { EventArgs } from 'react-ga';

export interface DealsAndDiscountContextType {
  action?: DealsAndDiscountActionType;
  productItemsInPromotion: PromotionProduct[];
  setProductItemsInPromotion: Dispatch<SetStateAction<PromotionProduct[]>>;
  selectProductsPage: boolean;
  setSelectProductsPage: Dispatch<SetStateAction<boolean>>;
  promotionDetail?: PromotionDetail;
  promotionID?: number;
  setIsLoadingImage: Dispatch<SetStateAction<boolean>>;
  isLoadingImage: boolean;
}

const DealsAndDiscountContext = React.createContext<DealsAndDiscountContextType | undefined>(
  undefined
);

export const DealsAndDiscountProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const routeMatch: match<{ promotionID: any; tab: string }> = useRouteMatch();

  const { setRequestGA } = useGoogleAnalytics();
  const [selectProductsPage, setSelectProductsPage] = useState<boolean>(false);
  const [action, setAction] = useState<DealsAndDiscountActionType>();
  const [productItemsInPromotion, setProductItemsInPromotion] = useState<PromotionProduct[]>([]);
  const [promotionID, setPromotionID] = useState<number>();
  const [promotionDetail, setPromotionDetail] = useState<PromotionDetail>();
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

  const [
    loadPromotionList,
    { data: graphQLPromotion, loading: isLoadingPromotion, error: errorLoadPromotion },
  ] = usePromotionDetailLazyQuery({
    client: GatewayGraphQLClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      promotionID: Number(promotionID)!,
      input: {
        sortBy: PromotionProductItemSortByType.ProductName,
        sortDir: SortDirType.Asc,
        page: 1,
        pageSize: -1,
        searches: [],
      }
    },
  });

  useEffect(() => {
    if (selectProductsPage) {
      setRequestGA({
        suffixPathAction: GoogleAnalyticsConstantEvent.SELECT_PRODUCT,
        event: { action: GoogleAnalyticsConstantEvent.SELECT_PRODUCT } as EventArgs
      });
    }
  }, [selectProductsPage]);

  useEffect(() => {
    if (promotionID) {
      loadPromotionList();
    }
  }, [promotionID]);

  useEffect(() => {
    if (graphQLPromotion?.promotion) {
      setPromotionDetail(graphQLPromotion.promotion as PromotionDetail);
    }
  }, [graphQLPromotion]);

  useEffect(() => {
    if (errorLoadPromotion) {
      const ex = CommonHelper.transformGraphQLErrors(errorLoadPromotion);
      if (ex && ex.length) {
        if (ex.find(e => e.code === ErrorCodeEnum.NOT_FOUND) ||
          ex.find(e => e.code === ErrorCodeEnum.PROMOTION_DELETED)) {
          setAction(DealsAndDiscountAction.NOT_FOUND);
        } else {
          setAction(DealsAndDiscountAction.ERROR);
        }
      } else {
        setAction(DealsAndDiscountAction.ERROR);
      }
    }
  }, [errorLoadPromotion]);

  useEffect(() => {
    if (promotionDetail) {
      if (promotionDetail.promotionStatus === PromotionStatusEnum.PENDING) {
        setAction(DealsAndDiscountAction.ADD);
      } else {
        setAction(DealsAndDiscountAction.EDIT);
      }
    }
  }, [promotionDetail]);

  useEffect(() => {
    if (routeMatch?.params?.promotionID) {
      setPromotionID(routeMatch?.params?.promotionID);
    } else {
      setAction(DealsAndDiscountAction.ADD);
    }
  }, [routeMatch.params.promotionID]);

  return (
    <DealsAndDiscountContext.Provider
      value={{
        action,
        productItemsInPromotion,
        setProductItemsInPromotion,
        selectProductsPage,
        setSelectProductsPage,
        promotionDetail,
        promotionID,
        setIsLoadingImage,
        isLoadingImage
      }}
    >
      {children}
    </DealsAndDiscountContext.Provider>
  );
};

export const useDealsAndDiscountContext = () => {
  return useContext(DealsAndDiscountContext) as DealsAndDiscountContextType;
};
