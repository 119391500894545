import React from 'react';

export function SkeletonLoadFull(
  props: React.PropsWithChildren<any>
): React.FunctionComponentElement<any> {

  return (
    <>
      <div className="mb-5">
        <div className="row">
          <div className="col-3">
            <div style={{ width: '100%' }} className="skeleton-input"></div>
          </div>
        </div>
      </div>
      <div className="mt--1 mb-3">
        <div style={{ height: '180px' }} className="skeleton-card"></div>
        <div style={{ height: '112px' }} className="skeleton-card"></div>
      </div>
      <div className="row mb-8px">
        <div className="col-3">
          <div style={{ height: '200px' }} className="skeleton-card"></div>
        </div>
        <div className="col-9">
          <div style={{ height: '200px' }} className="skeleton-card"></div>
        </div>
      </div>
    </>
  );
}
