export enum AccountSettingPageRoute {
  PROFILE = 'profile',
  PREFERENCE = 'preference',
  ACCOUNT_ACCESS = 'account-access'
}

export enum AccountAccessAction {
  DETAIL = 'detail',
  CHANGE_EMAIL = 'change-email',
  CHANGE_USERNAME = 'change-username',
  CHANGE_PASSWORD = 'change-password'
}

export enum ProfileActionEnum {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum ErrorCodeEnum {
  MOBILE_NUMBER_INVALID = 'CorrectValue',
  MOBILE_NUMBER_SIZE = 'Size',
  EMAIL_IS_EXPIRED = '4001'
}

export enum UserErrorCodeEnum {
  VERIFIED_STATUS_MUST_BE_WAITING = '4014',
  USER_UPDATE_VERIFIED_NOT_FOUND = '4007',
  EMAIL_IS_VERIFIED = '4013',
  EMAIL_ALREADY_EXIST = '4001',
  EMAIL_ALREADY_EXIST_IN_USER_UPDATE_VERIFIED = '4019'

}

export enum AuthenErrorCodeEnum {
  INVALID_CURRENT_PASSWORD = '4007',
  SAME_PASSWORD = '4009',
  EMAIL_ALREADY_EXIST = '4017',
  PASSWORD_IS_EASILY_GUESSED = '4021'
}

export enum ErrorMessageEnum {
  MOBILE_NUMBER = 'mobile',
  PHONE = 'phone',
  FAX = 'fax'
}

export enum UserUpdateVerifiedStatus {
  WAITING = 'waiting',
  VERIFIED = 'verified',
  CANCELED = 'canceled'
}