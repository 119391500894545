import Environment from "./base";

const environments: Environment = {
  isDevelopment: false,
  serviceHostName: "https://service.pantavanij.com",
  clientID: "supplier",
  keycloakHostName: "https://service.pantavanij.com/keycloak",
  keycloakRealm: "ptvn",
  supplierFrontend: "https://supplier.pantavanij.com",
  supplierWebWorkFrontend: "https://www.supplierwebwork.com",
  emarketPlaceFrontend: "https://emarketplace.pantavanij.com",
  pantavanijIntelligenceFrontend: "https://emarketplace.pantavanij.com/pi",
  lameSurvayFrontend: "https://saq.pantavanij.com/loginFromSC",
  oneTrust: "https://cdn-apac.onetrust.com",
  oneTrustDataDomainScript: "74c3f702-f397-434c-b05f-eeed9027365a",
  googleAnalyticKey: "G-QS5W838MQS",
  cocDocumentTH: "https://supplier.pantavanij.com/files/CPGCG-CoC-TH.pdf",
  cocDocumentEN: "https://supplier.pantavanij.com/files/CPGCG-CoC-EN.pdf",
  AccepAutoPOTH: "https://supplier.pantavanij.com/files/Supplier_Auto_Accept_PO_V3_Thai.pdf",
  AccepAutoPOEN: "https://supplier.pantavanij.com/files/Supplier_Auto_Accept_PO_V3_Eng.pdf",
  hotjar: {
    siteId: 3564234,
    hotjarVersion: 6,
  }
};

export default environments;
