import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import { ExtendedUserInformation } from '@models/User';
import UserInformation from '../component/UserInformation';
import { SettingActionType } from '@utils/constants/SettingConstants';
import { SettingActionEnum } from '@utils/enum/SettingEnum';
import ContactAddress from '../component/ContactAddress';
import { FormProvider, useForm } from 'react-hook-form';
import { AccountDetail } from '@models/AccountDetail';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountDetailsSchema } from './AccountDetailsSchema';
import PageRoute from '@utils/constants/PageRoute';
import { useSettingContext } from '../SettingContext';
import { SkeletonLoadFull } from '@components/loading/SkeletonLoadFull';
import { UserBranchDetailResponse } from '@api/response/UserBranchResponse';
import { ContactAddressResponse } from '@api/response/AddressResponse';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { Subscription, forkJoin } from 'rxjs';
import { CompanyClient } from '@api/CompanyClient';
import { ContactAddressRequest } from '@api/request/AddressRequest';
import { SCButton } from '@components/button/SCButton';
import { UserBranchDetailRequest } from '@api/request/UserBranchRequest';
import { useSmartAlert } from '@components/smart-alert/SmartAlert';
import { TypeOfTheme } from '@utils/enum/Theme';
import { Prompt, useHistory } from 'react-router';
import * as H from 'history';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { LeaveThisPageModal } from '@components/modal/LeaveThisPage';
import UserAvailableService from '../component/UserAvailableService';
import { PTVNErrorAPI } from '@api/response/BaseResponse';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import useGoogleAnalytics from "@google/useGoogleAnalytics";
import { GoogleAnalyticsConstantEvent, GoogleAnalyticsConstantSuffixPage } from "@utils/constants/GoogleAnalyticsConstant";
import { EventArgs } from "react-ga";

export default function AccountDetailPage(): React.FunctionComponentElement<any> {
    const elementID = 'accountDetail';
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const alert = useSmartAlert();
    const { push } = useModalStack();
    const history = useHistory();
    const { requestGAObservable, setRequestGA } = useGoogleAnalytics();
    const [subscription, setSubscription] = useState<Subscription>();
    const { currentCompany } = usePTVNAuthentication<ExtendedUserInformation>();
    const { loadingAccountDetailAllData, userBranchDetail, contactAddress, fetchAccountDetailData } = useSettingContext();
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [action, setAction] = useState<SettingActionType>(SettingActionEnum.VIEW);

    const accountDetailForm = useForm<AccountDetail>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: yupResolver(AccountDetailsSchema()),
        shouldFocusError: true,
        defaultValues: {}
    });

    const {
        getValues
    } = accountDetailForm;

    useEffect(() => {
        return () => {
            subscription && subscription.unsubscribe();
        };
    }, [subscription]);

    useEffect(() => {
        if (!loadingAccountDetailAllData && action === SettingActionEnum.VIEW) {
            initialForm(userBranchDetail, contactAddress);
            setLoadingUpdate(false);
        }
    }, [loadingAccountDetailAllData, action]);

    const initialForm = (userbranchDetail?: UserBranchDetailResponse, contactAddress?: ContactAddressResponse) => {
        let data = null;
        if (userbranchDetail && contactAddress) {
            data = {
                ...getValues(),
                jobTitleId: userbranchDetail?.jobTitleId.toString()! || '',
                otherJobTitleName: userbranchDetail?.otherJobTitle! || '',
                department: userbranchDetail?.department! || '',
                addressEn: contactAddress?.addressEn! || '',
                addressLocal: contactAddress?.addressLocal! || '',
                countryCode: contactAddress?.countryCode! || '',
                countryNameCode: contactAddress?.countryNameCode! || '',
                districtEn: contactAddress?.districtEn! || '',
                districtLocal: contactAddress?.districtLocal! || '',
                subDistrictEn: contactAddress?.subDistrictEn! || '',
                subDistrictLocal: contactAddress?.subDistrictLocal! || '',
                provinceEn: contactAddress?.provinceEn! || '',
                provinceLocal: contactAddress?.provinceLocal! || '',
                postalCode: contactAddress?.postalCode! || ''
            } as AccountDetail;
        } else if (userbranchDetail) {
            data = {
                ...getValues(),
                jobTitleId: userbranchDetail?.jobTitleId.toString()! || '',
                otherJobTitleName: userbranchDetail?.otherJobTitle! || '',
                department: userbranchDetail?.department! || ''
            } as AccountDetail;
        } else if (contactAddress) {
            data = {
                ...getValues(),
                addressEn: contactAddress?.addressEn! || '',
                addressLocal: contactAddress?.addressLocal! || '',
                countryCode: contactAddress?.countryCode! || '',
                countryNameCode: contactAddress?.countryNameCode! || '',
                districtEn: contactAddress?.districtEn! || '',
                districtLocal: contactAddress?.districtLocal! || '',
                subDistrictEn: contactAddress?.subDistrictEn! || '',
                subDistrictLocal: contactAddress?.subDistrictLocal! || '',
                provinceEn: contactAddress?.provinceEn! || '',
                provinceLocal: contactAddress?.provinceLocal! || '',
                postalCode: contactAddress?.postalCode! || ''
            } as AccountDetail;
        }

        if (data) {
            accountDetailForm.reset(
                data as AccountDetail
            );
        }
    };

    const redirectTo = (url: string) => {
        window.location.href = url;
    }

    const onSubmitUserInformation = async () => {
        let isCurrentValid = true;
        isCurrentValid = await accountDetailForm.trigger();

        if (isCurrentValid) {
            setLoadingUpdate(true);
            updateUserInformation(getValues());
        } else {
            CommonHelper.scrollToError(ref?.current);
        }
    }

    const updateUserInformation = async (request: AccountDetail) => {
        setSubscription(forkJoin({
            updateUserBranch: CompanyClient.updateUserBranch({
                ...request,
                orgId: currentCompany?.orgID!
            } as UserBranchDetailRequest),
            updateContactAddress: CompanyClient.updateContactAddress(request as ContactAddressRequest)
        }).subscribe((response) => {
            fetchAccountDetailData();
            setLoadingUpdate(false);
            setAction(SettingActionEnum.VIEW);
            alert.show(t('ProfileSetting.AlertMessage.UpdateInformationSuccess'), 'success', true,
                TypeOfTheme.TOP_SUPPLIER_CONNECT);
        }, (err) => {
            CommonHelper.handleErrorSomtingWentWrong(err, push);
        }));
    }

    const onCancel = () => {
        if (Object.keys(accountDetailForm.formState.dirtyFields).length > 0) {
            push(LeaveThisPageModal, {
                elementID: elementID,
                disabledSaveAndLeave: true,
                onSubmit: (action: string) => {
                    setAction(SettingActionEnum.VIEW);
                },
            });
        } else {
            setAction(SettingActionEnum.VIEW);
        }
    }

    return (<>
        {loadingAccountDetailAllData ?
            <div className="p-hz-default">
                <SkeletonLoadFull />
            </div> :
            <>
                <div className="p-hz-default">
                    <h2 className="d-flex align-items-center">
                        <span className="ellipsis">{t('AccountDetails.Label.UserInformation')}</span>
                        {action === SettingActionEnum.VIEW &&
                            <div className="ml-auto">
                                <button type="button" className="btn btn-outline noborder"
                                    onClick={() => {
                                        setRequestGA({
                                            suffixPathAction: GoogleAnalyticsConstantEvent.EDIT_ACCOUNT_DETAIL,
                                            event: { action: GoogleAnalyticsConstantEvent.EDIT_ACCOUNT_DETAIL } as EventArgs
                                        });
                                        setAction(SettingActionEnum.EDIT)
                                    }}>
                                    <i className="icon-edit mr-8px">
                                        <span className="path1">
                                        </span><span className="path2"></span>
                                    </i>
                                    {t('Common.Button.Edit')}
                                </button>
                            </div>
                        }
                    </h2>
                    <div className="alert alert-primary">{
                        t('AccountDetails.Label.SomeSettingsGoToYour')}
                        <strong>
                            <a onClick={() => {
                                setSubscription(requestGAObservable({
                                    suffixPathAction: GoogleAnalyticsConstantEvent.ACCOUNT_SETTING,
                                    event: { action: GoogleAnalyticsConstantEvent.ACCOUNT_SETTING } as EventArgs
                                }).subscribe(() => {
                                    redirectTo(PageRoute.ACCOUNT_SETTING_PROFILE)
                                }));
                            }}>
                                {t('MyAccount.Title.AccountSettings')}
                            </a>
                        </strong>.
                        &nbsp;<i className="icon-launch icon-sm"></i>
                    </div>
                </div>
                <div className="p-hz-default" ref={ref}>
                    <FormProvider {...accountDetailForm}>
                        <UserInformation elementID={elementID} action={action} />
                        <ContactAddress elementID={elementID} action={action} />
                        <UserAvailableService elementID={elementID} />
                        {
                            action === SettingActionEnum.EDIT &&
                            <div className="mt-3 d-flex align-items-center">
                                <div className="ml-auto">
                                    <button type="submit" className="btn btn-outline"
                                        onClick={() => {
                                            setRequestGA({
                                                suffixPathAction: GoogleAnalyticsConstantEvent.CANCEL_ACCOUNT_DETAIL,
                                                event: { action: GoogleAnalyticsConstantEvent.CANCEL_ACCOUNT_DETAIL } as EventArgs
                                            });
                                            onCancel()
                                        }}>
                                        {t('Common.Button.Cancel')}
                                    </button>
                                    <SCButton
                                        id={`btn-${elementID}-update`}
                                        type="primary"
                                        onClick={() => {
                                            setRequestGA({
                                                suffixPathAction: GoogleAnalyticsConstantEvent.UPDATE_ACCOUNT_DETAIL,
                                                event: { action: GoogleAnalyticsConstantEvent.UPDATE_ACCOUNT_DETAIL } as EventArgs
                                            });
                                            onSubmitUserInformation()
                                        }
                                        }
                                        isLoading={loadingUpdate}
                                    >
                                        {t('Common.Button.Update')}
                                    </SCButton>
                                </div>
                            </div>
                        }
                    </FormProvider >
                </div >
                <Prompt
                    when={true}
                    message={(nextLocation: H.Location<any>) => {
                        if (nextLocation.state?.forceRedirect) {
                            return true;
                        }
                        if (Object.keys(accountDetailForm.formState.dirtyFields).length > 0) {
                            push(LeaveThisPageModal, {
                                elementID: elementID,
                                disabledSaveAndLeave: true,
                                onSubmit: (action: string) => {
                                    history.push(nextLocation.pathname, {
                                        ...(nextLocation.state || {}),
                                        forceRedirect: true,
                                    });
                                },
                            });
                            return false;
                        }
                        return true;
                    }}
                />
            </>
        }

    </>
    )
}