import PageRoute from "@utils/constants/PageRoute";
import { TypeOfTheme } from "@utils/enum/Theme";
import React, { lazy } from "react";
import { useLocation } from "react-router";
const SupplierConnectTheme = lazy(() => import('./SupplierConnectTheme'));
const AccountSettingTheme = lazy(() => import('./AccountSettingTheme'));
export default function ThemeSelector({ children }: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
    const location = useLocation();
    const checkUseThemeFormPathname = (pathname: string) => {
        if (pathname?.includes(PageRoute.ACCOUNT_SETTING) || pathname?.includes(PageRoute.VERIFY_CHANGE_MAIL)) {
            return TypeOfTheme.ACCOUNT_SETTING;
        }
        else {
            return TypeOfTheme.DEFAULT;
        }
    }

    return (
        <>
            <React.Suspense fallback={<></>}>
                {(checkUseThemeFormPathname(location?.pathname!) === TypeOfTheme.SUPPLIER_CONNECT) && <SupplierConnectTheme />}
                {(checkUseThemeFormPathname(location?.pathname!) === TypeOfTheme.ACCOUNT_SETTING) && <AccountSettingTheme />}
            </React.Suspense>
            {children}
        </>
    )

}