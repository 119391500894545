import React, { lazy, Suspense } from 'react';
import { match, useRouteMatch } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import urljoin from 'url-join';
import SettingLayout from '@components/layout/SettingLayout';
import { SkeletonLoad } from '@components/loading/SkeletonLoad';
import AccountDetailPage from './account-detail/AccountDetailPage';
import { SettingPageRoute } from '@utils/enum/SettingEnum';
import { PrivateRoute } from './../../middlewares/privateRoute';
import { SettingProvider } from './SettingContext';

const NotificationPage = lazy(() => import('./notification/AccountNotification'))

interface RoutesItem {
  path: string;
  component: React.FunctionComponent;
  exact: boolean;
}
export default function SettingRouting(
  props: React.PropsWithChildren<any>
): React.FunctionComponentElement<any> {
  const routeMatch: match<any> = useRouteMatch();

  const privateAccountSettingPageRoutes: RoutesItem[] = [
    { path: SettingPageRoute.ACCOUNT_DETAIL, component: AccountDetailPage, exact: false },
    { path: SettingPageRoute.NOTIFICATION, component: NotificationPage, exact: false },

  ];
  return (
    <>
      <SettingProvider>
        <SettingLayout>
          <Suspense fallback={<SkeletonLoad />}>
            <Switch>
              {privateAccountSettingPageRoutes.map((route) => (
                <PrivateRoute
                  key={route.path}
                  exact
                  path={urljoin(routeMatch.url, route.path)}
                  component={route.component} />
              ))}
              <Redirect to={urljoin(routeMatch.url, SettingPageRoute.ACCOUNT_DETAIL)} />
            </Switch>
          </Suspense>
        </SettingLayout>
      </SettingProvider>
    </>
  );
}
