import { FileClient } from '@api/FileClient';
import { SCButton } from '@components/button/SCButton';
import { DeleteModal } from '@components/modal/DeleteModal';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { IFileDetail } from '@models/File';
import { FileStatus } from '@utils/constants/FileConstant';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import classNames from 'classnames';
import path from 'path';
import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProductImageRequest } from '@api/request/ProductRequest';
import { FileChangedBy } from '@utils/constants/FileConstant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import dayjs from 'dayjs';
import emptyFile from '@assets/images/thumbnail/empty-thumbnail-64.png';
import { CropImageModal, ICropImageData, ICropImageState } from './CropImageModal';
import { IconAlertModal } from '@components/modal/IconAlertModal';
import UploadFileIcon from '@assets/images/frontend/not-found.png';
import { useDealsAndDiscountContext } from '../DealsAndDiscountContext';

interface IDraggable {
  draggable?: boolean;
  onDragStart?: (id: string) => void;
  onDrop?: (e: DragEvent) => void;
}
interface IImageCardProps extends IDraggable {
  file: IFileDetail;
  index: number;
  fileThumbnail: string | null;
  isMaximumFiles?: boolean;
  previewImages: IFileDetail[];
  elementID: string;
  enableEdit?: boolean;
  enableDownload?: boolean;
  confirmDeleteImag?: boolean;
  showIconProfile?: boolean;
  imageDescription?: boolean;
  onDelete?: (filename: string) => void;
  onChange: (action: FileChangedBy, filename: string, data: IFileDetail) => void;
  onPreview: (filename: string, previewImages: IFileDetail[]) => void;
  onError?: () => void;
}

export default function ImageCard(
  props: React.PropsWithChildren<IImageCardProps>
): React.FunctionComponentElement<IImageCardProps> {
  const {
    file,
    index,
    fileThumbnail,
    isMaximumFiles,
    previewImages,
    elementID,
    enableEdit,
    enableDownload,
    draggable,
    confirmDeleteImag,
    showIconProfile,
    imageDescription,
    onDelete,
    onChange,
    onPreview,
    onDragStart,
    onDrop,
    onError,
  } = props;

  const { t } = useTranslation();
  const { push } = useModalStack();

  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const { setIsLoadingImage, isLoadingImage } = useDealsAndDiscountContext();
  const [showModalCrop, setShowModalCrop] = useState<boolean>(false);
  const [cropImageDataModal, setCropImageDataModal] = useState<ICropImageData>();
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    file.order = index + 1;
  }, [file, index]);

  useEffect(() => {
    if (includeFileStatus(file.status, [FileStatus.PENDING])) {
      uploadFile(file);
    }
  }, [file.status]);

  const includeFileStatus = (status: FileStatus, validStatusList: FileStatus[]) => {
    return validStatusList.includes(status);
  };

  const onUploadProgress = useCallback((progressEvent: ProgressEvent) => {
    setUploadPercentage(Math.round((progressEvent.loaded / progressEvent.total) * 100));
  }, []);

  const uploadFile = useCallback((fileDetail: IFileDetail) => {
    FileClient.uploadPromotionImage(fileDetail.file!, 'image', onUploadProgress).subscribe(
      (res) => {
        fileDetail.uuid = res.data.uuid;
        fileDetail.originalFileUrl = res.data.originalFileUrl;
        fileDetail.fileUrl = res.data.fileUrl;
        fileDetail.originalFileName = res.data.originalFileName;
        fileDetail.uploadByUserID = res.data.uploadByUserID;
        fileDetail.uploadByUserName = res.data.uploadByUserName;
        fileDetail.uploadDate = CommonHelper.getTimestampFormUnix(dayjs(res.data.uploadDate).unix());
        fileDetail.status = FileStatus.UPLOADED;
        fileDetail.file = undefined;
        onChange(FileChangedBy.UPLOAD, fileDetail.originalFileName, fileDetail);
        onClickEditImage(fileDetail, true);
      },
      () => {
        fileDetail.status = FileStatus.UPLOAD_FAIL;
        onChange(FileChangedBy.UPLOAD, fileDetail.originalFileName!, fileDetail);
        push(IconAlertModal, {
          data: {
            title: t('Common.Modal.UploadFile.Title.Failed'),
            description: t('Common.Modal.UploadFile.Description.SomethingWentWrong'),
            icon: UploadFileIcon,
            cancelBtn: true,
            cancelText: t('Common.Modal.UploadFile.Button.Close'),
            submitText: t('Common.Modal.UploadFile.Button.TryAgain'),
            submitBtnIcon: 'reload'
          },
          pageElementID: elementID,
          onClose: () => {
          },
          onSubmit: () => {
            onClickReload(fileDetail)
          },
        });
      }
    );
  }, []);

  const onClickDownload = useCallback((url: string, originalFileName: string) => {
    FileClient.downloadFile(url, originalFileName).subscribe();
  }, []);

  const onClickDelete = useCallback(
    (fileDetail: IFileDetail) => {
      if (fileDetail.status === FileStatus.UPLOADED) {
        if (confirmDeleteImag) {
          push(DeleteModal, {
            data: {
              title: t('UploadImagesComponent.Modal.DeleteModal.Title'),
              description: t('UploadImagesComponent.Modal.DeleteModal.Description'),
            },
            pageElementID: elementID,
            onClose: () => { },
            onSubmit: () => onConfirmDelete(fileDetail),
          });
        } else {
          onConfirmDelete(fileDetail)
        }
      } else {
        onDelete && onDelete(fileDetail.originalFileName!);
      }
    },
    [elementID]
  );

  const onConfirmDelete = useCallback((fileDetail: IFileDetail) => {
    onDelete && onDelete(fileDetail.originalFileName!);
  }, []);

  const onClickReload = useCallback((fileDetail: IFileDetail) => {
    fileDetail.status = FileStatus.PENDING;
    onChange(FileChangedBy.UPLOAD, fileDetail.originalFileName!, fileDetail);
  }, []);

  const onClickPreview = useCallback((fileDetail: IFileDetail, previewImages: IFileDetail[]) => {
    if (fileDetail.status === FileStatus.UPLOADED) {
      onPreview(fileDetail.originalFileName!, previewImages);
    }
  }, []);

  const onSaveEditedImage = (fileDetail: IFileDetail, val: ICropImageState) => {
    setIsLoadingImage(true);
    setUploading(true);
    fileDetail.status = FileStatus.PENDING_EDIT;
    const request: EditProductImageRequest = {
      uuid: fileDetail.uuid!,
      extension: path.extname(fileDetail.originalFileUrl!).slice(1),
      width: val.cropArea?.width!,
      height: val.cropArea?.height!,
      x: val.cropArea?.x!,
      y: val.cropArea?.y!,
      degree: val.rotation!,
      verticalFlip: val.flip.vertical,
      horizontalFlip: val.flip.horizontal,
    };
    FileClient.editPromotionImage(request, onUploadProgress)
      .subscribe(
        (res) => {
          fileDetail.uuid = res.uuid;
          fileDetail.originalFileUrl = res.originalFileUrl;
          fileDetail.fileUrl = res.fileUrl + `?v=${new Date().getTime()}`;
          fileDetail.uploadByUserID = res.uploadByUserID;
          fileDetail.uploadByUserName = res.uploadByUserName;
          fileDetail.uploadDate = CommonHelper.getTimestampFormUnix(dayjs(res.uploadDate).unix());
          fileDetail.extension = res.extension;
          onChange(FileChangedBy.EDIT, fileDetail.originalFileName!, fileDetail);
          setIsLoadingImage(false);
          setShowModalCrop(false);
        },
        (err) => {
          onChange(FileChangedBy.EDIT, fileDetail.originalFileName!, fileDetail);

          push(IconAlertModal, {
            data: {
              title: t('Common.Modal.UploadFile.Title.Failed'),
              description: t('Common.Modal.UploadFile.Description.SomethingWentWrong'),
              icon: UploadFileIcon,
              cancelBtn: true,
              cancelText: t('Common.Modal.UploadFile.Button.Close'),
              submitText: t('Common.Modal.UploadFile.Button.TryAgain'),
              submitBtnIcon: 'reload'
            },
            pageElementID: elementID,
            onClose: () => {
              onError && onError();
              setShowModalCrop(false);
            },
            onSubmit: () => {
              onSaveEditedImage(fileDetail, val)
              setShowModalCrop(true);
            },
          });
        }
      )
      .add(() => {
        fileDetail.status = FileStatus.UPLOADED;
        setUploading(false);
        setIsLoadingImage(false);
      });
  };

  const onClickEditImage = (file: IFileDetail, notShowCancelBtn?: boolean) => {
    setCropImageDataModal({ attachmentURL: file.originalFileUrl!, notShowCancelBtn: notShowCancelBtn! } as ICropImageData);
    setShowModalCrop(true);
  };

  const showThumbnailIcon = useCallback(
    (fileDetail: IFileDetail, fileThumbnail?: string): boolean => {
      return fileThumbnail !== undefined && fileDetail.originalFileName === fileThumbnail;
    },
    []
  );

  return (
    <>
      <div
        className={classNames(
          'upload-banner',
          {
            uploading: includeFileStatus(file.status, [
              FileStatus.PENDING,
              FileStatus.PENDING_EDIT,
            ]),
          },
          {
            'is-invalid': includeFileStatus(file.status, [
              FileStatus.UPLOAD_FAIL,
              FileStatus.INVALID,
            ]),
          }
        )}
        id={`div-${elementID}-box-${file.uuid}`}
        draggable={draggable}
        onDragOver={(e) => e.preventDefault()}
        onDragStart={(e) => onDragStart && onDragStart(`div-${elementID}-box-${file.uuid}`)}
        onDrop={(e) => onDrop && onDrop(e)}
      >
        <div
          id={`div-${elementID}-image-${index}`}
          onClick={() =>
            !includeFileStatus(file.status, [FileStatus.PENDING, FileStatus.PENDING_EDIT]) &&
            onClickPreview(file, previewImages)
          }
        >
          {includeFileStatus(file.status, [FileStatus.PENDING, FileStatus.PENDING_EDIT]) && (
            <i className="icon-loading spinner"></i>
          )}
          {showIconProfile && showThumbnailIcon(file, fileThumbnail!) && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="m-0" id={``}>
                  {t('ProductManagement.Tooltips.CoverPhoto')}
                </Tooltip>
              }
            >
              <i id={`ico-${elementID}-thumbnail-${index}`} className="icon-thumbnail"></i>
            </OverlayTrigger>
          )}
          <div className="box-action">
            <div className="thumbnail-btn">
              {file.status === FileStatus.UPLOAD_FAIL && !isMaximumFiles && (
                <button
                  id={`btn-${elementID}-reupload-${index}`}
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickReload(file);
                  }}
                >
                  <i className="icon-reload">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              )}
              {enableEdit && file.status === FileStatus.UPLOADED && (
                <SCButton
                  type="secondary"
                  icon="crop"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickEditImage(file);
                  }}
                />
              )}
              {enableDownload && file.status === FileStatus.UPLOADED && (
                <button
                  id={`btn-${elementID}-download-${index}`}
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickDownload(file.originalFileUrl!, file.originalFileName!);
                  }}
                >
                  <i className="icon-download">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              )}
              {file.status !== FileStatus.PENDING && onDelete && (
                <button
                  id={`btn-${elementID}-delete-${index}`}
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickDelete(file);
                  }}
                >
                  <i className="icon-delete">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              )}
            </div>
          </div>
          {file.fileUrl && file.status !== FileStatus.PENDING_EDIT && (
            <img draggable={false} src={file.fileUrl} alt={file.originalFileName!} onError={(ev: any) => { ev.target.src = emptyFile }} />
          )}
        </div>
      </div>

      {showModalCrop &&
        <CropImageModal
          elementID={`${elementID}-editImage`}
          show={showModalCrop}
          data={cropImageDataModal!}
          loading={uploading}
          onClose={(val: any) => {
            if (val) { onSaveEditedImage(file, val) }
            else {
              setShowModalCrop(false);
            }
          }}
        />
      }
    </>
  );
}
