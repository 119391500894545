import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/layout/Header';
import mailPng from '../../../assets/images/frontend/send-email.png';
import PageRoute from '@utils/constants/PageRoute';
import { Button } from 'react-bootstrap';
import { CompanyItem, usePTVNAuthentication } from '@ptvn-react/authentication';
import { Subscription } from 'rxjs';
import { CompanyClient } from '@api/CompanyClient';
import { BranchConstant, UserBranchStatus } from '@utils/constants/BranchConstant';
import { BranchResponse } from '@api/response/CompanyResponse';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import parse from 'html-react-parser';
import { Language } from '@utils/constants/CommonConstants';

export default function SentRequestToJoin(
  props: React.PropsWithChildren<any>
): React.FunctionComponentElement<any> {
  const { t, i18n } = useTranslation();
  const { push } = useModalStack();
  const {
    currentCompany,
    extendedUserInformation,
    setCompanies,
    setExtendedUserInformation,
    setCurrentCompany,
  } = usePTVNAuthentication();

  useEffect(() => {
    getCurrentCompany();
  }, []);

  const getCurrentCompany = () => {
    let subscriptionGetMyCompany: Subscription;

    subscriptionGetMyCompany = CompanyClient.getCompany()
      .subscribe(
        (res) => {
          if (res && res.companies.length) {
            const companies = res.companies.map((company, index) => ({
              companyID: company.companyId,
              taxID: company.taxId,
              companyNameEN: company.companyNameEN,
              companyNameLocal: company.companyName,
              branchId: findBranchNotCancelOrNotReject(company.branches).branchId,
              branchNameEN: findBranchNotCancelOrNotReject(company.branches).branchNameEN,
              branchNameLocal: findBranchNotCancelOrNotReject(company.branches).branchName,
              branchNumber: findBranchNotCancelOrNotReject(company.branches).branchNumber,
              headQuarterBranch:
                findBranchNotCancelOrNotReject(company.branches).branchNumber ===
                BranchConstant.HEAD_QUARTER,
              countryCode: company.countryCode,
              isDefault: index === 0,
              isFullService: false,
              orgID: findBranchNotCancelOrNotReject(company.branches).orgID,
            }));
            setCompanies(companies);
            setCurrentCompany(companies[companies.length - 1].companyID);
          }
          setExtendedUserInformation({ ...extendedUserInformation, companies: res.companies });
        },
        (_err) => push(SomethingWentWrongModal, { onSubmit: () => { } })
      )
      .add(() => {
        subscriptionGetMyCompany.unsubscribe();
      });
  };

  const findBranchNotCancelOrNotReject = (branchList: BranchResponse[]): BranchResponse => {
    const defaultBranchResponse: BranchResponse = {
      branchId: 0,
      branchName: '',
      branchNameEN: '',
      branchNumber: '',
      jobTitleId: undefined,
      otherJobTitle: undefined,
      userBranchStatusId: undefined,
      userBranchStatusDescription: undefined,
      actionDated: '',
      orgID: '',
      branchStatusId: 0
    };
    if (branchList) {
      const newBranchResponse = branchList.find(
        (branch) =>
          branch.userBranchStatusId !== UserBranchStatus.REJECTED &&
          branch.userBranchStatusId !== UserBranchStatus.CANCEL_REQUEST_TO_JOIN
      );
      if (newBranchResponse) {
        return newBranchResponse;
      }
    }
    return defaultBranchResponse;
  };

  const redirectToHome = () => {
    window.location.href = PageRoute.LANDING;
  };

  return (
    <>
      <Header notShowIconSideMenu={true} noAppSwitcher={true} clickLogoNoRedirect={true} />
      <div className="content">
        <div className="card-content">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <img src={mailPng} className="card-icon"></img>
                <div className="title">{t('WaitingCompany.Title.SendMail')}</div>
                <p className="mb-3">
                  {parse(
                    t('WaitingCompany.Label.SendMail', {
                      companyName: (i18n.language === Language.en.key) ? currentCompany?.companyNameEN || currentCompany?.companyNameLocal : currentCompany?.companyNameLocal || currentCompany?.companyNameEN,
                      branch: (currentCompany?.branchNumber ? `${currentCompany?.branchNumber} ` : '') +
                        (i18n.language === Language.en.key) ? currentCompany?.branchNameEN || currentCompany?.branchNameLocal : currentCompany?.branchNameLocal || currentCompany?.branchNameEN
                    })
                  )}
                </p>
                <Button variant="primary" id={`btn-goToHomePage`} onClick={() => redirectToHome()}>
                  {t('Common.Button.GoToHomepage')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
