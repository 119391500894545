import environment from 'environment';

export const oneTrustScript = () => {

    const scriptOtSDKStub = document.createElement("script");
    scriptOtSDKStub.setAttribute('type', 'text/javascript');
    scriptOtSDKStub.setAttribute('src', `${environment.oneTrust}/scripttemplates/otSDKStub.js`);
    scriptOtSDKStub.setAttribute('charset', 'UTF-8');
    scriptOtSDKStub.setAttribute('data-domain-script', environment.oneTrustDataDomainScript);
    scriptOtSDKStub.setAttribute('data-document-language', 'true');
    document.getElementsByTagName("head")[0].appendChild(scriptOtSDKStub);

    const scriptFunction = document.createElement("script");
    scriptFunction.setAttribute('type', 'text/javascript');
    scriptFunction.innerText = "function OptanonWrapper() {}";
    document.getElementsByTagName("head")[0].appendChild(scriptFunction);

}
