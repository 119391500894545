import { isEmpty } from 'lodash';
import React, { UIEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useModalStack } from '@components/modal/ModalStackProvider';
import {
  SupplierProductListItemFragment,
  SupplierPromotionListItemFragment,
} from '@graphql/autogenerate/operations';
import {
  DealsAndDiscountListStatusEnum,
  DealsAndDiscountPageRoute,
} from '@utils/enum/DealsAndDiscountEnum';
import {
  LangType,
  ProductListSortByType,
  PromotionListFilterStatusType,
  PromotionListSortByType,
  SortDirType,
} from '@graphql/autogenerate/schemas';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { DateTimeFormat } from '@utils/constants/CommonConstants';
import PageRoute from '@utils/constants/PageRoute';
import { CancelModal } from '@components/modal/CancelModal';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import { DealsAndDiscountHelper } from '@utils/helpers/DealsAndDiscountHelper';
import { useUpsertPromotionMutation } from '@graphql/autogenerate/hooks';
import { ApolloError } from '@apollo/client';
import { DeleteModal } from '@components/modal/DeleteModal';
import { useSmartAlert } from '@components/smart-alert/SmartAlert';
import { DescriptionModal } from '@components/modal/DescriptionModal';
import { DescriptionWithFooterModal } from '@components/modal/DescriptionWithFooterModal';
import useGoogleAnalytics from '@google/useGoogleAnalytics';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsConstantEvent } from '@utils/constants/GoogleAnalyticsConstant';
import { EventArgs } from 'react-ga';

interface IProps {
  elementIdPrefix: string;
  promotionList: (SupplierPromotionListItemFragment | null)[];
  sortBy: PromotionListSortByType;
  sortNameBy: LangType;
  sortDir: SortDirType;
  onSortChange: (by: PromotionListSortByType, sortName: LangType, dir: SortDirType) => void;
  onSelectItem: (promotionID: number[]) => void;
  onAction: () => void;
}

interface ItemChecked {
  itemID: number;
  checked: boolean;
}

export default function PromotionListTable(
  props: React.PropsWithChildren<IProps>
): React.FunctionComponentElement<IProps> {
  const {
    elementIdPrefix,
    promotionList,
    sortBy,
    sortNameBy,
    sortDir,
    onSortChange,
    onSelectItem,
    onAction,
  } = props;

  const elementID = `${elementIdPrefix}-product`;
  const alert = useSmartAlert();
  const history = useHistory();
  const { push } = useModalStack();
  const { t } = useTranslation();
  const [upsertPromotionMutation] = useUpsertPromotionMutation();
  const { requestGAObservable } = useGoogleAnalytics();

  const wrapperTable = useRef<HTMLDivElement>(null);
  const tableColFix = useRef<HTMLTableElement>(null);

  const [selectedItem, setSelectedItem] = useState<ItemChecked[]>(
    promotionList.map((p) => {
      return {
        itemID: p!.promotionID!,
        checked: false,
      } as ItemChecked;
    })
  );
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    window.addEventListener('resize', handleResizeTable);
    return () => {
      window.removeEventListener('resize', handleResizeTable);
    };
  }, []);

  useEffect(() => {
    return () => {
      subscription && subscription.unsubscribe();
    }
  }, [subscription]);

  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      if (selectedItem.every((item) => item.checked)) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      onSelectItem(selectedItem.filter((item) => item.checked).map((item) => item.itemID));
    }
  }, [selectedItem]);

  const handleResizeTable = () => {
    if (wrapperTable?.current && tableColFix?.current) {
      if (tableColFix.current.offsetWidth > wrapperTable.current.offsetWidth) {
        wrapperTable.current.classList.add('slide-left');
      } else {
        wrapperTable.current.classList.remove('slide-left');
      }
    }
  };

  const listenScrollEvent = (event: UIEvent<HTMLDivElement | UIEvent>) => {
    const table = event.currentTarget as HTMLDivElement;
    if (table.scrollLeft === 0) {
      table.classList.add('slide-left');
      table.classList.remove('slide-right');
    } else if (table.scrollLeft === table.scrollWidth - table.offsetWidth) {
      table.classList.remove('slide-left');
    } else if (table.scrollLeft !== 0) {
      table.classList.add('slide-left');
      table.classList.add('slide-right');
    }
  };

  const renderStatus = (promotion: SupplierPromotionListItemFragment | null, index: number) => {
    switch (promotion?.status?.toUpperCase()) {
      case PromotionListFilterStatusType.Ongoing:
        return (
          <div className="d-flex status-flex" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-green">{t('DealsAndDiscount.Label.Ongoing')}</div>
            </div>
          </div>
        );
      case PromotionListFilterStatusType.Upcoming:
        return (
          <div className="d-flex status-flex" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-blue">{t('DealsAndDiscount.Label.Upcoming')}</div>
            </div>
          </div>
        );
      case PromotionListFilterStatusType.Draft:
        return (
          <div className="d-flex status-flex" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-yellow">{t('DealsAndDiscount.Label.Draft')}</div>
            </div>
          </div>
        );

      case PromotionListFilterStatusType.Cancelled:
        return (
          <>
            <div className="d-flex status-flex" id={`lbl-${elementID}-status-${index}`}>
              <div className="d-flex align-items-center">
                <div className="status status-dark-gray">
                  {t('DealsAndDiscount.Label.Cancelled')}
                </div>
              </div>

              {promotion && promotion?.remark ? (
                <>
                  <div className="ml-1">
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={
                        <Tooltip
                          className="m-0"
                          id={`lbl-${elementID}-note-tooltip`}
                          style={{ zIndex: 999 }}
                        >
                          {t('DealsAndDiscount.Table.Body.Note')}
                        </Tooltip>
                      }
                    >
                      <button
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Note"
                        className="btn btn-icon"
                        onClick={() => handleClickNote(promotion)}
                      >
                        <i className="icon-note">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </button>
                    </OverlayTrigger>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        );

      case PromotionListFilterStatusType.Expired:
        return (
          <div className="d-flex status-flex" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-light-gray">{t('DealsAndDiscount.Label.Expired')}</div>
            </div>
          </div>
        );
    }
  };

  const handleClickNote = (promotion: SupplierPromotionListItemFragment) => {
    push(DescriptionWithFooterModal, {
      elementID: elementID,
      data: {
        title: t('DealsAndDiscount.Modal.NotePromotion.Title'),
        description: promotion.remark! || '',
        actor: promotion.lastUpdateBy! || '',
        date:
          CommonHelper.displayDate(
            promotion?.lastUpdate,
            DateTimeFormat.DEFAULT_DATE_TIME_FORMAT
          ) || '-',
      },
    });
  };

  const handleClickEdit = (promotion: SupplierPromotionListItemFragment) => {
    setSubscription(requestGAObservable({
      suffixPathAction: GoogleAnalyticsConstantEvent.EDIT_PROMOTION,
      event: { action: GoogleAnalyticsConstantEvent.EDIT_PROMOTION } as EventArgs
    }).subscribe(() => {
      const promotionID = promotion.promotionID!.toString();
      history.push(`${PageRoute.DEALS_AND_DISCOUNT_EDIT.replace(':promotionID', promotionID)}`);
    }));
  };

  const handleClickDetail = (promotion: SupplierPromotionListItemFragment) => {
    setSubscription(requestGAObservable({
      suffixPathAction: GoogleAnalyticsConstantEvent.VIEW_PROMOTION,
      event: { action: GoogleAnalyticsConstantEvent.VIEW_PROMOTION } as EventArgs
    }).subscribe(() => {
      const promotionID = promotion.promotionID!.toString();
      history.push(`${PageRoute.DEALS_AND_DISCOUNT_DETAIL.replace(':promotionID', promotionID)}`);
    }));
  };

  const handleClickCancel = (promotion: SupplierPromotionListItemFragment) => {
    push(CancelModal, {
      data: {
        title: t('DealsAndDiscount.Modal.CancelPromotion.Title'),
        description: t('DealsAndDiscount.Modal.CancelPromotion.Description'),
        submitText: t('Common.Button.YesCancel'),
        cancelText: t('Common.Button.No'),
        submitType: 'primary',
        label: t('Common.Label.Note'),
      },
      pageElementID: elementID,
      onClose: () => { },
      onSubmit: (value: string) => {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.CANCEL_PROMOTION,
          event: { action: GoogleAnalyticsConstantEvent.CANCEL_PROMOTION } as EventArgs
        }).subscribe(() => {
          cancelPromotion(promotion, value);
        }));
      }
    });
  };

  const handleClickDelete = (promotion: SupplierPromotionListItemFragment) => {
    push(DeleteModal, {
      data: {
        title: t('DealsAndDiscount.Modal.DeletePromotion.Title'),
        description: t('DealsAndDiscount.Modal.DeletePromotion.Description'),
        submitText: t('Common.Button.YesDelete'),
        cancelText: t('Common.Button.No'),
      },
      pageElementID: elementID,
      onClose: () => { },
      onSubmit: () => {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.DELETE_PROMOTION,
          event: { action: GoogleAnalyticsConstantEvent.DELETE_PROMOTION } as EventArgs
        }).subscribe(() => {
          deletePromotion(promotion);
        }));
      }
    });
  };

  const cancelPromotion = (promotion: SupplierPromotionListItemFragment, remark: string) => {
    upsertPromotionMutation({
      variables: {
        input: {
          ...DealsAndDiscountHelper.getUpdateStatusRequestObject('cancelled', remark, promotion),
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        } else {
          alert.show(t('DealsAndDiscount.Alert.YourPromotionCancelled'), 'success', true);
          onAction();
        }
      })
      .catch((error: ApolloError) => {
        const ex = CommonHelper.transformGraphQLErrors(error);
        if (ex && ex.length > 0) {
          let errMessage = '';
          ex.forEach((e) => {
            if (e.message) {
              if (errMessage) {
                errMessage += ' ' + e.message;
              } else {
                errMessage += e.message;
              }
            }
          });
          push(SomethingWentWrongModal, {
            errMessage: errMessage,
            onSubmit: () => { },
          });
        } else {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        }
      });
  };

  const deletePromotion = (promotion: SupplierPromotionListItemFragment) => {
    upsertPromotionMutation({
      variables: {
        input: {
          ...DealsAndDiscountHelper.getUpdateStatusRequestObject(
            'deleted',
            promotion.remark,
            promotion
          ),
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        } else {
          alert.show(t('DealsAndDiscount.Alert.YourPromotionDeleted'), 'success', true);
          onAction();
        }
      })
      .catch((error: ApolloError) => {
        const ex = CommonHelper.transformGraphQLErrors(error);
        if (ex && ex.length > 0) {
          let errMessage = '';
          ex.forEach((e) => {
            if (e.message) {
              if (errMessage) {
                errMessage += ' ' + e.message;
              } else {
                errMessage += e.message;
              }
            }
          });
          push(SomethingWentWrongModal, {
            errMessage: errMessage,
            onSubmit: () => { },
          });
        } else {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        }
      });
  };

  const actionDelete = (promotion: SupplierPromotionListItemFragment, index: number) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-action-delete-${index}`}
        className="dropdown-item"
        onSelect={() => handleClickDelete(promotion)}
      >
        <div className="dropdown-inline-icon">
          <i className="icon-delete">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="dropdown-inline-text">{t('DealsAndDiscount.Table.Action.Delete')}</div>
        </div>
      </Dropdown.Item>
    );
  };

  const actionCancel = (promotion: SupplierPromotionListItemFragment, index: number) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-action-cancel-${index}`}
        className="dropdown-item"
        onSelect={() => handleClickCancel(promotion)}
      >
        <div className="dropdown-inline-icon">
          <i className="icon-error">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="dropdown-inline-text">{t('DealsAndDiscount.Table.Action.Cancel')}</div>
        </div>
      </Dropdown.Item>
    );
  };

  const actionEdit = (promotion: SupplierPromotionListItemFragment, index: number) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-action-edit-${index}`}
        className="dropdown-item"
        onSelect={() => handleClickEdit(promotion)}
      >
        <div className="dropdown-inline-icon">
          <i className="icon-edit">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="dropdown-inline-text">{t('DealsAndDiscount.Table.Action.Edit')}</div>
        </div>
      </Dropdown.Item>
    );
  };

  const renderAction = (promotion: SupplierPromotionListItemFragment | null, index: number) => {
    switch (promotion?.status?.toUpperCase()) {
      case DealsAndDiscountListStatusEnum.ONGOING:
        return (
          <>
            {actionEdit(promotion, index)}
            {actionCancel(promotion, index)}
          </>
        );
      case DealsAndDiscountListStatusEnum.UPCOMING:
        return (
          <>
            {actionEdit(promotion, index)}
            {actionCancel(promotion, index)}
          </>
        );
      case DealsAndDiscountListStatusEnum.DRAFT:
        return (
          <>
            {actionEdit(promotion, index)}
            {actionDelete(promotion, index)}
          </>
        );
      case DealsAndDiscountListStatusEnum.CANCELLED:
        return <></>;
      case DealsAndDiscountListStatusEnum.EXPIRED:
        return <></>;
    }
  };

  return (
    <div className="table-sort-overflow table-wrap" onScroll={listenScrollEvent} ref={wrapperTable}>
      <table className="table-no-berder" ref={tableColFix}>
        <colgroup>
          <col style={{ width: '675px' }} />
          <col style={{ width: '150px' }} />
          <col style={{ width: '72px' }} />
          <col style={{ width: '210px' }} />
          <col style={{ width: '210px' }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              <div className="d-flex align-items-center nowrap">
                {t('DealsAndDiscount.Table.Header.Promotion')}
                <Dropdown>
                  <Dropdown.Toggle
                    id={`btn-${elementID}-sort`}
                    as="a"
                    variant=""
                    bsPrefix="btn-icon"
                  >
                    {sortBy === PromotionListSortByType.PromotionName ? (
                      <i
                        className={classNames('icon-active', {
                          'icon-sort asc ml-8px': sortDir === SortDirType.Desc,
                          'icon-sort desc ml-8px': sortDir === SortDirType.Asc,
                        })}
                      >
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    ) : (
                      <i className="icon-sort ml-8px">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ minWidth: '230px' }}>
                    <div className="dropdown-list">
                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-asc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.PromotionName,
                            LangType.En,
                            SortDirType.Asc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.PromotionNameEnAsc')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-desc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.PromotionName,
                            LangType.En,
                            SortDirType.Desc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.PromotionNameEnDesc')}
                      </Dropdown.Item>

                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-asc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.PromotionName,
                            LangType.Local,
                            SortDirType.Asc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.PromotionNameLocalAsc')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-desc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.PromotionName,
                            LangType.Local,
                            SortDirType.Desc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.PromotionNameLocalDesc')}
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </th>
            <th>{t('DealsAndDiscount.Table.Header.Status')}</th>
            <th>&nbsp;</th>
            <th>
              <div className="d-flex align-items-center nowrap">
                {t('DealsAndDiscount.Table.Header.Period')}
                <Dropdown>
                  <Dropdown.Toggle
                    id={`btn-${elementID}-sort`}
                    as="a"
                    variant=""
                    bsPrefix="btn-icon"
                  >
                    {sortBy === PromotionListSortByType.StartDate ||
                      sortBy === PromotionListSortByType.EndDate ? (
                      <i
                        className={classNames('icon-active', {
                          'icon-sort asc ml-8px': sortDir === SortDirType.Desc,
                          'icon-sort desc ml-8px': sortDir === SortDirType.Asc,
                        })}
                      >
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    ) : (
                      <i className="icon-sort ml-8px">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-list">
                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-asc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.StartDate,
                            LangType.En,
                            SortDirType.Asc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.StartDateDesc')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-desc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.StartDate,
                            LangType.En,
                            SortDirType.Desc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.StartDateAsc')}
                      </Dropdown.Item>

                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-asc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.EndDate,
                            LangType.En,
                            SortDirType.Asc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.EndDateDesc')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        id={`btn-${elementID}-sort-sku-desc`}
                        onSelect={() =>
                          onSortChange(
                            PromotionListSortByType.EndDate,
                            LangType.En,
                            SortDirType.Desc
                          )
                        }
                      >
                        {t('DealsAndDiscount.Table.Sorting.EndDateAsc')}
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </th>
            <th>{t('DealsAndDiscount.Table.Header.DiscountDetails')}</th>
          </tr>
        </thead>
        <tbody>
          {promotionList.map((promotion, index) => {
            return (
              <tr key={index}>
                <td>
                  <a onClick={() => handleClickDetail(promotion!)}>
                    <div className="d-flex align-items-start">
                      <div className="d-block">
                        <strong id={`lbl-${elementID}-name-${index}`} className="ws-pre">
                          {promotion?.name || '-'}
                        </strong>
                      </div>
                    </div>
                  </a>
                </td>
                <td>{renderStatus(promotion, index)}</td>
                <td>
                  {promotion?.status?.toUpperCase() !== DealsAndDiscountListStatusEnum.CANCELLED &&
                    promotion?.status?.toUpperCase() !== DealsAndDiscountListStatusEnum.EXPIRED ? (
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={
                        <Tooltip
                          className="m-0"
                          id={`lbl-${elementID}-action-tooltip`}
                          style={{ zIndex: 999 }}
                        >
                          {t('DealsAndDiscount.Table.Body.Action')}
                        </Tooltip>
                      }
                    >
                      <Dropdown
                        as="div"
                        style={{ borderRadius: 'unset' }}
                        className="btn-dropdown out-dropdown"
                        bsPrefix="-"
                      >
                        <Dropdown.Toggle
                          id={`ddl-${elementID}-option-${index}`}
                          as="button"
                          bsPrefix="btn"
                          className="btn-icon btn-action-table"
                        >
                          <i className="icon-action"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>{renderAction(promotion, index)}</Dropdown.Menu>
                      </Dropdown>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  <div className="box-period">
                    <div className="bl">{t('DealsAndDiscount.Table.Body.Start')}</div>
                    <div className="br" id={`lbl-${elementID}-startdate-${index}`}>
                      {CommonHelper.displayDate(
                        promotion?.startDate,
                        DateTimeFormat.DEFAULT_DATE_TIME_FORMAT
                      ) || '-'}
                    </div>
                  </div>
                  <div className="box-period mt-4px">
                    <div className="bl">{t('DealsAndDiscount.Table.Body.End')}</div>
                    <div className="br" id={`lbl-${elementID}-enddate-${index}`}>
                      {promotion?.endDate != DateTimeFormat.MAX_DATE ? (
                        <>
                          {CommonHelper.displayDate(
                            promotion?.endDate,
                            DateTimeFormat.DEFAULT_DATE_TIME_FORMAT
                          ) || '-'}
                        </>
                      ) : (
                        <>{t('DealsAndDiscount.Table.Body.NoEndDate')}</>
                      )}
                    </div>
                  </div>
                </td>
                <td id={`lbl-${elementID}-discountdetail-${index}`}>
                  {promotion?.discountDetail || '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
