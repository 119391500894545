import React, { ChangeEvent, useEffect, useState,useRef } from 'react';
import classNames from 'classnames';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DropdownSelectOptionBase } from '@models/Model';
import useOnClickOutside from '@components/hook/OnClickOutside';

export interface DropdownSelectOption extends DropdownSelectOptionBase {}

interface IDropdownSelectProp extends DropdownProps {
  name: string;
  selectedName: string;
  options: DropdownSelectOption[];
  selectedOptionList: any[];
  onChange: (selectedList: any[]) => void;
  submitFilter: () => void;
  clearFilter: () => void;
  label?: string;
  elementIdPrefix: string;
  elementIdSuffix: string;
  className?: string;
}

export default function DropdownMultipleSelectWithSubmit(
  props: React.PropsWithChildren<IDropdownSelectProp>
): React.FunctionComponentElement<IDropdownSelectProp> {
  const {
    name,
    selectedName,
    selectedOptionList,
    options,
    className,
    onChange,
    submitFilter,
    clearFilter,
    elementIdPrefix,
    elementIdSuffix,
  } = props;

  const elementID = `${elementIdPrefix}-${elementIdSuffix}`;
  const ref = useRef(null);
  const { t } = useTranslation();

  const [selectedList, setSelectedList] = useState<any[]>(selectedOptionList);
  const [show, setShow] = useState(false);
  useEffect(() => {
    selectedOptionList && setSelectedList(selectedOptionList);
  }, [selectedOptionList]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    const checked = event.currentTarget.checked;
    if (checked) {
      onChange([...selectedList, value]);
    } else {
      onChange(selectedList.filter((item) => item !== value));
    }
  };

  const handleSubmitFilter = () => {
    selectedOptionList && setSelectedList(selectedOptionList);
    submitFilter();
    setShow(false);
  };

  const handleClearFilter = () => {
    onChange([]);
    clearFilter();
    setShow(false);
  };

  const handleClickOutside = () => {
    setShow(false);
  }

  useOnClickOutside(ref, handleClickOutside);

  return (
    <Dropdown show={show} ref={ref} className={classNames(`${className || ''}`)}>
      <Dropdown.Toggle
        id={`btn-${elementID}`}
        variant=""
        as="span"
        bsPrefix="btn-toggle-tag"
        className="tag-status"
        style={{ marginRight: '8px' }}
        onClick={() => setShow(!show)}
      >
        {selectedList.length > 0 ? (
          <>
            <span className="select-status">{selectedList.length}</span>
            <span>{t(selectedName)}</span>
            <a
              id={`btn-${elementID}-clear`}
              className="clear-select ml-8px"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClearFilter();
              }}
            >
              <i className="icon-cancel"></i>
            </a>
          </>
        ) : (
          <>
            <span>{t(name)}</span>
            <i className="icon-small-dropdown ml-auto"></i>
          </>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option, index) => {
          return (
            <label key={index} className="form-check" htmlFor={`cb-${elementID}-${index}`}>
              <input
                id={`cb-${elementID}-${index}`}
                type="checkbox"
                className="form-check-input"
                checked={!!selectedList.find((item) => item === option.value)}
                onChange={(e) => handleChangeFilter(e, option.value)}
              />
              <span className="form-check-label">
                <span className="nowrap">{t(option.label)}</span>
              </span>
            </label>
          );
        })}
        <div className="dropdown-divider"></div>
        <div className="dropdown-action">
          <div className="d-flex">
            <a onClick={handleClearFilter} className="btn-link">
              {t('Common.Dropdown.Clear')}
            </a>
            <a onClick={handleSubmitFilter} className="btn-link-apply ml-auto">
              {t('Common.Dropdown.Apply')}
            </a>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
