import { IFileDetail } from '@models/File';
import momentTz from 'moment-timezone';
import { DateTimeFormat } from '../constants/CommonConstants';
import { PhoneNumberUtil } from 'google-libphonenumber';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { DeepMap, FieldError, FieldName } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { Country } from '../../models/Address';
import { CountryCode, CountryListConst as CountryConst } from '../constants/CountryConstant';
import { ApolloError } from '@apollo/client';
import { GraphQLErrorResponse } from '../../models/Model';
import { isArray, isPlainObject, mapValues } from 'lodash';
import { CountryResponse } from '@api/response/CountryResponse';
import { PTVNErrorAPI } from '@api/response/BaseResponse';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';

export class CommonHelper {
  public static objectToFormData(obj: any, rootName: string = ''): FormData {
    const formData = new FormData();

    function appendFormData(data: any, root: string) {
      root = root || '';
      if (data instanceof File || data instanceof Blob) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + '.' + key);
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }

    appendFormData(obj, rootName);
    return formData;
  }

  public static getUserClientTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public static composeDateTimeToTimestamp(
    date: string,
    time: string,
    timeZoneID: string,
    dateFormat: string = DateTimeFormat.DDMMYYYY_HYPHEN,
    timeFormat: string = DateTimeFormat.HHmm
  ): number {
    return +momentTz.tz(`${date} ${time}`, `${dateFormat} ${timeFormat}`, timeZoneID).format('x');
  }

  public static isValidPhoneNumber(phone: string): boolean {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parse(phone);
    if (phoneNumber) {
      return phoneNumberUtil.isValidNumber(phoneNumber);
    }
    return false;
  }

  public static objectToQueryString(obj: any, notFilter = false): string {
    const query = Object.keys(obj)
      .filter(
        (key) => (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) || notFilter
      )
      .map((key) => `${key}=${obj[key]}`)
      .join('&');
    return query.length > 0 ? `?${query}` : '';
  }

  public static getUnixFormTimestamp(timeStamp: number): number {
    return timeStamp / 1000;
  }

  public static getTimestampFormUnix(unix: number): number {
    return unix * 1000;
  }

  public static displayDate(timeStamp: number, format: string): string {
    return dayjs.unix(CommonHelper.getUnixFormTimestamp(timeStamp)).format(format);
  }

  public static getDefaultCountryPhoneInput(): Country[] {
    const country = [...CountryConst];
    return country.map((c) => ({
      countryCode: c.alpha2Code,
      titleEN: c.name,
      countryCallingCode: `+${c.callingCodes[0]}`,
    }));
  }

  public static changeDuplicateFilename(fileList: IFileDetail[], file: File): File {
    const filename = file.name;
    const filterList = fileList.filter((item) => item.originalFileName === filename);

    if (filterList.length > 0) {
      const prefixDate = dayjs().format('DDMMYYYYHHmmss');
      let ext = filename.split('.').pop();
      let filenameNoExt = filename;
      if (ext === filename) {
        ext = '';
      } else if (ext) {
        filenameNoExt = filename.substr(0, filename.length - ext.length - 1);
      }

      return new File([file], `${filenameNoExt}_${prefixDate}.${ext}`, { type: file.type });
    } else {
      return file;
    }
  }

  public static renderFieldError<T>(
    t: TFunction,
    errors: DeepMap<T, FieldError>,
    field: FieldName<T>,
    elementID?: string,
    iconClass?: string
  ) {
    if (field && !!get(errors, field)) {
      return (
        <>
          <div id={elementID} className="invalid-feedback" style={{ display: 'block' }}>
            {iconClass && <i className={iconClass}></i>}{t(get(errors, field).message)}
          </div>
        </>
      );
    }
    return <></>;
  }

  public static scrollToError(ref: HTMLElement | null | undefined) {
    if (!ref) return;
    var isInvalidElement: NodeListOf<any> = ref.querySelectorAll('.is-invalid');
    if (!isEmpty(isInvalidElement)) {
      isInvalidElement[0] && isInvalidElement[0].scrollIntoView({ block: 'center' });
      isInvalidElement[0] && isInvalidElement[0].focus();
    } else {
      var invalidFeedback: NodeListOf<any> = ref.querySelectorAll(
        '.invalid-feedback,.font-small.text-danger,.color-danger'
      );
      invalidFeedback[0] && invalidFeedback[0].scrollIntoView({ block: 'center' });
    }
  }

  public static transformGraphQLErrors(error: ApolloError): GraphQLErrorResponse[] | null {
    try {
      return error.graphQLErrors.map((ge) => JSON.parse(ge.message) as GraphQLErrorResponse);
    } catch {
      return null;
    }
  }

  public static nullToUndefined = (value: any): any => {
    if (isPlainObject(value)) {
      return mapValues(value, CommonHelper.nullToUndefined);
    }
    if (isArray(value)) {
      return value.map(CommonHelper.nullToUndefined);
    }
    if (value === null) {
      return undefined; // THIS SHOULD BE UNDEFINED
    }
    return value;
  };

  public static trimValue = (value: string) => {
    return value ? value.replace(/\s+/g, ' ').trim() : '';
  };

  public static transformBEtoCE = (BE: number) => {
    return BE - 543;
  };

  public static splitFileName = (originalFileName: string) => {
    const indexOfDot = originalFileName.lastIndexOf('.');
    const fileName = originalFileName.substring(0, indexOfDot);
    const extension = originalFileName.substring(indexOfDot + 1);

    return {
      fileName,
      extension,
    };
  };

  public static getFileExtension(fileName: string): string {
    return (fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1));
  }

  public static deepEqual(object1: any, object2: any) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;
  }

  public static isObject(object: any) {
    return object != null && typeof object === 'object';
  }

  public static renderFlagCountry = (countryCode?: string, className?: string): JSX.Element => {
    if (countryCode) {
      try {
        var icon = require(`@assets/images/flags/${countryCode}.png`);
        return (!!icon?.default) ? <img src={icon.default} className={className} /> : <></>;
      }
      catch (err) {
        return <></>
      }
    } else {
      return <></>;
    }
  };

  public static spliceIntoChunks = (list: any[], chunkSize: number): any[] => {
    const res = [];
    while (list.length > 0) {
      const chunk = list.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  }

  public static renderPhone = (callingCode?: string, phoneNumber?: string, ext?: string, extWording?: string) => {
    let response = '';

    if (callingCode! && phoneNumber) {
      response += `${callingCode}`;
    }

    if (phoneNumber) {
      response += (response) ? ` ${phoneNumber}` : `${phoneNumber}`;
    }

    if (response && phoneNumber && ext) {
      if (extWording) {
        response += ` ${extWording} ${ext}`;
      } else {
        response += ` ${ext}`;
      }
    }
    return response || '-';
  }

  public static getCountryCodeFromPhoneCallingCode = (callingCode: string, number: string, defaultCountryCode?: string) => {
    if (callingCode && number) {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const numberProto = phoneUtil.parse(
          callingCode! + number!,
          ''
        );
        const countryCode = numberProto.getCountryCode();
        return phoneUtil.getRegionCodeForCountryCode(countryCode!);
      } catch (errors) {
        return defaultCountryCode || CountryCode.TH;
      }
    } else {
      return defaultCountryCode || CountryCode.TH;
    }
  }

  public static coutryByCountryCode = (countries: CountryResponse[], countryCode: string): CountryResponse => {
    return countries.filter(f => f.countryCode == countryCode)[0];
  }

  public static handleErrorSomtingWentWrong = (err: any, pushModal: any, onSubmit?: () => void) => {
    let error = err?.data?.errors! as PTVNErrorAPI[];
    if (error && error?.length > 0) {
      pushModal(SomethingWentWrongModal, {
        errMessage: JSON.stringify(error),
        errorCode: error.map(e => e.code).toString(),
        onSubmit: () => onSubmit && onSubmit()
      })
    } else {
      pushModal(SomethingWentWrongModal, {
        errMessage: JSON.stringify(err),
        onSubmit: () => onSubmit && onSubmit()
      });
    }
  }

  public static clearCheckRadioSuggestCategory() {
    const radioButtons = document.getElementsByName('radioSuggestion');
    for (let i = 0; i < radioButtons.length; i++) {
      (radioButtons[i] as HTMLInputElement).checked = false;
    }
  }
}
