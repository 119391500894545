export enum DealsAndDiscountPageRoute {
  LIST = 'list',
  ADD = 'add',
  DETAIL = ':promotionID/detail',
  DRAFT = '',
  EDIT = ':promotionID/edit',
}

export type DealsAndDiscountActionType = 'add' | 'edit' | 'notFound' | 'error';

export enum DealsAndDiscountAction {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  NOT_FOUND = 'notFound',
  ERROR = 'error',
}

export enum DiscountTypeOptionEnum {
  percent = 'percent',
  value = 'value',
}

export enum ErrorCodeEnum {
  NOT_FOUND = '4401',
  PROMOTION_DELETED = '4205'
}

export enum PromotionStatusEnum {
  PENDING = 'pending'
}

export enum DealsAndDiscountListStatusEnum {
  ONGOING = 'ONGOING',
  UPCOMING = 'UPCOMING',
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
  EXPIRED='EXPIRED'
}
