export enum FieldIdEnum {
    PASSWORD = 'password',
    CONFIRM_PASSWORD = 'confirmPassword',
}

export enum ErrorEnum {
    TAX_ID_ALREADY_EXITS = '4012',
    BRANCH_NUMBER_ALREADY_EXIT = '4013',
    COMPANY_STATUS_DEACTIVATE = '4014',
    IDENTITY_TYPE_INVALID = '4015',
    COMPANY_NOT_FOUND = '4408',
    BRANCH_NUMBER_NOT_FOUND = '4410',
    INVITATION_CODE_DUPLICATE = '4029',
    INVITATION_CAN_NOT_ADDED = '4030',
    COMPANY_REQUEST_UPDATE_IS_AWAITING = "4033",
    BRANCH_NUMBER_AWAITING_APPROVE = '4042',
    TAX_ID_IS_INVALID = '4045',
    TAX_ID_IS_DISABLE = '4098',
    APPLICATION_ALREADY_EXIST = '4043',
    GLOBAL_SSA_NOT_FOUND = '4483'
}

export enum CompanyProfileMenuEnum {
    INTRODUCION = 'introduction',
    ADDRESS = 'address',
    BUSINESS_INFORMATION = 'businessInformation',
    DOCUMENT = 'document'
}

export enum CompanyProfileActionEnum {
    VIEW = 'view',
    EDIT = 'edit',
}

export enum ContactTypeEnum {
    COMPANY_CONTACT = 'Common.ContactType.Company',
    BILLING_CONTACT = 'Common.ContactType.Billing',
    PURCHASE_CONTACT = 'Common.ContactType.Purchase',
}

export enum CertificatedBy {
    CERTIFICATED_BY_OTHER_ID = 1
}

export enum JobTitle {
    JOB_TITIL_OTHER_ID = -1
}

export enum InvitationCode {
    LOTUS_TH = "Lotus's_TH",
    LOTUS_MY = "Lotus's_MY",
    KBANK = "KBK0710"
}

export enum RequestUpdateStatusEnum {
    APPROVED = 'Approved',
    AWAITING = 'Awaiting',
    REJECTED = 'Rejected',
    CANCELED = 'Cancelled',
}


export enum SectionDocumentEnum {
    COMPANY_REGISTRATION = 'Company Registration',
    VAT_REGISTRATION = 'VAT Registration',
    OTHER = 'Others',
    OTHER_EXTERNAL = 'OtherExternals',
}

export enum RequestStatus {
    AWAITING = 1,
    APPROVED = 2,
    REJECTED = 3,
    CANCELED = 4,
    COMPLETED = 5
}

export enum CompanyProfilePageRoute {
    DEFAULT = ''
}