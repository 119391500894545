import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { SCIcon } from '../icon/SCIcon';
import classNames from 'classnames';

export interface IDropdownBtnProps {
  elementIdPrefix: string;
  elementIdSuffix: string;
  value: any;
  items: any[];
  iconCheckedInItem?: boolean;
  onSelect: (val: any) => void;
}

export function DropdownBtn(
  props: React.PropsWithChildren<IDropdownBtnProps>
): React.FunctionComponentElement<IDropdownBtnProps> {
  const { elementIdPrefix, elementIdSuffix, value, items, iconCheckedInItem, onSelect } = props;

  const elementID = `${elementIdPrefix}-${elementIdSuffix}`;

  return (
    <Dropdown>
      <Dropdown.Toggle id={`ddl-${elementID}`} variant="">
        <span>{value}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="dropdown-list">
          {items.map((i, index) => (
            <Dropdown.Item
              id={`opt-${elementID}-${index}`}
              className={classNames({ active: i === value })}
              onSelect={() => onSelect(i)}
              key={'dropdown-btn-' + i}
            >
              {(value === i && iconCheckedInItem) && <SCIcon icon="sm-check"></SCIcon>} {i}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
