import * as yup from 'yup';
import { DealsAndDiscount, PromotionDetail, PromotionImage, PromotionProduct } from '@models/DealsAndDiscount';
import { DiscountTypeOptionEnum, DealsAndDiscountListStatusEnum } from '@utils/enum/DealsAndDiscountEnum';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import dayjs from 'dayjs';
import { defaultTime } from '@components/dropdown/DropdownTimepicker';
import { REGEX } from '@utils/constants/CommonConstants';

export class PromotionErrors {
  // Deals And Discount
  static readonly PRODUCT_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.ProductIsRequire';

  // Promotion Detail
  static readonly PROMOTION_NAME_LOCAL_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.PromotionNameLocalIsRequire';
  static readonly PROMOTION_NAME_EN_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.PromotionNameENIsRequire';
  static readonly START_DATE_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.StartDateIsRequire';
  static readonly END_DATE_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.EndDateIsRequire';
  static readonly START_CAN_NOT_MORE_THAN_END = 'DealsAndDiscount.ErrorMessage.StartNotMoreThanEnd';
  static readonly END_CAN_NOT_LESS_THAN_START = 'DealsAndDiscount.ErrorMessage.EndNotLessThanStart';
  static readonly DESCRIPTION_NAME_IN_EN = 'DealsAndDiscount.ErrorMessage.DescriptionNameInEN';
  static readonly START_CAN_NOT_LESS_THAN_NOW_DATE = 'DealsAndDiscount.ErrorMessage.StartNotLessThanNowDate';

  // Promotion Product
  static readonly PRODUCT_ID_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.ProductIDIsRequire';
  static readonly DISCOUNT_VALUE_IS_REQUIRED = 'DealsAndDiscount.ErrorMessage.DiscountValueIsRequire';
}


export const PromotionImageSchema = (): yup.SchemaOf<PromotionImage> => {
  return yup.object({
    order: yup.number().optional(),
    uuid: yup.string().optional(),
    originalFileName: yup.string().optional(),
    uploadDate: yup.number().optional(),
    uploadByUserID: yup.number().optional(),
    uploadByUserName: yup.string().optional(),
    fileUrl: yup.string().optional(),
    originalFileUrl: yup.string().optional(),
    extension: yup.string().optional()
  }).optional();
};

export const PromotionDetailSchema = (): yup.SchemaOf<PromotionDetail> => {
  return yup.object({
    promotionNameLocal: yup.string().required(PromotionErrors.PROMOTION_NAME_LOCAL_IS_REQUIRED),
    promotionNameEN: yup.string().required(PromotionErrors.PROMOTION_NAME_EN_IS_REQUIRED)
      .test(
        PromotionErrors.PROMOTION_NAME_EN_IS_REQUIRED,
        PromotionErrors.PROMOTION_NAME_EN_IS_REQUIRED,
        (val: string | undefined) => {
          return (REGEX.PRODUCT_NAME_ENGLISH_LANGUAGE.test(val!));
        }
      ),
    descriptionLocal: yup.string().optional(),
    descriptionEN: yup.string().optional()
      .test(
        PromotionErrors.DESCRIPTION_NAME_IN_EN,
        PromotionErrors.DESCRIPTION_NAME_IN_EN,
        (val: string | undefined) => {
          if (val) {
            return REGEX.PRODUCT_NAME_ENGLISH_LANGUAGE.test(val!);
          } else {
            return true;
          }
        }
      ),
    imageLocal: PromotionImageSchema().optional(),
    imageGlobal: PromotionImageSchema().optional(),
    startDate: yup.string()
      .required(PromotionErrors.START_DATE_IS_REQUIRED)
      .test(
        PromotionErrors.START_CAN_NOT_MORE_THAN_END,
        PromotionErrors.START_CAN_NOT_MORE_THAN_END,
        function (val) {
          const invalidDate: boolean = this.parent.invalidDate;
          return !(val && invalidDate);
        }
      )
      .test(
        PromotionErrors.START_CAN_NOT_LESS_THAN_NOW_DATE,
        PromotionErrors.START_CAN_NOT_LESS_THAN_NOW_DATE,
        function (val) {
          const invalidTime: boolean = this.parent.invalidTime;
          const promotionComingStatus = this.parent.promotionComingStatus;
          return !(promotionComingStatus?.toUpperCase() !== DealsAndDiscountListStatusEnum.ONGOING && invalidTime);
        }
      ),
    startTime: yup.string().required(PromotionErrors.START_DATE_IS_REQUIRED),
    endDate: yup.string().optional()
      .when('isNoEndDate', {
        is: false,
        then: yup.string()
          .required(PromotionErrors.END_DATE_IS_REQUIRED)
          .test(
            PromotionErrors.END_CAN_NOT_LESS_THAN_START,
            PromotionErrors.END_CAN_NOT_LESS_THAN_START,
            function (val) {
              const invalidDate: boolean = this.parent.invalidDate;
              return !(val && invalidDate);
            }
          )
      }),
    endTime: yup.string().optional()
      .when('isNoEndDate', {
        is: false,
        then: yup.string()
          .required(PromotionErrors.END_DATE_IS_REQUIRED)
      }),
    isNoEndDate: yup.boolean().required(),
    discountType: yup.string().required(),
    invalidDate: yup.boolean().default(false),
    promotionComingStatus: yup.string().optional().default('Default'),
    invalidTime: yup.boolean().default(false)
  }).default({
    promotionNameLocal: '',
    promotionNameEN: '',
    descriptionLocal: '',
    descriptionEN: '',
    imageLocal: undefined,
    imageGlobal: undefined,
    startDate: undefined,
    startTime: '',
    endDate: undefined,
    endTime: '',
    isNoEndDate: false,
    discountType: DiscountTypeOptionEnum.percent,
    invalidDate: false,
    promotionComingStatus: '',
    invalidTime: false
  });
};

export const PromotionProductSchema = (): yup.SchemaOf<PromotionProduct> => {
  return yup.object({
    productID: yup.number().required(PromotionErrors.PRODUCT_ID_IS_REQUIRED),
    discountValue: yup.string().optional()
      .test(
        'required',
        PromotionErrors.DISCOUNT_VALUE_IS_REQUIRED,
        function (val) {
          return (val || this.parent.promotionOverlap || this.parent.canNotBeApplied);
        }
      ),
    sku: yup.string().optional(),
    name: yup.string().optional(),
    categoryName: yup.string().optional(),
    productImageUrl: yup.string().optional(),
    unitPrice: yup.number().nullable().optional(),
    promotionOverlap: yup.string().nullable().optional(),
    canNotBeApplied: yup.boolean().default(false)
  });
};

export const DealsAndDiscountSchema = (): yup.SchemaOf<DealsAndDiscount> => {
  return yup.object({
    companyId: yup.number().required(),
    branchId: yup.number().required(),
    promotionId: yup.string().optional(),
    promotionDetail: PromotionDetailSchema(),
    discountAll: yup.string().optional(),
    promotionProduct: yup
      .array()
      .of(PromotionProductSchema())
      .required(PromotionErrors.PRODUCT_IS_REQUIRED)
      .min(1, PromotionErrors.PRODUCT_IS_REQUIRED)
      .test(
        'required',
        PromotionErrors.PRODUCT_IS_REQUIRED,
        (products: any[] | undefined) => {
          return (products?.filter(p => !p.promotionOverlap && !p.canNotBeApplied)?.length! > 0);
        }
      )
  });
};

export const DealsAndDiscountDefaultValues = (companyId: number, branchId: number): DealsAndDiscount => {
  let currentDateTime = new Date();
  let currentTime = defaultTime(currentDateTime.getHours().toString()) + ':' + defaultTime(currentDateTime.getMinutes().toString());
  let nextDateTime = new Date(currentDateTime);
  nextDateTime.setDate(currentDateTime.getDate() + 1);
  return {
    companyId: companyId,
    branchId: branchId,
    discountAll: undefined,
    promotionProduct: [],
    promotionDetail: {
      promotionNameLocal: '',
      promotionNameEN: '',
      descriptionLocal: '',
      descriptionEN: '',
      imageLocal: undefined,
      imageGlobal: undefined,
      startDate: CommonHelper.getTimestampFormUnix(dayjs(currentDateTime).unix()).toString(),
      startTime: currentTime,
      endDate: CommonHelper.getTimestampFormUnix(dayjs(nextDateTime).unix()).toString(),
      endTime: '23:59',
      isNoEndDate: false,
      discountType: DiscountTypeOptionEnum.percent,
      invalidDate: false,
      invalidTime: false,
      promotionComingStatus: ''
    }
  };
};