import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownBtn } from '../dropdown/DropdownBtn';

export interface IPagination {
  elementIdPrefix: string;
  elementIdSuffix: string;
  currentPage: number;
  totalPage: number;
  totalItem: number;
  pageSize: number;
  pageSizes: number[];
  onSelectPageSize: (pageSize: number) => void;
  onSelectPageNumber: (pageNo: number) => void;
}

const getPagination = (currentPage: number, totalPage: number) => {
  const page = new Array();
  const left = currentPage > 5 && totalPage > 9;
  const right = currentPage < totalPage - 4 && totalPage > 9;

  if (left && right) {
    page.push(1);
    page.push(0);
    for (let i = 0; i < 5; i++) {
      page.push(currentPage - 2 + i);
    }
    page.push(0);
    page.push(totalPage);
  } else if (left && !right) {
    page.push(1);
    page.push(0);
    for (let i = 0; i < 7; i++) {
      page.push(totalPage - 6 + i);
    }
  } else if (!left && right) {
    for (let i = 0; i < 7; i++) {
      page.push(i + 1);
    }
    page.push(0);
    page.push(totalPage);
  } else {
    for (let i = 1; i <= totalPage; i++) {
      page.push(i);
    }
  }
  return page;
};

export default function Pagination(
  props: React.PropsWithChildren<IPagination>
): React.FunctionComponentElement<IPagination> {
  const {
    elementIdPrefix,
    elementIdSuffix,
    currentPage,
    totalPage,
    totalItem,
    pageSize,
    pageSizes,
    onSelectPageSize,
    onSelectPageNumber,
  } = props;

  const elementID = `${elementIdPrefix}-${elementIdSuffix}`;

  const { t } = useTranslation();

  const pages = useMemo(() => getPagination(currentPage, totalPage), [currentPage, totalPage]);
  const fromItem = useMemo(() => (currentPage - 1) * pageSize + 1, [currentPage, pageSize]);
  const toItem = useMemo(
    () =>
      pages?.length === 0 || pages?.length === 1
        ? totalItem
        : currentPage === pages[pages?.length - 1] && totalItem % pageSize !== 0
          ? currentPage * pageSize - (pageSize - (totalItem % pageSize))
          : currentPage * pageSize,
    [pages, totalItem, currentPage, pageSize]
  );

  const handleSelectPageNumber = (page: number) => {
    page !== 0 && onSelectPageNumber(page);
  };

  return (
    <div className="page-navigation p-hz-default">
      <div className="float-left">
        <span className="showing">{t('Common.Label.Showing')}</span>
        <DropdownBtn
          elementIdPrefix={elementID}
          elementIdSuffix="showing"
          value={pageSize}
          items={pageSizes}
          onSelect={onSelectPageSize}
        />
      </div>
      <div className="float-right">
        {totalItem !== 0 && pages?.length !== 1 && (
          <ul className="pagination">
            <li className="page-item" hidden={currentPage === 1 || pages.length === 0}>
              <button type="button" id={`btn-${elementID}-page-previous`} className="page-link" onClick={() => handleSelectPageNumber(currentPage - 1)}>
                <i className="icon-angle-left"></i>
              </button>
            </li>
            {pages.map((pageNumber, i) => (
              <li
                key={i}
                className={classNames('page-item', {
                  active: currentPage === pageNumber,
                })}
              >
                <button
                  id={`btn-${elementID}-page-${i}`}
                  onClick={() => handleSelectPageNumber(pageNumber)}
                  type="button"
                  className={classNames('page-link', { 'page-dot': pageNumber === 0 })}
                >
                  {pageNumber === 0 ? '...' : pageNumber}
                </button>
              </li>
            ))}
            <li
              className="page-item"
              hidden={currentPage === pages[pages.length - 1] || pages.length === 0}
            >
              <button type="button" id={`btn-${elementID}-page-next`} className="page-link" onClick={() => handleSelectPageNumber(currentPage + 1)}>
                <i className="icon-angle-right"></i>
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
