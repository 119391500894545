import environment from 'environment';
import { ProductPageRoute } from './ProductConstant';
import { BuyerPageRoute } from './BuyerConstant';
import { DealsAndDiscountPageRoute } from '@utils/enum/DealsAndDiscountEnum';
import { SettingPageRoute } from '@utils/enum/SettingEnum';
import { AccountSettingPageRoute } from '@utils/enum/AccountSettingEnum';
import { AutoAcceptPOPageRoute } from '@utils/enum/AutoAcceptPOEnum';
class PageRoute {
    static readonly REDIRECT = "/redirect";
    static readonly LOGIN = "/login";
    static readonly LOGOUT = "/logout";
    static readonly NOT_FOUND = "/not-found";
    static readonly LANDING = "/home";
    static readonly PORTAL = "/portal";
    static readonly NEW_LANDING = "/";
    static readonly TERMS_AND_PRIVACY = "/terms-and-privacy/:tab";
    static readonly VERSION = "/version";

    // company
    static readonly COMPANY_REGISTER = "/company-register";
    static readonly COMPANY_WAITING = "/company-waiting";
    static readonly COMPANY_SEND_MAIL = "/company-sendmail";
    static readonly USER_DETAIL = "/company-userDetail";
    static readonly COMPANY_MANAGEMENT = "/company-management";
    static readonly COMPANY_INFO = "/company-info";

    // product
    static readonly PRODUCT = "/product"
    static readonly PRODUCT_ADD = PageRoute.PRODUCT + "/" + ProductPageRoute.ADD;
    static readonly PRODUCT_DRAFT = PageRoute.PRODUCT + "/" + ProductPageRoute.DRAFT;
    static readonly PRODUCT_SINGLE = PageRoute.PRODUCT + "/" + ProductPageRoute.SINGLE;
    static readonly PRODUCT_SINGLE_EDIT = PageRoute.PRODUCT + "/" + ProductPageRoute.SINGLE_EDIT;
    static readonly PRODUCT_LIST_SELECT = PageRoute.PRODUCT + "/" + ProductPageRoute.LIST_SELECT;

    // register service
    static readonly REGISTER_SERVICE = "/register-service";

    // buyer
    static readonly BUYER = "/buyer"
    static readonly BUYER_LIST = PageRoute.BUYER + "/" + BuyerPageRoute.LIST;

    // supplier web work
    static readonly SWW_COMPANY_PROFILE = `${environment.supplierWebWorkFrontend}/RedirectIDP/RedirectKeycloakAuthorize`;
    static readonly SWW_HOME_PAGE = `${environment.supplierWebWorkFrontend}/Home/Index`;

    // deals and discount
    static readonly SELLING_TOOLS = "/selling-tools";
    static readonly DEALS_AND_DISCOUNT = PageRoute.SELLING_TOOLS + "/deals-and-discount";
    static readonly DEALS_AND_DISCOUNT_ADD = PageRoute.DEALS_AND_DISCOUNT + "/" + DealsAndDiscountPageRoute.ADD;
    static readonly DEALS_AND_DISCOUNT_EDIT = PageRoute.DEALS_AND_DISCOUNT + "/" + DealsAndDiscountPageRoute.EDIT;
    static readonly DEALS_AND_DISCOUNT_DETAIL = PageRoute.DEALS_AND_DISCOUNT + "/" + DealsAndDiscountPageRoute.DETAIL;

    // procurement board
    static readonly PROCUREMENT_BOARD = `${environment.emarketPlaceFrontend}/login?idp=sww`;

    // My Account
    static readonly ACCOUNT_SETTING = "/account-setting";
    static readonly ACCOUNT_SETTING_PROFILE = PageRoute.ACCOUNT_SETTING + "/" + AccountSettingPageRoute.PROFILE;
    static readonly VERIFY_CHANGE_MAIL = "/verifiedEmail";

    // Setting
    static readonly SETTING = "/setting";
    static readonly SETTING_ACCOUNT_DETAIL = PageRoute.SETTING + "/" + SettingPageRoute.ACCOUNT_DETAIL;
    static readonly SETTING_NOTIFICATION = PageRoute.SETTING + "/" + SettingPageRoute.NOTIFICATION;

    // Auto Accept PO
    static readonly AUTO_ACCEPT_PO = PageRoute.BUYER + '/' + AutoAcceptPOPageRoute.AUTO_ACCEPT_PO;

    // Pantavanij intelligence
    static readonly PANTAVANIJ_INTELLIGENCE = `${environment.pantavanijIntelligenceFrontend}/manage-center`;
    static readonly EMERGENCY_MAINTENANCE_PAGE = "/emergency-maintenance-page"

    // Lime Survey
    static readonly LIME_SURVAY_RANDING_PAGE = `${environment.lameSurvayFrontend}`;

    static DEFAULT = PageRoute.LANDING;
}

export default PageRoute