export enum ProductPageRoute {
  LIST = 'list',
  LIST_SELECT = 'list/select',
  ADD = 'add',
  DETAIL = ':productID/detail',
  DRAFT = '',
  SINGLE_EDIT = 'single/:productID/edit',
  EDIT = ':productID/edit',
  CATEGORY_LIST = '/category/list'
}

export enum UOMEnum {
  SIZE = 'size',
  WEIGHT = 'weight',
}

export enum ProductStatusEnum {
  active = 'active',
  inactive = 'inactive',
  delete = 'delete',
}

export enum ProductOptionPricingEnum {
  adjustable_product_price,
  non_price_product,
}

export enum ProductActionEnum {
  SET_INACTIVE = 'setInactive',
  SET_ACTIVE = 'setActive',
  DELETE = 'delete',
  HISTORY = 'history'
}

export enum ProductListStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
}

export enum ProductListSortByEnum {
  SKU = 'SKU',
  PRODUCT_NAME = 'PRODUCT_NAME',
  LAST_UPDATED = 'LAST_UPDATED',
}
