import { ConsentDataResponse, ConsentDetailResponse } from '../../api/response/ConsentResponse';
import { CompanyClient } from '../../api/CompanyClient';
import { useEffect, useState } from 'react';
import { ConsentTrueRequest } from '@api/request/ConsentRequest';
import { TokenHelper } from '@ptvn-react/authentication';

const usePDPA = () => {

    const initialConsentDetail: ConsentDetailResponse = {
        id: '',
        name: 'string',
        descriptionEn: 'Loading...',
        descriptionTh: 'Loading...',
        revision: 0,
        status: 'string'
    }

    const initialConsentData: ConsentDataResponse = {
        id: '',
        application: '',
        descriptionEn: '',
        descriptionTh: '',
        lifespan: 0,
        name: '',
        privacyNoticeRef: '',
        purposes: [],
        ref1: '',
        ref2: '',
        ref3: '',
        revision: 0,
        status: '',
    }

    const [acceptConsentSC, setAcceptConsentSC] = useState<boolean>(false);
    const [termsAndCondition, setTermsAndCondition] = useState<ConsentDataResponse>(initialConsentData);
    const [privacyNotice, setPrivacyNotice] = useState<ConsentDetailResponse>(initialConsentDetail);

    const [acceptPDPACP, setAcceptPDPACP] = useState<boolean>(false);
    const [pdpaContentCP, setPDPAContentCP] = useState<ConsentDataResponse>(initialConsentData);

    const [requestCallTrueCOC, setRequestCallTrueCOC] = useState<ConsentTrueRequest>();
    const [loading, setLoading] = useState<boolean>(false);
    const [acceptPDPATrue, setAcceptPDPATrue] = useState<boolean>(false);
    const [pdpaContentTrue, setPDPAContentTrue] = useState<ConsentDataResponse>(initialConsentData);

    const [convertFormat, setConvertFormat] = useState<boolean>(false);

    const prefix18DivColor = '<h3 class="title-border">';
    const prefix16DivColor = '<div style=\"color:#01365B;font-size:14px;font-weight: bold;\">';
    const postfixDivColor = '</div>';
    const postFixH = '</h3>';

    useEffect(() => {
        let token = TokenHelper.getToken()
        if (token) {
            getConsent();
            getCOC();
        } else {
            setAcceptConsentSC(true);
        }
    }, []);

    useEffect(() => {
        if (requestCallTrueCOC?.username) {
            getCOCTrue();
        }
    }, [requestCallTrueCOC]);

    const getConsent = () => {
        CompanyClient.getRequestConsent().subscribe(res => {
            if (res.data.id != null) {
                setTermsAndCondition(res.data.data);
                setPrivacyNotice(res.data.privacyNotice);
                if (res.data.data != null) {
                    transformContent(res.data.data, res.data.privacyNotice)
                }
            } else {
                setAcceptConsentSC(true);
            }
        }, error => {
            setAcceptConsentSC(true);
        });
    }

    const getCOC = () => {
        // get COC CP
        CompanyClient.getCOC('CP').subscribe(res => {
            if (res?.data?.id != null) {
                let coc = res.data.data;
                coc.descriptionEn = res?.data?.privacyNotice?.descriptionEn;
                coc.descriptionTh = res?.data?.privacyNotice?.descriptionTh;
                setPDPAContentCP(coc);
            } else {
                setAcceptPDPACP(true);
            }
        }, error => {
            setAcceptPDPACP(true);
        });
    }

    const getCOCTrue = () => {
        setLoading(true);
        // should validate role before get COC True
        const username = requestCallTrueCOC?.username ? requestCallTrueCOC.username : '';
        CompanyClient.getCOC('True', username).subscribe(res => {
            if (res && res.data && res.data.id != null) {
                let coc = res.data.data;
                coc.descriptionEn = res?.data?.privacyNotice?.descriptionEn;
                coc.descriptionTh = res?.data?.privacyNotice?.descriptionTh;
                setPDPAContentTrue(coc);
            } else {
                setAcceptPDPATrue(true)
            }
        }, error => {
            setAcceptPDPATrue(true);
        }).add(() => { setLoading(false); });
    }

    const transformContent = (termsAndCondition: ConsentDataResponse, privacyNotice: ConsentDetailResponse) => {
        let termEN = termsAndCondition.descriptionEn;
        let termTH = termsAndCondition.descriptionTh;

        termEN = termEN.split('thisisadiv16start').join(prefix16DivColor);
        termEN = termEN.split('thisisadiv18start').join(prefix18DivColor);
        termEN = termEN.split('thisisadivend').join(postfixDivColor);
        termEN = termEN.split('thisisadiv18end').join(postFixH);

        termTH = termTH.split('thisisadiv16start').join(prefix16DivColor);
        termTH = termTH.split('thisisadiv18start').join(prefix18DivColor);
        termTH = termTH.split('thisisadivend').join(postfixDivColor);
        termTH = termTH.split('thisisadiv18end').join(postFixH);

        let termAndCon = termsAndCondition;
        termAndCon.descriptionEn = termEN;
        termAndCon.descriptionTh = termTH;
        setTermsAndCondition(termAndCon);

        let privacyEN = privacyNotice.descriptionEn;
        let privacyTH = privacyNotice.descriptionTh;

        privacyEN = privacyEN.split('thisisadiv16start').join(prefix16DivColor);
        privacyEN = privacyEN.split('thisisadiv18start').join(prefix18DivColor);
        privacyEN = privacyEN.split('thisisadivend').join(postfixDivColor);
        privacyEN = privacyEN.split('thisisadiv18end').join(postFixH);

        privacyTH = privacyTH.split('thisisadiv16start').join(prefix16DivColor);
        privacyTH = privacyTH.split('thisisadiv18start').join(prefix18DivColor);
        privacyTH = privacyTH.split('thisisadivend').join(postfixDivColor);
        privacyTH = privacyTH.split('thisisadiv18end').join(postFixH);

        let privacy = privacyNotice;
        privacy.descriptionEn = privacyEN;
        privacy.descriptionTh = privacyTH;
        setPrivacyNotice(privacy);
        setConvertFormat(true);
    }

    const onAcceptConsent = () => {
        CompanyClient.acceptConsent(termsAndCondition.id).subscribe((responsePost) => {
            setAcceptConsentSC(true);
        }, (error) => {
            setAcceptPDPATrue(false);
        });
    }

    const onAcceptPDPACP = () => {
        CompanyClient.acceptCOC('CP', pdpaContentCP.id).subscribe((responsePost) => {
            setAcceptPDPACP(true);
        }, (error) => {
            setAcceptPDPATrue(false);
        });
    }

    const onAcceptPDPATrue = () => {
        const username = requestCallTrueCOC?.username ? requestCallTrueCOC.username : '';
        CompanyClient.acceptCOC('True', pdpaContentTrue.id, username).subscribe((responsePost) => {
            setAcceptPDPATrue(true);
        }, (error) => {
            setAcceptPDPATrue(false);
        });
    }

    return {
        termsAndCondition,
        privacyNotice,
        acceptConsentSC,
        onAcceptConsent,

        pdpaContentCP,
        acceptPDPACP,
        onAcceptPDPACP,

        setRequestCallTrueCOC,
        pdpaContentTrue,
        acceptPDPATrue,
        onAcceptPDPATrue,

        loading,
        convertFormat
    };

};

export default usePDPA;