import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nHelper } from '../../../i18n/i18n';
import "../../../assets/css/pdpa_style.css";
import { ConsentDataResponse, ConsentDetailResponse } from '@api/response/ConsentResponse';
import { Language } from '@utils/constants/CommonConstants';
import { Dropdown } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

interface IConsentModalProps {
    onAccept: () => void;
    onDecline: () => void;
    termsOfservice: ConsentDataResponse;
    privacyAndNotice: ConsentDetailResponse;
}

export default function ConsentModal(
    props: React.PropsWithChildren<IConsentModalProps>
): React.FunctionComponentElement<IConsentModalProps> {

    const {
        onAccept,
        onDecline,
        termsOfservice,
        privacyAndNotice
    } = props;

    const { i18n } = useTranslation();
    const currentLangItem = Language.getLangByKey(i18n.language.substring(0, 2));
    const languages = [Language.en, Language.th];

    const [termsActive, setTermsActive] = useState<string>('active');
    const [privacyActive, setPrivacyActive] = useState<string>('');
    const [terms, setTerms] = useState<string>('');
    const [privacy, setPrivacy] = useState<string>('');

    useEffect(() => {
        handleChangeTab('terms');
    }, []);

    useEffect(() => {
        handleChangeLanguage()
    }, [currentLangItem]);

    const handleChangeTab = (tab: string) => {
        if (tab == 'terms') {
            setTermsActive('active')
            setPrivacyActive('')
        } else if (tab == 'privacy') {
            setTermsActive('')
            setPrivacyActive('active')
        }
    }

    const handleChangeLanguage = () => {
        if (currentLangItem?.key == 'th') {
            setTerms(termsOfservice.descriptionTh);
            setPrivacy(privacyAndNotice.descriptionTh);
        } else {
            setTerms(termsOfservice.descriptionEn);
            setPrivacy(privacyAndNotice.descriptionEn);
        }
    }

    return (<>
        {
            <div style={{ backgroundColor: '#C6CACD', height: '100%', overflow: 'auto' }} className="modal-open modal-pdpa">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body p-0 overflow-hidden">
                            <div className="nav-custom">
                                <div className="nav nav-tabs">
                                    <a href="#" data-toggle="tab" className={`${termsActive}`} onClick={() => handleChangeTab('terms')}>{i18nHelper.translate('Common.Label.TermOfService')}</a>
                                    <a href="#" data-toggle="tab" className={`${privacyActive}`} onClick={() => handleChangeTab('privacy')}>{i18nHelper.translate('Common.Label.PrivacyPolicy')}</a>
                                    <div className="lang" style={{ backgroundColor: '#f4f5f8' }}>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" data-toggle="dropdown">
                                                <i className="icon-globe" style={{ zIndex: 1 }}>
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>{currentLangItem?.key.toUpperCase()}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                {languages.map((lang) => {
                                                    return <Dropdown.Item onSelect={() => i18n.changeLanguage(lang.key)} key={'switch-lang-' + lang.key}>{lang.title}</Dropdown.Item>;
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className={`tab-pane ${termsActive}`}>
                                        <h3 className='title-border'>{i18nHelper.translate('Common.Label.SCTermsAndConditions')}</h3>
                                        <div className='content-group'>
                                            <div dangerouslySetInnerHTML={{ __html: terms }} />
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${privacyActive}`}>
                                        <h3 className='title-border'>{i18nHelper.translate('Common.Label.SCPrivacyPolicy')}</h3>
                                        <div className='content-group'>
                                            {
                                                currentLangItem?.key == 'th' ?
                                                    <ReactMarkdown children={privacy} /> :
                                                    <div dangerouslySetInnerHTML={{ __html: privacy }} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="float-custom">
                                <button type="button" data-dismiss="modal" className="btn btn-noborder" onClick={() => onDecline()}>{i18nHelper.translate('Common.Label.DeclineAndLogOut')}</button>
                                <button type="submit" className="btn btn-primary" onClick={() => onAccept()}>{i18nHelper.translate('Common.Label.Accept')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    );
}
