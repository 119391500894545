import { FileStatus } from "@utils/constants/FileConstant";

export enum FileMIME {
    PNG = "image/png",
    JPG = "image/jpg",
    JPEG = "image/jpeg",
    PDF = "application/pdf",
    XLS = "application/vnd.ms-excel",
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    DOC = "application/msword",
    DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

export interface IFileDetail {
    order?: number|null;
    uuid?: string|null;
    originalFileName: string|null;
    uploadByUserID?: number|null;
    uploadByUserName?: string|null;
    uploadDate?: number;
    status: FileStatus;
    errorMessage?: string;
    file?: File;
    dropDate?: number;
    originalFileUrl?: string|null;
    fileUrl?: string;
    extension?: string;

    attachmentURL?: string|null;
  }
