import { ApolloError } from '@apollo/client';
import { SCButton } from '@components/button/SCButton';
import { ConfirmButtonCenterModal } from '@components/modal/ConfirmButtonCenterModal';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import { useSmartAlert } from '@components/smart-alert/SmartAlert';
import { useUpsertPromotionMutation } from '@graphql/autogenerate/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { DealsAndDiscount, PromotionProduct } from '@models/DealsAndDiscount';
import { CompanyItem, usePTVNAuthentication } from '@ptvn-react/authentication';
import PageRoute from '@utils/constants/PageRoute';
import {
  DealsAndDiscountAction,
  DealsAndDiscountPageRoute,
  DiscountTypeOptionEnum,
} from '@utils/enum/DealsAndDiscountEnum';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { DealsAndDiscountHelper, PromotionStatus } from '@utils/helpers/DealsAndDiscountHelper';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import PromotionDetail from './components/PromotionDetail';
import PromotionProductItem from './components/PromotionProductItem';
import { useDealsAndDiscountContext } from './DealsAndDiscountContext';
import { DealsAndDiscountDefaultValues, DealsAndDiscountSchema } from './DealsAndDiscountSchema';
import SelectProduct from './SelectProduct';
import SaveIcon from '@assets/images/frontend/saved.png';
import * as H from 'history';
import { LeaveThisPageModal } from '@components/modal/LeaveThisPage';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defaultTime } from '@components/dropdown/DropdownTimepicker';
import { Language } from '@utils/constants/CommonConstants';
import { LangType } from '@graphql/autogenerate/schemas';
import useGoogleAnalytics from '@google/useGoogleAnalytics';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsConstantEvent } from '@utils/constants/GoogleAnalyticsConstant';
import { EventArgs } from 'react-ga';

interface IDealsAndDiscountPageProps {
  elementID: string;
}

export default function DealsAndDiscountPage(
  props: React.PropsWithChildren<IDealsAndDiscountPageProps>
): React.FunctionComponentElement<IDealsAndDiscountPageProps> {
  const { elementID } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { push } = useModalStack();
  const alert = useSmartAlert();
  const { requestGAObservable } = useGoogleAnalytics();
  const {
    action,
    selectProductsPage,
    promotionDetail,
    setProductItemsInPromotion,
    isLoadingImage,
  } = useDealsAndDiscountContext();
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const reference = useRef<HTMLDivElement>(null);
  const promotionRef = useRef<HTMLDivElement>(null);
  const productRef = useRef<HTMLDivElement>(null);
  const refRightContent = useRef<HTMLDivElement>(null);
  const [loadingUpsert, setLoadingUpsert] = useState<boolean>(false);
  const [menuActive, setMenuActive] = useState<string>('promotionDetail');
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    return () => {
      subscription && subscription.unsubscribe();
    }
  }, [subscription]);

  const getTimeFromDate = (dateTime?: string): string => {
    if (dateTime) {
      let date = new Date(dateTime!);
      return (
        defaultTime(date.getHours().toString()) + ':' + defaultTime(date.getMinutes().toString())
      );
    } else {
      return '00:00';
    }
  };

  const renderPromotionOverlapName = (promotionOverlap: any[]): string => {
    if (!promotionOverlap) return '';
    return promotionOverlap
      .map((ov) => {
        if (i18n.language === Language.en.key) {
          if (ov.promotionNameEN) {
            return ov.promotionNameEN;
          } else if (ov.promotionNameLocal) {
            return ov.promotionNameLocal;
          }
        } else {
          if (ov.promotionNameLocal) {
            return ov.promotionNameLocal;
          } else if (ov.promotionNameEN) {
            return ov.promotionNameEN;
          }
        }
        return '';
      })
      .toString();
  };

  const getDefaultValues = () => {
    if (promotionDetail) {
      let imageEn = promotionDetail.promotionFileList
        ?.filter((f) => f.language?.toLocaleLowerCase() == LangType.En.toLocaleLowerCase())
        .sort(function (a, b) {
          return b.promotionFileID! - a.promotionFileID!;
        })[0];
      let imageEnObject =
        imageEn && imageEn?.fileUrl
          ? {
            order: imageEn?.order!,
            uuid: imageEn?.uuid!,
            originalFileName: imageEn?.originalFileName!,
            uploadDate: CommonHelper.getTimestampFormUnix(dayjs(imageEn?.uploadDate).unix())!,
            uploadByUserID: imageEn?.uploadByUserID!,
            uploadByUserName: imageEn?.uploadByName!,
            fileUrl: imageEn?.fileUrl!,
            originalFileUrl: imageEn?.originalFileUrl!,
            extension: imageEn?.promotionFileType!,
          }
          : undefined;
      let imageLocal = promotionDetail.promotionFileList
        ?.filter((f) => f.language?.toLocaleLowerCase() == LangType.Local.toLocaleLowerCase())
        .sort(function (a, b) {
          return b.promotionFileID! - a.promotionFileID!;
        })[0];
      let imageLocalObject =
        imageLocal && imageLocal?.fileUrl
          ? {
            order: imageLocal?.order!,
            uuid: imageLocal?.uuid!,
            originalFileName: imageLocal?.originalFileName!,
            uploadDate: CommonHelper.getTimestampFormUnix(dayjs(imageLocal?.uploadDate).unix())!,
            uploadByUserID: imageLocal?.uploadByUserID!,
            uploadByUserName: imageLocal?.uploadByName!,
            fileUrl: imageLocal?.fileUrl!,
            originalFileUrl: imageLocal?.originalFileUrl!,
            extension: imageLocal?.promotionFileType!,
          }
          : undefined;
      return {
        companyId: currentCompany.companyID!,
        branchId: currentCompany.branchId!,
        discountAll: undefined,
        promotionId: promotionDetail.promotionID?.toString()!,
        promotionProduct: promotionDetail.promotionProductItemList?.items?.map((p) => {
          let productName = '';
          if (i18n.language === Language.en.key) {
            if (p?.product?.nameEn) {
              productName = p?.product?.nameEn;
            } else if (p?.product?.nameLocal) {
              productName = p?.product?.nameLocal;
            }
          } else {
            if (p?.product?.nameLocal) {
              productName = p?.product?.nameLocal;
            } else if (p?.product?.nameEn) {
              productName = p?.product?.nameEn;
            }
          }
          return {
            productID: p?.product?.productID,
            discountValue: p?.value!,
            sku: p?.product?.sku!,
            name: productName,
            categoryName: p?.product?.productCategory?.find(
              (category) => category?.categoryLevel === 3
            )?.nameLocal,
            productImageUrl: p?.promotion?.promotionFileList!.sort(function (a, b) {
              return b.promotionFileID! - a.promotionFileID!;
            })[0].fileUrl!,
            unitPrice: p?.product?.productOption?.unitPrice,
            promotionOverlap: renderPromotionOverlapName(p?.promotionOverlap!),
            canNotBeApplied:
              !p?.product?.productOption?.unitPrice! &&
              (promotionDetail?.discountType!
                ? promotionDetail.discountType
                : DiscountTypeOptionEnum.percent) === DiscountTypeOptionEnum.value,
          } as PromotionProduct;
        }),
        promotionDetail: {
          promotionNameLocal: promotionDetail.promotionNameLocal!,
          promotionNameEN: promotionDetail.promotionNameEN!,
          descriptionLocal: promotionDetail.descriptionLocal!,
          descriptionEN: promotionDetail.descriptionEN!,
          imageLocal: imageLocalObject,
          imageGlobal: imageEnObject,
          startDate: promotionDetail.startDate!,
          startTime: getTimeFromDate(promotionDetail?.startDate!)!,
          endDate: promotionDetail.endDate!,
          endTime: promotionDetail.noEndDate
            ? '00:00'
            : getTimeFromDate(promotionDetail?.endDate!)!,
          isNoEndDate: promotionDetail.noEndDate!,
          discountType: promotionDetail.discountType
            ? promotionDetail.discountType
            : DiscountTypeOptionEnum.percent,
          invalidDate: false,
          invalidTime: false,
          promotionComingStatus: promotionDetail.status!.toUpperCase(),
        },
      };
    } else {
      return DealsAndDiscountDefaultValues(currentCompany?.companyID!, currentCompany?.branchId!);
    }
  };

  const formMethod = useForm<DealsAndDiscount>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(DealsAndDiscountSchema()),
    shouldFocusError: false,
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (promotionDetail) {
      setProductItemsInPromotion([
        ...promotionDetail.promotionProductItemList?.items?.map((p) => {
          let productName = '';
          if (i18n.language === Language.en.key) {
            if (p?.product?.nameEn) {
              productName = p?.product?.nameEn;
            } else if (p?.product?.nameLocal) {
              productName = p?.product?.nameLocal;
            }
          } else {
            if (p?.product?.nameLocal) {
              productName = p?.product?.nameLocal;
            } else if (p?.product?.nameEn) {
              productName = p?.product?.nameEn;
            }
          }
          return {
            productID: p?.product?.productID,
            discountValue: p?.value!,
            sku: p?.product?.sku!,
            name: productName,
            categoryName: p?.product?.productCategory?.find(
              (category) => category?.categoryLevel === 3
            )?.nameLocal,
            productImageUrl: p?.product?.productFileList[0].fileUrl,
            unitPrice: p?.product?.productOption?.unitPrice,
            promotionOverlap: renderPromotionOverlapName(p?.promotionOverlap!),
            canNotBeApplied:
              !p?.product?.productOption?.unitPrice! &&
              (promotionDetail?.discountType!
                ? promotionDetail.discountType
                : DiscountTypeOptionEnum.percent) === DiscountTypeOptionEnum.value,
          } as PromotionProduct;
        })!,
      ]);
    }
  }, [promotionDetail]);

  const [upsertPromotionMutation] = useUpsertPromotionMutation();

  const handleScroll = () => {
    if (reference && reference.current) {
      const { scrollTop } = reference.current;
      if (scrollTop >= 232) {
        setMenuActive('product');
      } else {
        setMenuActive('promotionDetail');
      }
    }
  };

  const handleUpsertPromotion = (
    isDraft: boolean,
    status: PromotionStatus,
    redirectAfterSaved?: string
  ) => {
    setLoadingUpsert(true);
    upsertPromotionMutation({
      variables: {
        input: {
          ...DealsAndDiscountHelper.getRequestObject(isDraft, status, formMethod.getValues()),
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        } else {
          const alertMsg = isDraft
            ? 'DealsAndDiscount.Alert.YourPromotionDraft'
            : action === DealsAndDiscountAction.EDIT
              ? 'DealsAndDiscount.Alert.YourPromotionUpdated'
              : 'DealsAndDiscount.Alert.YourPromotionAdded';

          alert.show(t(alertMsg), 'success', true);
          history.push(redirectAfterSaved || DealsAndDiscountPageRoute.LIST, {
            forceRedirect: true,
          });
        }
        setLoadingUpsert(false);
      })
      .catch((error: ApolloError) => {
        const ex = CommonHelper.transformGraphQLErrors(error);
        if (ex && ex.length > 0) {
          let errMessage = '';
          ex.forEach((e) => {
            if (e.message) {
              if (errMessage) {
                errMessage += ' ' + e.message;
              } else {
                errMessage += e.message;
              }
            }
          });
          push(SomethingWentWrongModal, {
            errMessage: errMessage,
            onSubmit: () => { },
          });
        } else {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        }
        setLoadingUpsert(false);
      });
  };

  const handleSubmit = async (redirectAfterSaved?: string) => {
    let isValid = await formMethod.trigger();
    if (isValid) {
      if (action === DealsAndDiscountAction.EDIT) {
        push(ConfirmButtonCenterModal, {
          elementID: elementID,
          icon: SaveIcon,
          titleBody: t('DealsAndDiscount.Modal.SaveChange.Title'),
          description1: t('DealsAndDiscount.Modal.SaveChange.Description'),
          btnConfirmText: t('Common.Button.YesSave'),
          btnCancelText: t('Common.Button.Cancel'),
          onSubmit: () => {
            setSubscription(requestGAObservable({
              suffixPathAction: GoogleAnalyticsConstantEvent.UPDATE_PROMOTION,
              event: { action: GoogleAnalyticsConstantEvent.UPDATE_PROMOTION } as EventArgs
            }).subscribe(() => {
              handleUpsertPromotion(false, 'completed', redirectAfterSaved);
            }));
          },
        });
      } else {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.CREATE_PROMOTION,
          event: { action: GoogleAnalyticsConstantEvent.CREATE_PROMOTION } as EventArgs
        }).subscribe(() => {
          handleUpsertPromotion(false, 'completed', redirectAfterSaved);
        }));
      }
    } else {
      CommonHelper.scrollToError(reference?.current);
    }
  };

  const handleSaveDraft = async (redirectAfterSaved?: string) => {
    let isValid = await formMethod.trigger(
      ['promotionDetail.promotionNameLocal', 'promotionDetail.promotionNameEN'],
      { shouldFocus: false }
    );

    if (isValid) {
      setSubscription(requestGAObservable({
        suffixPathAction: GoogleAnalyticsConstantEvent.CREATE_PROMOTION,
        event: { action: GoogleAnalyticsConstantEvent.CREATE_PROMOTION } as EventArgs
      }).subscribe(() => {
        handleUpsertPromotion(true, 'pending', redirectAfterSaved);
      }));
    } else {
      CommonHelper.scrollToError(reference?.current);
    }
  };

  return (
    <>
      <FormProvider {...formMethod}>
        <form>
          <div style={{ display: selectProductsPage ? 'block' : 'none' }}>
            <SelectProduct elementID={elementID} />
          </div>
          <div
            className="full-modal-wrap"
            style={{ display: !selectProductsPage ? 'block' : 'none' }}
          >
            <div className="full-modal-header">
              <div className="full-modal-title" id={`lbl-${elementID}-header`}>
                {action === DealsAndDiscountAction.EDIT
                  ? t('DealsAndDiscount.Title.EditPromotion')
                  : t('DealsAndDiscount.Title.AddPromotion')}
              </div>
              <button
                type="button"
                className="close"
                id={`btn-${elementID}-exit`}
                onClick={() => {
                  setSubscription(requestGAObservable({
                    suffixPathAction: GoogleAnalyticsConstantEvent.CLOSE_PAGE,
                    event: { action: GoogleAnalyticsConstantEvent.CLOSE_PAGE } as EventArgs
                  }).subscribe(() => {
                    history.push(`${PageRoute.DEALS_AND_DISCOUNT}/${DealsAndDiscountPageRoute.LIST}`);
                  }));
                }}
              >
                <i className="icon-cancel"></i>
              </button>
            </div>
            <div className="full-modal-body" ref={reference} onScroll={() => handleScroll()}>
              <div className="template-sticky-left">
                <div className="left-sticky">
                  <ul id="menu-group" className="nav list-menu-group">
                    <li className="nav-item">
                      <a
                        onClick={() =>
                          promotionRef?.current?.scrollIntoView({ behavior: 'smooth' })
                        }
                        className={`nav-link menu-link-item${menuActive === 'promotionDetail' ? ' active' : ''
                          }`}
                      >
                        {t('DealsAndDiscount.Title.PromotionDetails')}
                        {formMethod.formState.errors.promotionDetail && (
                          <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            className="icon-info-invalid ml-12px"
                            id={`ico-${elementID}-error-PromotionDetails`}
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        )}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        onClick={() => productRef?.current?.scrollIntoView({ behavior: 'smooth' })}
                        className={`nav-link menu-link-item${menuActive === 'product' ? ' active' : ''
                          }`}
                      >
                        {t('DealsAndDiscount.Label.Products')}
                        {formMethod.formState.errors.promotionProduct && (
                          <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            className="icon-info-invalid ml-12px"
                            id={`ico-${elementID}-error-Products`}
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        )}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="right-content" ref={refRightContent}>
                  <PromotionDetail elementID={elementID} refBox={promotionRef} />
                  <PromotionProductItem elementID={elementID} refBox={productRef} />
                </div>
              </div>
            </div>

            <div className="full-modal-footer">
              <div className="float-right">
                <SCButton
                  type="primary"
                  className="float-right"
                  onClick={() => handleSubmit()}
                  isLoading={loadingUpsert || isLoadingImage}
                  disabled={loadingUpsert || isLoadingImage}
                >
                  {action === DealsAndDiscountAction.EDIT
                    ? t('DealsAndDiscount.Button.Edit')
                    : t('DealsAndDiscount.Button.Create')}
                </SCButton>
                {action === DealsAndDiscountAction.ADD && (
                  <SCButton
                    type="outline"
                    className="float-right noborder mr-2 mobile"
                    id={`btn-${elementID}-saveDraft`}
                    onClick={() => handleSaveDraft()}
                    isLoading={loadingUpsert || isLoadingImage}
                    disabled={loadingUpsert || isLoadingImage}
                  >
                    {t('DealsAndDiscount.Button.SaveDraft')}
                  </SCButton>
                )}

                {action === DealsAndDiscountAction.EDIT && (
                  <SCButton
                    type="outline"
                    className="float-right noborder mr-2 mobile"
                    id={`btn-${elementID}-cancel`}
                    onClick={() =>
                      history.push(
                        `${PageRoute.DEALS_AND_DISCOUNT}/${DealsAndDiscountPageRoute.LIST}`
                      )
                    }
                    isLoading={loadingUpsert || isLoadingImage}
                    disabled={loadingUpsert || isLoadingImage}
                  >
                    {t('Common.Button.Cancel')}
                  </SCButton>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <Prompt
        when
        message={(nextLocation: H.Location<any>) => {
          if (nextLocation.state?.forceRedirect) {
            return true;
          }
          if (Object.keys(formMethod.formState.dirtyFields).length > 0) {
            push(LeaveThisPageModal, {
              elementID,
              onSubmit: (action) => {
                if (action === 'do_not_save') {
                  history.push(nextLocation.pathname, {
                    ...(nextLocation.state || {}),
                    forceRedirect: true,
                  });
                } else {
                  handleSaveDraft(nextLocation.pathname);
                }
              },
            });
            return false;
          }
          return true;
        }}
      />
    </>
  );
}
