
import { QuickRegisterModel, CompanyInformation } from '../../models/QuickRegisterModel';
import { BranchConstant } from '../constants/BranchConstant';
import { CountryResponse } from '../../api/response/CountryResponse';
import { BusinessEntityType, CompanyDetailResponse, InvitationFieldResponse } from '../../api/response/CompanyResponse';
import { IDropdownLabelItem } from "../../components/dropdown/DropdownLabel";
import { AddressType, BusinessEntityTypeId, IdentityType } from '@utils/constants/CompanyConstant';
import { QuickRegisterRequest } from '@api/request/QuickRegisterRequest';
import { AddNewBranchModel } from '../../models/AddNewBranchModel';
import { AddNewBranchRequest } from '../../api/request/AddNewBranchRequest';
import { CompanyAddressInformation, CompanyDocuments, UpdateCompanyIntroduction } from '@models/Company';
import { PhoneNumber, UpdateAddressRequest, UpdateIntroductionRequest, UpdateBusinessInformationRequest, TradeAbility, DecisionMaker, PhoneWithCountryCode, BoardOfDirector, CustomerReference, UpdateDocumentsInformationRequest, DocumentList, DocumentFile } from '@api/request/CompanyProfile';
import { CommonHelper } from './CommonHelper';
import { Config, DateTimeFormat } from '@utils/constants/CommonConstants';
import { UpdateBusinessInformation } from '@models/BusinessInformationModel';
import { Category } from '../../api/request/CompanyProfile';
import { ConfigResponse, MaintenanceConfigResponse } from '@api/response/ConfigResponse';
import { Branch, Company, CompanyAddress, CompanyCategory, CompanyContact, CompanyRequestUpdateDetail } from '@graphql/autogenerate/schemas';
import dayjs from 'dayjs';

export class CompanyHelper {
  public static transfromQuickRegisterModelToViewInformation(data: QuickRegisterModel, countries?: CountryResponse[],
    businessTypeEntity?: BusinessEntityType[]): CompanyInformation {
    const countyrName = (countries && countries.length) ? countries.filter(f => f.countryCode === data.countryCode)[0].countryNameCode : '';
    const masterIdentityTypes: IDropdownLabelItem[] = [
      { label: 'RegisterCompany.Label.Corporation', val: 1 },
      { label: 'RegisterCompany.Label.Individual', val: 2 },
    ]
    let branchName = '';
    if (data.branchNumber == BranchConstant.HEAD_QUARTER) {
      branchName = 'RegisterCompany.Label.Headquarter';
    } else if (data.branchNumber) {
      branchName = data.branchNumber + '' + ((data.branchName) ? ' ' + data.branchName : '')
    } else {
      branchName = 'RegisterCompany.Label.NotSpecify';
    }

    let businessEntityTypeName = '';
    let otherBusinessEntityName = ''
    if (data.identityTypeId == IdentityType.COPORATION
      && businessTypeEntity
      && businessTypeEntity.length
      && data?.businessEntityTypeId) {
      const businessType = businessTypeEntity.filter(f => f.masterBusinessEntityId == Number(data?.businessEntityTypeId))[0];

      businessEntityTypeName = businessType.businessEntityCode;
      if (businessType.masterBusinessEntityId == Number(BusinessEntityTypeId.OTHER) && data.businessEntityTypeOther) {
        otherBusinessEntityName = data.businessEntityTypeOther;
      }
    }
    let vat = '';
    if (data.identityTypeId == IdentityType.INDIVIDUAL) {
      vat = (Number(data.vat)) ? 'Common.Label.Yes' : 'Common.Label.No';
    }
    return {
      countryName: countyrName,
      identityTypeName: (masterIdentityTypes && masterIdentityTypes.length && data.identityTypeId) ? masterIdentityTypes.filter(f => f.val == data.identityTypeId)[0].label : '',
      taxId: data.taxId,
      businessEntityTypeName: businessEntityTypeName,
      otherBusinessEntityName: otherBusinessEntityName,
      companyName: (data.identityTypeId == IdentityType.COPORATION) ? data.companyName : '',
      companyNameEN: (data.identityTypeId == IdentityType.COPORATION) ? data.companyNameEN : '',
      shopName: (data.identityTypeId == IdentityType.INDIVIDUAL) ? data.companyName : '',
      shopNameEN: (data.identityTypeId == IdentityType.INDIVIDUAL) ? data.companyNameEN : '',
      branchName: branchName,
      taxIdName: (data.firstName) ? data.firstName + ' ' + data.lastName : '',
      taxIdNameEN: (data.firstNameEN) ? data.firstNameEN + ' ' + data.lastNameEN : '',
      vat: vat,
      address: data?.taxAddress
        + ' ' + data?.subDistrict
        + ' ' + data?.district
        + ' ' + data?.province
        + ' ' + data?.postalCode
    };
  }

  public static transfromAddNewBranchModelToViewInformation(data: AddNewBranchModel, countries?: CountryResponse[],
    businessTypeEntity?: BusinessEntityType[]): CompanyInformation {
    const countyrName = (countries && countries.length) ? countries.filter(f => f.countryCode === data.countryCode)[0].countryNameCode : '';
    const masterIdentityTypes: IDropdownLabelItem[] = [
      { label: 'RegisterCompany.Label.Corporation', val: 1 },
      { label: 'RegisterCompany.Label.Individual', val: 2 },
    ]
    let branchName = '';
    if (data.branchNumber == BranchConstant.HEAD_QUARTER) {
      branchName = 'RegisterCompany.Label.Headquarter';
    } else if (data.branchNumber) {
      branchName = data.branchNumber + '' + ((data.branchName) ? ' ' + data.branchName : '')
    } else {
      branchName = 'RegisterCompany.Label.NotSpecify';
    }

    let businessEntityTypeName = '';
    let otherBusinessEntityName = '';
    if (data.identityTypeId == IdentityType.COPORATION
      && businessTypeEntity
      && businessTypeEntity.length
      && data?.businessEntityTypeId) {

      const businessType = businessTypeEntity.filter(f => f.masterBusinessEntityId == Number(data?.businessEntityTypeId))[0];

      businessEntityTypeName = (businessType.businessEntityCode) ? businessType.businessEntityCode : '';
      if (businessType.masterBusinessEntityId == Number(BusinessEntityTypeId.OTHER) && data.businessEntityTypeOther) {
        otherBusinessEntityName = data.businessEntityTypeOther;
      }
    }

    let vat = '';
    if (data.identityTypeId == IdentityType.INDIVIDUAL) {
      vat = (Number(data.vat)) ? 'Common.Label.Yes' : 'Common.Label.No';
    }

    return {
      countryName: countyrName,
      identityTypeName: (masterIdentityTypes && masterIdentityTypes.length) ? masterIdentityTypes.filter(f => f.val == data.identityTypeId)[0].label : '',
      taxId: data.taxId ? data.taxId : '',
      businessEntityTypeName: businessEntityTypeName,
      otherBusinessEntityName: otherBusinessEntityName,
      companyName: (data.identityTypeId == IdentityType.COPORATION) ? data.companyName : '',
      companyNameEN: (data.identityTypeId == IdentityType.COPORATION) ? data.companyNameEN : '',
      shopName: (data.identityTypeId == IdentityType.INDIVIDUAL) ? data.companyName : '',
      shopNameEN: (data.identityTypeId == IdentityType.INDIVIDUAL) ? data.companyNameEN : '',
      branchName: branchName,
      taxIdName: (data.firstName) ? data.firstName + ' ' + data.lastName : '',
      taxIdNameEN: (data.firstNameEN) ? data.firstNameEN + ' ' + data.lastNameEN : '',
      vat: vat,
      address: data?.taxAddress
        + ' ' + data?.subDistrict
        + ' ' + data?.district
        + ' ' + data?.province
        + ' ' + data?.postalCode
    };
  }

  public static transfromQuickRegisterModelToQuickRegisterRequest(data: QuickRegisterModel): QuickRegisterRequest {
    const requestBody: QuickRegisterRequest = {
      taxId: data.taxId,
      taxIdFirstName: data.firstName,
      taxIdLastName: data.lastName,
      taxIdFirstNameEN: data.firstNameEN,
      taxIdLastNameEN: data.lastNameEN,
      taxCountryCode: data.countryCode,
      identityTypeId: data.identityTypeId,
      businessEntityId: Number(data.businessEntityTypeId),
      otherBusinessEntityName: data.businessEntityTypeOther,
      companyNameLocal: data.companyName,
      companyNameEN: data.companyNameEN,
      branchTypeId: Number(data.branchType),
      branchNumber: data.branchNumber,
      branchName: data.branchName,
      isRDVerify: data.rdVerify,
      taxAddress: {
        address: data.taxAddress,
        countryCode: data.countryCode,
        provinceName: data.province,
        districtName: data.district,
        subDistrictName: data.subDistrict,
        postalCode: data.postalCode
      }
    }

    if (data.identityTypeId == IdentityType.INDIVIDUAL) {
      requestBody.isVatRegistration = !!Number(data.vat)
    }
    return requestBody;
  }

  public static transfromAddNewBranchModelToAddNewBranchRequest(data: AddNewBranchModel): AddNewBranchRequest {
    const requestBody: AddNewBranchRequest = {
      companyId: data.companyId,
      branchTypeId: Number(data.branchType),
      branchNumber: data.branchNumber,
      branchName: data.branchName,
      taxAddress: {
        address: data.taxAddress,
        countryCode: data.countryCode,
        provinceName: data.province,
        districtName: data.district,
        subDistrictName: data.subDistrict,
        postalCode: data.postalCode
      }
    }
    return requestBody;
  }

  public static transfromToRequestUpdateIntroduction(data: UpdateCompanyIntroduction): UpdateIntroductionRequest {

    let request: UpdateIntroductionRequest = {
      branchId: data.branchId,
      images: data.companyImages.map(i => {
        return {
          seq: i.order! || 1,
          UUID: i.uuid!,
          originalFileName: i.originalFileName!
        }
      })!,
      brochures: data.companyBrochures.map(b => {
        return {
          brochureID: b.brochureID!,
          brochureName: b.brochureName!,
          UUID: b.UUID!,
          originalFileName: b.originalFileName!
        }
      })!,
      videos: data.companylinks.map(l => { return { ...l } })!,
      certificates: data.companyCertificate.map(c => {
        return {
          certificateID: c?.certificateId!,
          certificateName: c?.certificateName!,
          certificateByID: c?.certificateById ? Number(c?.certificateById) : 0,
          certificateByOtherName: c?.certificateByOtherName!,
          startDate: c?.startDate ? CommonHelper.displayDate(c.startDate!, DateTimeFormat.YYYYMMDD_HYPHEN) : undefined,
          expireDate: c?.expireDate ? CommonHelper.displayDate(c.expireDate!, DateTimeFormat.YYYYMMDD_HYPHEN) : undefined,
          files: c?.files.map(f => {
            return {
              UUID: f.uuid!,
              originalFileName: f.originalFileName!,
              seq: f.order! || 1
            }
          })! || [],
          description: c?.description!
        }
      })!
    }
    return request;
  }

  public static transfromToRequestUpdateAddress(data: CompanyAddressInformation): UpdateAddressRequest {
    let faxNumber: PhoneNumber;
    if (data.companyContact?.officeFaxNumbers?.phone) {
      faxNumber = {
        callingCode: data.companyContact?.officeFaxNumbers?.phoneCallingCode!,
        phoneNumber: data.companyContact?.officeFaxNumbers?.phone!,
        ext: data.companyContact?.officeFaxNumbers?.phoneExt!,
      }
    }

    return {
      companyId: data.companyId,
      branchId: data.branchId,
      website: data?.companyContact?.website!,
      taxAddress: {
        countryCode: data.taxAddress?.countryCode!,
        provinceEn: data.taxAddress?.provinceEN!,
        provinceLocal: data.taxAddress?.provinceLocal!,
        districtEn: data.taxAddress?.districtEN!,
        districtLocal: data.taxAddress?.districtLocal!,
        subDistrictEn: data.taxAddress?.subDistrictEN!,
        subDistrictLocal: data.taxAddress?.subDistrictLocal!,
        postalCode: data.taxAddress?.postalCode!,
        addressEn: data.taxAddress?.addressEN!,
        addressLocal: data.taxAddress?.addressLocal!,
      },
      companyAddress: {
        countryCode: data.companyAddress?.countryCode!,
        provinceEn: data.companyAddress?.provinceEN!,
        provinceLocal: data.companyAddress?.provinceLocal!,
        districtEn: data.companyAddress?.districtEN!,
        districtLocal: data.companyAddress?.districtLocal!,
        subDistrictEn: data.companyAddress?.subDistrictEN!,
        subDistrictLocal: data.companyAddress?.subDistrictLocal!,
        postalCode: data.companyAddress?.postalCode!,
        addressEn: data.companyAddress?.addressEN!,
        addressLocal: data.companyAddress?.addressLocal!,
      },
      documentDeliveryAddress: {
        countryCode: data.documentDeliveryAddress?.countryCode!,
        provinceEn: data.documentDeliveryAddress?.provinceEN!,
        provinceLocal: data.documentDeliveryAddress?.provinceLocal!,
        districtEn: data.documentDeliveryAddress?.districtEN!,
        districtLocal: data.documentDeliveryAddress?.districtLocal!,
        subDistrictEn: data.documentDeliveryAddress?.subDistrictEN!,
        subDistrictLocal: data.documentDeliveryAddress?.subDistrictLocal!,
        postalCode: data.documentDeliveryAddress?.postalCode!,
        addressEn: data.documentDeliveryAddress?.addressEN!,
        addressLocal: data.documentDeliveryAddress?.addressLocal!,
      },
      billingAddress: {
        countryCode: data.billingAddress?.countryCode!,
        provinceEn: data.billingAddress?.provinceEN!,
        provinceLocal: data.billingAddress?.provinceLocal!,
        districtEn: data.billingAddress?.districtEN!,
        districtLocal: data.billingAddress?.districtLocal!,
        subDistrictEn: data.billingAddress?.subDistrictEN!,
        subDistrictLocal: data.billingAddress?.subDistrictLocal!,
        postalCode: data.billingAddress?.postalCode!,
        addressEn: data.billingAddress?.addressEN!,
        addressLocal: data.billingAddress?.addressLocal!,
      },
      billingContact: (data?.billingContact || []).map((b) => (
        {
          fullNameEn: b.fullNameEN!,
          fullNameLocal: b.fullNameLocal!,
          email: b.email!,
          phoneNumber: [
            {
              callingCode: b.phoneCallingCode!,
              phoneNumber: b.phone!,
              ext: b.phoneExt!,
            },
          ],
        }
      )),
      companyContact: {
        email: data.companyContact?.email!,
        phoneNumber: (data.companyContact?.officePhoneNumbers || []).map(
          (item) => ({
            callingCode: item.phoneCallingCode!,
            phoneNumber: item.phone!,
            ext: item.phoneExt!,
          })
        ),
        faxNumber: [faxNumber!]
      },
      contactInformation: (data?.contactInformation || []).map((ci) => {
        let mobileNumber: PhoneNumber;
        if (ci?.mobileNumber?.phone) {
          mobileNumber = {
            callingCode: ci.mobileNumber.phoneCallingCode!,
            phoneNumber: ci.mobileNumber.phone!,
            ext: ci.mobileNumber.phoneExt!
          }
        }
        return {
          titleNameId: ci.titleNameId!,
          firstNameLocal: ci.firstNameLocal!,
          lastNameLocal: ci.lastNameLocal!,
          firstNameEN: ci.firstNameEN!,
          lastNameEN: ci.lastNameEN!,
          departmentName: ci.departmentName!,
          email: ci.email!,
          jobTitleId: ci.jobTitleId!,
          otherJobTitleName: ci.otherJobTitleName!,
          phoneNumber: [{
            callingCode: ci.phoneNumber.phoneCallingCode!,
            phoneNumber: ci.phoneNumber.phone!,
            ext: ci.phoneNumber.phoneExt!
          }],
          mobileNumber: [mobileNumber!]
        }
      })
    }
  }

  public static transfromToRequestUpdateBusinessInformation(data: UpdateBusinessInformation): UpdateBusinessInformationRequest {

    let newCategory: Category[] = []
    if (data.category) {
      if (data.category) {
        data.category.forEach((c) => {
          let category: Category = {
            categoryLevelId: c.categoryLevelId,
            businessType: c.businessType
          }
          newCategory.push(category)
        })
      }
    }

    let newTradeAbility: TradeAbility
    if (data.tradeAbility) {

      newTradeAbility = {
        totalAnnualRevenue: data.tradeAbility.totalAnnualRevenue!,
        netProfitLoss: data.tradeAbility.netProfit!,
        totalAssets: data.tradeAbility.totalAssets!,
        shareholdersEquity: data.tradeAbility.shareholdersEquity!
      }
    }

    let newDecisionMaker: DecisionMaker[] = []
    if (data.decisionMakers) {
      data.decisionMakers.forEach(
        (d) => {
          let newPhone: PhoneWithCountryCode = {
            countryCode: d.phoneNumber.countryCode!,
            phoneNumber: d.phoneNumber.phone!,
            ext: d.phoneNumber.phoneExt
          }
          let decisionMaker: DecisionMaker = {
            fullName: d.fullName,
            email: d.email,
            phoneNumber: [newPhone]
          }
          newDecisionMaker.push(decisionMaker)
        }
      )
    }

    let newBoardOfDirector: BoardOfDirector[] = []
    if (data.boardOfDirectors) {
      data.boardOfDirectors.forEach(
        (b) => {
          let boardOfDirector: BoardOfDirector = {
            fullName: b.fullName
          }
          newBoardOfDirector.push(boardOfDirector)
        }
      )
    }

    let newCustomerReference: CustomerReference[] = []
    if (data.customerReference) {
      data.customerReference.forEach(
        (c) => {
          let customerReference: CustomerReference = {
            projectOrCustomerName: c.projectOrCustomerName,
            countryCode: c.countryCode,
            productSupplyToCustomer: c.productSupplyToCustomer,
            description: c.description,
            files: c.files.map((f) => {
              return {
                UUID: f.uuid!,
                originalFileName: f.originalFileName!,
                seq: f.order! || 1
              }
            })! || []
          }

          newCustomerReference.push(customerReference)
        }
      )
    }

    return {
      branchId: data.branchId,
      registeredCapital: data.registeredCapital!.toString(),
      yearEstablished: data.yearEstablished!,
      category: newCategory!,
      keywords: data.keyWords!,
      totalEmployees: data.totalEmployees!,
      tradeAbility: newTradeAbility!,
      decisionMaker: newDecisionMaker!,
      boardOfDirector: newBoardOfDirector!,
      customerReference: newCustomerReference!,
      paymentID: data.paymentID!
    };
  }

  public static transfromToRequestUpdateDocumentsInformation(data: CompanyDocuments): UpdateDocumentsInformationRequest {
    let documentList: DocumentList[] = [];

    if (data?.companyRegistration) {
      data?.companyRegistration.forEach(f => {
        documentList.push({
          documentTypeId: f.documentTypeId,
          documentTypeName: f.documentTypeName,
          file: {
            ...f.files[0],
            uploadDate: (f?.files[0].uploadDate!) ? dayjs(f.files[0].uploadDate, DateTimeFormat.YYYYMMDD_HYPHEN).format(DateTimeFormat.YYYYMMDD_HYPHEN) : undefined,
            seq: 1
          } as DocumentFile,
          otherDocumentName: f.otherDocumentName,
          isVerified: !!(f.isVerified),
          isPISApprove: !!(f.isPISApprove)
        } as DocumentList)
      });
    }

    if (data?.vatRegistration) {
      data?.vatRegistration.forEach(f => {
        documentList.push({
          documentTypeId: f.documentTypeId,
          documentTypeName: f.documentTypeName,
          file: {
            ...f.files[0],
            uploadDate: (f?.files[0].uploadDate!) ? dayjs(f.files[0].uploadDate, DateTimeFormat.YYYYMMDD_HYPHEN).format(DateTimeFormat.YYYYMMDD_HYPHEN) : undefined,
            seq: 1
          } as DocumentFile,
          otherDocumentName: f.otherDocumentName,
          isVerified: !!(f.isVerified),
          isPISApprove: !!(f.isPISApprove)
        } as DocumentList)
      });
    }

    if (data?.others) {
      data?.others.forEach(f => {
        documentList.push({
          documentTypeId: f.documentTypeId,
          documentTypeName: f.documentTypeName,
          file: {
            ...f.files[0],
            uploadDate: (f?.files[0].uploadDate!) ? dayjs(f.files[0].uploadDate, DateTimeFormat.YYYYMMDD_HYPHEN).format(DateTimeFormat.YYYYMMDD_HYPHEN) : undefined,
            seq: 1
          } as DocumentFile,
          otherDocumentName: f.otherDocumentName,
          isVerified: !!(f.isVerified),
          isPISApprove: !!(f.isPISApprove)
        } as DocumentList)
      });
    }

    return {
      branchId: data.branchId!,
      documentList: documentList
    } as UpdateDocumentsInformationRequest;
  }

  public static fineInvitationFieldRequired = (fieldCode: string, invitationFieldRequired?: InvitationFieldResponse[]): boolean => {
    return invitationFieldRequired?.find(f => f.fieldCode === fieldCode) ? true : false;
  }

  public static transfromConfigResponseToMaintenanceConfigResponse(data: ConfigResponse[]): MaintenanceConfigResponse {
    const maintenanceConfigResponse: MaintenanceConfigResponse = {
      date: '',
      startTime: '',
      endTime: ''
    }

    if (data != null && data.length > 0) {
      return {
        date: data.find(x => x.name === Config.Maintenance_Date)?.value!,
        startTime: data.find(x => x.name === Config.Maintenance_StartTime)?.value!,
        endTime: data.find(x => x.name === Config.Maintenance_EndTime)?.value!,
      }
    } else {
      return {
        date: '',
        startTime: '',
        endTime: ''
      }
    }
  }

  public static transfromCompanyRequestUpdateDetailToCompany(data: CompanyRequestUpdateDetail, oldData: Company): Company {
    let branch = oldData.branchList.find(f => f.branchNumber === data.companyInformation?.branchNumber) as Branch;

    let branchAddressList = data?.address?.length ? data.address?.map(a => {
      return {
        ...a,
        addressType: AddressType.getAddressTypeByName(a?.addressTypeNameCode!)
      } as CompanyAddress;
    }) : branch.branchAddressList;

    let categoryList = data?.productServiceCategory?.length ?
      data?.productServiceCategory?.map(c => {
        return c as CompanyCategory;
      }) : branch.categoryList;

    let contactList: CompanyContact[] = [];

    if (data?.billingContact?.length) {
      data?.billingContact.forEach(c => contactList.push(c as CompanyContact));
    }

    if (data?.companyContact?.length) {
      data?.companyContact.forEach(c => contactList.push(c as CompanyContact));
    }

    if (data?.becomeABuyer?.contact?.length) {
      data?.becomeABuyer?.contact.forEach(c => contactList.push(c as CompanyContact));
    }

    return {
      ...oldData,
      companyNameEn: data?.companyInformation?.companyNameEn ? data?.companyInformation?.companyNameEn : oldData?.companyNameEn,
      companyNameLocal: data?.companyInformation?.companyNameLocal ? data?.companyInformation?.companyNameLocal : oldData.companyNameLocal,
      registeredCapital: data?.companyInformation?.registeredCapital! ? Number(data?.companyInformation?.registeredCapital) : oldData.registeredCapital,
      taxFirstNameEn: data?.companyInformation?.taxFirstNameEn ? data?.companyInformation?.taxFirstNameEn : oldData.taxFirstNameEn,
      taxFirstNameLocal: data?.companyInformation?.taxFirstNameLocal ? data?.companyInformation?.taxFirstNameLocal : oldData.taxFirstNameLocal,
      taxLastNameEn: data?.companyInformation?.taxLastNameEn ? data?.companyInformation?.taxLastNameEn : oldData.taxLastNameEn,
      taxLastNameLocal: data?.companyInformation?.taxLastNameLocal ? data?.companyInformation?.taxLastNameLocal : oldData.taxLastNameLocal,
      yearEstablished: data?.companyInformation?.yearEstablished ? data?.companyInformation?.yearEstablished : oldData.yearEstablished,
      branchList: [{
        ...branch,
        branchAddressList: branchAddressList,
        branchNameEn: data?.companyInformation?.branchNameEn ? data?.companyInformation?.branchNameEn : branch.branchNameEn,
        branchNameLocal: data?.companyInformation?.branchNameLocal ? data?.companyInformation?.branchNameLocal : branch.branchNameLocal,
        keywords: data.productKeyword?.length ? data.productKeyword?.toString() : branch.keywords,
        totalEmployee: data.companyInformation?.numberOfEmployee ? data.companyInformation?.numberOfEmployee : branch.totalEmployee,
        categoryList: categoryList,
        contactList: contactList
      }],
    }
  }

  public static transfromCompanyDetailResponseToViewInformation(data: CompanyDetailResponse): CompanyInformation {

    let branchName = '';
    if (data.branchNumber == BranchConstant.HEAD_QUARTER) {
      branchName = 'RegisterCompany.Label.Headquarter';
    } else if (data.branchNumber) {
      branchName = data.branchNumber + '' + ((data.branchNameLocal) ? ' ' + data.branchNameLocal : '')
    } else {
      branchName = 'RegisterCompany.Label.NotSpecify';
    }

    let vat = '';
    if (data.identityTypeId == IdentityType.INDIVIDUAL) {
      vat = data.isVatRegistration ? 'Common.Label.Yes' : 'Common.Label.No';
    }

    let businessEntityTypeName = '';
    let otherBusinessEntityName = ''
    if (data.identityTypeId == IdentityType.COPORATION) {

      businessEntityTypeName = data.businessEntityCode;
      if (data.businessEntityCode === 'Common.BusinessEntityMaster.Other') {
        otherBusinessEntityName = data.otherBusinessEntityName;
      }
    }

    let address = '';
    if (data?.address[0]) {
      address = data?.address[0].addressLocal
        + ' ' + data?.address[0].subDistrictLocal
        + ' ' + data?.address[0].districtLocal
        + ' ' + data?.address[0].provinceLocal
        + ' ' + data?.address[0].postalCode;
    }

    return {
      countryName: data.countryNameCode,
      identityTypeName: data.identityTypeNameCode,
      taxId: data.taxID,
      businessEntityTypeName: businessEntityTypeName,
      otherBusinessEntityName: otherBusinessEntityName,
      companyName: (data.identityTypeId == IdentityType.COPORATION) ? data.companyNameLocal : '',
      companyNameEN: (data.identityTypeId == IdentityType.COPORATION) ? data.companyNameEn : '',
      shopName: (data.identityTypeId == IdentityType.INDIVIDUAL) ? data.companyNameLocal : '',
      shopNameEN: (data.identityTypeId == IdentityType.INDIVIDUAL) ? data.companyNameEn : '',
      branchName: branchName,
      taxIdName: (data.taxFirstNameLocal) ? data.taxFirstNameLocal + ' ' + data.taxLastNameLocal : '',
      taxIdNameEN: (data.taxFirstNameEn) ? data.taxFirstNameEn + ' ' + data.taxLastNameEn : '',
      vat: vat,
      address: address
    }
  }

}
