import {
    HeaderTypes,
    HttpClientHelper,
    PTVNClient
} from "@ptvn-react/authentication";
import { AxiosRequestConfig } from "axios";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import APIConstant from "../utils/constants/APIConstant";
import { UserRequest, UserProfileRequest, VerifyChangeEmailRequest } from "./request/UserRequest";
import { PTVNResponse } from "./response/BaseResponse";
import { CountryResponse } from "./response/CountryResponse";
import { MasterUserTitleResponse, UserProfileResponse } from "./response/UserResponse";
import { UserUpdateVerifiedResponse } from "./response/UserUpdateVerifiedResponse";

export class UserClient {

    public static getUserProfile(): Observable<PTVNResponse<UserProfileResponse>> {
        const url = `${APIConstant.user.getUserProfile}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.get<PTVNResponse<UserProfileResponse>>(
            url,
            options
        ).pipe(map((m) => m.data));
    }

    public static getUpdateUserBroadcast(request: UserRequest): Observable<PTVNResponse<UserProfileResponse>> {
        const url = `${APIConstant.user.updateBroadCastUser}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.post<PTVNResponse<UserProfileResponse>>(
            url,
            request,
            options
        ).pipe(map((m) => m.data));
    }

    public static getUpdateUserProfile(request: UserProfileRequest): Observable<PTVNResponse<UserProfileResponse>> {
        const url = `${APIConstant.user.updateUserProfile}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        let requestBody: UserProfileRequest = request;
        if (!request.mobileNumber) {
            requestBody = {
                ...request,
                mobileCountryCode: ''
            }
        }

        if (!request.fax) {
            requestBody = {
                ...requestBody,
                faxCountryCode: ''
            }
        }
        return PTVNClient.put<PTVNResponse<UserProfileResponse>>(
            url,
            requestBody,
            options
        ).pipe(map((m) => m.data));
    }

    public static getUserUpdateVerified(): Observable<PTVNResponse<UserUpdateVerifiedResponse>> {
        const url = `${APIConstant.user.userUpdateVerified}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.get<PTVNResponse<UserUpdateVerifiedResponse>>(
            url,
            options
        ).pipe(map((m) => m.data));
    }

    public static requestUpdateEmail(email: string): Observable<PTVNResponse<UserUpdateVerifiedResponse>> {
        const requestBody = {
            email: email
        };
        const url = `${APIConstant.user.requestChangeEmail}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.post<PTVNResponse<UserUpdateVerifiedResponse>>(
            url,
            requestBody,
            options
        ).pipe(map((m) => m.data));
    }

    public static resendEmail(): Observable<PTVNResponse<UserUpdateVerifiedResponse>> {
        const requestBody = {

        };
        const url = `${APIConstant.user.resendEmail}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.post<PTVNResponse<UserUpdateVerifiedResponse>>(
            url,
            requestBody,
            options
        ).pipe(map((m) => m.data));
    }

    public static updateStatusVerifiedEmail(status: string): Observable<PTVNResponse<UserUpdateVerifiedResponse>> {
        const requestBody = {
            status: status
        };
        const url = `${APIConstant.user.updateStatusVerifiedEmail}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.put<PTVNResponse<UserUpdateVerifiedResponse>>(
            url,
            requestBody,
            options
        ).pipe(map((m) => m.data));
    }

    public static verifyChangeEmail(request: VerifyChangeEmailRequest): Observable<PTVNResponse<any>> {
        const url = `${APIConstant.user.verifyChangeEmail}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.post<PTVNResponse<any>>(
            url,
            request,
            options
        ).pipe(map((m) => m.data));
    }

    public static getMasterUserTitle(): Observable<PTVNResponse<MasterUserTitleResponse[]>> {
        const url = `${APIConstant.user.masterUserTitle}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.get<PTVNResponse<MasterUserTitleResponse[]>>(
            url,
            options
        ).pipe(map((m) => m.data));
    }

    public static updateRegisterFrom(): Observable<PTVNResponse<any>> {
        const url = `${APIConstant.user.updateRegisterFrom}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.get<PTVNResponse<any>>(
            url,
            options
        ).pipe(map((m) => m.data));
    }
}
