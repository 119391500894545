import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Middleware } from 'redux';
import reduxFreeze from 'redux-freeze';
import thunk from 'redux-thunk';
import createRootReducer from './rootReducer';
import persistReducer from 'redux-persist/es/persistReducer';
import { PersistConfig } from 'redux-persist/es/types';
import Cookies from 'js-cookie';
import { CookieStorage } from 'redux-persist-cookie-storage';

export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);
export type RootState = ReturnType<typeof rootReducer>;

const router = routerMiddleware(history);
const middleware: Middleware[] = [thunk, router];
if (process.env.NODE_ENV === 'development') {
    middleware.push(reduxFreeze);
}

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage: new CookieStorage(Cookies),
    blacklist: ['router']
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configuredStore = (initialState?: Partial<RootState>): any => {
    const store = configureStore({
        reducer: persistedReducer,
        middleware,
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: initialState
    });
    return store;
};


export type Store = ReturnType<typeof configuredStore>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
