import Header from "@components/layout/Header";
import React from "react";
import FooterContact from "@components/layout/FooterContact";
import Img404 from '../../assets/images/frontend/404.png'
import { useTranslation } from "react-i18next";

export interface IServiceUnavailablePageProp {
}

export default function ServiceUnavailablePage(props: React.PropsWithChildren<IServiceUnavailablePageProp>): React.FunctionComponentElement<any> {
    const { t } = useTranslation()

    return <>
        <Header
            notShowIconSideMenu={true}
            noAppSwitcher={true}
            clickLogoNoRedirect={true}
        />
        <div className="content">
            <div className="content-align-middle">
                <div className="d-flex align-items-center">
                    <div className="text-center">
                        <img src={Img404} width="240" className="mb-4" />
                        <h3 className="title-header">{t("Common.Label.ServiceUnavailable")}</h3>
                        <p>{t("Common.Label.ServiceUnavailableDetail")}</p>
                        <button
                            id={`btn-ServiceUnavailable-reupload`}
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                window.location.reload();
                            }}
                        >
                            {t('Common.Button.ReloadThisPage')}
                        </button>
                    </div>
                </div>
            </div>
            <FooterContact />
        </div>

    </>;
}