import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalSize } from '../../utils/constants/StyleConstant';
import { SCButton } from '@components/button/SCButton';
import { ModalStackPropsBase } from './ModalStackProvider';
import DeleteIcon from '@assets/images/frontend/delete.png';

export interface IDeleteModalData {
  modalSize?: ModalSize;
  title?: string;
  description?: string;
  submitText?: string;
  submitType?: 'primary' | 'reject';
  cancelText?: string;
  bothButtonCenter?: boolean;
  dialogClassName?: string;
}

interface IDeleteModalProps extends ModalStackPropsBase<unknown, unknown> {
  data: IDeleteModalData;
  pageElementID: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  elementRef?: any;
}

export function DeleteModal(
  props: React.PropsWithChildren<IDeleteModalProps>
): React.FunctionComponentElement<IDeleteModalProps> {
  const { data, pageElementID, isLoading, onClose, onSubmit, elementRef, show } = props;

  const { t } = useTranslation();

  const elementID = `${pageElementID}-delete`;

  const handleSubmit = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onClose();
  };
  return (
    <>
      {
        <Modal
          id={`modal-${elementID}`}
          show={show}
          size={data.modalSize === ModalSize.MD ? undefined : data.modalSize}
          dialogClassName={data.modalSize === ModalSize.MD ? `modal-dialog modal-md ${data.dialogClassName}` : data.dialogClassName}
          onHide={handleCancel}
        >
          <Modal.Body ref={elementRef}>
            <div className="text-center">
              <img src={DeleteIcon} className="card-icon" />
              <div id={`lbl-${elementID}-header`} className="title">
                {data.title ? t(data.title) : t('Delete ?')}
              </div>
              {data?.description && (
                <p id={`lbl-${elementID}-description`}>{t(data.description)}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              data.bothButtonCenter ? (
                <>
                  <div className='text-center'>
                    <SCButton id={`btn-${elementID}-cancel`} type="outline" onClick={handleCancel} disabled={isLoading}>
                      {data.cancelText || t('Common.Button.Cancel')}
                    </SCButton>
                    <SCButton
                      id={`btn-${elementID}-yes`}
                      type={data.submitType ? data.submitType : 'reject'}
                      onClick={handleSubmit}
                      isLoading={isLoading}
                    >
                      {data.submitText || t('Common.Button.YesDelete')}
                    </SCButton>
                  </div>
                </>
              ) : (
                <>
                  <div className="float-left">
                    <SCButton id={`btn-${elementID}-cancel`} type="outline" onClick={handleCancel} disabled={isLoading}>
                      {data.cancelText || t('Common.Button.Cancel')}
                    </SCButton>
                  </div>
                  <div className="float-right">
                    <SCButton
                      id={`btn-${elementID}-yes`}
                      type={data.submitType ? data.submitType : 'reject'}
                      onClick={handleSubmit}
                      isLoading={isLoading}
                    >
                      {data.submitText || t('Common.Button.YesDelete')}
                    </SCButton>
                  </div>
                </>
              )
            }
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}
