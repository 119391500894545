import { SupplierConnectLayoutProvider } from '@components/provider/SupplierConnectLayoutProvider';
import { SmartAlertContext } from '@components/smart-alert/SmartAlert';
import PageRoute from '@utils/constants/PageRoute';
import React, { useContext, useEffect } from 'react';
import { Collapse } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import SideMenu from './SideMenu';

export default function SupplierConnectLayout({
  children,
}: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
  const alert = useContext(SmartAlertContext);
  const history = useHistory()
  const isMenuMatch = useRouteMatch({
    path: PageRoute.DEALS_AND_DISCOUNT,
    strict: true,
    sensitive: true,
  });

  const classContent = isMenuMatch ? "content no-hz-space" : "content";

  useEffect(() => {
    return history.listen(() => {
      alert.setAlertAsBar(undefined, false);
    })
  }, [history])

  return (
    <>
      <SupplierConnectLayoutProvider>
        <SideMenu />
        <div className="container-fluid">
          <Header />
          <div className={classContent} style={{ minHeight: '100vh' }}>
            <div className="card-content w-full">
              <style dangerouslySetInnerHTML={{
                __html: `
              .smart-alert.collapse.show + .alert { margin-top: -16px }
            `}} />
              <Collapse in={alert.alertAsBar.show} timeout={3000}>
                <div
                  className="smart-alert"
                  style={{ right: 'unset', position: 'static', marginBottom: '24px' }}
                >
                  {alert.alertAsBar.node}
                </div>
              </Collapse>
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </SupplierConnectLayoutProvider>
    </>
  );
}
