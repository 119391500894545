import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingActionEnum } from '@utils/enum/SettingEnum';
import { SettingActionType } from '@utils/constants/SettingConstants';
import defaultProfileImage from '@assets/images/account/user.svg';
import { Controller, useFormContext } from 'react-hook-form';
import { AccountDetail } from '@models/AccountDetail';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import { ExtendedUserInformation } from '@models/User';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { DropdownSelectOption, SimpleDropdownSelect } from '@components/dropdown/SimpleDropdownSelect';
import { JobTitleResponse } from '@api/response/CompanyResponse';
import { useSettingContext } from '../SettingContext';
import { JobTitleId, TitleNameId } from '@utils/constants/CompanyConstant';
import { Validate } from '../account-detail/AccountDetailsSchema';
import parse from 'html-react-parser';

export interface UserInformationProps {
    elementID: string;
    action: SettingActionType;
}

export default function UserInformation(props: React.PropsWithChildren<UserInformationProps>): React.FunctionComponentElement<any> {

    const { elementID, action } = props;
    const { t } = useTranslation();
    const { extendedUserInformation } = usePTVNAuthentication<ExtendedUserInformation>();
    const { masterJobTitle, masterTitleName } = useSettingContext();

    const form = useFormContext<AccountDetail>();
    const {
        formState: { errors },
        trigger,
        watch,
        clearErrors,
        control
    } = form;

    const transformJobTitleListToDropdownSelectOption = (
        masterJobTitleList?: JobTitleResponse[]
    ): DropdownSelectOption[] => {
        if (masterJobTitleList && masterJobTitleList.length) {
            return masterJobTitleList.map((jobTitle) => {
                return {
                    label: t(jobTitle.jobTitleCode),
                    value: jobTitle.masterJobTitleId.toString(),
                };
            });
        } else {
            return [];
        }
    };

    const renderName = () => {
        if (extendedUserInformation?.userInformation!) {
            const userInformation = extendedUserInformation?.userInformation;
            let name = '';
            let titleName = masterTitleName?.filter((title) => title.masterUserTitleID.toString() === userInformation.titleID?.toString())[0];

            if (userInformation?.firstNameEN! &&
                userInformation?.lastNameEN!) {
                let titleEN = (userInformation?.titleID?.toString() === TitleNameId.OTHER) ? userInformation.otherTitleName! || '' : titleName?.nameEN!;
                name += `${titleEN! ? titleEN : ''} ${userInformation?.firstNameEN} ${userInformation?.lastNameEN}`
            }

            if (userInformation?.firstName! &&
                userInformation?.lastName! &&
                extendedUserInformation?.companies[0]?.isEnglishOfficial! != '1') {
                let nameTH = (userInformation?.titleID?.toString() === TitleNameId.OTHER) ? userInformation.otherTitleName! || '' : titleName?.nameTH!;
                if (name) {
                    name += ` (${nameTH! ? nameTH : ''} ${userInformation?.firstName} ${userInformation?.lastName})`
                } else {
                    name += `${nameTH! ? nameTH : ''} ${userInformation?.firstName} ${userInformation?.lastName}`
                }
            }

            return (
                <p>{parse(name)}</p>
            )
        }

        return <></>
    }

    const renderJobTitle = () => {
        let jobTitleName = '-';
        if (watch('jobTitleId') && masterJobTitle?.length!) {
            const jobTitle = masterJobTitle.find(j => j.masterJobTitleId.toString() === watch('jobTitleId'))!;
            if (jobTitle) {
                jobTitleName = t(jobTitle?.jobTitleCode);
            }

            if (watch('jobTitleId') === JobTitleId.OTHER) {
                jobTitleName += ` (${watch('otherJobTitleName')})`
            }
        }
        return (<p>{jobTitleName}</p>);
    }

    const view = () => {
        return (<>
            <div className="col-list">
                <div className="col-label label-lg">{t('AccountDetails.Label.JobTitle')}</div>
                <div className="col-detail">
                    {renderJobTitle()}
                </div>
            </div>
            <div className="col-list">
                <div className="col-label label-lg">{t('AccountDetails.Label.Department')}</div>
                <div className="col-detail">
                    {watch('department') && watch('department')! || '-'}
                </div>
            </div>
        </>)
    }

    const edit = () => {
        return (<>
            <Form.Group>
                <div className="row">
                    <div className={classNames({
                        'col-3': watch('jobTitleId') === JobTitleId.OTHER,
                        'col-6': watch('jobTitleId') !== JobTitleId.OTHER,
                    })}>
                        <label id={`lbl-${elementID}-jobTitleId`}>
                            {t('Congratulations.Label.JobTitle')}
                            <span className="required">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="jobTitleId"
                            render={({ field }) => (
                                <SimpleDropdownSelect
                                    {...field}
                                    ref={field.ref}
                                    placeholder={t('Common.JobTitle.PlaceHolder.JobTitle')}
                                    value={field.value}
                                    hasError={!!errors.jobTitleId}
                                    options={transformJobTitleListToDropdownSelectOption(masterJobTitle)}
                                    onChange={(name: any, val: any) => {
                                        field.onChange(val);
                                        trigger(field.name);
                                        clearErrors('otherJobTitleName');
                                    }}
                                    id={`${elementID}-jobTitleId`}
                                />
                            )}
                        />
                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'jobTitleId',
                            `txt-${elementID}-jobTitleId-error`
                        )}
                    </div>
                    {watch('jobTitleId') === JobTitleId.OTHER && (
                        <div className="col-3">
                            <label>&nbsp;</label>
                            <Controller
                                control={control}
                                name="otherJobTitleName"
                                render={({ field }) => (
                                    <input type="text"
                                        id={`txt-${elementID}-otherJobTitleName`}
                                        className={classNames({
                                            'form-control': true,
                                            'is-invalid': errors?.otherJobTitleName,
                                        })}
                                        maxLength={Validate.otherJobTitleNameMaxLength}
                                        placeholder={t('Common.JobTitle.PlaceHolder.JobTitle')}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            trigger(field.name);
                                        }}
                                        onBlur={(e) => {
                                            field.onChange(e.target.value.trim());
                                            trigger(field.name);
                                        }} />
                                )}
                            />
                            {CommonHelper.renderFieldError<AccountDetail>(
                                t,
                                errors,
                                'otherJobTitleName',
                                `txt-${elementID}-otherJobTitleName-error`
                            )}
                        </div>)
                    }
                    <div className="col-6">
                        <label id={`lbl-${elementID}-department`}>
                            {t('AccountDetails.Label.Department')}
                        </label>
                        <Controller
                            control={control}
                            name="department"
                            render={({ field }) => (
                                <input type="text"
                                    id={`txt-${elementID}-department`}
                                    className={classNames({
                                        'form-control': true,
                                        'is-invalid': errors?.department,
                                    })}
                                    maxLength={Validate.departmentMaxLength}
                                    placeholder={t('AccountDetails.Label.Department')}
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        trigger(field.name);
                                    }}
                                    onBlur={(e) => {
                                        field.onChange(e.target.value.trim());
                                        trigger(field.name);
                                    }} />
                            )}
                        />
                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'department',
                            `txt-${elementID}-department-error`
                        )}
                    </div>
                </div>
            </Form.Group>
        </>)
    }

    return (<>
        <div className="mb-4">
            <div className="col-list align-items-center">
                <div className="col-label label-lg">{t('AccountDetails.Label.Photo')}</div>
                <div className="col-detail">
                    <div className="media">
                        <div className="float-left mr-3">
                            <div className="upload-image no-hover border-circle">
                                <span className="thumbnail-img border-circle">
                                    <a href="" className="img-thumbnail">
                                        <img src={defaultProfileImage} />
                                    </a>
                                </span>
                                <span className="img thumb-64 border-circle"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-list">
                <div className="col-label label-lg">{t('AccountDetails.Label.Name')}</div>
                <div className="col-detail">
                    {renderName()}
                </div>
            </div>
            <div className="col-list">
                <div className="col-label label-lg">{t('ProfileSetting.Label.PhoneNumber')}</div>
                <div className="col-detail">
                    {CommonHelper.renderPhone(extendedUserInformation?.userInformation?.phoneCallingCode!,
                        extendedUserInformation?.userInformation?.phone!,
                        extendedUserInformation?.userInformation?.phoneExt!,
                        t('Common.Modal.ContactModal.ExtLabel')) || '-'}
                </div>
            </div>
            <div className="col-list">
                <div className="col-label label-lg">{t('AccountDetails.Label.MobileNumber')}</div>
                <div className="col-detail">
                    {CommonHelper.renderPhone(extendedUserInformation?.userInformation?.mobileCallingCode!,
                        extendedUserInformation?.userInformation?.mobile!) || '-'}
                </div>
            </div>
            <div className="col-list">
                <div className="col-label label-lg">{t('ProfileSetting.Label.FaxNumber')}</div>
                <div className="col-detail">
                    {CommonHelper.renderPhone(extendedUserInformation?.userInformation?.faxCallingCode!,
                        extendedUserInformation?.userInformation?.fax!,
                        extendedUserInformation?.userInformation?.faxExt!,
                        t('Common.Modal.ContactModal.ExtLabel')) || '-'}
                </div>
            </div>
            <>{(action === SettingActionEnum.VIEW) ? view() : edit()}</>
        </div>
    </>
    )
}