import React, { createContext, Reducer, useContext, useReducer } from 'react';
import {
  AlertComponentProps,
  AlertProviderProps,
  Provider as AlertProvider,
  useAlert,
} from 'react-alert';
import {
  ISmartAlertContentProp,
  SmartAlertContent,
  SmartAlertTemplate,
  ThemeSmartAlert
} from './SmartAlertTemplate';

type SmartAlertKey = string;
const getSmartAlertKey = (
  text: ISmartAlertContentProp['text'],
  icon?: ISmartAlertContentProp['icon']
): SmartAlertKey => {
  return `${text}${icon ? `-${icon}` : ''}`;
};

interface SmartAlertState {
  alerts: { [smartAlertKey: string]: AlertComponentProps };
  alertAsBar: {
    node: React.ReactNode | undefined;
    show: boolean;
  };
  timeout: number | undefined;
}
interface SmartAlertAction {
  addAlert: (smartAlertKey: SmartAlertKey, alertComponent: AlertComponentProps) => void;
  removeAlert: (smartAlertKey: SmartAlertKey) => void;
  setAlertAsBar: (node: React.ReactNode | undefined, show: boolean) => void;
}
type Action =
  | { type: 'ADD_ALERT'; smartAlertKey: SmartAlertKey; alertComponent: AlertComponentProps }
  | { type: 'REMOVE_ALERT'; smartAlertKey: SmartAlertKey }
  | { type: 'SET_ALERT_AS_BAR'; node: React.ReactNode | undefined; show: boolean }
  | { type: 'SET_TIMEOUT'; timeout: number };
const smartAlertCReducer: Reducer<SmartAlertState, Action> = (state, action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      return {
        ...state,
        alerts: { ...state.alerts, [action.smartAlertKey]: action.alertComponent },
      };
    case 'REMOVE_ALERT':
      const alerts = { ...state.alerts };
      if (alerts[action.smartAlertKey]) {
        const alertComponent = alerts[action.smartAlertKey];
        alertComponent.close();
        // setTimeout(() => {
        //   alertComponent.close();
        // }, 500);
        delete alerts[action.smartAlertKey];
      }
      return { ...state, alerts };
    case 'SET_ALERT_AS_BAR':
      return {
        ...state,
        alertAsBar: {
          node: action.node,
          show: action.show,
        },
      };
    default:
      return state;
  }
};

export const SmartAlertContext = createContext<SmartAlertState & SmartAlertAction>({
  alerts: {},
  addAlert: () => { },
  removeAlert: () => { },
  setAlertAsBar: () => { },
  alertAsBar: {
    node: undefined,
    show: false,
  },
  timeout: 1000,
});
type SmartAlertProviderProps = Omit<AlertProviderProps, 'template'>;
export const SmartAlertProvider: React.FC<SmartAlertProviderProps> = ({
  children,
  ...alertOptions
}: React.PropsWithChildren<SmartAlertProviderProps>) => {
  const [smartAlertState, smartAlertDispatch] = useReducer(smartAlertCReducer, {
    alerts: {},
    alertAsBar: {
      node: undefined,
      show: false,
    },
    timeout: alertOptions.timeout,
  });

  return (
    <SmartAlertContext.Provider
      value={{
        ...smartAlertState,
        addAlert: (smartAlertKey, alertComponent) => {
          smartAlertDispatch({ type: 'ADD_ALERT', smartAlertKey, alertComponent });
        },
        removeAlert: (smartAlertKey) => {
          smartAlertDispatch({ type: 'REMOVE_ALERT', smartAlertKey });
        },
        setAlertAsBar: (node, show) => {
          smartAlertDispatch({ type: 'SET_ALERT_AS_BAR', node, show });
        },
      }}
    >
      <AlertProvider template={SmartAlertTemplate} {...alertOptions}>
        {children}
      </AlertProvider>
    </SmartAlertContext.Provider>
  );
};

export const useSmartAlert = () => {
  const alert = useAlert();
  const { addAlert, removeAlert, setAlertAsBar, timeout } = useContext(SmartAlertContext);

  const show = (
    text: ISmartAlertContentProp['text'],
    color?: ISmartAlertContentProp['color'],
    alertAsBar?: boolean,
    theme?: ThemeSmartAlert,
    cutomTimeOut?: number
  ) => {
    if (alertAsBar) {
      const node = <SmartAlertContent text={text} color={color} asBar={true} theme={theme} onClose={() => {
        setAlertAsBar(node, false)
      }} />;
      setAlertAsBar(node, true);
      setTimeout(() => {
        setAlertAsBar(node, false);
      }, cutomTimeOut ? cutomTimeOut : timeout);
    } else {
      const key = getSmartAlertKey(text);
      const alertComponent = alert.show(<SmartAlertContent text={text} color={color} theme={theme} />);
      removeAlert(key);
      addAlert(key, alertComponent);
    }
  };

  const showCustom = (
    customContent: JSX.Element | undefined,
    useTimeOut?: boolean,
    cutomTimeOut?: number
  ) => {
    let node = customContent;
    // set close icon
    if (node?.props?.onClose! && customContent) {
      node = React.cloneElement(
        customContent,
        {
          ...customContent?.props, onClose: (e?: any) => {
            setAlertAsBar(node, false);
            customContent.props.onClose(e);
          }
        }
      );
    }

    setAlertAsBar(node, true);
    if (useTimeOut) {
      setTimeout(() => {
        setAlertAsBar(node, false);
      }, cutomTimeOut ? cutomTimeOut : timeout);
    }
  };

  return {
    show: show,
    showCustom: showCustom
  };
};
