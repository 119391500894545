import React from 'react';
import classNames from 'classnames';
import { IconType } from '../icon/SCIcon';

export interface ISCButtonProps extends React.DOMAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  type: 'primary' | 'secondary' | 'sm' | 'link' | 'inline' | 'outline' | 'reject';
  color?: 'primary';
  className?: string;
  icon?: IconType;
  id?: string;
  disabled?: boolean;
  active?: boolean;
}

export function SCButton({
  isLoading,
  type,
  children,
  className,
  icon,
  id,
  color,
  active,
  onClick,
  ...props
}: React.PropsWithChildren<ISCButtonProps>): React.FunctionComponentElement<ISCButtonProps> {
  const buttonProps = {
    ...props,
    id,
    className: classNames(
      `btn btn-${type}`,
      `${color ? 'color-' + color : ''}`,
      {
        loading: isLoading,
      },
      {
        active: active
      },
      className
    ),
    onClick: isLoading ? undefined : onClick
  };
  return (
    <button {...buttonProps} type="button">
      {isLoading && <i className="icon-loading spinner"></i>}
      {icon && <i className={`icon-${icon} ${color ? 'color-' + color : ''}`} />}
      {children}
    </button>
  );
}
