import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { AuthenticationService, TokenHelper } from '@ptvn-react/authentication';
import APIConstant from "../utils/constants/APIConstant";
import { setContext } from '@apollo/client/link/context';
import ApiConstant from '../utils/constants/APIConstant';

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});

const authenticationLink = setContext(async (_, { headers }) => {
    let token = TokenHelper.getToken()
    if (token) {
        let accessToken = token.accessToken;
        try {
            const publicKey = await TokenHelper.getAccessTokenPublicKey(
                APIConstant.authenticationCenterService.accountRealmInformationURL
            );

            if (publicKey) {
                if (!TokenHelper.isNotExpired(accessToken)) {
                    await AuthenticationService.renewToken(
                        token,
                        APIConstant.authenticationCenterService.accountRenewAccessTokenURL
                    );
                    accessToken = TokenHelper.getToken()!.accessToken;
                }
            }

        } catch (error) {
            console.error(error);
            // window.location.href = defaultPage;
        }

        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${TokenHelper.getToken()?.accessToken}`,
            }
        }
    }

});

const http = createHttpLink({
    uri: `${ApiConstant.graphQLGateway}`
});

export const GatewayGraphQLClient = new ApolloClient({
    link: ApolloLink.from([
        cleanTypeName,
        authenticationLink,
        http,
    ]),
    cache: new InMemoryCache(),
});
