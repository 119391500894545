import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { GatewayGraphQLClient } from '@api/GatewayGraphQLClient';
import { ApolloProvider } from '@apollo/client';
import { PTVNUserProvider } from '@ptvn-react/authentication';
import { Store } from '@reduxjs/toolkit';
import environment from 'environment';
import React from 'react';
import { positions, transitions } from 'react-alert';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';
import { Compose, withProvider } from './app/components/compose/index';
import { ModalStackProvider } from './app/components/modal/ModalStackProvider';
import { SmartAlertProvider } from './app/components/smart-alert/SmartAlert';
import { configuredStore, history, RootState } from './app/store/rootStore';
import APIConstant from './app/utils/constants/APIConstant';
import PageRoute from './app/utils/constants/PageRoute';
import './i18n/i18n';
import { ConnectedRouter } from 'connected-react-router';
import ThemeSelector from '@components/theme/ThemeSelector';

const initialState: Partial<RootState> = {};
const store: Store<RootState> = configuredStore(initialState);
const persistor = persistStore(store);

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 8000,
  transition: transitions.FADE,
  offset: '0 auto 24px',
  containerStyle: {
    zIndex: 10000000000,
  } as React.CSSProperties,
};

const PTVNUserConfig = {
  redirectURL: APIConstant.redirectURL,
  clientID: environment.clientID,
  unauthorizedDefaultPage: PageRoute.NEW_LANDING,
  accountLoginPageURL: APIConstant.authenticationCenterService.accountLoginPageURL,
  accountGetAccessTokenURL: APIConstant.authenticationCenterService.accountGetAccessTokenURL,
  accountRealmInformationURL: APIConstant.authenticationCenterService.accountRealmInformationURL,
  accountRenewAccessTokenURL: APIConstant.authenticationCenterService.accountRenewAccessTokenURL,
};

ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={<>loading...</>} persistor={persistor}>
      <Compose
        components={[
          withProvider(PTVNUserProvider, {
            config: PTVNUserConfig,
          }),
          withProvider(Provider, { store: store }),
          withProvider(ConnectedRouter, { history }),
          withProvider(SmartAlertProvider, alertOptions),
          withProvider(ApolloProvider, { client: GatewayGraphQLClient }),
          withProvider(ModalStackProvider),
        ]}
      >
        <ThemeSelector>
          <App history={history} />
        </ThemeSelector>
      </Compose>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
