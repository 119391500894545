import { CompanyClient } from '@api/CompanyClient';
import { ExtendedUserInformation } from '@models/User';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import PageRoute from '@utils/constants/PageRoute';

export interface SupplierConnectContextType {
    isLoading: boolean
    isTrueUser: boolean | undefined
    checkShowAutoAcceptPOMenu: () => void
}

export interface SidebarMenuItem {
    title: string;
    icon?: string;
    to?: string;
    activePath?: string;
    children?: SidebarMenuItem[];
}

const SupplierConnectContext = React.createContext<SupplierConnectContextType | undefined>(
    undefined
)

export const SupplierConnectLayoutProvider = ({ children }: React.PropsWithChildren<{}>) => {

    const [subscription, setSubscription] = useState<Subscription>();
    const { currentCompany } = usePTVNAuthentication<ExtendedUserInformation>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isTrueUser, setIsTrueUser] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        return () => {
            subscription && subscription.unsubscribe();
        };
    }, [subscription]);

    useEffect(() => {
        if (currentCompany?.branchId!) {
            checkShowAutoAcceptPOMenu();
            setIsTrueUser(false)
        }
    }, [currentCompany])


    const checkShowAutoAcceptPOMenu = () => {
    }

    return (
        <SupplierConnectContext.Provider
            value={{
                isLoading,
                isTrueUser,
                checkShowAutoAcceptPOMenu
            }}
        >
            {children}
        </SupplierConnectContext.Provider >
    )
}

export const useSupplierConnectContext = () => {
    return useContext(SupplierConnectContext) as SupplierConnectContextType
}