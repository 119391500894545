import { useEffect, useState } from 'react';
import environment from 'environment';
import ReactGA from 'react-ga4';
import { EventArgs } from 'react-ga';
import { useLocation } from 'react-router';
import { of, Observable } from 'rxjs';

export interface IGoogleAnalytic {
  path?: string | 'currentUrl';
  suffixPath?: string;
  suffixPathAction?: string;
  pageTrackerNames?: string[];
  pageTitle?: string;
  event?: EventArgs;
  eventTrackerNames?: string[];
}

const useGoogleAnalytics = (firstNotSendToGA?: boolean) => {
  const location = useLocation();
  const [requestGA, setRequestGA] = useState<IGoogleAnalytic>();

  useEffect(() => {
    if (environment.googleAnalyticKey && requestGA) {
      ReactGA.initialize(environment.googleAnalyticKey);
      if (requestGA?.path) {
        let path = requestGA?.path;
        if (path = 'currentUrl') {
          path = location.pathname;
        }
        if (requestGA?.suffixPath) {
          path += ` (${requestGA?.suffixPath})`
        }
        if (requestGA?.suffixPathAction) {
          path += `_${requestGA?.suffixPathAction}`
        }
        ReactGA.send({hitType:"pageview", page:path, title:path});
      } else {
        let path = location.pathname;
        if (requestGA?.suffixPath) {
          path += ` (${requestGA?.suffixPath})`
        }
        if (requestGA?.suffixPathAction) {
          path += `_${requestGA?.suffixPathAction}`
        }
        ReactGA.send({hitType:"pageview", page:path, title:path});
      }

      if (requestGA?.event) {

        let event = requestGA?.event;
        if (!event?.category) {
          event.category = location.pathname;
          if (requestGA?.suffixPath) {
            event.category += ` (${requestGA?.suffixPath})`
          }
        }
        ReactGA.event(event, requestGA.eventTrackerNames);
      }
    }
  }, [requestGA]);

  const requestGAObservable = (requestPostGA: IGoogleAnalytic): Observable<any> => {
    if (environment.googleAnalyticKey && requestPostGA) {
      ReactGA.initialize(environment.googleAnalyticKey);
      if (requestPostGA?.path) {
        let path = requestPostGA?.path;
        if (path = 'currentUrl') {
          path = location.pathname;
        }
        if (requestPostGA?.suffixPath) {
          path += ` (${requestGA?.suffixPath})`
        }
        if (requestGA?.suffixPathAction) {
          path += `_${requestGA?.suffixPathAction}`
        }
        ReactGA.send({hitType:"pageview", page:path, title:path});
      } else {
        let path = location.pathname;
        if (requestPostGA?.suffixPath) {
          path += ` (${requestPostGA?.suffixPath})`
        }
        if (requestGA?.suffixPathAction) {
          path += `_${requestGA?.suffixPathAction}`
        }
        ReactGA.send({hitType:"pageview", page:path, title:path});
      }

      if (requestPostGA?.event) {

        let event = requestPostGA?.event;
        if (!event?.category) {
          event.category = location.pathname;
          if (requestPostGA?.suffixPath) {
            event.category += ` (${requestPostGA?.suffixPath})`
          }
        }
        ReactGA.event(event, requestPostGA.eventTrackerNames);
      }
    }
    return of(true);
  }

  useEffect(() => {
    if (environment.googleAnalyticKey && !firstNotSendToGA) {
      ReactGA.initialize(environment.googleAnalyticKey);
      ReactGA.send({hitType:"pageview", page:location.pathname, title:location.pathname});
    }
  }, []);


  return {
    setRequestGA,
    requestGAObservable
  };
};

export default useGoogleAnalytics;
