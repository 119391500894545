import { DealsAndDiscountProvider } from './DealsAndDiscountDetailContext';
import DealsAndDiscountDetailPageLayout from './DealsAndDiscountDetailPageLayout';

interface IDealsAndDiscountPageProps {
  elementID: string;
}

export default function DealsAndDiscountDetailPage(
  props: React.PropsWithChildren<IDealsAndDiscountPageProps>
): React.FunctionComponentElement<IDealsAndDiscountPageProps> {

  return (
    <DealsAndDiscountProvider>
      <DealsAndDiscountDetailPageLayout />
    </DealsAndDiscountProvider>
  );
}
