import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import PageRoute from '@utils/constants/PageRoute';
import { useSupplierConnectContext } from '@components/provider/SupplierConnectLayoutProvider';
import init from '@assets/js/common';
import useGoogleAnalytics, { IGoogleAnalytic } from "@google/useGoogleAnalytics";
import { GoogleAnalyticsConstantEvent, GoogleAnalyticsConstantSuffixPage } from "@utils/constants/GoogleAnalyticsConstant";
import { EventArgs } from "react-ga";

export interface SidebarMenuItem {
  title: string;
  icon?: string;
  to?: string;
  activePath?: string;
  children?: SidebarMenuItem[];
  gaEvent?: IGoogleAnalytic;
}

export default function SideMenu({
  children,
}: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
  const { t } = useTranslation();
  const history = useHistory();
  const { isTrueUser } = useSupplierConnectContext();
  const [menuConfig, setMenuConfig] = useState<SidebarMenuItem[]>([]);
  const { setRequestGA } = useGoogleAnalytics();
  const defaultMenu: SidebarMenuItem[] = [
    {
      title: 'MainMenu.Company.Title',
      icon: 'icon-company',
      activePath: '/company',
      children: [
        {
          title: 'MainMenu.Company.SubMenu.Company',
          to: '/company-management',
          activePath: '/company-management',
        },
      ],
      gaEvent :  {
        suffixPathAction: GoogleAnalyticsConstantEvent.COMPANY_MANAGEMENT,
        event: { action: GoogleAnalyticsConstantEvent.COMPANY_MANAGEMENT } as EventArgs }
    }, {
      title: 'MainMenu.Store.Title',
      icon: 'icon-store',
      activePath: '/product',
      children: [
        {
          title: 'MainMenu.Store.SubMenu.ProductAndService',
          to: '/product/list',
          activePath: '/product',
        },
      ],
      gaEvent: {
        suffixPathAction: GoogleAnalyticsConstantEvent.PRODUCT_AND_SERVICE,
        event: { action: GoogleAnalyticsConstantEvent.PRODUCT_AND_SERVICE } as EventArgs
      }
    }, {
      title: 'MainMenu.Buyer.Title',
      icon: 'icon-manage-buyer',
      activePath: '/buyer',
      children: [
        {
          title: 'MainMenu.Buyer.SubMenu.BuyerInvitationCode',
          to: PageRoute.BUYER_LIST,
          activePath: PageRoute.BUYER_LIST,
        }
      ],
      gaEvent: {
        suffixPathAction: GoogleAnalyticsConstantEvent.BUYER_INVITATION_CODE,
        event: { action: GoogleAnalyticsConstantEvent.BUYER_INVITATION_CODE } as EventArgs
      }
    }, {
      title: 'MainMenu.SellingTools.Title',
      icon: 'icon-selling_tools',
      activePath: '/selling-tools',
      children: [
        {
          title: 'MainMenu.SellingTools.SubMenu.DealsAndDiscount',
          to: '/selling-tools/deals-and-discount/list',
          activePath: '/selling-tools/deals-and-discount',
        },
      ],
      gaEvent: {
        suffixPathAction: GoogleAnalyticsConstantEvent.DEAL_AND_DISCOUNT,
        event: { action: GoogleAnalyticsConstantEvent.DEAL_AND_DISCOUNT } as EventArgs
      }
    }
  ];

  useEffect(() => {
    init && init.navigation();

    window.addEventListener('resize', () => {
      init && init.navigation();
    });
  }, [menuConfig])

  useEffect(() => {
    if (isTrueUser) {
      let newMenu = [...defaultMenu];
      const index = newMenu.findIndex(f => f.activePath === '/buyer');
      newMenu[index].children?.push({
        title: 'MainMenu.Buyer.SubMenu.AutoAcceptPO',
        to: PageRoute.AUTO_ACCEPT_PO,
        activePath: PageRoute.AUTO_ACCEPT_PO,
      });
      setMenuConfig(newMenu);
    } else if (isTrueUser != undefined && isTrueUser === false) {
      setMenuConfig(defaultMenu);
    }
  }, [isTrueUser])

  const redirect = (url?: string) => {
    if (url) {
      history.push(url);
    }
  }

  return (
    <>
      {
        menuConfig?.length > 0 &&
        <nav className="nav-menu">
          <ul className="mainmenu">
            {menuConfig.map((menu, menuIndex) => {
              const hasSubMenu = menu.children && menu.children.length > 0;
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const isMenuMatch = useRouteMatch({
                path: menu.activePath,
                strict: true,
                sensitive: true,
              });
              const subMenuMatches: boolean[] = hasSubMenu
                ? menu.children?.map(
                  (sm) =>
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    !!useRouteMatch({
                      path: sm.activePath,
                      strict: true,
                      sensitive: true,
                    })
                ) || []
                : [];

              return (
                <li
                  key={menuIndex}
                  className={classNames({
                    active: !!isMenuMatch || !!subMenuMatches.find((sm) => sm),
                    'has-submenu': hasSubMenu,
                  })}
                >
                  {menu.to ? (
                    <a href="" onClick={() => redirect(menu.to)}>
                      {menu.icon && (
                        <i className={menu.icon}>
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      )}
                      <span>{t(menu.title)}</span>
                      {hasSubMenu && <i className="icon-small-angle-right"></i>}
                    </a>
                  ) : (
                    <a href="">
                      {menu.icon && (
                        <i className={menu.icon}>
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </i>
                      )}
                      <span>{t(menu.title)}</span>
                      {hasSubMenu && <i className="icon-small-angle-right"></i>}
                    </a>
                  )}

                  {hasSubMenu && (
                    <ul style={{ display: 'block' }} className="submenu">
                      {menu.children?.map((subMenu, subMenuIndex) => (
                        <li
                          key={`${menuIndex}-${subMenuIndex}`}
                          className={classNames({ active: subMenuMatches[subMenuIndex] })}
                        >
                          {subMenu.to ? (
                            <a href="" onClick={() => {
                              if (menu?.gaEvent) {
                                setRequestGA({
                                  suffixPathAction: menu.gaEvent.suffixPathAction,
                                  event: menu.gaEvent.event
                                });
                              }
                              redirect(subMenu.to)
                            }}>
                              <span>{t(subMenu.title)}</span>
                            </a>
                          ) : (
                            <a href="">
                              <span>{t(subMenu.title)}</span>
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      }
    </>
  );
}
