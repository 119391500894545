import React, { useContext } from 'react';
import { SmartAlertContext } from '@components/smart-alert/SmartAlert';
import { Collapse } from 'react-bootstrap';
import Header from './Header';
import FooterPTVN from './FooterPTVN';
import SettingSideMenu from './SettingSideMenu';

export default function SettingLayout({
    children
}: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
    const alert = useContext(SmartAlertContext);
    return (
        <>
            <Header notShowIconSideMenu={true} notShowCurrentCompany={true} showSCCompanyManageMent={false} />
            <div className="content-wrap">
                <SettingSideMenu />
                <div className="container-fluid has-dropdown">
                    <div className="content main-content" style={{ height: '100%' }}>
                        <Collapse in={alert.alertAsBar.show} timeout={3000}>
                            <div>{alert.alertAsBar.node}</div>
                        </Collapse>
                        <div className="card-content w-full">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <FooterPTVN />
        </>
    )
}