import { useTranslation } from "react-i18next";
import { SCButton } from "@components/button/SCButton";
import { useDealsAndDiscountContext } from "../DealsAndDiscountContext";
import NoItemIcon from '@assets/images/frontend/save-draft.png';
import { useEffect, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { Controller, FieldErrors, useFormContext } from "react-hook-form";
import { DealsAndDiscount, PromotionProduct } from "@models/DealsAndDiscount";
import { DiscountTypeOptionEnum } from '@utils/enum/DealsAndDiscountEnum';
import { CompanyItem, usePTVNAuthentication } from "@ptvn-react/authentication";
import { CountryCode } from "@utils/constants/CountryConstant";
import { CommonHelper } from "@utils/helpers/CommonHelper";
import classNames from "classnames";
import { isEmpty, omit } from "lodash";
import { PromotionErrors } from "../DealsAndDiscountSchema";
import { ConfirmButtonCenterModal } from "@components/modal/ConfirmButtonCenterModal";
import { useModalStack } from "@components/modal/ModalStackProvider";
import DeleteIcon from "@assets/images/frontend/remove.png"
import emptyFile from '@assets/images/thumbnail/empty-thumbnail-64.png';
import { LangType, ProductListSortByType, SortDirType } from "@graphql/autogenerate/schemas";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

interface SortInput {
  sortBy: ProductListSortByType;
  sortDir: SortDirType;
  sortNameBy: LangType;
}

interface IPromotionProductItemProps {
  elementID: string;
  refBox: any;
}

export default function PromotionProductItem(
  props: React.PropsWithChildren<IPromotionProductItemProps>
): React.FunctionComponentElement<IPromotionProductItemProps> {
  const { elementID, refBox } = props;
  const thisElementID = elementID + '-products';
  const { t } = useTranslation();
  const { setSelectProductsPage, productItemsInPromotion, setProductItemsInPromotion } = useDealsAndDiscountContext();
  const [showAlertProduct, setShowAlertProduct] = useState<boolean>(true);
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const { push } = useModalStack();
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);

  const defaultFilter = (): SortInput => {
    return {
      sortBy: ProductListSortByType.ProductName,
      sortDir: SortDirType.Asc,
      sortNameBy: LangType.En
    } as SortInput;
  };
  const [sortProducts, setSortProducts] = useState<SortInput>(defaultFilter);

  const form = useFormContext<DealsAndDiscount>();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
  } = form;

  useEffect(() => {
    setLoadingProducts(false);
  }, [watch('promotionProduct')])

  useEffect(() => {
    let temp = [...getValues('promotionProduct')];
    temp = temp.map(i => {
      return {
        ...i,
        discountValue: '',
        canNotBeApplied: (!i.unitPrice && getValues('promotionDetail.discountType') === DiscountTypeOptionEnum.value)
      }
    });
    setProductItemsInPromotion(temp);
    setValue('discountAll', '');
  }, [watch('promotionDetail.discountType')])

  useEffect(() => {
    if (productItemsInPromotion) {
      reset({
        ...getValues(),
        promotionProduct: [...productItemsInPromotion]
      });

      if (!productItemsInPromotion.length) {
        setValue('discountAll', '');
      }
    }

    if (productItemsInPromotion.length > 0) {
      clearErrors('promotionProduct');
    }
  }, [productItemsInPromotion])

  const handleSortProductChange = (by: ProductListSortByType, dir: SortDirType, sortNameBy: LangType) => {
    if (productItemsInPromotion.length > 0) {
      setLoadingProducts(true);
      let sorting = [...productItemsInPromotion];
      if (by === ProductListSortByType.ProductName) {
        sorting = sorting.sort((left, right) => {
          if (sortNameBy === LangType.En) {
            if (left.name && right.name && dir === SortDirType.Asc) {
              return left.name.localeCompare(right.name, 'en');
            } else if (left.name && right.name && dir === SortDirType.Desc) {
              return right.name.localeCompare(left.name, 'en');
            }
          } else if (sortNameBy === LangType.Local) {
            if (left.name && right.name && dir === SortDirType.Asc) {
              return left.name.localeCompare(right.name, 'th');
            } else if (left.name && right.name && dir === SortDirType.Desc) {
              return right.name.localeCompare(left.name, 'th');
            }
          }
          return 0;
        });
      }
      reset({
        ...getValues(),
        promotionProduct: sorting
      });
      setSortProducts({
        sortBy: by,
        sortDir: dir,
        sortNameBy: sortNameBy
      });
    }
  }

  const handleDeleteProduct = (index: number) => {
    if (index > -1) {
      push(ConfirmButtonCenterModal,
        {
          elementID: elementID,
          icon: DeleteIcon,
          titleBody: t('DealsAndDiscount.Modal.RemoveProduct.Title'),
          description1: t('DealsAndDiscount.Modal.RemoveProduct.Description'),
          btnConfirmText: t('Common.Button.YesRemove'),
          btnCancelText: t('Common.Button.Cancel'),
          onSubmit: (() => {
            let temp = [...getValues('promotionProduct')];
            temp.splice(index, 1);
            setProductItemsInPromotion(temp);
          })
        });
    }
  }

  const calculateDisplayInput = (valInput?: number, unitPrice?: number) => {
    if (watch('promotionDetail.discountType') === DiscountTypeOptionEnum.percent) {
      if (valInput) {
        if (valInput < 1) {
          return 1.00;
        } else if (valInput > 100) {
          return 100.00;
        } else {
          return valInput;
        }
      } else {
        return '';
      }
    } else {
      if (valInput && unitPrice && valInput! > unitPrice) {
        return unitPrice;
      } else if (valInput) {
        return valInput;
      } else {
        return '';
      }
    }
  }

  const handleEnterInputAllDiscount = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let value = e?.target?.value.replaceAll(',', '');
      applyAllDiscount(Number(value));
    }
  };

  const applyAllDiscount = (discount?: number) => {
    if (discount && discount > 0) {
      if (watch('promotionDetail.discountType') === DiscountTypeOptionEnum.percent) {
        setProductItemsInPromotion(productItemsInPromotion.map(i => {
          if (!i.promotionOverlap && !i.canNotBeApplied) {
            return {
              ...i,
              discountValue: discount.toString()
            }
          } else {
            return i;
          }
        }))
      } else {
        setProductItemsInPromotion(productItemsInPromotion.map(i => {
          if (!i.promotionOverlap && !i.canNotBeApplied) {
            return {
              ...i,
              discountValue: calculateDisplayInput(Number(discount), i.unitPrice!)?.toString()!
            }
          } else {
            return i;
          }
        }))
      }
      setValue('discountAll', '');
    }
  }

  const renderCurrency = () => {
    switch (currentCompany?.countryCode) {
      case CountryCode.TH:
        return 'THB';
      case CountryCode.VN:
        return 'VND'
      default:
        return 'USD';
    }
  }

  const renderUnitValue = () => {

    return (<>{
      watch('promotionDetail.discountType') === DiscountTypeOptionEnum.percent ?
        <>%</> : <>{
          renderCurrency()
        }</>
    }</>)
  }

  const renderDiscount = (index: number, unitPrice?: number, disabled?: boolean) => {
    return (<>
      <div className="input-group icon">
        <Controller
          control={control}
          name={`promotionProduct.${index}.discountValue`}
          render={({ field }) => {
            return (
              <NumberFormat
                disabled={disabled}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="input"
                placeholder={'0.00'}
                allowLeadingZeros={false}
                allowNegative={false}
                className={classNames('form-control text-right', {
                  'is-invalid': isEmpty(errors.promotionProduct) ? false : errors.promotionProduct![index]?.discountValue
                })}
                {...omit(field, ['onChange', 'value'])}
                onValueChange={(val: NumberFormatValues) => {
                  let temp = [...productItemsInPromotion];
                  field.onChange(calculateDisplayInput(val.floatValue, unitPrice)!);
                  temp[index] = {
                    productID: temp[index].productID,
                    discountValue: calculateDisplayInput(val.floatValue, unitPrice)?.toString()!,
                    sku: temp[index].sku,
                    name: temp[index].name,
                    categoryName: temp[index].categoryName,
                    productImageUrl: temp[index].productImageUrl,
                    unitPrice: temp[index].unitPrice,
                    canNotBeApplied: temp[index].canNotBeApplied
                  }
                  setProductItemsInPromotion(temp);
                }}
                id={`txt-${thisElementID}-discountValue${index}`}
                value={field.value}
                autoComplete="off"
              />
            );
          }}
        />
        <div className="input-group-append btn">
          <div className="input-group-text nostyle">{renderUnitValue()}</div>
        </div>
      </div>
      {CommonHelper.renderFieldError<DealsAndDiscount>(
        t,
        errors,
        `promotionProduct.${index}.discountValue`,
        `txt-${thisElementID}-discountValue-error`
      )}
    </>)
  }

  const renderTotalDiscount = (discount?: number, unitPrice?: number) => {
    let discountPrice = 0.00;
    if (discount && unitPrice) {
      if (watch('promotionDetail.discountType') === DiscountTypeOptionEnum.percent) {
        discountPrice = (unitPrice - ((discount * unitPrice) / 100));
      } else {
        discountPrice = (unitPrice - discount);
      }
    } else if (unitPrice) {
      discountPrice = unitPrice;
    }
    return (<>
      {
        <NumberFormat
          displayType="text"
          value={(discountPrice || discountPrice > -1) ? discountPrice : '-'}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={true}
        />
      }
    </>)
  }

  const checkDisableProductItem = (product: PromotionProduct): boolean => {
    return !!(product.promotionOverlap || product.canNotBeApplied);
  }

  return (<>
    <div ref={refBox} id={`div-${thisElementID}-header`} className="card">
      <div className="card-header">{t('DealsAndDiscount.Label.Products')}
        {(errors.promotionProduct as FieldErrors)?.type === 'min' ||
          (errors.promotionProduct as FieldErrors)?.type === "required" &&
          <small className="color-danger ml-auto">{t(PromotionErrors.PRODUCT_IS_REQUIRED)}</small>
        }
      </div>
      <div className="card-body">

        {showAlertProduct &&
          <div className="alert alert-primary alert-dismissible">{t('DealsAndDiscount.Label.ProductsPromotionDiscountAlert')}
            <button type="button" data-dismiss="alert" className="close" onClick={() => setShowAlertProduct(false)}>
              <i className="icon-cancel"></i>
            </button>
          </div>
        }
        {!loadingProducts && productItemsInPromotion.length > 0 && watch('promotionProduct').length > 0 ? (<>
          <div className="d-flex align-items-end mb-2">
            <div className="title mb-0">
              {t('DealsAndDiscount.Label.SelectedProducts')}
              <small className="ml-2">
                {t('DealsAndDiscount.Label.NumberProducts', {
                  number: productItemsInPromotion.length
                })}
              </small>
            </div>
            <div className="ml-auto">
              <SCButton
                type="outline"
                className="mr-2"
                onClick={() => setSelectProductsPage(true)}
              >
                {t('DealsAndDiscount.Button.SelectProduct')}
              </SCButton>
            </div>
          </div>
          <div className="box-background no-border mb-2">
            <div className="d-flex">
              <div className="bl-150"><span>{t('DealsAndDiscount.Label.BatchSetting')}</span></div>
              <div className="bl-auto ml-3">
                <div className="form-group">
                  <label>{t('DealsAndDiscount.Label.Discount')}</label>
                  <div className="d-flex align-items-center">
                    <div className="input-group icon">
                      <Controller
                        control={control}
                        name={`discountAll`}
                        render={({ field }) => {
                          return (
                            <NumberFormat
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType="input"
                              placeholder={'0.00'}
                              allowLeadingZeros={false}
                              allowNegative={false}
                              className={classNames('form-control text-right', {
                                'is-invalid': errors.discountAll
                              })}
                              {...omit(field, ['onChange', 'value'])}
                              onValueChange={(val: NumberFormatValues) => {
                                field.onChange(calculateDisplayInput(val.floatValue)!);
                              }}
                              id={`txt-${thisElementID}-AllDiscountValue`}
                              value={field.value}
                              onKeyDown={handleEnterInputAllDiscount}
                              autoComplete="off"
                            />
                          );
                        }}
                      />
                      <div className="input-group-append btn">
                        <div className="input-group-text nostyle">
                          {renderUnitValue()}
                        </div>
                      </div>
                    </div>
                    <div className="nowrap ml-8px">
                      <SCButton
                        type="outline"
                        className="noborder"
                        id={`btn-${elementID}-saveDraft`}
                        onClick={() => applyAllDiscount(Number(getValues('discountAll')))}
                        disabled={!(getValues('discountAll') && Number(getValues('discountAll'))! > 0)}
                      >
                        {t('DealsAndDiscount.Button.UpdateAll')}
                      </SCButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-no-berder">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex align-items-center nowrap">
                      {t('ProductList.Label.Product')}
                      <Dropdown>
                        <Dropdown.Toggle
                          id={`btn-${elementID}-sort`}
                          as="a"
                          variant=""
                          bsPrefix="btn-icon"
                        >
                          {sortProducts?.sortBy === ProductListSortByType.ProductName ?
                            (
                              <i className={classNames('icon-sort', 'ml-8px', {
                                'desc': sortProducts?.sortDir === SortDirType.Desc,
                                'asc': sortProducts?.sortDir === SortDirType.Asc,
                              })}>
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            ) :
                            (
                              <i className="icon-sort ml-8px">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            )
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: '110px', minWidth: '110px' }}>
                          <div className="dropdown-list">
                            <Dropdown.Item
                              id={`btn-${elementID}-sort-productName-en-asc`}
                              onSelect={() => handleSortProductChange(ProductListSortByType.ProductName, SortDirType.Asc, LangType.En)}
                            >
                              {t('Product Name: A - Z')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={`btn-${elementID}-sort-productName-en-desc`}
                              onSelect={() => handleSortProductChange(ProductListSortByType.ProductName, SortDirType.Desc, LangType.En)}
                            >
                              {t('Product Name: Z - A')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={`btn-${elementID}-sort-productName-local-asc`}
                              onSelect={() => handleSortProductChange(ProductListSortByType.ProductName, SortDirType.Asc, LangType.Local)}
                            >
                              {t('Product Name: ก - ฮ')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={`btn-${elementID}-sort-productName-local-desc`}
                              onSelect={() =>
                                handleSortProductChange(ProductListSortByType.ProductName, SortDirType.Desc, LangType.Local)
                              }
                            >
                              {t('Product Name: ฮ - ก')}
                            </Dropdown.Item>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </th>
                  <th style={{ width: '178px' }} className="text-right">
                    {t('DealsAndDiscount.Label.OriginalPriceCurrency', {
                      currency: renderCurrency()
                    })}
                  </th>
                  <th style={{ width: '168px' }} >{t('DealsAndDiscount.Label.Discount')}</th>
                  <th style={{ width: '190px' }} className="text-right">
                    {t('DealsAndDiscount.Label.DiscountPriceCurrency', {
                      currency: renderCurrency()
                    })}
                  </th>
                  <th style={{ width: '60px' }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {getValues('promotionProduct').map((product, index) => {
                  return (
                    <tr key={index} className={checkDisableProductItem(product) ? 'disabled' : ''}>
                      <td className="align-middle">
                        <div className="box-product">
                          <div className="product-img"> <img src={product.productImageUrl}
                            onError={(ev: any) => { ev.target.src = emptyFile }} width="64" /></div>
                          <div className="product-detail">
                            <div className="product-name"> <strong>{product.name}</strong></div>
                            <div className="mt-8px">
                              <small className={checkDisableProductItem(product) ? 'disabled' : ''}>{
                                t('DealsAndDiscount.Label.ParentSKU', {
                                  SKU: product.sku
                                })
                              }</small>
                            </div>
                            {checkDisableProductItem(product) &&
                              <div className="mt-8px">
                                <small className="color-danger">
                                  {product.canNotBeApplied ?
                                    t('DealsAndDiscount.Label.NonPriceProductCanNotBeApplied')
                                    : product.promotionOverlap &&
                                    t('DealsAndDiscount.Label.OverlappingPromotion', {
                                      promotionName: product?.promotionOverlap!
                                    })
                                  }
                                </small>
                              </div>
                            }
                          </div>
                        </div>
                      </td>
                      <td className="text-right align-middle">
                        {product?.unitPrice ?
                          <NumberFormat
                            displayType="text"
                            value={Number(product?.unitPrice)! || '-'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                          /> :
                          <p className="disabled">
                            {t('DealsAndDiscount.Label.NonPriceProduct')}
                          </p>
                        }
                      </td>
                      <td className="align-middle">
                        {renderDiscount(index, product.unitPrice!, checkDisableProductItem(product)!)}
                      </td>
                      <td className="text-right align-middle">
                        {
                          product?.unitPrice ?
                            renderTotalDiscount(Number(getValues(`promotionProduct.${index}.discountValue`)), product.unitPrice!) :
                            <p className="disabled">
                              {t('DealsAndDiscount.Label.YourDiscountDisplayedOnMarketplace')}
                            </p>
                        }
                      </td>
                      <td className="col-action action-icon align-middle">
                        <OverlayTrigger
                          placement="top"
                          flip={true}
                          overlay={
                            <Tooltip
                              className="m-0"
                              id={`lbl-${elementID}-remove-tooltip`}
                              style={{ zIndex: 999 }}
                            >
                              {t('DealsAndDiscount.Tootip.Remove')}
                            </Tooltip>
                          }
                        >
                          <button onClick={() => handleDeleteProduct(index)} type="button" data-toggle="tooltip" data-placement="top" title="" data-original-title="Remove" className="btn btn-icon">
                            <i className="icon-delete">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>)
                }
                )}
              </tbody>
            </table>
          </div>
        </>) : (<>
          <div className="result-found">
            {loadingProducts ?
              (
                <div className="loading" style={{ position: 'absolute' }}>
                  <i className="icon-loading spinner"></i>
                </div>
              ) :
              (<>
                < img src={NoItemIcon} className="img-result" />
                <div className="title">{t('DealsAndDiscount.Label.NoSelectedProducts')}</div>
                <p className="mb-3">{t('DealsAndDiscount.Label.NoSelectedProductsDescription')}</p>
                <SCButton
                  type="outline"
                  onClick={() => setSelectProductsPage(true)}
                >
                  {t('DealsAndDiscount.Button.SelectProduct')}
                </SCButton>
              </>)
            }
          </div>
        </>)
        }
      </div>
    </div>
  </>);
}
