import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm, UseFormProps } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
export type ModalStackPropsBase<SubmitValue, CloseValue> = {
    show?: boolean;
    onSubmit?: (val: SubmitValue) => void;
    onClose?: (val: CloseValue) => void;
    children?: React.ReactNode;
}

interface ModalStackBase<
    SubmitValue,
    CloseValue,
    ModalStackProps extends ModalStackPropsBase<SubmitValue, CloseValue>
    > extends React.FC<ModalStackProps> { }

interface ModalStackItem {
    key: string;
    state: { [key: string]: any };
    element: JSX.Element;
    onClose?: (dismissAll: () => void) => void;
}

const ModalStackContextDataDefault = (
    setModalStack?: React.Dispatch<React.SetStateAction<ModalStackItem[]>>,
    modalStack: ModalStackItem[] = []
) => {
    const dismissAll = () => {
        setModalStack && setModalStack([]);
    };

    const popModal = () => {
        setModalStack &&
            setModalStack((currModalStack) => {
                if (currModalStack.length > 0) {
                    const currModal = currModalStack[currModalStack.length - 1];
                    currModal && currModal.onClose && currModal.onClose(dismissAll);
                }
                return currModalStack.slice(0, currModalStack.length - 1);
            });
    };

    function renderModal<P extends ModalStackPropsBase<any, any>>(
        ModalComponent: React.FC<P>,
        modalProps: React.Attributes & P | null,
    ) {
        if (popModal) {
            return modalProps ? <ModalComponent
                {...modalProps}
                show={true}
                onClose={(val) => {
                    modalProps.onClose && modalProps.onClose(val);
                    popModal();
                }}
                onSubmit={(val) => {
                    modalProps.onSubmit && modalProps.onSubmit(val);
                    popModal();
                }}
            /> : <ModalComponent
                {...modalProps}
                show={true}
                onClose={(val) => {
                    popModal();
                }}
                onSubmit={(val) => {
                    popModal();
                }}
            />
        }
    }

    function useModalMemory<S>(
        key: string,
        init?: S
    ): [S, Dispatch<SetStateAction<S>>] {
        const currModal = modalStack[modalStack.length - 1] || { state: {} };
        if (currModal.state[key] === undefined) {
            currModal.state[key] = init;
        }
        const [state, setState] = useState(currModal.state[key]);
        return [
            state,
            (val: S | ((prevState: S) => S)) => {
                if (typeof val === 'function') {
                    const fnVal = val as ((prevState: S) => S);
                    val = fnVal(currModal.state[key]);
                }
                currModal.state[key] = val;
                setState(val);
            },
        ];
    }

    return {
        push: function <P extends ModalStackPropsBase<any, any>>(
            ModalComponent: React.FunctionComponent<P>,
            modalProps: React.Attributes & P | null,
            onClose?: ModalStackItem["onClose"],
            key: string = uuidv4()
        ) {
            if (setModalStack) {
                const renderedModal = renderModal(ModalComponent, modalProps);
                renderedModal &&
                    setModalStack((curr) => {
                        const existsModalIndex = curr.findIndex(item => item.key === key);
                        const newModal = { element: renderedModal, state: {}, onClose, key };
                        return existsModalIndex >= 0 ? [...curr.filter((_item, index) => index !== existsModalIndex), newModal] :
                            [
                                ...curr,
                                newModal,
                            ]
                    });
            }
        },
        pop: () => {
            setModalStack && setModalStack((curr) => [...curr]);
        },
        dismissAll,
        useModalMemory: useModalMemory,
        useModalFrom: function <
            TFieldValues extends FieldValues = FieldValues,
            TContext extends object = object
        >(formName: string, props: UseFormProps<TFieldValues, TContext>) {
            const [form] = useModalMemory(`${formName}-from`, useForm<TFieldValues, TContext>(props));
            return form;
        },
    };
};

export type ModalStackContextData = ReturnType<
    typeof ModalStackContextDataDefault
>;

const ModalStackContext = React.createContext<ModalStackContextData>(
    ModalStackContextDataDefault()
);
const useModalStack = () => useContext(ModalStackContext);
const ModalStackProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    const [modalStack, setModalStack] = useState<ModalStackItem[]>([]);
    const [currentModal, setCurrentModal] = useState<JSX.Element>();
    const ModalStackContextData = useMemo(
        () => ModalStackContextDataDefault(setModalStack, modalStack),
        [modalStack]
    );

    const location = useLocation();

    useEffect(() => {
        setCurrentModal(modalStack[modalStack.length - 1]?.element);
    }, [modalStack]);

    useEffect(() => {
        return () => {
            setModalStack([]);
        };
    }, [location]);

    return (
        <ModalStackContext.Provider value={ModalStackContextData}>
            {children}
            {currentModal}
        </ModalStackContext.Provider>
    );
};

export { ModalStackProvider, useModalStack };
