import { IFileDetail } from '@models/File';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Carousel, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  ReactZoomPanPinchProps,
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { ModalStackPropsBase } from './ModalStackProvider';
import emptyFile from '@assets/images/thumbnail/empty-thumbnail-64.png';

interface IPreviewImagesModalProps extends ModalStackPropsBase<unknown, unknown> {
  initIndex: number;
  title: string;
  data: IFileDetail[];
  pageElementID: string;
  onClose: () => void;
  onDownload?: () => void;
}

export function PreviewImagesModal(
  props: React.PropsWithChildren<IPreviewImagesModalProps>
): React.FunctionComponentElement<IPreviewImagesModalProps> {
  const { initIndex, title, data, pageElementID, onClose, show, onDownload } = props;

  const { t } = useTranslation();

  const elementID = `${pageElementID}-previewImages`;

  const [index, setIndex] = useState<number>(initIndex);

  const zoomPanPinchRef: React.Ref<ReactZoomPanPinchRef> = React.createRef();
  const mainAnimation = 'easeInOutQuad';

  const handleSelect = (selectedIndex: number) => {
    zoomPanPinchRef.current?.resetTransform();
    setIndex(selectedIndex);
  };

  const handleClose = () => {
    onClose();
  };

  const velocityAnimation: ReactZoomPanPinchProps['velocityAnimation'] = {
    disabled: false,
    sensitivity: 400,
    animationTime: 400,
    animationType: mainAnimation,
    equalToMove: true,
  };

  return (
    <>
      {
        <Modal id={`modal-${elementID}`} show={show} dialogClassName={classNames('modal-540')} onHide={() => { }}>
          <Modal.Header>
            <Modal.Title>
              <div className="d-flex">
                {title}
                <div className="ml-auto">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="close"
                    onClick={() => handleClose()}
                    id={`btn-${elementID}-close`}
                  >
                    <i className="icon-cancel"></i>
                  </button>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="fix-height">
            <div className="modal-nav" style={{ zIndex: 1000 }}>
              {index > 0 && (
                <div
                  id={`btn-${elementID}-prev`}
                  className="nav-prev"
                  onClick={() => handleSelect(index - 1)}
                >
                  <i className="icon-angle-left"></i>
                </div>
              )}
              {index < data.length - 1 && (
                <div
                  id={`btn-${elementID}-next`}
                  className="nav-next"
                  onClick={() => handleSelect(index + 1)}
                >
                  <i className="icon-angle-right"></i>
                </div>
              )}
            </div>
            <Carousel activeIndex={index} >
              <Carousel.Item >
                <TransformWrapper ref={zoomPanPinchRef} velocityAnimation={velocityAnimation}>
                  <TransformComponent >
                    <div className="thumbnail-img">
                      <div className="img-thumbnail thumbnail-bg">
                        <img id={`div-${elementID}-image-${index}`} src={data[index]?.fileUrl} onError={(ev: any) => { ev.target.src = emptyFile }} ></img>
                      </div>
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              </Carousel.Item>
            </Carousel>
          </Modal.Body>

          <Modal.Footer>
            <div className="text-center">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="m-0" id={``}>
                    {t('PreviewImagesModal.ToolTip.ZoomOut')}
                  </Tooltip>
                }
              >
                <button
                  id={`btn-${elementID}-zoomOut`}
                  onClick={() => zoomPanPinchRef.current?.zoomOut(1, 400, mainAnimation)}
                  type="button"
                  className="btn btn-icon btn-noborder"
                >
                  <i className="icon-remove-circle">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="m-0" id={``}>
                    {t('PreviewImagesModal.ToolTip.ResetZoom')}
                  </Tooltip>
                }
              >
                <button
                  id={`btn-${elementID}-resetZoom`}
                  onClick={() => zoomPanPinchRef.current?.resetTransform()}
                  type="button"
                  className="btn btn-icon btn-noborder"
                >
                  <i className="icon-actual-size"></i>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="m-0" id={``}>
                    {t('PreviewImagesModal.ToolTip.ZoomIn')}
                  </Tooltip>
                }
              >
                <button
                  id={`btn-${elementID}-zoomIn`}
                  onClick={() => zoomPanPinchRef.current?.zoomIn(1, 400, mainAnimation)}
                  type="button"
                  className="btn btn-icon btn-noborder"
                >
                  <i className="icon-add-circle">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              </OverlayTrigger>
              {onDownload && <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="m-0" id={``}>
                    {t('Download')}
                  </Tooltip>
                }
              >
                <>
                  <div className="border-btn"></div>
                  <button id={`btn-${elementID}-download`} type="button" className="btn btn-icon btn-secondary" onClick={() => onDownload()}>
                    <i className="icon-download">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </button>
                </>
              </OverlayTrigger>}
            </div>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}
