import classNames from "classnames";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownProps,
  FormControl,
  InputGroup
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DropdownSelectOptionBase } from "@models/Model";
import { InputToggle } from "./InputToggle";

export interface DropdownSelectOption extends DropdownSelectOptionBase { }

interface IDropdownSelectProp extends DropdownProps {
  name: string;
  options: DropdownSelectOption[];
  onChange: (fieldName: string, fieldValue: any) => void;
  value?: any;
  placeholder?: string;
  disabled?: boolean;
  id: string;
  searchBox?: boolean;
  noBorder?: boolean;
  className?: string;
  translate?: boolean;
  hasError?: boolean;
  isCustomReadOnly?: boolean;
  dropdownClassName?: string
  customIconDropdown?: string
  searchingNotFound?: string;
}

export const SimpleDropdownSelect = React.forwardRef((
  props: React.PropsWithChildren<IDropdownSelectProp>, ref: any
) => {
  const {
    children,
    name,
    options,
    onChange,
    value,
    placeholder,
    disabled,
    id,
    className,
    searchBox = false,
    noBorder = false,
    translate = true,
    hasError = false,
    isCustomReadOnly = false,
    dropdownClassName,
    customIconDropdown,
    searchingNotFound = '',
    ...dropdownProps
  } = props;
  const [label, setLabel] = useState(placeholder);
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValuePlaceholder, setSearchValuePlaceholder] = useState<string>('');

  useEffect(() => {
    if (uniqBy(options, "value").length !== options.length) {
      throw new Error("Options value cannot duplicates");
    }
  }, [options]);

  useEffect(() => {
    setLabel(options.find((item) => item.value === value)?.label);
    searchInputRef?.current?.blur();
  }, [value, options]);

  useEffect(() => {
    if (!searchValue && !searchValuePlaceholder) {
      searchInputRef?.current?.blur();
    }
  }, [searchValue, searchValuePlaceholder]);

  const handleChange = (currentValue: any) => {
    value !== currentValue && onChange(name, currentValue);
  };

  const toggleDropdown = (e: boolean) => {
    if (e) {
      setSearchValuePlaceholder(t(placeholder as string));
    } else {
      setSearchValue('');
      setSearchValuePlaceholder('');
    }
  };

  const dropdownItem = options.filter((option, i) => option.label
    .toLocaleLowerCase()
    .includes(searchValue.toLocaleLowerCase()))
    .map((option, i) => {
      return (<Dropdown.Item
        key={i}
        id={`opt-${id}-item-${i}`}
        className={classNames({ active: option.value === value })}
        onClick={() => handleChange(option.value)}
        dangerouslySetInnerHTML={{
          __html: translate ? t(option.label as string) : option.label as string,
        }}
      >
      </Dropdown.Item>)
    });

  return (
    <Dropdown {...dropdownProps}
      className={classNames(
        {
          "disabled": disabled || isCustomReadOnly,
          "is-invalid": hasError
        },
        `${dropdownClassName || ''}`
      )}
      onToggle={toggleDropdown}
    >
      {searchBox ?
        <>
          <Dropdown.Toggle as={InputToggle}
            variant=""
            disabled={disabled || isCustomReadOnly}
            id={`ddl-${id}`}
            className={classNames(
              { noborder: noBorder },
              { "disabled": isCustomReadOnly },
              { "is-invalid": hasError },
              `${className || ''}`)}
            ref={searchInputRef}
            elementID={id}
            value={searchValue}
            onChangeInput={(e: any) => { setSearchValue(e?.target?.value!) }}>
            {!searchValuePlaceholder ? <>
              <i data-toggle="dropdown" data-reference="parent"
                className={(customIconDropdown ? customIconDropdown : 'icon-small-dropdown dropdown-button')}></i>
              <div className="dropdown-text">
                {(value === undefined || value === '') ? (
                  <span className="placeholder">{t(placeholder as string)}</span>
                ) : (
                  <span dangerouslySetInnerHTML={{
                    __html: translate ? t(label as string) : label as string,
                  }}></span>
                )}
              </div>
            </> :
              <>{!searchValue &&
                <div className="dropdown-text">
                  <div className="dropdown-inline-icon">
                    {searchValuePlaceholder}
                  </div>
                </div>
              }
              </>
            }
          </Dropdown.Toggle>
        </>
        :
        <Dropdown.Toggle
          variant=""
          disabled={disabled || isCustomReadOnly}
          ref={ref}
          id={`ddl-${id}`}
          className={classNames(
            { noborder: noBorder },
            { "disabled": isCustomReadOnly },
            { "is-invalid": hasError },
            `${className || ''}`)}
        >
          {(value === undefined || value === '') ? (
            <span className="placeholder">{t(placeholder as string)}</span>
          ) : (
            <span dangerouslySetInnerHTML={{
              __html: translate ? t(label as string) : label as string,
            }}></span>
          )}
        </Dropdown.Toggle>
      }

      <Dropdown.Menu>
        <div className="dropdown-list">
          {dropdownItem.length > 0 ? dropdownItem :
            <div className="no-results" id={`opt-${id}-no-results`} style={{ display: 'block' }}>
              {searchingNotFound ? searchingNotFound : t('Common.Label.NoResultsFound')}
            </div>
          }
        </div>
      </Dropdown.Menu>
      {children}
    </Dropdown>
  );
});
