import environment from 'environment';

export const services = {
  authenticationCenter: `${environment.serviceHostName}/authentication-center`,
  authorizationSupplier: ``,
  authorizationEmarketplace: `${environment.serviceHostName}/authorize-emarketplace`,
  eMarketplace: `${environment.serviceHostName}/emarketplace`,
  product: `${environment.serviceHostName}/product`,
  company: `${environment.serviceHostName}/company-v2`,
  graphqlGateway: `${environment.serviceHostName}/graphql`,
  eMarketplaceIdp: `${environment.emarketPlaceFrontend}`,
  user: `${environment.serviceHostName}/user`,
  pi: `${environment.serviceHostName}/pi`,
  mock: `https://eebfbe3a-d6c1-4879-9314-f57e3e3b88c8.mock.pstmn.io`,
  notification: `${environment.serviceHostName}/notification`,
};

const APIConstant = {
  logout: `${services.authenticationCenter}/api/v1/logout?redirect_uri=/`,
  redirectURL: `${environment.supplierFrontend}/redirect`,
  registerURLKeyCloak: `${environment.keycloakHostName}/auth/realms/ptvn/protocol/openid-connect/registrations?client_id=supplier&response_type=code&scope=openid&redirect_uri=${environment.supplierFrontend}/redirect`,
  authenticationCenterService: {
    accountLoginPageURL: `${environment.keycloakHostName}/auth/realms/${environment.keycloakRealm}/protocol/openid-connect/auth`,
    accountGetAccessTokenURL: `${services.authenticationCenter}/api/v1/token`,
    accountRealmInformationURL: `${environment.keycloakHostName}/auth/realms/${environment.keycloakRealm}`,
    accountRenewAccessTokenURL: `${services.authenticationCenter}/api/v1/oauth/token`,
    checkPermission: `${services.authenticationCenter}/api/v1/user/authorize/permission`,
    changePassword: `${services.authenticationCenter}/api/v1/account/password`,
    accessTokenPassword: `${services.authenticationCenter}/api/v1/token-password`,
    forceLogout: `${services.authenticationCenter}/api/v1/forceLogout`,
  },
  eMarketplace: {
    getJobBoardList: `${services.eMarketplace}/api/v1/buyer/{companyID}/job/list`,
    getCategory: `${services.eMarketplace}/api/v1/category?search={search}`,
    postBuyingLead: `${services.eMarketplace}/api/v1/buyer/{companyID}/job`,
    getBuyerJobDetail: `${services.eMarketplace}/api/v1/buyer/{companyID}/job/{jobID}`,
    downloadJobAttachment: `${services.eMarketplace}/api/v1/job/{jobID}/attachment/{attachmentID}`,
    downloadJobResponseAttachment: `${services.eMarketplace}/api/v1/job-response/{jobResponseID}/attachment/{attachmentID}`,
    getSupplierJobBoardList: `${services.eMarketplace}/api/v1/supplier/{companyID}/job/list`,
    getSupplierJobDetail: `${services.eMarketplace}/api/v1/supplier/{supplierID}/job/{jobID}`,
    postQuote: `${services.eMarketplace}/api/v1/supplier/{supplierID}/job/{jobID}/quote`,
    getSupplierBuyerList: `${services.eMarketplace}/api/v1/supplier/{supplierID}/buyer-list`,
    getSubsciption: `${services.authorizationEmarketplace}/api/v1/subscription?companyID={companyId}`,
    loginIDP: `${services.eMarketplaceIdp}/login?idp=sww&redirectURI={redirectURI}`,
    redirectURI: `${services.eMarketplaceIdp}/subscription-plan/choose`,
    getCategoryList: `${services.eMarketplace}/api/v1/category/list`
  },
  product: {
    uploadProductFile: `${services.product}/api/v1/product/attachment/{type}`,
    graphql: `${services.product}/graphql`,
    checkExistProductName: `${services.product}/api/v1/product/exist`,
    getUOMList: `${services.product}/api/v1/uom/filter`,
    downloadTemplate: `${services.product}/api/v1/mass-upload/product/template`,
    massUploadProduct: `${services.product}/api/v1/mass-upload/product`,
    exportProduct: `${services.product}/api/v1/export/product`,
    uploadPromotionFile: `${services.product}/api/v1/promotion/attachment/{type}`,
    productAttribute: `${services.product}/api/v1/product/attribute?categoryID={categoryID}`,
    suggestionProductAttribute: `${services.product}/api/v1/product/attribute/suggest?categoryID={categoryID}`,
    productPredictSuggestCategory: `${services.product}/api/v1/product/predict/category`
  },
  authorizationSupplier: {
    getMyProfile: `${services.authorizationSupplier}/api/v1/profile`,
  },
  company: {
    getMyCompanies: `${services.company}/api/v1/company`,
    getCountries: `${services.company}/api/v1/address/countries`,
    checkAvailability: `${services.company}/api/v1/company/checkAvailability`,
    verifyUserSWW: `${services.company}/api/v1/company/verifySWWUser`,
    getThaiProvinceOption: `${services.company}/api/v1/address/state/TH`,
    getCityInfo: `${services.company}/api/v1/address/city`,
    quickRegister: `${services.company}/api/v1/company/quickRegister`,
    getBusinessEntityType: `${services.company}/api/v1/business/businessEntityType`,
    getBranches: `${services.company}/api/v1/branch?taxId={taxId}&countryCode={countryCode}&branchStatusId={branchStatusId}`,
    requestToJoin: `${services.company}/api/v1/company/quickRegister/requestToJoin`,
    updateUserDetail: `${services.company}/api/v1/user/updateUserDetails`,
    getJobTitle: `${services.company}/api/v1/jobTitle`,
    companyServices: `${services.company}/api/v1/company/service`,
    addNewBranch: `${services.company}/api/v1/company/quickRegister/addNewBranch`,
    getUserBranch: `${services.company}/api/v1/company/userBranchStatus?companyId={companyId}&branchNumber={branchNumber}`,
    checkCompanyInvitationCode: `${services.company}/api/v1/company/after-registration/invitation-code`,
    companyDocument: `${services.company}/api/v1/company/after-registration/attachment`,
    registerService: `${services.company}/api/v1/company/after-registration`,
    companyAttachment: `${services.company}/api/v1/company/attachment/{type}`,
    checkUpdateCompanyStatus: `${services.company}/api/v1/company/checkUpdateRequestCompanyStatus?companyId={companyId}&branchId={branchId}`,
    updateIntroduction: `${services.company}/api/v1/company/introduction`,
    updateAddress: `${services.company}/api/v1/company/address`,
    updateBusinessInformation: `${services.company}/api/v1/company/businessInformation`,
    updateDocumentsInformation: `${services.company}/api/v1/company/document`,
    getHistory: `${services.company}/api/v1/history/getHistory`,
    getBuyerList: `${services.company}/api/v1/company/searchBuyer`,
    validateAddCompanyInvitationCode: `${services.company}/api/v1/company/after-registration/validateInvitationCode`,
    checkStatusAddInvitationCode: `${services.company}/api/v1/company/after-registration/checkStatusAddInvitationCode?branchId={branchId}`,
    updateCompanyDetail: `${services.company}/api/v1/company/updateCompanyDetail`,
    getRequestStatusAndServiceStatus: `${services.company}/api/v1/company/requestStatusAndServiceStatus`,
    getCompanyInfo: `${services.company}/api/v1/company/companyDetail?ticketCode={ticketCode}`,
    downloadCompanyDocument: `${services.company}/api/v1/company/attachment/download`,
    getCompanyDocumentType: `${services.company}/api/v1/companyDocumentType?identityType={identityType}&countryCode={countryCode}`,
    companyDocumentFile: `${services.company}/api/v1/company/attachment/file`,
    updateUserContactEmail: `${services.company}/api/v1/user/update-contact-email`,
    getUserBranchDetail: `${services.company}/api/v1/user/userBranch?branchId={branchId}`,
    getUserContactAddress: `${services.company}/api/v1/user/contactAddress`,
    updateContactAddress: `${services.company}/api/v1/user/updateContactAddress`,
    updateUserBranch: `${services.company}/api/v1/user/update-userbranch`,
    getAvailableService: `${services.company}/api/v1/user/availableService`,
    getUserSWWInformation: `${services.company}/api/v1/user/userInfoFromSWW`,
    getMasterFieldApprove: `${services.company}/api/v1/company/masterFieldApprove?identityTypeID={identityTypeID}`,
    getAutoAcceptPOShowNotification: `${services.company}/api/v1/autoAcceptPO/showNotification?branchId={branchId}`,
    saveAutoAcceptPOShowNotification: `${services.company}/api/v1/autoAcceptPO/notificationDate`,
    getAutoAcceptPODetail: `${services.company}/api/v1/autoAcceptPO?branchId={branchId}`,
    upsertPurchaser: `${services.company}/api/v1/autoAcceptPO/upsertPurchaser`,
    deletePurchaser: `${services.company}/api/v1/autoAcceptPO/deletePurchaser`,
    autoAcceptPODownloadTemplate: `${services.company}/api/v1/autoAcceptPO/downloadTemplate`,
    autoAcceptPOAttachment: `${services.company}/api/v1/autoAcceptPO/attachment`,
    deleteAutoAcceptPOAttachment: `${services.company}/api/v1/autoAcceptPO/deleteAttachment`,
    autoAcceptPOActive: `${services.company}/api/v1/autoAcceptPO/active`,
    updateAutoAcceptPOStatus: `${services.company}/api/v1/autoAcceptPO/status`,
    submitAutoAcceptPORequest: `${services.company}/api/v1/autoAcceptPO/submitRequest`,
    getAutoAcceptPOHistory: `${services.company}/api/v1/autoAcceptPO/history?autoAcceptPOID={autoAcceptPOID}`,
    checkShowAutoAcceptPOMenu: `${services.company}/api/v1/menu/autoAcceptPO?branchId={branchId}`,
    userMassFirstTimeAccessToSupplierConnect: `${services.company}/api/v1/mass-upload/user/firstTimeAccess?branchId={branchId}`,
    popupMessage: `${services.company}/api/v1/popupMessage?id={id}`,
    masterPaymentTerm: `${services.company}/api/v1/payment/paymentTerm`,
    getUserAdminPO: `${services.company}/api/v1/user/listFromBranch/{branchId}`
  },
  graphQLGateway: `${services.graphqlGateway}`,
  consent: {
    getConsent: `${services.company}/api/v1/consent`,
    getRequestConsent: `${services.company}/api/v1/consent/request/sc`,
    acceptConsent: `${services.company}/api/v1/consent/accept/sc`,
    getCOC: `${services.company}/api/v1/consent/request`,
    getCOCCP: `${services.company}/api/v1/consent/request/coc_cp`,
    acceptCOC: `${services.company}/api/v1/consent/accept`,
    acceptCOCCP: `${services.company}/api/v1/consent/accept/coc_cp`,
    getCOCTrue: `${services.company}/api/v1/consent/request/coc_true`,
    acceptCOCTrue: `${services.company}/api/v1/consent/accept/coc_true`,
    getCOCByCompany: `${services.company}/api/v1/consent/request/company?applicationName={applicationName}`,
    globalSSA: `${services.company}/api/v1/global-ssa`
  },
  branch: {
    cancelRequestToJoin: `${services.company}/api/v1/branch/cancelRequestToJoin`,
    registerStatus: `${services.company}/api/v1/branch/registerStatus?branchID=`,
  },
  config: {
    getAppConfig: `${services.company}/api/v1/config`,
    getMaintenanceConfig: `${services.company}/api/v1/config/maintenance`,
    getAppConfigByName: `${services.company}/api/v1/config?name={name}`,
  },
  user: {
    getUserProfile: `${services.user}/api/v1/profile`,
    updateBroadCastUser: `${services.user}/api/v1/updateUserBroadcast`,
    updateUserProfile: `${services.company}/api/v1/user/profile`,
    userUpdateVerified: `${services.user}/api/v1/userUpdateVerified`,
    requestChangeEmail: `${services.user}/api/v1/requestChangeEmail`,
    resendEmail: `${services.user}/api/v1/resendEmailUserVerifyStatus`,
    updateStatusVerifiedEmail: `${services.user}/api/v1/updateStatusVerifiedEmail`,
    verifyChangeEmail: `${services.company}/api/v1/user/verifyEmail`,
    masterUserTitle: `${services.user}/api/v1/user/masterUserTitle`,
    updateRegisterFrom: `${services.user}/api/v1/user/updateRegisterFrom`
  },
  locale: {
    getMasterLocale: `${services.company}/api/v1/locale`,
    getMasterTimeZone: `${services.company}/api/v1/timeZone`,
    updatePreference: `${services.company}/api/v1/user/preference`

  },
  pi: {
    getCategory: `${services.pi}/api/v1/categories?search={search}`,
    getCategoryList: `${services.pi}/api/v1/category/list`
  },
  notificatin: {
    getAnnoucements: `${services.notification}/api/v1/announcement/service`
  }
};

export default APIConstant;