import { useTranslation } from 'react-i18next';
import { useDealsAndDiscountDetailContext } from '../DealsAndDiscountDetailContext';
import { useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { DealsAndDiscount, PromotionProduct } from '@models/DealsAndDiscount';
import { DiscountTypeOptionEnum } from '@utils/enum/DealsAndDiscountEnum';
import { CompanyItem, usePTVNAuthentication } from '@ptvn-react/authentication';
import { CountryCode } from '@utils/constants/CountryConstant';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import classNames from 'classnames';
import EmptyImg from '@assets/images/frontend/save-draft.png';
import emptyFile from '@assets/images/thumbnail/empty-thumbnail-64.png';
import NotFoundImg from '@assets/images/frontend/not-found.png';

import {
  LangType,
  ProductListSortByType,
  PromotionDetail,
  PromotionProductItemListInput,
  PromotionProductItemSortByType,
  SortDirType,
} from '@graphql/autogenerate/schemas';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Language, PageSizeDefaultValues } from '@utils/constants/CommonConstants';
import Pagination from '@components/pagination/Pagination';

interface SortInput {
  sortBy: ProductListSortByType;
  sortDir: SortDirType;
  sortNameBy: LangType;
}

interface IPromotionProductItemProps {
  elementID: string;
  refBox: any;
}

export default function PromotionProductItem(
  props: React.PropsWithChildren<IPromotionProductItemProps>
): React.FunctionComponentElement<IPromotionProductItemProps> {
  const { elementID, refBox } = props;
  const thisElementID = elementID + '-products';
  const { t, i18n } = useTranslation();
  const { setFilter, filter, promotionDetail, defaultProductFilter, isLoadingPromotion } =
    useDealsAndDiscountDetailContext();
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const [searches, setSearches] = useState<string[]>([]);
  const [searchBtn, setSearchBtn] = useState<boolean>(true);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');

  useEffect(() => {
    filter &&
      setFilter({
        ...filter,
        page: 1,
        searches: searches,
      });
  }, [searches]);

  const addSearch = (value: string) => {
    const trimmedValue = CommonHelper.trimValue(value);
    if (trimmedValue) {
      setSearches([trimmedValue]);
      setSearchBtn(false);
    } else {
      setSearches([]);
      setSearchBtn(true);
    }
  };

  const handelChangeSearchFilter = (value: string) => {
    const trimmedValue = CommonHelper.trimValue(value);
    if (!trimmedValue) {
      addSearch(value);
    }

    setInputSearchValue(value);
  };

  const handleEnterSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setInputSearchValue(e.currentTarget.value);
      addSearch(e.currentTarget.value);
    }
  };

  const removeSearch = () => {
    setInputSearchValue('');
    setSearches([]);
    setSearchBtn(true);
  };

  const onSortChange = (
    by: PromotionProductItemSortByType,
    sortName: LangType,
    dir: SortDirType
  ) => {
    filter &&
      setFilter((x) => {
        return x
          ? {
              ...x,
              sortBy: by,
              sortDir: dir,
              sortNameBy: sortName,
            }
          : defaultProductFilter;
      });
  };

  const rederDiscountDetail = (productDiscount: string) => {
    if (promotionDetail?.discountType === DiscountTypeOptionEnum.percent) {
      return (
        <>
          {
            <NumberFormat
              displayType="text"
              value={Number(productDiscount) || '-'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          }
          %
        </>
      );
    } else {
      return (
        <>
          {
            <NumberFormat
              displayType="text"
              value={Number(productDiscount) || '-'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          }{' '}
          {renderCurrency()}
        </>
      );
    }
  };
  const renderCurrency = () => {
    switch (currentCompany?.countryCode) {
      case CountryCode.TH:
        return 'THB';
      case CountryCode.VN:
        return 'VND';
      default:
        return 'USD';
    }
  };

  const renderTotalDiscount = (discount?: number, unitPrice?: number) => {
    let discountPrice = 0.0;
    if (discount && unitPrice) {
      if (promotionDetail?.discountType === DiscountTypeOptionEnum.percent) {
        discountPrice = unitPrice - (discount * unitPrice) / 100;
      } else {
        discountPrice = unitPrice - discount;
      }
    } else if (unitPrice) {
      discountPrice = unitPrice;
    }
    return (
      <>
        {
          <NumberFormat
            displayType="text"
            value={discountPrice || discountPrice > -1 ? discountPrice : '-'}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        }
      </>
    );
  };

  return (
    <>
      <div id="tab-2" className="tab-pane active">
        <div className="card">
          <div className="card-header">
            {t('DealsAndDiscount.Label.SelectedProducts')}{' '}
            <small className="ml-2">
              {promotionDetail?.promotionProductItemList?.totalItem!}{' '}
              {t('DealsAndDiscount.Label.Products')}
            </small>
          </div>
          <div className="card-body no-hz-space">
            {promotionDetail?.promotionProductItemList?.totalItem || searches.length > 0 ? (
              <>
                <div className="row-search mb-12px p-hz-default">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <div className="input-group icon">
                        <input
                          id={`txt-${elementID}-search`}
                          type="text"
                          placeholder={t('DealsAndDiscount.Label.SearchProductPlaceholder')}
                          className="form-control"
                          value={inputSearchValue}
                          onChange={(e) => handelChangeSearchFilter(e.target.value)}
                          onKeyDown={handleEnterSearchInput}
                        />

                        {searchBtn ? (
                          <>
                            <button
                              id={`btn-${elementID}-search`}
                              type="button"
                              className="btn"
                              onClick={() => addSearch(inputSearchValue)}
                            >
                              <i className="icon-search">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              id={`btn-${elementID}-search`}
                              type="button"
                              className="btn"
                              onClick={() => removeSearch()}
                            >
                              <i className="icon-cancel"></i>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {filter &&
            promotionDetail?.promotionProductItemList &&
            promotionDetail?.promotionProductItemList?.items!.length > 0 ? (
              <>
                <div className="table-sort-overflow table-wrap">
                  <table className="table-no-berder">
                    <thead>
                      <tr>
                        <th>
                          <div className="d-flex align-items-center nowrap">
                            {t('DealsAndDiscount.Table.Header.Product')}
                            <Dropdown bsPrefix="-" className="dropdown">
                              <Dropdown.Toggle
                                id={`btn-${elementID}-sort`}
                                as="button"
                                variant=""
                                bsPrefix="-"
                                className="btn-icon"
                              >
                                {filter.sortBy === PromotionProductItemSortByType.ProductName ? (
                                  <i
                                    className={classNames('icon-active', {
                                      'icon-sort desc ml-8px': filter.sortDir === SortDirType.Desc,
                                      'icon-sort asc ml-8px': filter.sortDir === SortDirType.Asc,
                                    })}
                                  >
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                ) : (
                                  <i className="icon-sorting">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div className="dropdown-list">
                                  <Dropdown.Item
                                    id={`btn-${elementID}-sort-sku-asc`}
                                    onSelect={() =>
                                      onSortChange(
                                        PromotionProductItemSortByType.ProductName,
                                        LangType.En,
                                        SortDirType.Asc
                                      )
                                    }
                                  >
                                    {t('DealsAndDiscount.Table.Sorting.ProductNameEnAsc')}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={`btn-${elementID}-sort-sku-desc`}
                                    onSelect={() =>
                                      onSortChange(
                                        PromotionProductItemSortByType.ProductName,
                                        LangType.En,
                                        SortDirType.Desc
                                      )
                                    }
                                  >
                                    {t('DealsAndDiscount.Table.Sorting.ProductNameEnDesc')}
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    id={`btn-${elementID}-sort-sku-asc`}
                                    onSelect={() =>
                                      onSortChange(
                                        PromotionProductItemSortByType.ProductName,
                                        LangType.Local,
                                        SortDirType.Asc
                                      )
                                    }
                                  >
                                    {t('DealsAndDiscount.Table.Sorting.ProductNameLocalAsc')}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={`btn-${elementID}-sort-sku-desc`}
                                    onSelect={() =>
                                      onSortChange(
                                        PromotionProductItemSortByType.ProductName,
                                        LangType.Local,
                                        SortDirType.Desc
                                      )
                                    }
                                  >
                                    {t('DealsAndDiscount.Table.Sorting.ProductNameLocalDesc')}
                                  </Dropdown.Item>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </th>
                        <th className="text-right">
                          {t('DealsAndDiscount.Table.Header.OriginalPriceCurrency', {
                            currency: renderCurrency(),
                          })}
                        </th>
                        <th className="text-right">
                          {t('DealsAndDiscount.Table.Header.DiscountPriceCurrency', {
                            currency: renderCurrency(),
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {promotionDetail?.promotionProductItemList?.items?.map((product, index) => {
                        return (
                          <tr key={index}>
                            <td className="align-middle">
                              <div className="box-product">
                                <div className="product-img">
                                  <img
                                    src={product?.product?.productFileList[0].fileUrl}
                                    onError={(ev: any) => {
                                      ev.target.src = emptyFile;
                                    }}
                                    width="64"
                                  />
                                </div>
                                <div className="product-detail">
                                  <div className="product-name">
                                    <strong>
                                      {i18n.language === Language.en.key
                                        ? product?.product?.nameEn
                                        : product?.product?.nameLocal}
                                    </strong>
                                  </div>
                                  <div className="mt-8px">
                                    <small>
                                      {t('DealsAndDiscount.Table.Body.ParentSKU', {
                                        SKU: product?.product?.sku,
                                      })}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-right">
                              {product?.product?.productOption?.unitPrice ? (
                                <NumberFormat
                                  displayType="text"
                                  value={Number(product?.product?.productOption?.unitPrice)! || '-'}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              ) : (
                                <p className="disabled text-none">
                                  {t('DealsAndDiscount.Label.NonPriceProduct')}
                                </p>
                              )}
                            </td>
                            <td className="align-middle text-right">
                              <span>
                                {product?.product?.productOption?.unitPrice ? (
                                  <>
                                    {renderTotalDiscount(
                                      Number(product?.value!),
                                      product?.product?.productOption?.unitPrice!
                                    )}
                                  </>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                              <div className="mt-8px">
                                <small>
                                  {t('DealsAndDiscount.Label.Discount')}:{' '}
                                  {rederDiscountDetail(product?.value!)}
                                </small>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  elementIdPrefix={elementID}
                  elementIdSuffix="product"
                  currentPage={promotionDetail?.promotionProductItemList?.page!}
                  totalPage={promotionDetail?.promotionProductItemList?.totalPage!}
                  totalItem={promotionDetail?.promotionProductItemList?.totalItem!}
                  pageSize={promotionDetail?.promotionProductItemList?.pageSize!}
                  pageSizes={PageSizeDefaultValues}
                  onSelectPageSize={(pageSize: number) => {
                    filter && setFilter({ ...filter, page: 1, pageSize });
                  }}
                  onSelectPageNumber={(pageNo: number) => {
                    filter && setFilter({ ...filter, page: pageNo });
                  }}
                />
              </>
            ) : (
              <div className="result-found">
                {isLoadingPromotion ? (
                  <div className="loading" style={{ position: 'absolute' }}>
                    <i className="icon-loading spinner"></i>
                  </div>
                ) : (
                  <>
                    {searches.length > 0 ? (
                      <>
                        <img src={NotFoundImg} className="img-result" />
                        <div className="title">{t('DealsAndDiscount.Label.NotFound')}</div>
                      </>
                    ) : (
                      <>
                        <img src={EmptyImg} className="img-result" />
                        <div className="title">
                          {t('DealsAndDiscount.Label.NoSelectedProducts')}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
