import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalSize } from '../../utils/constants/StyleConstant';
import { SCButton } from '@components/button/SCButton';
import { ModalStackPropsBase } from './ModalStackProvider';
import CancaelIcon from '@assets/images/frontend/cancel-promotion.png';

export interface ICancelModalData {
  modalSize?: ModalSize;
  title?: string;
  description?: string;
  submitText?: string;
  submitType?: 'primary' | 'reject';
  cancelText?: string;
  label?: string;
  backdropClassName?: string
}

interface ICancelModalProps extends ModalStackPropsBase<unknown, unknown> {
  data: ICancelModalData;
  pageElementID: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (value?: any) => void;
  elementRef?: any;
  noTextArea?: boolean
}

export function CancelModal(
  props: React.PropsWithChildren<ICancelModalProps>
): React.FunctionComponentElement<ICancelModalProps> {
  const {
    data,
    pageElementID,
    isLoading,
    onClose,
    onSubmit,
    elementRef,
    show,
    noTextArea
  } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');

  const elementID = `${pageElementID}-delete`;

  const handleSubmit = (value: string) => {
    onSubmit(value!);
  };

  const handleCancel = () => {
    onClose();
  };


  return (
    <>
      {
        <Modal
          id={`modal-${elementID}`}
          show={show}
          size={data.modalSize === ModalSize.MD ? undefined : data.modalSize}
          dialogClassName={'modal-dialog modal-540'}
          onHide={handleCancel}
          backdropClassName={data.backdropClassName}
        >
          <Modal.Body ref={elementRef}>
            <div className="text-center">
              <img src={CancaelIcon} className="card-icon" />
              <div id={`lbl-${elementID}-header`} className="title">
                {data.title}
              </div>
              {data?.description && (
                <p id={`lbl-${elementID}-description`}>{t(data.description)}</p>
              )}
            </div>
            {!noTextArea &&
              <div className="form-group mt-2">
                <label>{data?.label}</label>
                <textarea
                  placeholder={t('CompanyProfile.Label.TypeTheDescription')}
                  rows={3}
                  className="form-control"
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <SCButton id={`btn-${elementID}-no`} type="outline" onClick={handleCancel}>
                {data.cancelText || t('Common.Button.No')}
              </SCButton>
              <SCButton
                id={`btn-${elementID}-yes`}
                type={data.submitType ? data.submitType : 'reject'}
                onClick={() => handleSubmit(inputValue)}
              >
                {data.submitText || t('Common.Button.YesCancel')}
              </SCButton>
            </div>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}
