import PageRoute from '@utils/constants/PageRoute';
import React from 'react';

interface IProps {
  notShowLinkTermAndPolicy?: boolean;
  classNames?: string;
}

export default function FooterPTVN(props: React.PropsWithChildren<IProps>): React.FunctionComponentElement<unknown> {

  const { notShowLinkTermAndPolicy, classNames } = props;

  return (
    <>
      <footer className={`footer ${classNames ? classNames : 'footer-style-1'}`}>
        <div className="footer-body"><a href="" className="logo"><span>Supplier Connect</span></a>
          <div className="ml-auto navigate">
          </div>
        </div>
        <div className="footer-nav-bottom"><small>&copy; Copyright 2021 pantavanij.com. All Rights Reserved.</small>
          {!notShowLinkTermAndPolicy &&
            <div className="ml-auto"><a href={PageRoute.TERMS_AND_PRIVACY.replace(':tab', 'terms')} target="_blank">Term of Service</a><a href={PageRoute.TERMS_AND_PRIVACY.replace(':tab', 'terms#privacy')} target="_blank">Privacy Policy</a></div>
          }
        </div>
      </footer>
    </>
  );
}
