import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { MaintenanceConfigResponse } from '@api/response/ConfigResponse';
import { CommonClient } from '@api/CommonClient';
import { CompanyHelper } from '@utils/helpers/CompanyHelper';
import MaintenancePage from '@pages/MaintenancePage';
import { SkeletonLoad } from '@components/loading/SkeletonLoad';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { ExtendedUserInformation } from '@models/User';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import { AxiosResponse } from 'axios';
import { HttpStatus } from '@utils/constants/CommonConstants';
import ServiceUnavailablePage from '@pages/ServiceUnavailablePage';

export interface MainTenanceProviderContextType {
    loadingConfig: boolean;
    maintenanceConfig: MaintenanceConfigResponse | undefined;
}

const MainTenanceProviderContext = React.createContext<MainTenanceProviderContextType | undefined>(
    undefined
)

export const MainTenanceProviderProvider = ({ children }: React.PropsWithChildren<{}>) => {

    const { userInformation } =
        usePTVNAuthentication<ExtendedUserInformation>();
    const [subscription, setSubscription] = useState<Subscription>();
    const [maintenanceConfig, setMaintenanceConfig] = useState<MaintenanceConfigResponse>();
    const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
    const [serverError, setServerError] = useState<boolean>(false);

    useEffect(() => {
        getMaintenanceConfig();
    }, [userInformation])

    useEffect(() => {
        return () => {
            subscription && subscription.unsubscribe();
        };
    }, [subscription]);

    const getMaintenanceConfig = () => {
        setLoadingConfig(true);
        setSubscription(CommonClient.getMaintenanceConfig().subscribe((res: AxiosResponse) => {
            if (res?.data?.data?.length! > 0) {
                setMaintenanceConfig(CompanyHelper.transfromConfigResponseToMaintenanceConfigResponse(res.data.data));
            }
            setServerError(false);
            setLoadingConfig(false);
        }, (error: AxiosResponse) => {
            if (error?.status! === HttpStatus.Unauthorized) {
                setLoadingConfig(false);
            } else {
                setServerError(true);
                setLoadingConfig(false);
            }
        }));
    }

    const render = () => {
        return (<>
            {
                maintenanceConfig! ?
                    <MaintenancePage
                        maintenanceConfig={maintenanceConfig}
                    /> : (serverError ? <ServiceUnavailablePage /> : children)
            }
        </>);
    }

    return (
        <MainTenanceProviderContext.Provider
            value={{
                loadingConfig,
                maintenanceConfig
            }}
        >
            <>
                {loadingConfig ? <SkeletonLoad /> : render()}
            </>
        </MainTenanceProviderContext.Provider>
    )
}

export const useMainTenanceProviderContext = () => {
    return useContext(MainTenanceProviderContext) as MainTenanceProviderContextType
}