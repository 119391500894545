import classNames from 'classnames';
import React, { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { Area, MediaSize, Point } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import { SCButton } from '@components/button/SCButton';
import { ModalStackPropsBase } from '@components/modal/ModalStackProvider';
export interface ICropImageState {
  zoom: number;
  rotation: number;
  cropPosition: Point;
  cropArea?: Area;
  flip: {
    horizontal: boolean;
    vertical: boolean;
  };
}

export interface ICropImageData {
  attachmentURL: string;
  notShowCancelBtn?: boolean;
}

interface ICropImageModalProps extends ModalStackPropsBase<unknown, unknown> {
  elementID: string;
  data: ICropImageData;
  loading?: boolean;
}

export function CropImageModal(
  props: React.PropsWithChildren<ICropImageModalProps>
): React.FunctionComponentElement<ICropImageModalProps> {
  const { elementID, show, data, loading, onClose } = props;
  const { t } = useTranslation();

  const defaultState: ICropImageState = {
    zoom: 1,
    rotation: 0,
    cropPosition: {
      x: 0,
      y: 0,
    },
    flip: {
      horizontal: false,
      vertical: false,
    },
  };
  const [imgSize, setImgSize] = useState<MediaSize>();

  const zoomStep = 0.1;
  const aspect = 10 / 2;
  const defaultZoomRatio = 1;
  const maxZoonIn = 3;
  const rotateStep = 90;

  const [editedState, setEditedState] = useState<ICropImageState>(defaultState);

  const handleRoundProblem = (crop: Area, imgSize: MediaSize) => {
    crop.x = Math.abs(crop.x);
    crop.y = Math.abs(crop.y);
    if (crop.x + crop.width > imgSize.naturalHeight) {
      crop.x -= crop.x + crop.width - imgSize.naturalHeight;
    }
    if (crop.y + crop.height > imgSize.naturalWidth) {
      crop.y -= crop.y + crop.height - imgSize.naturalWidth;
    }
    return crop;
  };

  const correctCroppedAreaPixels = (
    mediaSize: MediaSize,
    croppedAreaPixels: Area,
    angle: number
  ) => {
    if (angle === 90 || angle === 270) {
      const offset = ((mediaSize.naturalWidth - mediaSize.naturalHeight) / 2) ^ 0; // round towards zero
      croppedAreaPixels.x -= offset;
      croppedAreaPixels.y += offset;
      croppedAreaPixels = handleRoundProblem(croppedAreaPixels, mediaSize);
    }

    return croppedAreaPixels;
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    if (imgSize && !isNaN(croppedAreaPixels.x)) {
      const correctCrop = correctCroppedAreaPixels(
        imgSize,
        croppedAreaPixels,
        editedState.rotation
      );
      setEditedState((prev) => ({
        ...prev,
        cropArea: correctCrop,
      }));
    }
  };

  const handleReset = () => {
    setEditedState(defaultState);
  };

  const onSave = () => {
    onClose && onClose(editedState);
  };

  return (
    <>
      <Modal id={`modal-${elementID}`} show={show} dialogClassName={classNames('modal-540')}>
        <Modal.Header>
          <Modal.Title>
            <div className="d-flex">{t('EditImageModal.Title')}</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="fix-height" style={{ overflowY: 'auto' }}>
          <div className="thumbnail-img">
            <div className="img-thumbnail thumbnail-bg">
              <Cropper
                style={{ mediaStyle: { left: '0', top: '0' } }}
                image={data.attachmentURL}
                crop={editedState.cropPosition}
                zoom={editedState.zoom}
                rotation={editedState.rotation}
                aspect={aspect}
                transform={[
                  `translate(${editedState.cropPosition.x}px, ${editedState.cropPosition.y}px)`,
                  `rotateZ(${editedState.rotation}deg)`,
                  `${editedState.flip.vertical ? 'scaleY(-1)' : ''}`,
                  `${editedState.flip.horizontal ? 'scaleX(-1)' : ''}`,
                  `scale(${editedState.zoom})`,
                ].join(' ')}
                onCropChange={(location: Point) => {
                  setEditedState((prev) => ({
                    ...prev,
                    cropPosition: location,
                  }));
                }}
                onCropComplete={onCropComplete}
                onZoomChange={(zoomNo: number) =>
                  setEditedState((prev) => ({ ...prev, zoom: zoomNo }))
                }
                onMediaLoaded={(mediaSize: MediaSize) => setImgSize(mediaSize)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt--1">
          <div className="d-flex align-items-center">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="m-0" id={``}>
                  {t('EditImageModal.ToolTip.ZoomOut')}
                </Tooltip>
              }
            >
              <button
                id={`btn-${elementID}-zoomOut`}
                onClick={() => {
                  editedState.zoom - zoomStep >= defaultZoomRatio &&
                    setEditedState((prev) => ({ ...prev, zoom: prev.zoom - zoomStep }));
                }}
                type="button"
                className="btn btn-icon btn-noborder"
              >
                <i className="icon-remove-circle">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="m-0" id={``}>
                  {t('EditImageModal.ToolTip.ZoomIn')}
                </Tooltip>
              }
            >
              <button
                id={`btn-${elementID}-zoomIn`}
                onClick={() => {
                  editedState.zoom + zoomStep < maxZoonIn &&
                    setEditedState((prev) => ({ ...prev, zoom: prev.zoom + zoomStep }));
                }}
                type="button"
                className="btn btn-icon btn-noborder"
              >
                <i className="icon-add-circle">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </button>
            </OverlayTrigger>
            <div className="line-button"></div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="m-0" id={``}>
                  {t('EditImageModal.ToolTip.Rotate')}
                </Tooltip>
              }
            >
              <button
                id={`btn-${elementID}-rotate`}
                type="button"
                className="btn btn-icon btn-noborder"
                onClick={() => {
                  editedState.rotation + rotateStep > 270
                    ? setEditedState((prev) => ({ ...prev, rotation: 0 }))
                    : setEditedState((prev) => ({ ...prev, rotation: prev.rotation + rotateStep }));
                }}
              >
                <i className="icon-rotate-90"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="m-0" id={``}>
                  {t('EditImageModal.ToolTip.FlipHorizontal')}
                </Tooltip>
              }
            >
              <button
                id={`btn-${elementID}-flipHorizontal`}
                type="button"
                className="btn btn-icon btn-noborder"
                onClick={() => {
                  setEditedState((prev) => ({
                    ...prev,
                    flip: { ...prev.flip, horizontal: !prev.flip.horizontal },
                  }));
                }}
              >
                <i className="icon-flip-horizontal"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="m-0" id={``}>
                  {t('EditImageModal.ToolTip.FlipVertical')}
                </Tooltip>
              }
            >
              <button
                id={`btn-${elementID}-flipVertical`}
                type="button"
                className="btn btn-icon btn-noborder"
                onClick={() => {
                  setEditedState((prev) => ({
                    ...prev,
                    flip: { ...prev.flip, vertical: !prev.flip.vertical },
                  }));
                }}
              >
                <i className="icon-flip-vertical"></i>
              </button>
            </OverlayTrigger>
            <div className="ml-auto">
              <button id={`btn-${elementID}-resetZoom`} className="btn-link" onClick={handleReset}>
                {t('EditImageModal.Button.Reset')}
              </button>
            </div>
          </div>
        </Modal.Footer>
        <Modal.Footer>
          <div className="float-left">
            {!data?.notShowCancelBtn &&
              <SCButton
                id={`btn-${elementID}-cancel`}
                type="outline"
                onClick={() => onClose && onClose(undefined)}
              >
                {t('Common.Button.Cancel')}
              </SCButton>
            }
          </div>
          <div className="float-right">
            <SCButton id={`btn-${elementID}-cropAndSave`} type="primary"
              isLoading={loading}
              onClick={onSave}>
              {t('EditImageModal.Button.CropAndSave')}
            </SCButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
