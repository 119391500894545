import React from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableServiceEnum } from '@utils/enum/SettingEnum';
import { useSettingContext } from '../SettingContext';
import { AvailableServiceDataResponse } from '@api/response/AvailableServiceResponse';
import environment from 'environment';
import useGoogleAnalytics from "@google/useGoogleAnalytics";
import { GoogleAnalyticsConstantEvent } from "@utils/constants/GoogleAnalyticsConstant";
import { EventArgs } from "react-ga";

export interface UserAvailableServiceProps {
    elementID: string;
}

export default function UserAvailableService(props: React.PropsWithChildren<UserAvailableServiceProps>): React.FunctionComponentElement<any> {
    const { setRequestGA } = useGoogleAnalytics();
    const { elementID } = props;
    const { t, i18n } = useTranslation();
    const {
        userAvailableService
    } = useSettingContext();

    const renderServiceName = (availableService: AvailableServiceDataResponse) => {
        return i18n.language.startsWith('en') ? availableService.serviceNameEn : availableService.serviceNameLocal;
    }

    const onClickMoreSettingOP = () => {
        setRequestGA({
            suffixPathAction: GoogleAnalyticsConstantEvent.MORE_SETTING,
            event: { action: GoogleAnalyticsConstantEvent.MORE_SETTING } as EventArgs
        });
    }

    return (<>
        <div>
            <div className="title">{t('AccountDetails.Label.AvailableService')}</div>
            <div className="table-wrap">
                <table className="table-no-berder clear-border-bottom">
                    <tbody className="no-bg">
                        {userAvailableService && userAvailableService?.availableServices!.map((availableService, index) => {
                            return (
                                <tr key={index}>
                                    <td className="col-check-circle">
                                        {availableService?.active && availableService?.enableService ?
                                            <>
                                                <i className="icon-check-circle-fill"><span className="path1"></span><span className="path2"></span>
                                                </i>
                                            </>
                                            : <>
                                                <i className="icon-check-circle disabled"><span className="path1"></span><span className="path2"></span>
                                                </i>
                                            </>}

                                    </td>
                                    <td className="col-detail-check-circle">

                                        {renderServiceName(availableService)}
                                        {userAvailableService?.enableMoreSetting && availableService?.active && availableService?.enableService &&
                                            availableService.serviceNameEn.toLowerCase() === AvailableServiceEnum.OP.toLowerCase() ?
                                            <>
                                                <div className="mt-8">
                                                    <small>
                                                        {t('AccountDetails.Label.OPMoreSetting')}{' '}
                                                        <a
                                                            href={`${environment.supplierWebWorkFrontend}/${userAvailableService.userSystemMappingPath}`}
                                                            onClick={onClickMoreSettingOP}
                                                            target="_blank"
                                                            rel="noreferrer">
                                                            {t('AccountDetails.Label.MoreSetting')}
                                                        </a>
                                                    </small>
                                                </div>
                                            </>
                                            : <></>}

                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </>
    )
}