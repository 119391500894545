import { MasterAddressType } from "@graphql/autogenerate/schemas";
import { AddressTypeEnum } from "@utils/enum/AddressEnum";
import { CompanyProfileActionEnum, CompanyProfileMenuEnum, RequestUpdateStatusEnum } from "@utils/enum/CompanyEnum";

export type RequestStatusType = RequestUpdateStatusEnum.AWAITING | RequestUpdateStatusEnum.APPROVED | RequestUpdateStatusEnum.REJECTED | RequestUpdateStatusEnum.CANCELED;

export type ActionType = CompanyProfileActionEnum.VIEW | CompanyProfileActionEnum.EDIT;

export type CompanyProfileMenuType = CompanyProfileMenuEnum.ADDRESS | CompanyProfileMenuEnum.BUSINESS_INFORMATION | CompanyProfileMenuEnum.DOCUMENT | CompanyProfileMenuEnum.INTRODUCION;

export class BusinessEntityIdentityType {
  static readonly INDIVIDUAL = [2, 0];
  static readonly COPORATION = [1, 0];
  static readonly OTHER = 0;
}

export class IdentityType {
  static readonly INDIVIDUAL = 2;
  static readonly COPORATION = 1;
}

export class Vat {
  static readonly YES = '1';
  static readonly NO = '0';
}

export class BusinessEntityTypeId {
  static readonly OTHER = "-1";
  static readonly K = "13";
  static readonly COP_LTD = "4";
}

export class TitleNameId {
  static readonly OTHER = "5";
}

export class JobTitleId {
  static readonly OTHER = "-1";
}

export const MasterCertificateByList = [
  {
    masterCertificateById: 2,
    masterCertificateByName: 'GMP'
  },
  {
    masterCertificateById: 3,
    masterCertificateByName: 'HACCP'
  },
  {
    masterCertificateById: 4,
    masterCertificateByName: 'ISO/IEC 17025'
  },
  {
    masterCertificateById: 5,
    masterCertificateByName: 'ISO 14000,14001'
  },
  {
    masterCertificateById: 6,
    masterCertificateByName: 'ISO 9000,9001,9400,19011:2000'
  },
  {
    masterCertificateById: 7,
    masterCertificateByName: 'Food BRC'
  },
  {
    masterCertificateById: 1,
    masterCertificateByName: 'Other'
  }
]

export class MasterRequestUpdateStatusId {
  static readonly AWAITING = 1;
  static readonly APPROVED = 2;
  static readonly REJECTED = 3;
  static readonly CANCELLED = 4;
  static readonly COMPLETED = 5;
}

export class MasterAddressTypeId {
  static readonly TAX_ADDRESS = 1;
  static readonly DOCUMENT_ADDRESS = 2;
  static readonly BILLING_ADDRESS = 3;
  static readonly COMPANY_ADDRESS = 4;
}

export class AddressType {
  public static readonly tax: MasterAddressType = { description: 'Tax Address', masterAddressTypeId: 1, name: AddressTypeEnum.TAX_ADDRESS };
  public static readonly document: MasterAddressType = { description: 'Document Address', masterAddressTypeId: 2, name: AddressTypeEnum.DOCUMENT_ADDRESS };
  public static readonly billing: MasterAddressType = { description: 'Billing Address', masterAddressTypeId: 3, name: AddressTypeEnum.BILLING_ADDRESS };
  public static readonly company: MasterAddressType = { description: 'Company Address', masterAddressTypeId: 4, name: AddressTypeEnum.COMPANY_ADDRESS };

  public static getAddressTypeByName(name: string) {
    switch (name) {
      case AddressTypeEnum.TAX_ADDRESS:
        return AddressType.tax;
      case AddressTypeEnum.DOCUMENT_ADDRESS:
        return AddressType.document;
      case AddressTypeEnum.BILLING_ADDRESS:
        return AddressType.billing;
      case AddressTypeEnum.COMPANY_ADDRESS:
        return AddressType.company;
      default:
        return undefined;
    }
  }
}

export class CompanyDocumentTypeName {
  static readonly COMPANY_REGISTRATION = 'Company Registration';
  static readonly MEMORANDOM_OF_ASSOCIATION = 'Memorandom of Association';
  static readonly VAT_REGISTRATION_POR_POR_20 = 'VAT Registration Por Por 20';
  static readonly VAT_REGISTRATION_POR_POR_01 = 'VAT Registration Por Por 01';
  static readonly VAT_REGISTRATION_POR_POR_09 = 'VAT Registration Por Por 09';
  static readonly FINANCIAL_STATEMENT = 'Financial Statement';
  static readonly POWER_OF_ATTORNEY = 'Power of Attorney';
  static readonly COMPANY_PROFILE = 'Company Profile';
  static readonly BUSINESS_REGISTRATION_CERTIFICATE = 'Business Registration Certificate';
  static readonly COPY_OF_ID_CARD = 'Copy of ID Card';
  static readonly COPY_OF_HOUSE_REGISTRATION = 'Copy of House Registration';
  static readonly COMPANY_CERTIFICATION_DOCUMENT = 'Company Certification Document';
  static readonly AFFIDAVIT = 'Affidavit';
  static readonly COPY_OF_PASSPORT = 'Copy of Passport';
  static readonly VAT_REGISTRATION = 'VAT Registration';
  static readonly COPY_OF_PASSPORT_PERSON = 'Copy of Passport (Authorized Person)';
  static readonly OTHER = 'Other';
}

export class CompanyWaitingDisplayStatus {
  static readonly AWAITING_BRANCH = 'AwaitingBranch';
  static readonly AWAITING_ADD_NEW_BRANCH = 'AwaitingAddNewBranch';
  static readonly APPROVED = 'Approved';
  static readonly AWAITING_REQUEST_TO_JOIN = 'AwaitingRequestToJoin';
  static readonly CANCEL_REQUEST_TO_JOIN = 'CancelRequestToJoin';
  static readonly REJECTED_BRANCH = 'RejectedBranch';
  static readonly REJECTED_ADD_NEW_BRANCH = 'RejectedAddNewBranch';
  static readonly REJECTED_REQUEST_TO_JOIN = 'RejectedRequestToJoin';
  static readonly AWAITING_USER_BRANCH = 'AwaitingUserBranch'
}

export class COCApplicatioName {
  static readonly LOTUS = ["Lotus's_TH", "Lotus's_MY"];
  static readonly KBANK = ["KBK0710"];
  static readonly TRUE = ["SWW-CODE-OF-CONDUCT", "PTVN-CONSENT-SWW", "PTVN-TRUE-PDPA"];
  static readonly CPG = ["PTVN-SWW-CPG-COC"];
  static readonly GLOBAL_COC = ["PTVN-GLOBAL-COC"];

  static readonly TITLE_PDPA = ["PTVN-TRUE-PDPA"];
}

export class PopupMessageID {
  static readonly SELF_ASSESSMENT_TRUE = 1;
  static readonly ANNOUNCEMENT = 2;
  static readonly GLOBAL_SSA = 3;
}