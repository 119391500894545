import { CompanyClient } from '@api/CompanyClient';
import { BranchResponse } from '@api/response/CompanyResponse';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Subscription } from 'rxjs';
import SupplierConnectLayout from './components/layout/SupplierConnectLayout';
import { SomethingWentWrongModal } from './components/modal/SomethingWentWrongModal';
import { PrivateRoute } from './middlewares/privateRoute';
import { BranchConstant, UserBranchStatus } from './utils/constants/BranchConstant';
import PageRoute from './utils/constants/PageRoute';
import SentRequestToJoin from './pages/company/SentRequestToJoin';
import { useUserInformationLazyQuery } from '@graphql/autogenerate/hooks';
import { GatewayGraphQLClient } from '@api/GatewayGraphQLClient';
import { ExtendedUserInformation } from '@models/User';
import { UserInformation } from '@graphql/autogenerate/schemas';
import { oneTrustScript } from './utils/helpers/OneTrust';
import { SkeletonLoad } from '@components/loading/SkeletonLoad';
import DealsAndDiscountPageRouting from '@pages/deals-and-discount/DealsAndDiscountPageRouting';
import AddAndEditDealsAndDiscountPage from '@pages/deals-and-discount/add-edit';
import SettingRouting from '@pages/setting/SettingRouting';
import { SkeletonLoadFull } from '@components/loading/SkeletonLoadFull';
import { MainTenanceProviderProvider } from '@components/provider/MainTenanceProvider';
import Hotjar from '@hotjar/browser';
import environment from 'environment';

interface IProps {
  readonly history: History;
}

interface RoutesItem {
  path: string;
  component: React.FunctionComponent;
  exact: boolean;
}

const RedirectPage = lazy(() => import('./pages/RedirectPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const CompanyRegister = lazy(() => import('./pages/company/CompanyRegister'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const ProductPageRouting = lazy(() => import('./pages/product/ProductPageRouting'));
const AddProductPage = lazy(() => import('./pages/product/add-product/AddProductPage'));
const SingleEditProductPage = lazy(() => import('./pages/product/edit/SingleEditProductPage'));
const ProductListSelectPage = lazy(() => import('./pages/product/product-list/ProductListSelectPage'));
const ProductDraftPage = lazy(() => import('./pages/product/ProductDraftPage'));
const PotalPage = lazy(() => import('./pages/PotalPage'));
const TermsAndPrivacyPage = lazy(() => import('./pages/TermsAndPrivacyPage'));
const WaitingPage = lazy(() => import('./pages/company/WaitingPage'));
const Congratulation = lazy(() => import('./pages/company/Congratulation'));
const RegisterServicePage = lazy(() => import('./pages/register-service'));
const CompanyProfileRouting = lazy(() => import('./pages/company/companyProfile/CompanyProfileRouting'));
const BuyerPageRouting = lazy(() => import('./pages/buyer/BuyerPageRouting'));
const GitVersionPage = lazy(() => import('./pages/GitVersionPage'));
const CompanyInfoPage = lazy(() => import('./pages/CompanyInfoPage'));
const AccountSettingRouting = lazy(() => import('./pages/account-setting/AccountSettingRouting'));
const VerifyChangeEmail = lazy(() => import('./pages/account-setting/VerifyChangeEmail'));
const LandingPageSC = lazy(() => import('./pages/LandingPageSC'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const EmergencyMaintenancePage = lazy(() => import('./pages/emergency/EmergencyMaintenancePage'));

function App(props: React.PropsWithChildren<IProps>) {
  const { userInformation, setCompanies, setExtendedUserInformation, extendedUserInformation, setCurrentCompany } =
    usePTVNAuthentication<ExtendedUserInformation>();
  const { i18n } = useTranslation();
  const modalStack = useModalStack();
  const [extendedInformation, setExtendedInformation] = useState<ExtendedUserInformation>();
  const [loadUserInformation, { data: resUserInformation, error: errUserInformation }] =
    useUserInformationLazyQuery({
      client: GatewayGraphQLClient,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    oneTrustScript();
    Hotjar.init(environment.hotjar.siteId, environment.hotjar.hotjarVersion);
    let subscriptionGetMyCompany: Subscription;
    if (userInformation && !document.URL.includes(PageRoute.LOGOUT)) {
      subscriptionGetMyCompany = CompanyClient.getCompany().subscribe(
        (res) => {
          if (res && res.companies.length) {
            const companies = res.companies.map((company, index) => ({
              companyID: company.companyId,
              taxID: company.taxId,
              companyNameEN: company.companyNameEN,
              companyNameLocal: company.companyName,
              branchId: findBranchNotCancelOrNotReject(company.branches).branchId,
              branchNameEN: findBranchNotCancelOrNotReject(company.branches).branchNameEN,
              branchNameLocal: findBranchNotCancelOrNotReject(company.branches).branchName,
              branchNumber: findBranchNotCancelOrNotReject(company.branches).branchNumber,
              headQuarterBranch:
                findBranchNotCancelOrNotReject(company.branches).branchNumber ===
                BranchConstant.HEAD_QUARTER,
              countryCode: company.countryCode,
              isDefault: index === 0,
              isFullService: false,
              orgID: findBranchNotCancelOrNotReject(company.branches).orgID
            }));
            setCompanies(companies);
            setCurrentCompany(companies[companies.length - 1].companyID)
          }
          loadUserInformation();
          setExtendedInformation({ companies: res.companies });
        },
        (_err) => {
          modalStack.push(SomethingWentWrongModal, { onSubmit: () => { } })
        }
      );
    }
    return () => {
      subscriptionGetMyCompany && subscriptionGetMyCompany.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (extendedInformation) {
      setExtendedUserInformation(extendedInformation);
    }
  }, [extendedInformation]);

  useEffect(() => {
    if (resUserInformation) {
      setExtendedInformation({
        userInformation: resUserInformation.userInformation as UserInformation,
        companies: extendedInformation?.companies!,
      });
    }
  }, [resUserInformation]);

  useEffect(() => {
    errUserInformation &&
      modalStack.push(SomethingWentWrongModal, { onSubmit: () => { } });
  }, [errUserInformation]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);
  const findBranchNotCancelOrNotReject = (branchList: BranchResponse[]): BranchResponse => {
    const defaultBranchResponse: BranchResponse = {
      branchId: 0,
      branchName: '',
      branchNameEN: '',
      branchNumber: '',
      jobTitleId: undefined,
      otherJobTitle: undefined,
      userBranchStatusId: undefined,
      userBranchStatusDescription: undefined,
      actionDated: '',
      orgID: '',
      branchStatusId: 0
    };
    if (branchList) {
      const newBranchResponse = branchList.find(
        (branch) =>
          branch.userBranchStatusId !== UserBranchStatus.REJECTED &&
          branch.userBranchStatusId !== UserBranchStatus.CANCEL_REQUEST_TO_JOIN
      );
      if (newBranchResponse) {
        return newBranchResponse;
      }
    }
    return defaultBranchResponse;
  };

  const publicRoutes: RoutesItem[] = [
    { path: PageRoute.TERMS_AND_PRIVACY, component: TermsAndPrivacyPage, exact: true },
    { path: PageRoute.REDIRECT, component: RedirectPage, exact: true },
    { path: PageRoute.LOGIN, component: LoginPage, exact: true },
    { path: PageRoute.VERSION, component: GitVersionPage, exact: true },
    { path: PageRoute.COMPANY_INFO, component: CompanyInfoPage, exact: true },
    { path: PageRoute.VERIFY_CHANGE_MAIL, component: VerifyChangeEmail, exact: true },
    { path: PageRoute.NEW_LANDING, component: LandingPageSC, exact: true },
    { path: PageRoute.NOT_FOUND, component: NotFoundPage, exact: true },
    { path: PageRoute.EMERGENCY_MAINTENANCE_PAGE, component: EmergencyMaintenancePage, exact: true }
  ];
  const privateRoutes: RoutesItem[] = [
    { path: PageRoute.PRODUCT_ADD, component: AddProductPage, exact: false },
    { path: PageRoute.PRODUCT_DRAFT, component: ProductDraftPage, exact: false },
    { path: PageRoute.PRODUCT_SINGLE_EDIT, component: SingleEditProductPage, exact: false },
    { path: PageRoute.PRODUCT_LIST_SELECT, component: ProductListSelectPage, exact: false },
    { path: PageRoute.LANDING, component: LandingPage, exact: false },
    { path: PageRoute.COMPANY_REGISTER, component: CompanyRegister, exact: false },
    { path: PageRoute.PORTAL, component: PotalPage, exact: false },
    { path: PageRoute.COMPANY_WAITING, component: WaitingPage, exact: false },
    { path: PageRoute.USER_DETAIL, component: Congratulation, exact: false },
    { path: PageRoute.REGISTER_SERVICE, component: RegisterServicePage, exact: false },
    { path: PageRoute.COMPANY_SEND_MAIL, component: SentRequestToJoin, exact: false },
    { path: PageRoute.DEALS_AND_DISCOUNT_ADD, component: AddAndEditDealsAndDiscountPage, exact: false },
    { path: PageRoute.DEALS_AND_DISCOUNT_EDIT, component: AddAndEditDealsAndDiscountPage, exact: false },
    { path: PageRoute.ACCOUNT_SETTING, component: AccountSettingRouting, exact: false },
    { path: PageRoute.SETTING, component: SettingRouting, exact: false },
  ];
  const privateSupplierConnectLayoutRoutes: RoutesItem[] = [
    { path: PageRoute.PRODUCT, component: ProductPageRouting, exact: false },
    { path: PageRoute.COMPANY_MANAGEMENT, component: CompanyProfileRouting, exact: false },
    { path: PageRoute.DEALS_AND_DISCOUNT, component: DealsAndDiscountPageRouting, exact: false },
    { path: PageRoute.BUYER, component: BuyerPageRouting, exact: false },
  ];

  return (
    <>
      <ConnectedRouter history={props.history}>
        <Suspense fallback={<SkeletonLoad />}>
          <Switch>
            <PrivateRoute path={PageRoute.LOGOUT} component={LogoutPage} exact />
            {publicRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <MainTenanceProviderProvider>
              <Switch>
                {privateRoutes.map((route) => (
                  <PrivateRoute key={route.path} {...route} show={!!extendedUserInformation} />
                ))}

                <SupplierConnectLayout>
                  <Suspense fallback={<SkeletonLoadFull />}>
                    <Switch>
                      {privateSupplierConnectLayoutRoutes.map(({ ...route }) => (
                        <PrivateRoute key={route.path} {...route} show={!!extendedUserInformation} />
                      ))}
                      <Redirect to={PageRoute.NOT_FOUND} />
                    </Switch>
                  </Suspense>
                </SupplierConnectLayout>
              </Switch>
            </MainTenanceProviderProvider>
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </>
  );
}

export default App;
