import { CommonClient } from '@api/CommonClient';
import { CompanyClient } from '@api/CompanyClient';
import { GatewayGraphQLClient } from '@api/GatewayGraphQLClient';
import { UserClient } from '@api/UserClient';
import { ContactAddressResponse } from '@api/response/AddressResponse';
import { AvailableServiceResponse } from '@api/response/AvailableServiceResponse';
import { PTVNErrorAPI, PTVNResponse } from '@api/response/BaseResponse';
import { JobTitleResponse } from '@api/response/CompanyResponse';
import { CountryResponse } from '@api/response/CountryResponse';
import { UserBranchDetailResponse } from '@api/response/UserBranchResponse';
import { MasterUserTitleResponse } from '@api/response/UserResponse';
import ConsentModal from '@components/modal/ConsentModal';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import { useUserInformationLazyQuery } from '@graphql/autogenerate/hooks';
import { ExtendedUserInformation } from '@models/User';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import React, { useContext, useEffect, useState } from 'react';
import { Subscription, forkJoin, Observable } from 'rxjs';

export interface JoinAPIRequest {
  userBranch?: Observable<PTVNResponse<UserBranchDetailResponse>>;
  masterJobTitle?: Observable<JobTitleResponse[]>;
  contactAddress?: Observable<PTVNResponse<ContactAddressResponse>>;
  masterCountries?: Observable<CountryResponse[]>;
  userAvailableService?: Observable<PTVNResponse<AvailableServiceResponse>>;
  masterTitleName?: Observable<PTVNResponse<MasterUserTitleResponse[]>>
}

export interface JoinAPIRespone {
  userBranch?: PTVNResponse<UserBranchDetailResponse>;
  masterJobTitle?: JobTitleResponse[];
  contactAddress?: PTVNResponse<ContactAddressResponse>;
  masterCountries?: CountryResponse[];
  userAvailableService?: PTVNResponse<AvailableServiceResponse>;
  masterTitleName?: PTVNResponse<MasterUserTitleResponse[]>;
}

export interface SettingContextType {
  loadingAccountDetailAllData: boolean;
  userBranchDetail: UserBranchDetailResponse | undefined;
  contactAddress: ContactAddressResponse | undefined;
  masterCountries: CountryResponse[];
  masterJobTitle: JobTitleResponse[];
  userAvailableService: AvailableServiceResponse | undefined;
  masterTitleName: MasterUserTitleResponse[];
  fetchAccountDetailData: () => void;
}

const SettingContext = React.createContext<SettingContextType | undefined>(
  undefined
);

export const SettingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [subscription, setSubscription] = useState<Subscription>();
  const { currentCompany } = usePTVNAuthentication<ExtendedUserInformation>();
  const { push } = useModalStack();

  // Account setting
  const [loadingAccountDetailAllData, setLoadingAccountDetailAllData] = useState<boolean>(true);
  const [userBranchDetail, setUserBranchDetail] = useState<UserBranchDetailResponse>();
  const [contactAddress, setContactAddress] = useState<ContactAddressResponse>();
  const [masterCountries, setMasterCountries] = useState<CountryResponse[]>([]);
  const [masterJobTitle, setMasterJobTitle] = useState<JobTitleResponse[]>([]);
  const [userAvailableService, setUserAvailableService] = useState<AvailableServiceResponse>();
  const [masterTitleName, setMasterTitleName] = useState<MasterUserTitleResponse[]>([]);

  useEffect(() => {
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [subscription]);

  useEffect(() => {
    fetchGetMainData();
  }, []);

  // prepare data for account detail
  useEffect(() => {
    if (masterCountries?.length && userBranchDetail && contactAddress && masterJobTitle.length && userAvailableService && masterTitleName.length) {
      setLoadingAccountDetailAllData(false);
    }
  }, [masterCountries, userBranchDetail, contactAddress, masterJobTitle, userAvailableService, masterTitleName]);

  const requestGetAccountDetailData = (joinApi?: JoinAPIRequest) => {
    if (joinApi) {
      return {
        ...joinApi,
        userBranch: CompanyClient.getUserBranchDetail(currentCompany?.branchId!),
        contactAddress: CompanyClient.getUserContactAddress(),
        userAvailableService: CompanyClient.getUserAvailableService()
      }
    } else {
      return {
        userBranch: CompanyClient.getUserBranchDetail(currentCompany?.branchId!),
        contactAddress: CompanyClient.getUserContactAddress(),
        userAvailableService: CompanyClient.getUserAvailableService()
      }
    }
  }

  const fetchAccountDetailData = () => {
    setLoadingAccountDetailAllData(true);
    getForkJoinAPI(requestGetAccountDetailData());
  }

  const fetchGetMainData = () => {
    let joinApi: JoinAPIRequest | undefined = undefined;
    if (!masterCountries?.length!) {
      joinApi = {
        masterCountries: CommonClient.getCountry()
      }
    }

    if (!masterJobTitle?.length!) {
      joinApi = {
        ...joinApi,
        masterJobTitle: CompanyClient.getJobTitle()
      }
    }

    if (!masterTitleName?.length!) {
      joinApi = {
        ...joinApi,
        masterTitleName: UserClient.getMasterUserTitle()
      }
    }

    joinApi = requestGetAccountDetailData(joinApi);

    if (joinApi) {
      getForkJoinAPI(joinApi);
    }
  }

  const getForkJoinAPI = (joinApi: JoinAPIRequest) => {
    setSubscription(forkJoin(joinApi).subscribe((response: JoinAPIRespone) => {
      if (response?.masterJobTitle?.length!) {
        const masterJobTitleSorted = response?.masterJobTitle.sort((a, b) =>
          (a.masterJobTitleId < b.masterJobTitleId || b.masterJobTitleId === -1) ? -1 : (a.masterJobTitleId > b.masterJobTitleId) ? 1 : 0
        );
        setMasterJobTitle(masterJobTitleSorted);
      }

      if (response?.masterCountries?.length!) {
        setMasterCountries(response?.masterCountries);
      }

      if (response?.masterTitleName?.data && response?.masterTitleName?.data?.length!) {
        setMasterTitleName(response?.masterTitleName?.data);
      }

      if (response?.userBranch?.data!) {
        setUserBranchDetail(response?.userBranch?.data);
      }

      if (response?.contactAddress?.data!) {
        setContactAddress(response?.contactAddress?.data);
      }

      if (response?.userAvailableService?.data!) {
        setUserAvailableService(response?.userAvailableService?.data);
      }
    }, (err) => {
      CommonHelper.handleErrorSomtingWentWrong(err, push);
    }));
  }

  return (
    <SettingContext.Provider
      value={{
        loadingAccountDetailAllData,
        userBranchDetail,
        contactAddress,
        masterCountries,
        masterJobTitle,
        userAvailableService,
        masterTitleName,
        fetchAccountDetailData
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};

export const useSettingContext = () => {
  return useContext(SettingContext) as SettingContextType;
};
