import ConsentModal from "@components/modal/ConsentModal";
import {
  CompanyItem,
  IPrivateRoute, PrivateRoute as PTVNPrivateRoute, usePTVNAuthentication
} from "@ptvn-react/authentication";
import APIConstant from "@utils/constants/APIConstant";
import { default as React, useEffect, useState } from "react";
import usePDPA from '@pages/common-hooks/usePDPA';
import { useModalStack } from "@components/modal/ModalStackProvider";
import { CompanyClient } from "@api/CompanyClient";
import { RequestUpdateStatusEnum } from "@utils/enum/CompanyEnum";
import { SomethingWentWrongModal } from "@components/modal/SomethingWentWrongModal";
import PageRoute from "@utils/constants/PageRoute";
import { OpenServiceGuardConstant } from "@utils/constants/GuardConstant";
import { ConfirmButtonCenterModal } from "@components/modal/ConfirmButtonCenterModal";
import WaitingImage from '@assets/images/frontend/Waiting.png';
import { useTranslation } from "react-i18next";
import { Subscription } from 'rxjs';
import { useHistory } from "react-router";

export const PrivateRoute: React.FC<Omit<IPrivateRoute, "publicKeyURL">> = (
  props
) => {
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const { push } = useModalStack();
  const { t } = useTranslation();
  const [auth, setAuth] = useState<boolean>(!OpenServiceGuardConstant.includes(props.path?.toString()!));
  const { logout } = usePTVNAuthentication();
  const [subscription, setSubscription] = useState<Subscription>();
  const history = useHistory();
  const { termsAndCondition,
    privacyNotice,
    acceptConsentSC,
    onAcceptConsent,
    convertFormat } = usePDPA();

  const onDecline = () => {
    logout(APIConstant.logout).catch((err) => { });
  }

  useEffect(() => {
    return () => {
      subscription && subscription.unsubscribe();
    }
  }, [subscription])

  useEffect(() => {
    if (currentCompany?.branchId && OpenServiceGuardConstant.includes(props.path?.toString()!)) {
      setSubscription(CompanyClient.getRequestStatusAndServiceStatus(currentCompany.branchId!).subscribe(
        (response) => {
          if (response.data.reqStatus === RequestUpdateStatusEnum.AWAITING) {
            push(ConfirmButtonCenterModal, {
              elementID: 'modal-error',
              icon: WaitingImage,
              titleBody: t('RegisterService.Text.RequestAwaiting'),
              description1: t('RegisterService.Text.RequestAwaitingText'),
              btnConfirmText: t('RegisterService.Button.BackHomepage'),
              onSubmit: (() => {
                window.location.href = PageRoute.PORTAL;
              })
            });
          } else if (response.data.reqStatus === RequestUpdateStatusEnum.REJECTED) {
            history.push(PageRoute.REGISTER_SERVICE);
          } else {
            setAuth(true);
          }
        },
        (error) => {
          push(SomethingWentWrongModal, {
            errMessage: JSON.stringify(error),
            onSubmit: () => { },
          });
        }
      ));
    }
  }, [currentCompany]);

  return (
    <>
      {
        !acceptConsentSC && termsAndCondition.id && convertFormat && privacyNotice.id != '' &&
        <ConsentModal onAccept={onAcceptConsent} onDecline={onDecline} termsOfservice={termsAndCondition} privacyAndNotice={privacyNotice}></ConsentModal>
      }
      {
        acceptConsentSC && auth && (<PTVNPrivateRoute {...props}></PTVNPrivateRoute>)
      }
    </>
  );
};

