import { Controller, useFormContext } from 'react-hook-form';
import { DealsAndDiscount, PromotionImage } from "@models/DealsAndDiscount";
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { PromotionErrors } from '../DealsAndDiscountSchema';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { DealsAndDiscountListStatusEnum, DiscountTypeOptionEnum } from '@utils/enum/DealsAndDiscountEnum';
import UploadImage from './UploadImage';
import UploadFileIcon from '@assets/images/frontend/not-found.png';
import { FileMIME, IFileDetail } from '@models/File';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { DropdownTimepicker } from '@components/dropdown/DropdownTimepicker';
import { FileStatus } from '@utils/constants/FileConstant';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import { ImageAlertModal } from '@components/modal/ImageAlertModal';
import previewBanner from "@assets/images/thumbnail/preview.png"
import { ModalSize } from '@utils/constants/StyleConstant';
import { ConfirmButtonCenterModal } from '@components/modal/ConfirmButtonCenterModal';
import PromotionIcon from '@assets/images/frontend/Promotion.png';
import { useDealsAndDiscountContext } from "../DealsAndDiscountContext";
import ChangeProductIcon from "@assets/images/frontend/change-product.png";
import { useState } from 'react';

interface IPromotionDetailProps {
  elementID: string;
  refBox: any;
}

export default function PromotionDetail(
  props: React.PropsWithChildren<IPromotionDetailProps>
): React.FunctionComponentElement<IPromotionDetailProps> {
  const { elementID, refBox } = props;
  const thisElementID = elementID + '-promotionDetail';

  // file
  const acceptedFiles = [FileMIME.JPG, FileMIME.JPEG, FileMIME.PNG];
  const maxFileMBSize = 5;
  const fileAccept = '.jpg, .jpeg, .png';

  const maxDescription = 500;
  const maxPromotionName = 100;
  const { t } = useTranslation();
  const { push } = useModalStack();
  const { setProductItemsInPromotion, promotionDetail } = useDealsAndDiscountContext();
  const [isOnGoing, setIsOnGoing] = useState<boolean>(false);
  const form = useFormContext<DealsAndDiscount>();
  const {
    control,
    formState: { errors, dirtyFields },
    register,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
    watch
  } = form;

  useEffect(() => {
    if (getValues('promotionDetail.isNoEndDate')) {
      setValue('promotionDetail.endDate', undefined);
      setValue('promotionDetail.endTime', '00:00');
      trigger(['promotionDetail.endDate']);
      setProductItemsInPromotion([]);
    } else if (!getValues('promotionDetail.endDate')!) {
      let startDateTime = new Date(Number(getValues('promotionDetail.startDate'))!);
      let nextDateTime = new Date(Number(getValues('promotionDetail.startDate'))!);
      nextDateTime.setDate(startDateTime.getDate() + 1);
      setValue('promotionDetail.endDate', CommonHelper.getTimestampFormUnix(dayjs(nextDateTime).unix()).toString());
    }
  }, [watch('promotionDetail.isNoEndDate')]);

  useEffect(() => {
    if (watch('promotionDetail.promotionComingStatus')) {
      setIsOnGoing(watch('promotionDetail.promotionComingStatus') === DealsAndDiscountListStatusEnum.ONGOING)
    }
  }, [watch('promotionDetail.promotionComingStatus')])

  useEffect(() => {
    if (!isOnGoing) {
      validateDate();
    }
  }, [watch('promotionDetail.startDate'),
  watch('promotionDetail.endDate'),
  watch('promotionDetail.startTime'),
  watch('promotionDetail.endTime'), isOnGoing])

  const validateDate = () => {
    if (watch('promotionDetail.startDate') && watch('promotionDetail.endDate')) {
      setProductItemsInPromotion([]);
      let startDate = new Date(Number(getValues('promotionDetail.startDate'))!);
      if (getValues('promotionDetail.startTime')) {
        let time = getValues('promotionDetail.startTime')?.split(':');
        startDate.setHours(Number(time![0]!));
        startDate.setMinutes(Number(time![1]!));
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
      }

      let expireDate = new Date(Number(getValues('promotionDetail.endDate'))!);
      if (getValues('promotionDetail.endTime')) {
        let time = getValues('promotionDetail.endTime')?.split(':');
        expireDate.setHours(Number(time![0]!));
        expireDate.setMinutes(Number(time![1]!));
      }
      if (expireDate < startDate) {
        setValue('promotionDetail.invalidDate', true, { shouldDirty: true });
      } else {
        setValue('promotionDetail.invalidDate', false, { shouldDirty: true })
      }
      let newDate = new Date();
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      if (startDate < newDate) {
        setValue('promotionDetail.invalidTime', true, { shouldDirty: true });
      } else {
        setValue('promotionDetail.invalidTime', false, { shouldDirty: true });
      }
      trigger(['promotionDetail.startDate', 'promotionDetail.endDate']);
    }
  }

  const mappingFileDetailValue = (data?: PromotionImage): IFileDetail[] => {
    return data
      ? [{
        ...data,
        originalFileName: data.originalFileName ? data.originalFileName : null,
        status: FileStatus.UPLOADED
      }]
      : [];
  };

  const handleClickShowExampleBanner = () => {
    push(ImageAlertModal, {
      elementID: 'banner-show',
      data: {
        title: t('DealsAndDiscount.Label.WhereAreDisplay'),
        image: previewBanner,
        modalSize: ModalSize.LG
      }
    });
  }

  const handleChangeDiscountType = (value?: string) => {
    if (value && value != getValues("promotionDetail.discountType")) {
      if (getValues('promotionProduct').length > 0) {
        push(ConfirmButtonCenterModal,
          {
            elementID: thisElementID,
            icon: PromotionIcon,
            titleBody: t('DealsAndDiscount.Modal.ChangeDiscountType.Title'),
            description1: t('DealsAndDiscount.Modal.ChangeDiscountType.Description1'),
            description2: t('DealsAndDiscount.Modal.ChangeDiscountType.Description2'),
            btnConfirmText: t('Common.Button.YesChange'),
            btnCancelText: t('Common.Button.Cancel'),
            onSubmit: (() => {
              setValue('promotionDetail.discountType', value)
            })
          });
      } else {
        setValue('promotionDetail.discountType', value)
      }
    }
  }

  return (<>
    <div ref={refBox} id={`div-${thisElementID}-header`} className="card">
      <div className="card-header">{t('DealsAndDiscount.Title.PromotionDetails')}</div>
      <div className="card-body">
        {/* name */}
        <FormGroup>
          <Row>
            <Col>
              <label>{t('DealsAndDiscount.Label.PromotionNameLocal')}<span className="required">*</span></label>
              <div className="input-limit">
                <input {...register('promotionDetail.promotionNameLocal')}
                  id={`txt-${thisElementID}-promotionNameLocal`}
                  type="text"
                  placeholder={t('DealsAndDiscount.Placeholder.PromotionName')}
                  className={`form-control${classNames({ ' is-invalid': errors?.promotionDetail?.promotionNameLocal })}`}
                  maxLength={maxPromotionName} />
                <small className="limit">{watch('promotionDetail.promotionNameLocal')?.length}/{maxPromotionName}</small>
              </div>
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.promotionNameLocal',
                `txt-${thisElementID}-promotionNameLocal-error`
              )}
            </Col>
            <Col>
              <label>{t('DealsAndDiscount.Label.PromotionNameEN')}<span className="required">*</span></label>
              <div className="input-limit">
                <input {...register('promotionDetail.promotionNameEN')}
                  id={`txt-${thisElementID}-promotionNameLocal`}
                  type="text"
                  placeholder={t('DealsAndDiscount.Placeholder.PromotionName')}
                  className={`form-control${classNames({ ' is-invalid': errors?.promotionDetail?.promotionNameEN })}`}
                  maxLength={maxPromotionName} />
                <small className="limit">{watch('promotionDetail.promotionNameEN')?.length}/{maxPromotionName}</small>
              </div>
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.promotionNameEN',
                `txt-${thisElementID}-promotionNameEN-error`
              )}
            </Col>
          </Row>
        </FormGroup>
        {/* description */}
        <FormGroup>
          <Row>
            <Col>
              <label>{t('DealsAndDiscount.Label.DescriptionLocal')}</label>
              <textarea {...register('promotionDetail.descriptionLocal')} placeholder={t('DealsAndDiscount.Placeholder.TypeDescription')} rows={3}
                className={`form-control${classNames({ ' is-invalid': errors?.promotionDetail?.descriptionLocal })}`} maxLength={maxDescription} />
              <small className="float-right mt-8px">{watch('promotionDetail.descriptionLocal')?.length}/{maxDescription}</small>
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.descriptionLocal',
                `txt-${thisElementID}-descriptionLocal-error`
              )}
            </Col>
            <Col>
              <label>{t('DealsAndDiscount.Label.DescriptionEN')}</label>
              <textarea {...register('promotionDetail.descriptionEN')} placeholder={t('DealsAndDiscount.Placeholder.TypeDescription')} rows={3}
                className={`form-control${classNames({ ' is-invalid': errors?.promotionDetail?.descriptionEN })}`} maxLength={maxDescription} />
              <small className="float-right mt-8px">{watch('promotionDetail.descriptionEN')?.length}/{maxDescription}</small>
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.descriptionEN',
                `txt-${thisElementID}-descriptionEN-error`
              )}
            </Col>
          </Row>
        </FormGroup>
        {/* image */}
        <FormGroup className="mb-4">
          <label>{t('DealsAndDiscount.Label.Banner')}
            <a onClick={() => handleClickShowExampleBanner()} className="label-link">{t('DealsAndDiscount.Label.WhereAreDisplay')}</a>
            <small className="mt-1" style={{ whiteSpace: 'pre-line' }}>{t('DealsAndDiscount.Label.FileCondition', {
              fileType: fileAccept,
              fileMaxSize: maxFileMBSize,
            })}</small>
          </label>
          <Row>
            <Col>
              <Controller
                control={control}
                name="promotionDetail.imageLocal"
                render={({ field }) => (
                  <>
                    <UploadImage
                      elementID={thisElementID + '-imageGlobal'}
                      label={t('CompanyProfile.Label.Images')}
                      fileDescription={t('CompanyProfile.Label.ImageDescription')}
                      isRequired={false}
                      acceptanceExtension={acceptedFiles}
                      acceptancefileType={fileAccept}
                      confirmDeleteImag={true}
                      maxFiles={{
                        max: 1,
                        modalData: {
                          data: {
                            title: t('ProductManagement.Modal.UploadFail.Title'),
                            description: t('ProductManagement.Modal.UploadFail.Description', {
                              count: 1,
                            }),
                            icon: UploadFileIcon,
                          },
                          pageElementID: `${thisElementID}-imageGlobal-uploadFailed`,
                          onSubmit: () => { },
                        },
                      }}
                      existFiles={mappingFileDetailValue(field.value)}
                      maxFileMBSize={maxFileMBSize}
                      onChange={(data) => {
                        if (data) {
                          let value = data[0] as PromotionImage;
                          setValue('promotionDetail.imageLocal', value, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }
                      }}
                      onError={() => {
                        setValue('promotionDetail.imageLocal', undefined);
                      }}
                    />
                  </>
                )}
              />
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.imageLocal',
                `txt-${elementID}-imageLocal-error`
              )}
              <div className="mt-8px text-center sm-mb-2"><small>{t('DealsAndDiscount.Label.ImageThai')}</small></div>
            </Col>
            <Col>
              <Controller
                control={control}
                name="promotionDetail.imageGlobal"
                render={({ field }) => (
                  <>
                    <UploadImage
                      elementID={thisElementID + '-imageGlobal'}
                      label={t('CompanyProfile.Label.Images')}
                      fileDescription={t('CompanyProfile.Label.ImageDescription')}
                      isRequired={false}
                      acceptanceExtension={acceptedFiles}
                      acceptancefileType={fileAccept}
                      confirmDeleteImag={true}
                      maxFiles={{
                        max: 1,
                        modalData: {
                          data: {
                            title: t('ProductManagement.Modal.UploadFail.Title'),
                            description: t('ProductManagement.Modal.UploadFail.Description', {
                              count: 1,
                            }),
                            icon: UploadFileIcon,
                          },
                          pageElementID: `${thisElementID}-imageGlobal-uploadFailed`,
                          onSubmit: () => { },
                        },
                      }}
                      existFiles={mappingFileDetailValue(field.value)}
                      maxFileMBSize={maxFileMBSize}
                      onChange={(data) => {
                        if (data) {
                          let value = data[0] as PromotionImage;
                          setValue('promotionDetail.imageGlobal', value, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }
                      }}
                      onError={() => setValue('promotionDetail.imageGlobal', undefined)}
                    />
                  </>
                )}
              />
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.imageGlobal',
                `txt-${elementID}-imageGlobal-error`
              )}
              <div className="mt-8px text-center sm-mb-2"><small>{t('DealsAndDiscount.Label.ImageEN')}</small></div>
            </Col>
          </Row>
        </FormGroup>
        {/* period */}
        <div className="title">{t('DealsAndDiscount.Label.PromotionPeriod')}</div>
        <FormGroup className="mb-4">
          <Row>
            <Col className="col-lg-4 col-sm-12">
              <label>{t('DealsAndDiscount.Label.StartDate')}<span className="required">*</span></label>
              <div className="d-flex relative">
                <div className="input-group icon input-date mr-8px" style={{ position: 'relative', zIndex: 100 }}>
                  <Controller
                    control={control}
                    name="promotionDetail.startDate"
                    render={({ field }) => (
                      <DatePicker
                        onKeyDown={e => e.preventDefault()}
                        dateFormat="dd/MM/yyyy"
                        selected={(field.value) ? new Date(Number(field.value)) : undefined}
                        onChange={(val: Date) => {
                          if (getValues('promotionProduct').length > 0) {
                            push(ConfirmButtonCenterModal,
                              {
                                elementID: elementID,
                                icon: ChangeProductIcon,
                                titleBody: t('DealsAndDiscount.Modal.ChangePeriod.Title'),
                                description1: t('DealsAndDiscount.Modal.ChangePeriod.Description1'),
                                description2: t('DealsAndDiscount.Modal.ChangePeriod.Description2'),
                                btnConfirmText: t('Common.Button.YesRemove'),
                                btnCancelText: t('Common.Button.Cancel'),
                                onSubmit: (() => {
                                  if (val) {
                                    field.onChange(CommonHelper.getTimestampFormUnix(dayjs(val).unix()));
                                    clearErrors('promotionDetail.startDate');
                                  } else {
                                    field.onChange('');
                                  }
                                })
                              });
                          } else {
                            field.onChange(CommonHelper.getTimestampFormUnix(dayjs(val).unix()));
                            clearErrors('promotionDetail.startDate');
                          }
                        }}
                        onBlur={({ target: { value } }) => {
                          if (!value) {
                            setError('promotionDetail.startDate', {
                              type: 'require',
                              message: PromotionErrors.START_DATE_IS_REQUIRED,
                            })
                          }
                        }}
                        selectsStart
                        startDate={(field.value) ? new Date(field.value) : undefined}
                        endDate={(getValues('promotionDetail.endDate')) ? new Date(Number(getValues('promotionDetail.endDate'))!) : undefined}
                        showYearDropdown
                        minDate={new Date()}
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                        className={(errors?.promotionDetail?.startDate) ? 'form-control is-invalid' : 'form-control'}
                        placeholderText={t('CompanyProfile.Label.DDMMYYYY')}
                        disabled={isOnGoing}
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="promotionDetail.startTime"
                  render={({ field }) => (
                    <DropdownTimepicker
                      elementID={thisElementID + 'startTime'}
                      selected={field.value!}
                      invalid={!!(errors?.promotionDetail?.startTime || errors?.promotionDetail?.startDate)}
                      disable={isOnGoing}
                      onChange={(val: string) => {
                        if (getValues('promotionProduct').length > 0) {
                          push(ConfirmButtonCenterModal,
                            {
                              elementID: elementID,
                              icon: ChangeProductIcon,
                              titleBody: t('DealsAndDiscount.Modal.ChangePeriod.Title'),
                              description1: t('DealsAndDiscount.Modal.ChangePeriod.Description1'),
                              description2: t('DealsAndDiscount.Modal.ChangePeriod.Description2'),
                              btnConfirmText: t('Common.Button.YesRemove'),
                              btnCancelText: t('Common.Button.Cancel'),
                              onSubmit: (() => {
                                field.onChange(val);
                              })
                            });
                        } else {
                          field.onChange(val);
                        }
                      }}
                    />
                  )}
                />
              </div>
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.startDate',
                `txt-${thisElementID}-startDate-error`
              )}
            </Col>
            <Col className="col-lg-4 col-sm-12">
              <label>{t('DealsAndDiscount.Label.EndDate')}<span className="required">*</span></label>
              <div className="d-flex relative">
                <div className="input-group icon input-date mr-8px" style={{ position: 'relative', zIndex: 100 }}>
                  <Controller
                    control={control}
                    name="promotionDetail.endDate"
                    render={({ field }) => (
                      <DatePicker
                        onKeyDown={e => e.preventDefault()}
                        dateFormat="dd/MM/yyyy"
                        selected={(field.value) ? new Date(Number(field.value)) : undefined}
                        onChange={(val: Date) => {
                          if (getValues('promotionProduct').length > 0) {
                            push(ConfirmButtonCenterModal,
                              {
                                elementID: elementID,
                                icon: ChangeProductIcon,
                                titleBody: t('DealsAndDiscount.Modal.ChangePeriod.Title'),
                                description1: t('DealsAndDiscount.Modal.ChangePeriod.Description1'),
                                description2: t('DealsAndDiscount.Modal.ChangePeriod.Description2'),
                                btnConfirmText: t('Common.Button.YesRemove'),
                                btnCancelText: t('Common.Button.Cancel'),
                                onSubmit: (() => {
                                  if (val) {
                                    field.onChange(CommonHelper.getTimestampFormUnix(dayjs(val).unix()));
                                    clearErrors('promotionDetail.endDate');
                                  } else {
                                    field.onChange('');
                                  }
                                })
                              });
                          } else {
                            field.onChange(CommonHelper.getTimestampFormUnix(dayjs(val).unix()));
                            clearErrors('promotionDetail.endDate');
                          }
                        }}
                        onBlur={({ target: { value } }) => {
                          if (!value) {
                            setError('promotionDetail.endDate', {
                              type: 'require',
                              message: PromotionErrors.END_DATE_IS_REQUIRED,
                            })
                          }
                        }}
                        selectsEnd
                        startDate={(getValues('promotionDetail.startDate')) ? new Date(Number(getValues('promotionDetail.startDate'))!) : undefined}
                        endDate={(field.value) ? new Date(field.value) : undefined}
                        minDate={(getValues('promotionDetail.startDate')) ? new Date(Number(getValues('promotionDetail.startDate'))!) : undefined}
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                        disabled={getValues('promotionDetail.isNoEndDate') || isOnGoing}
                        className={(errors?.promotionDetail?.endDate) ? 'form-control is-invalid' : 'form-control'}
                        placeholderText={t('CompanyProfile.Label.DDMMYYYY')}
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="promotionDetail.endTime"
                  render={({ field }) => (
                    <DropdownTimepicker
                      elementID={thisElementID + 'endTime'}
                      selected={field.value!}
                      disable={getValues('promotionDetail.isNoEndDate') || isOnGoing}
                      invalid={!!(errors?.promotionDetail?.endTime || errors?.promotionDetail?.endDate)}
                      onChange={(val: string) => {
                        if (getValues('promotionProduct').length > 0) {
                          push(ConfirmButtonCenterModal,
                            {
                              elementID: elementID,
                              icon: ChangeProductIcon,
                              titleBody: t('DealsAndDiscount.Modal.ChangePeriod.Title'),
                              description1: t('DealsAndDiscount.Modal.ChangePeriod.Description1'),
                              description2: t('DealsAndDiscount.Modal.ChangePeriod.Description2'),
                              btnConfirmText: t('Common.Button.YesRemove'),
                              btnCancelText: t('Common.Button.Cancel'),
                              onSubmit: (() => {
                                field.onChange(val);
                              })
                            });
                        } else {
                          field.onChange(val);
                        }
                      }}
                    />
                  )}
                />
              </div>
              {CommonHelper.renderFieldError<DealsAndDiscount>(
                t,
                errors,
                'promotionDetail.endDate',
                `txt-${thisElementID}-endDate-error`
              )}
            </Col>
            <Col className="col-lg-4 col-sm-12">
              <label>&nbsp;</label>
              <label className="form-check">
                <Controller
                  control={control}
                  name="promotionDetail.isNoEndDate"
                  render={({ field }) => (
                    <input
                      id={`txt-${thisElementID}-isNoEndDate`}
                      type="checkbox"
                      className={`form-check-input${classNames({ ' is-invalid': errors?.promotionDetail?.isNoEndDate })}`}
                      onChange={(val: any) => {
                        const value = val.target.checked;
                        if (getValues('promotionProduct').length > 0) {
                          push(ConfirmButtonCenterModal,
                            {
                              elementID: elementID,
                              icon: ChangeProductIcon,
                              titleBody: t('DealsAndDiscount.Modal.ChangePeriod.Title'),
                              description1: t('DealsAndDiscount.Modal.ChangePeriod.Description1'),
                              description2: t('DealsAndDiscount.Modal.ChangePeriod.Description2'),
                              btnConfirmText: t('Common.Button.YesRemove'),
                              btnCancelText: t('Common.Button.Cancel'),
                              onSubmit: (() => {
                                field.onChange(value);
                              })
                            });
                        } else {
                          field.onChange(value);
                        }
                      }}
                      checked={field.value}
                      disabled={isOnGoing}
                    />
                  )}
                />

                <span className="form-check-label">{t('DealsAndDiscount.Label.NoEndDate')}</span>
                {CommonHelper.renderFieldError<DealsAndDiscount>(
                  t,
                  errors,
                  'promotionDetail.isNoEndDate',
                  `txt-${thisElementID}-isNoEndDate-error`
                )}
              </label>
            </Col>
          </Row>
        </FormGroup>
        {/* discount setting */}
        <div className="title">{t('DealsAndDiscount.Label.DiscountSetting')}</div>
        <FormGroup>
          <label>{t('DealsAndDiscount.Label.Type')}<span className="required">*</span></label>
          <Row>
            <Col md={3} sm={6}>
              <label className="form-check inline-check">
                <FormCheckInput
                  type="radio"
                  checked={!!(getValues("promotionDetail.discountType") == DiscountTypeOptionEnum.percent)}
                  onChange={(val: any) => {
                    handleChangeDiscountType(val?.target?.value!);
                  }}
                  value={
                    DiscountTypeOptionEnum[DiscountTypeOptionEnum.percent]
                  }
                  id={`rdb-${thisElementID}-percentageDiscount`}
                />
                <span className="form-check-label">
                  {t('DealsAndDiscount.Label.PercentageDiscount')}
                </span>
              </label>
            </Col>
            <Col md={4} sm={6}>
              <label className="form-check inline-check">
                <FormCheckInput
                  type="radio"
                  checked={!!(getValues("promotionDetail.discountType") == DiscountTypeOptionEnum.value)}
                  onChange={(val: any) => {
                    handleChangeDiscountType(val?.target?.value!);
                  }}
                  value={DiscountTypeOptionEnum[DiscountTypeOptionEnum.value]}
                  id={`rdb-${thisElementID}-moneyValue`}
                />
                <span className="form-check-label">
                  {t('DealsAndDiscount.Label.MoneyValue')}
                </span>
              </label>
            </Col>
          </Row>
          <input type="hidden" {...register('promotionDetail.discountType')} />
          <input type="hidden" {...register('promotionDetail.promotionComingStatus')} />

        </FormGroup>
      </div>
    </div>
  </>);
}
