import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalStackPropsBase } from './ModalStackProvider';

interface IConfirmButtonCenterModalProps extends ModalStackPropsBase<unknown, unknown> {
  elementID?: string;
  icon?: string;
  titleBody?: string;
  description1?: string;
  description2?: string;
  btnCancelText?: string;
  btnConfirmText?: string;
  onSubmit: () => void;
}

export function ConfirmButtonCenterModal(
  props: React.PropsWithChildren<IConfirmButtonCenterModalProps>
): React.FunctionComponentElement<IConfirmButtonCenterModalProps> {
  const { titleBody, description1, description2, btnCancelText, btnConfirmText, icon, elementID, onClose, onSubmit, show } = props;

  const { t } = useTranslation();

  return (
    <>
      <Modal show={show} dialogClassName="modal-dialog modal-540" scrollable>
        <Modal.Body id={`${elementID}-tatle-detail`} className="text-center">
          <img src={icon} className="card-icon" />
          {titleBody && <div className="title">{titleBody}</div>}
          <p>{description1}
            {description2 && <><br />{description2}</>}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            {btnCancelText &&
              <Button
                id={`btn-${elementID}-cancel`}
                variant="outline"
                type="button"
                onClick={() => onClose && onClose(undefined)}
              >
                {btnCancelText ? btnCancelText : t('Common.Button.Cancel')}
              </Button>
            }
            <Button
              id={`btn-${elementID}-submit`}
              variant="primary"
              type="button"
              onClick={onSubmit}
            >
              {btnConfirmText ? btnConfirmText : t('Common.Button.Submit')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
