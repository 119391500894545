import * as yup from 'yup';
import { CountryCode } from '@utils/constants/CountryConstant';
import { AccountDetail } from '@models/AccountDetail';
import { JobTitleId } from '@utils/constants/CompanyConstant';
import { REGEX } from '@utils/constants/CommonConstants';

export class AccountDetailErrors {
  // require
  static readonly JOB_TITLE_IS_REQUIRED = 'Congratulations.ErrorMessage.PleaseSelectYourJobTitle';
  static readonly OTHER_JOB_TITLE_NAME_IS_REQUIRED = 'Congratulations.ErrorMessage.PleaseEnterYourJobTitle';
  static readonly ADDRESS_EN_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredAddressInEnglish';
  static readonly ADDRESS_LOCAL_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredAddressInLocal';
  static readonly COUNTRY_CODE_IS_REQUIRED = 'CompanyProfile.Error.Country';
  static readonly DISTRICT_EN_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredEnterDistrict';
  static readonly DISTRICT_LOCAL_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredEnterDistrict';
  static readonly DISTRICT_EN_TH_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredSelectDistrict';
  static readonly DISTRICT_LOCAL_TH_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredSelectDistrict';
  static readonly SUBDISTRICT_EN_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredEnterSubDistrict';
  static readonly SUBDISTRICT_LOCAL_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredEnterSubDistrict';
  static readonly SUBDISTRICT_EN_TH_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredSelectSubDistrict';
  static readonly SUBDISTRICT_LOCAL_TH_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredSelectSubDistrict';
  static readonly PROVINCE_EN_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredEnterProvince';
  static readonly PROVINCE_LOCAL_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredEnterProvince';
  static readonly PROVINCE_EN_TH_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredSelectProvince';
  static readonly PROVINCE_LOCAL_TH_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredSelectProvince';
  static readonly POSTALCODE_IS_REQUIRED = 'RegisterService.ErrorMessage.RequiredPostalCode';

  // max length
  static readonly OTHER_JOB_TITLE_NAME_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.OtherJobTitleMaxLength';
  static readonly ADDRESS_EN_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.AddressEnMaxLength';
  static readonly ADDRESS_LOCAL_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.AddressLocalMaxLength';
  static readonly DISTRICT_EN_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.DistrictEnMaxLength';
  static readonly DISTRICT_LOCAL_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.DistrictLocalMaxLength';
  static readonly SUBDISTRICT_EN_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.SubDistrictEnMaxLength';
  static readonly SUBDISTRICT_LOCAL_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.SubDistrictLocalMaxLength';
  static readonly PROVINCE_EN_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.ProvinceEnMaxLength';
  static readonly PROVINCE_LOCAL_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.ProvinceLocalMaxLength';
  static readonly POSTALCODE_TH_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.PostalCodeTHMaxLength';
  static readonly POSTALCODE_IS_MAX_LENGTH = 'AccountDetails.ErrorMessage.PostalCodeMaxLength';
  // pattern
  static readonly POSTALCODE_TH_PATTERN = 'AccountDetails.ErrorMessage.PostalCodeTHPattern';
}

export const Validate = {
  otherJobTitleNameMaxLength: 100,
  addressEnMaxLength: 255,
  addressLocalMaxLength: 255,
  districtEnMaxLength: 255,
  districtLocalMaxLength: 255,
  subDistrictEnMaxLength: 255,
  subDistrictLocalMaxLength: 255,
  provinceEnMaxLength: 255,
  provinceLocalMaxLength: 255,
  postalCodeTHMaxLength: 5,
  postalCodeMaxLength: 50,
  departmentMaxLength: 255
};

export const AccountDetailsSchema = (
): yup.SchemaOf<AccountDetail> => {
  return yup
    .object({
      jobTitleId: yup
        .string()
        .required(AccountDetailErrors.JOB_TITLE_IS_REQUIRED),
      otherJobTitleName: yup
        .string()
        .optional()
        .when('jobTitleId', {
          is: JobTitleId.OTHER,
          then: yup
            .string()
            .required(AccountDetailErrors.OTHER_JOB_TITLE_NAME_IS_REQUIRED)
        })
        .max(Validate.otherJobTitleNameMaxLength, AccountDetailErrors.OTHER_JOB_TITLE_NAME_IS_MAX_LENGTH),
      addressEn: yup
        .string()
        .required(AccountDetailErrors.ADDRESS_EN_IS_REQUIRED)
        .max(Validate.addressEnMaxLength, AccountDetailErrors.ADDRESS_EN_IS_MAX_LENGTH),
      addressLocal: yup
        .string()
        .required(AccountDetailErrors.ADDRESS_LOCAL_IS_REQUIRED)
        .max(Validate.addressLocalMaxLength, AccountDetailErrors.ADDRESS_LOCAL_IS_MAX_LENGTH),
      countryCode: yup
        .string()
        .required(AccountDetailErrors.COUNTRY_CODE_IS_REQUIRED),
      districtEn: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.DISTRICT_EN_TH_IS_REQUIRED)
        })
        .required(AccountDetailErrors.DISTRICT_EN_IS_REQUIRED)
        .max(Validate.districtEnMaxLength, AccountDetailErrors.DISTRICT_EN_IS_MAX_LENGTH),
      districtLocal: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.DISTRICT_LOCAL_TH_IS_REQUIRED)
        })
        .required(AccountDetailErrors.DISTRICT_LOCAL_IS_REQUIRED)
        .max(Validate.districtLocalMaxLength, AccountDetailErrors.DISTRICT_LOCAL_IS_MAX_LENGTH),
      subDistrictEn: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.SUBDISTRICT_EN_TH_IS_REQUIRED)
        })
        .required(AccountDetailErrors.SUBDISTRICT_EN_IS_REQUIRED)
        .max(Validate.subDistrictEnMaxLength, AccountDetailErrors.SUBDISTRICT_EN_IS_MAX_LENGTH),
      subDistrictLocal: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.SUBDISTRICT_LOCAL_TH_IS_REQUIRED)
        })
        .required(AccountDetailErrors.SUBDISTRICT_LOCAL_IS_REQUIRED)
        .max(Validate.subDistrictLocalMaxLength, AccountDetailErrors.SUBDISTRICT_LOCAL_IS_MAX_LENGTH),
      provinceEn: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.PROVINCE_EN_TH_IS_REQUIRED)
        })
        .required(AccountDetailErrors.PROVINCE_EN_IS_REQUIRED)
        .max(Validate.provinceEnMaxLength, AccountDetailErrors.PROVINCE_EN_IS_MAX_LENGTH),
      provinceLocal: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.PROVINCE_LOCAL_TH_IS_REQUIRED)
        })
        .required(AccountDetailErrors.PROVINCE_LOCAL_IS_REQUIRED)
        .max(Validate.provinceLocalMaxLength, AccountDetailErrors.PROVINCE_LOCAL_IS_MAX_LENGTH),
      postalCode: yup
        .string()
        .when('countryCode', {
          is: CountryCode.TH,
          then: yup
            .string()
            .required(AccountDetailErrors.POSTALCODE_IS_REQUIRED)
            .max(Validate.postalCodeTHMaxLength, AccountDetailErrors.POSTALCODE_TH_IS_MAX_LENGTH)
            .matches(REGEX.POSTAL_CODE, AccountDetailErrors.POSTALCODE_TH_PATTERN),
        })
        .required(AccountDetailErrors.POSTALCODE_IS_REQUIRED)
        .max(Validate.postalCodeMaxLength, AccountDetailErrors.POSTALCODE_IS_MAX_LENGTH),
      countryNameCode: yup
        .string().optional(),
      department: yup
        .string().optional()
    })
    .default(null);
};