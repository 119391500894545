import { CityResponse } from './response/CityResponse';
import {
    HeaderTypes,
    HttpClientHelper,
    PTVNClient
} from "@ptvn-react/authentication";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";
import APIConstant from "../utils/constants/APIConstant";
import { PTVNResponse } from "./response/BaseResponse";
import { CountryResponse } from "./response/CountryResponse";
import { ConfigResponse } from './response/ConfigResponse';
import { AppConfigRequest } from './request/AppConfigRequest';
import { TimerEnum } from '@utils/enum/CommonEnum';

export class CommonClient {

    public static getCountry(): Observable<CountryResponse[]> {
        const url = `${APIConstant.company.getCountries}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.get<CountryResponse[]>(
            url,
            options

        ).pipe(map((m) => m.data));
    }

    public static getProvinceOption(): Observable<CityResponse[]> {
        const url = `${APIConstant.company.getThaiProvinceOption}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        return PTVNClient.get<CityResponse[]>(
            url,
            options

        ).pipe(map((m) => m.data));
    }

    public static getCityInfo(stateNameInput: string): Observable<CityResponse[]> {
        const url = `${APIConstant.company.getCityInfo}`;
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
            HeaderTypes.SSO_TOKEN
        );
        const requestBody = { "stateName": stateNameInput };
        return PTVNClient.post<CityResponse[]>(
            url,
            requestBody,
            options

        ).pipe(map((m) => m.data));
    }

    public static getMaintenanceConfig(): Observable<AxiosResponse> {
        const url = `${APIConstant.config.getMaintenanceConfig}`;

        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
        return PTVNClient.get<AxiosResponse>(url, options).pipe(
            timeout(TimerEnum.THIRTY_SECONDS),
            map((m) => m)
        );
    }

    public static getAppConfig(request: AppConfigRequest): Observable<PTVNResponse<ConfigResponse[]>> {
        const url = `${APIConstant.config.getAppConfig}`;

        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
        return PTVNClient.post<PTVNResponse<ConfigResponse[]>>(
            url,
            request,
            options
        ).pipe(map((m) => m.data));
    }

    public static getAppConfigByName(name: string): Observable<PTVNResponse<ConfigResponse>> {
        const url = APIConstant.config.getAppConfigByName.replace("{name}", name);
        const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
        return PTVNClient.get<PTVNResponse<ConfigResponse>>(url, options).pipe(
            map((m) => m.data)
        );
    }

}
