import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const CompanyDetailFragmentDoc = gql`
  fragment CompanyDetail on Company {
    companyId
    companyNameLocal
    companyNameEn
    otherBusinessEntityName
    taxId
    taxFirstNameLocal
    taxLastNameLocal
    taxFirstNameEn
    taxLastNameEn
    isVatRegistration
    isRDVerify
    createdBy
    createdDate
    updatedBy
    updatedDate
    registeredCapital
    yearEstablished
    website
    country {
      masterCountryId
      countryName
      countryNameCode
      countryCode
      isEnglishOfficial
      phoneCode
      currencyCode
    }
    identityType {
      masterIdentityTypeId
      name
      description
    }
    businessEntity {
      masterBusinessEntityId
      businessEntityName
      businessEntityCode
      identityType {
        masterIdentityTypeId
        name
        description
      }
    }
    companyStatus {
      masterCompanyStatusId
      name
      description
    }
    branchList(branchIDs: $branchID) {
      branchId
      branchNumber
      branchNameLocal
      branchNameEn
      keywords
      isPTVNVerified
      displayNameLocal
      displayNameEN
      useDisplayName
      dunsNumber
      branchType {
        masterBranchTypeId
        masterBranchTypeName
      }
      branchStatus {
        masterBranchStatusId
      }
      addressRequestUpdate {
        addressRequestUpdateId
        addressId
        addressEn
        addressLocal
        country {
          masterCountryId
          countryName
          countryNameCode
          countryCode
          isEnglishOfficial
          phoneCode
        }
        subDistrictEn
        subDistrictLocal
        districtEn
        districtLocal
        provinceEn
        provinceLocal
        postalCode
        requestBy
        requestDate
        swwReqId
      }
      branchAddressList {
        addressId
        addressLocal
        addressEn
        provinceLocal
        provinceEn
        districtLocal
        districtEn
        subDistrictLocal
        subDistrictEn
        postalCode
        createdBy
        createdDate
        updatedBy
        updatedDate
        addressType {
          masterAddressTypeId
          name
          description
        }
        country {
          masterCountryId
          countryName
          countryNameCode
          countryCode
          isEnglishOfficial
          phoneCode
        }
      }
      categoryList {
        categoryId
        categoryLevelId
        businessType
      }
      contactList {
        contactId
        fullNameEn
        fullNameLocal
        email
        masterTitleName {
          masterTitleNameId
          nameTitleLocal
          nameTitleEN
        }
        masterJobTitle {
          masterJobTitleId
          jobTitleName
          jobTitleCode
        }
        otherJobTitle
        firstNameLocal
        lastNameLocal
        firstNameEN
        lastNameEN
        department
        contactPhoneNumberList {
          contactPhoneNumberId
          callingCode
          phoneNumber
          ext
          masterPhoneNumberType {
            masterPhoneNumberTypeId
            phoneNumberType
          }
        }
        masterContactType {
          masterContactTypeId
          name
          description
        }
      }
      companyServiceRequestList {
        masterInvitationCode {
          masterInvitationCodeId
          invitationCode
        }
        masterServiceType {
          masterServiceTypeId
          name
          description
        }
        documentList {
          uuid
          originalFileName
          mimeType
          uploadByNameLocal
          uploadByNameEn
          uploadByUserID
          uploadDate
          documentType {
            documentTypeId
            nameEn
            nameLocal
            masterInvitationCode {
              masterInvitationCodeId
              invitationCode
            }
          }
        }
        serviceStatus
        adminPOUserID
      }
      images {
        uuid
        seq
        fileType
        originalFileName
        uploadDate
        uploadByName
        uploadByUserID
        fileUrl
      }
      videos {
        videoId
        titleName
        link
      }
      brochures {
        brochureId
        brochureName
        file {
          uuid
          seq
          fileType
          originalFileName
          uploadDate
          uploadByName
          uploadByUserID
          fileUrl
        }
      }
      certificates {
        certificateId
        certificateName
        certificateById
        certificateByOtherName
        startDate
        expireDate
        files {
          uuid
          seq
          fileType
          originalFileName
          uploadDate
          uploadByName
          uploadByUserID
          fileUrl
        }
        description
      }
      totalEmployee
      totalAnnualRevenue
      netProfit
      totalAssets
      shareholdersEquity
      decisionMakers {
        fullName
        email
        phoneNumber
        countryCode
        ext
      }
      boardOfDirectors {
        fullName
      }
      customerReferences {
        projectOrCustomerName
        productSupplyToCustomer
        description
        countryId
        files {
          uuid
          seq
          fileType
          originalFileName
          uploadDate
          uploadByName
          uploadByUserID
          fileUrl
        }
      }
      companyDocumentList {
        section
        documentList {
          documentTypeId
          companyDocumentType {
            documentTypeId
            documentTypeName
            documentGroupName
            isVATRegistration
            countryType
            isVerified
            isPISApprove
            swwDocumentNameId
          }
          documentTypeName
          otherDocumentName
          requestStatus
          files {
            fileStatus
            fileType
            fileUrl
            isDisplay
            originalFileName
            uploadByName
            uploadByUserID
            uploadDate
            uuid
          }
        }
      }
      paymentTerm {
        masterPaymentTermID
        paymentTermCode
        paymentName
      }
    }
  }
`;
export const CompanyDetailAddNewBranchFragmentDoc = gql`
  fragment CompanyDetailAddNewBranch on Company {
    companyId
    companyNameLocal
    companyNameEn
    otherBusinessEntityName
    taxFirstNameLocal
    taxLastNameLocal
    taxFirstNameEn
    taxLastNameEn
    isVatRegistration
    identityType {
      masterIdentityTypeId
      name
      description
    }
    businessEntity {
      masterBusinessEntityId
      businessEntityName
      businessEntityCode
    }
    country {
      masterCountryId
      countryName
      countryNameCode
      countryCode
      isEnglishOfficial
      phoneCode
      currencyCode
    }
  }
`;
export const CompanyPaymentFragmentDoc = gql`
  fragment CompanyPayment on Company {
    branchList(branchIDs: $branchID) {
      branchId
      paymentTerm {
        masterPaymentTermID
        paymentTermCode
        paymentName
      }
    }
  }
`;
export const CompanyContactPhoneNumberFragmentDoc = gql`
  fragment CompanyContactPhoneNumber on CompanyContactPhoneNumber {
    callingCode
    contactPhoneNumberId
    ext
    phoneNumber
    masterPhoneNumberType {
      masterPhoneNumberTypeId
      phoneNumberType
    }
  }
`;
export const CompanyContactFragmentDoc = gql`
  fragment CompanyContact on CompanyContact {
    contactId
    department
    email
    firstNameEN
    firstNameLocal
    fullNameEn
    fullNameLocal
    lastNameEN
    lastNameLocal
    otherJobTitle
    masterContactType {
      description
      masterContactTypeId
      name
    }
    masterJobTitle {
      jobTitleCode
      jobTitleName
      masterJobTitleId
    }
    masterTitleName {
      masterTitleNameId
      nameTitleEN
      nameTitleLocal
    }
    contactPhoneNumberList {
      ...CompanyContactPhoneNumber
    }
  }
  ${CompanyContactPhoneNumberFragmentDoc}
`;
export const BecomeABuyerFragmentDoc = gql`
  fragment BecomeABuyer on BecomeABuyer {
    isBecomeABuyer
    contact {
      ...CompanyContact
    }
  }
  ${CompanyContactFragmentDoc}
`;
export const MasterCountryFragmentDoc = gql`
  fragment MasterCountry on MasterCountry {
    countryCode
    countryName
    countryNameCode
    currencyCode
    isEnglishOfficial
    masterCountryId
    phoneCode
  }
`;
export const CompanyRequestUpdateAddressTypeFragmentDoc = gql`
  fragment CompanyRequestUpdateAddressType on CompanyRequestUpdateAddressType {
    addressEn
    addressLocal
    addressTypeId
    addressTypeName
    addressTypeNameCode
    districtEn
    districtLocal
    postalCode
    provinceEn
    provinceLocal
    subDistrictEn
    subDistrictLocal
    country {
      ...MasterCountry
    }
  }
  ${MasterCountryFragmentDoc}
`;
export const CompanyInformationFragmentDoc = gql`
  fragment CompanyInformation on CompanyInformation {
    branchNameEn
    branchNameLocal
    branchNumber
    companyNameEn
    companyNameLocal
    countryCode
    countryNameCode
    identityTypeCode
    shortName
    taxID
    registeredCapital
    currency
    yearEstablished
    numberOfEmployee
    taxFirstNameEn
    taxFirstNameLocal
    taxLastNameEn
    taxLastNameLocal
  }
`;
export const CompanyCategoryFragmentDoc = gql`
  fragment CompanyCategory on CompanyCategory {
    businessType
    categoryId
    categoryLevelId
  }
`;
export const RemarkItemFragmentDoc = gql`
  fragment RemarkItem on RemarkItem {
    section
    remark
  }
`;
export const AttachmentFragmentDoc = gql`
  fragment Attachment on Attachment {
    documentType
    fileName
    filePath
    uploadedBy
    uploadedDate
    extension
  }
`;
export const AdminPoUserInformationFragmentDoc = gql`
  fragment AdminPOUserInformation on AdminPOUserInformation {
    userID
  }
`;
export const CompanyRequestUpdateDetailFragmentDoc = gql`
  fragment CompanyRequestUpdateDetail on CompanyRequestUpdateDetail {
    approvedByEmail
    approvedByName
    approvedDate
    becomeABuyer {
      ...BecomeABuyer
    }
    invitationCode
    requestID
    requestStatus
    services
    address {
      ...CompanyRequestUpdateAddressType
    }
    billingContact {
      ...CompanyContact
    }
    companyContact {
      ...CompanyContact
    }
    companyInformation {
      ...CompanyInformation
    }
    productKeyword
    productServiceCategory {
      ...CompanyCategory
    }
    remarkList {
      ...RemarkItem
    }
    attachments {
      ...Attachment
    }
    adminPO {
      ...AdminPOUserInformation
    }
  }
  ${BecomeABuyerFragmentDoc}
  ${CompanyRequestUpdateAddressTypeFragmentDoc}
  ${CompanyContactFragmentDoc}
  ${CompanyInformationFragmentDoc}
  ${CompanyCategoryFragmentDoc}
  ${RemarkItemFragmentDoc}
  ${AttachmentFragmentDoc}
  ${AdminPoUserInformationFragmentDoc}
`;
export const ProductGeneralDetailsSectionFragmentDoc = gql`
  fragment ProductGeneralDetailsSection on Product {
    type
    nameEn
    nameLocal
    brand
    productKeywords
    companyProductList {
      companyID
      branchID
      order
    }
    productUOM {
      uomID
      commerceDesc
      descriptionTH
    }
    productSize {
      width
      length
      height
      uom {
        uomID
        commerceDesc
        descriptionTH
      }
    }
    productWeight {
      weight
      uom {
        uomID
        commerceDesc
        descriptionTH
      }
    }
    productCategory {
      categoryID
      nameEN
      nameLocal
      categoryLevel
      parentCategoryID
    }
  }
`;
export const ProductFileItemFragmentDoc = gql`
  fragment ProductFileItem on ProductFile {
    fileUrl
    originalFileUrl
    order
    originalFileName
    uploadByUserName: uploadByName
    uploadByUserID
    uploadDate
    uuid
  }
`;
export const ProductDescriptionsFragmentDoc = gql`
  fragment ProductDescriptions on Product {
    productFileList {
      ...ProductFileItem
    }
    productDescription {
      description
    }
    productDescriptionFileList {
      uuid
      extension
    }
  }
  ${ProductFileItemFragmentDoc}
`;
export const VariantsPricingFragmentDoc = gql`
  fragment VariantsPricing on Product {
    productOption {
      productRefNumber
      minQuantity
      maxQuantity
      pricingType
      unitPrice
      vat
      currency {
        currencyID
        currencyCode
      }
    }
    productUOM {
      uomID
      commerceDesc
      descriptionTH
    }
  }
`;
export const PromotionFragmentDoc = gql`
  fragment Promotion on PromotionDetail {
    promotionID
    promotionNameEN
    promotionNameLocal
    status
    updateDate
    createDate
  }
`;
export const PromotionProductItemFragmentDoc = gql`
  fragment PromotionProductItem on PromotionProductItem {
    promotion {
      ...Promotion
    }
    product {
      productID
    }
  }
  ${PromotionFragmentDoc}
`;
export const PromotionProductItemListFragmentDoc = gql`
  fragment PromotionProductItemList on Product {
    promotionProductItemList {
      ...PromotionProductItem
    }
  }
  ${PromotionProductItemFragmentDoc}
`;
export const PaymentTermFragmentDoc = gql`
  fragment PaymentTerm on Product {
    paymentTerm {
      paymentID
      paymentName
    }
  }
`;
export const ProductAttributesFragmentDoc = gql`
  fragment ProductAttributes on Product {
    productAttributes {
      attributeID
      attributeName
      value
      verifiedValue
    }
  }
`;
export const ProductDetailFragmentDoc = gql`
  fragment ProductDetail on Product {
    productID
    sku
    runningNumber
    status
    draft
    createDate
    createByUserID
    createByName
    updateDate
    updateByUserID
    updateByName
    productStatus
    ...ProductGeneralDetailsSection @include(if: $withGeneralDetailsSection)
    ...ProductDescriptions @include(if: $withDescriptionsSection)
    ...VariantsPricing @include(if: $withVariantsPricingSection)
    productHistoryList @include(if: $withProductHistoryList) {
      productHistoryID
      action
      updateDate
      updateByUserID
      updateByName
    }
    ...PromotionProductItemList @include(if: $withPromotionProductItemList)
    isRequiredImproveAttribute
    ...PaymentTerm
    ...ProductAttributes
  }
  ${ProductGeneralDetailsSectionFragmentDoc}
  ${ProductDescriptionsFragmentDoc}
  ${VariantsPricingFragmentDoc}
  ${PromotionProductItemListFragmentDoc}
  ${PaymentTermFragmentDoc}
  ${ProductAttributesFragmentDoc}
`;
export const PromotionDetailFragmentDoc = gql`
  fragment PromotionDetail on PromotionDetail {
    promotionID
    promotionNameLocal
    promotionNameEN
    descriptionLocal
    descriptionEN
    startDate
    endDate
    noEndDate
    discountType
    promotionStatus
    draft
    remark
    createDate
    createByUserID
    createByName
    updateDate
    updateByUserID
    updateByName
    discountDetail
    status
    promotionFileList {
      promotionFileID
      language
      uuid
      originalFileName
      order
      uploadDate
      uploadByName
      uploadByUserID
      originalFileUrl
      fileUrl
      promotionFileType
    }
    promotionHistoryList {
      promotionHistoryID
      action
      updateDate
      updateByUserID
      updateByName
    }
    promotionProductItemList(input: $input) {
      items {
        product {
          productID
          sku
          runningNumber
          status
          draft
          type
          nameEn
          nameLocal
          brand
          createDate
          createByUserID
          createByName
          updateDate
          updateByUserID
          updateByName
          companyProductList {
            companyID
            branchID
            order
          }
          productFileList {
            uuid
            originalFileName
            order
            uploadDate
            uploadByUserName: uploadByName
            uploadByUserID
            originalFileUrl
            fileUrl
            extension
          }
          productDescription {
            description
          }
          productDescriptionFileList {
            uuid
            extension
          }
          productOption {
            productRefNumber
            minQuantity
            maxQuantity
            pricingType
            unitPrice
            vat
            currency {
              currencyID
              currencyCode
            }
            multipleUnitPrice {
              thb
              vnd
              usd
            }
          }
          productHistoryList {
            productHistoryID
            action
            updateDate
            updateByUserID
            updateByName
          }
          productStatus
          productUOM {
            uomID
            commerceDesc
            descriptionTH
          }
          productSize {
            width
            length
            height
            uom {
              uomID
              commerceDesc
              descriptionTH
            }
          }
          productWeight {
            weight
            uom {
              uomID
              commerceDesc
              descriptionTH
            }
          }
          productCategory {
            categoryID
            nameEN
            nameLocal
            categoryLevel
            parentCategoryID
          }
          productKeywords
        }
        promotionOverlap {
          promotionID
          promotionNameEN
          promotionNameLocal
        }
        value
      }
      page
      pageSize
      totalPage
      totalItem
    }
  }
`;
export const SupplierProductListCompanyBranchFragmentDoc = gql`
  fragment SupplierProductListCompanyBranch on ProductListCompanyBranch {
    branchID
  }
`;
export const SupplierProductListItemFragmentDoc = gql`
  fragment SupplierProductListItem on ProductListItem {
    productID
    name(lang: $lang)
    sku
    productImageUrl
    attributePercent
    isNoAttribute
    productUOM {
      name(lang: $lang)
      uomID
    }
    category {
      categoryID
      name(lang: $lang)
      level
    }
    company {
      companyID
      branch {
        ...SupplierProductListCompanyBranch
      }
    }
    lastUpdate
    status
    productOption {
      unitPrice
    }
    promotion {
      promotionNameEN
      promotionNameLocal
      status
    }
    isRequiredImproveAttribute
  }
  ${SupplierProductListCompanyBranchFragmentDoc}
`;
export const SupplierProductListPromotionCompanyBranchFragmentDoc = gql`
  fragment SupplierProductListPromotionCompanyBranch on ProductListCompanyBranch {
    branchID
  }
`;
export const SupplierProductListPromotionItemFragmentDoc = gql`
  fragment SupplierProductListPromotionItem on ProductListItem {
    productID
    name(lang: $lang)
    sku
    productImageUrl
    productUOM {
      name(lang: $lang)
      uomID
    }
    category {
      categoryID
      name(lang: $lang)
      level
    }
    company {
      companyID
      branch {
        ...SupplierProductListPromotionCompanyBranch
      }
    }
    lastUpdate
    status
    productOption {
      unitPrice
    }
    promotionOverlap(
      startDate: $startDateInput
      endDate: $endDateInput
      promotionID: $promotionID
    ) {
      promotionID
      promotionNameEN
      promotionNameLocal
    }
  }
  ${SupplierProductListPromotionCompanyBranchFragmentDoc}
`;
export const SupplierPromotionListItemFragmentDoc = gql`
  fragment SupplierPromotionListItem on PromotionListItem {
    company {
      companyID
      branch {
        ...SupplierProductListCompanyBranch
      }
      nameEN
      nameLocal
    }
    createDate
    createBy
    descriptionEN
    descriptionLocal
    discountDetail
    discountType
    endDate
    lastUpdate
    lastUpdateBy
    name(lang: $lang)
    nameEN
    nameLocal
    product {
      name(lang: $lang)
      nameEN
      nameLocal
      productID
      productImageUrl
      productUOM {
        name(lang: $lang)
        uomID
        nameEN
        nameLocal
      }
    }
    promotionID
    promotionStatus
    remark
    startDate
    status
  }
  ${SupplierProductListCompanyBranchFragmentDoc}
`;
export const UomItemFragmentDoc = gql`
  fragment UOMItem on UnitOfMeasurement {
    uomID
    buyerCode
    buyerDesc
    commerceOneCode
    commerceDesc
    specificType
    descriptionTH
    scUOMID
  }
`;
export const CompanyDetailDocument = gql`
  query CompanyDetail($companyID: Long!, $branchID: [Long!]) {
    company(companyID: $companyID) {
      ...CompanyDetail
    }
  }
  ${CompanyDetailFragmentDoc}
`;

/**
 * __useCompanyDetailQuery__
 *
 * To run a query within a React component, call `useCompanyDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDetailQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      branchID: // value for 'branchID'
 *   },
 * });
 */
export function useCompanyDetailQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CompanyDetailQuery, Types.CompanyDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CompanyDetailQuery, Types.CompanyDetailQueryVariables>(
    CompanyDetailDocument,
    options
  );
}
export function useCompanyDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CompanyDetailQuery,
    Types.CompanyDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CompanyDetailQuery, Types.CompanyDetailQueryVariables>(
    CompanyDetailDocument,
    options
  );
}
export type CompanyDetailQueryHookResult = ReturnType<typeof useCompanyDetailQuery>;
export type CompanyDetailLazyQueryHookResult = ReturnType<typeof useCompanyDetailLazyQuery>;
export type CompanyDetailQueryResult = Apollo.QueryResult<
  Types.CompanyDetailQuery,
  Types.CompanyDetailQueryVariables
>;
export const CompanyDetailAddNewBranchDocument = gql`
  query CompanyDetailAddNewBranch($companyID: Long!) {
    company(companyID: $companyID) {
      ...CompanyDetailAddNewBranch
    }
  }
  ${CompanyDetailAddNewBranchFragmentDoc}
`;

/**
 * __useCompanyDetailAddNewBranchQuery__
 *
 * To run a query within a React component, call `useCompanyDetailAddNewBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDetailAddNewBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDetailAddNewBranchQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyDetailAddNewBranchQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CompanyDetailAddNewBranchQuery,
    Types.CompanyDetailAddNewBranchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CompanyDetailAddNewBranchQuery,
    Types.CompanyDetailAddNewBranchQueryVariables
  >(CompanyDetailAddNewBranchDocument, options);
}
export function useCompanyDetailAddNewBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CompanyDetailAddNewBranchQuery,
    Types.CompanyDetailAddNewBranchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CompanyDetailAddNewBranchQuery,
    Types.CompanyDetailAddNewBranchQueryVariables
  >(CompanyDetailAddNewBranchDocument, options);
}
export type CompanyDetailAddNewBranchQueryHookResult = ReturnType<
  typeof useCompanyDetailAddNewBranchQuery
>;
export type CompanyDetailAddNewBranchLazyQueryHookResult = ReturnType<
  typeof useCompanyDetailAddNewBranchLazyQuery
>;
export type CompanyDetailAddNewBranchQueryResult = Apollo.QueryResult<
  Types.CompanyDetailAddNewBranchQuery,
  Types.CompanyDetailAddNewBranchQueryVariables
>;
export const CompanyPaymentDocument = gql`
  query CompanyPayment($companyID: Long!, $branchID: [Long!]) {
    company(companyID: $companyID) {
      ...CompanyPayment
    }
  }
  ${CompanyPaymentFragmentDoc}
`;

/**
 * __useCompanyPaymentQuery__
 *
 * To run a query within a React component, call `useCompanyPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPaymentQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      branchID: // value for 'branchID'
 *   },
 * });
 */
export function useCompanyPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CompanyPaymentQuery,
    Types.CompanyPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CompanyPaymentQuery, Types.CompanyPaymentQueryVariables>(
    CompanyPaymentDocument,
    options
  );
}
export function useCompanyPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CompanyPaymentQuery,
    Types.CompanyPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CompanyPaymentQuery, Types.CompanyPaymentQueryVariables>(
    CompanyPaymentDocument,
    options
  );
}
export type CompanyPaymentQueryHookResult = ReturnType<typeof useCompanyPaymentQuery>;
export type CompanyPaymentLazyQueryHookResult = ReturnType<typeof useCompanyPaymentLazyQuery>;
export type CompanyPaymentQueryResult = Apollo.QueryResult<
  Types.CompanyPaymentQuery,
  Types.CompanyPaymentQueryVariables
>;
export const CompanyRequestUpdateDetailDocument = gql`
  query CompanyRequestUpdateDetail($requestID: Int!) {
    companyRequestUpdateDetail(requestID: $requestID) {
      ...CompanyRequestUpdateDetail
    }
  }
  ${CompanyRequestUpdateDetailFragmentDoc}
`;

/**
 * __useCompanyRequestUpdateDetailQuery__
 *
 * To run a query within a React component, call `useCompanyRequestUpdateDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRequestUpdateDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRequestUpdateDetailQuery({
 *   variables: {
 *      requestID: // value for 'requestID'
 *   },
 * });
 */
export function useCompanyRequestUpdateDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CompanyRequestUpdateDetailQuery,
    Types.CompanyRequestUpdateDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CompanyRequestUpdateDetailQuery,
    Types.CompanyRequestUpdateDetailQueryVariables
  >(CompanyRequestUpdateDetailDocument, options);
}
export function useCompanyRequestUpdateDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CompanyRequestUpdateDetailQuery,
    Types.CompanyRequestUpdateDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CompanyRequestUpdateDetailQuery,
    Types.CompanyRequestUpdateDetailQueryVariables
  >(CompanyRequestUpdateDetailDocument, options);
}
export type CompanyRequestUpdateDetailQueryHookResult = ReturnType<
  typeof useCompanyRequestUpdateDetailQuery
>;
export type CompanyRequestUpdateDetailLazyQueryHookResult = ReturnType<
  typeof useCompanyRequestUpdateDetailLazyQuery
>;
export type CompanyRequestUpdateDetailQueryResult = Apollo.QueryResult<
  Types.CompanyRequestUpdateDetailQuery,
  Types.CompanyRequestUpdateDetailQueryVariables
>;
export const UpsertProductDocument = gql`
  mutation UpsertProduct($input: UpsertProductInput!) {
    upsertProduct(input: $input) {
      productID
    }
  }
`;
export type UpsertProductMutationFn = Apollo.MutationFunction<
  Types.UpsertProductMutation,
  Types.UpsertProductMutationVariables
>;

/**
 * __useUpsertProductMutation__
 *
 * To run a mutation, you first call `useUpsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductMutation, { data, loading, error }] = useUpsertProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpsertProductMutation,
    Types.UpsertProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpsertProductMutation, Types.UpsertProductMutationVariables>(
    UpsertProductDocument,
    options
  );
}
export type UpsertProductMutationHookResult = ReturnType<typeof useUpsertProductMutation>;
export type UpsertProductMutationResult = Apollo.MutationResult<Types.UpsertProductMutation>;
export type UpsertProductMutationOptions = Apollo.BaseMutationOptions<
  Types.UpsertProductMutation,
  Types.UpsertProductMutationVariables
>;
export const UpsertPromotionDocument = gql`
  mutation UpsertPromotion($input: UpsertPromotionInput!) {
    upsertPromotion(input: $input) {
      promotionID
    }
  }
`;
export type UpsertPromotionMutationFn = Apollo.MutationFunction<
  Types.UpsertPromotionMutation,
  Types.UpsertPromotionMutationVariables
>;

/**
 * __useUpsertPromotionMutation__
 *
 * To run a mutation, you first call `useUpsertPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPromotionMutation, { data, loading, error }] = useUpsertPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPromotionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpsertPromotionMutation,
    Types.UpsertPromotionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpsertPromotionMutation, Types.UpsertPromotionMutationVariables>(
    UpsertPromotionDocument,
    options
  );
}
export type UpsertPromotionMutationHookResult = ReturnType<typeof useUpsertPromotionMutation>;
export type UpsertPromotionMutationResult = Apollo.MutationResult<Types.UpsertPromotionMutation>;
export type UpsertPromotionMutationOptions = Apollo.BaseMutationOptions<
  Types.UpsertPromotionMutation,
  Types.UpsertPromotionMutationVariables
>;
export const ProductDetailDocument = gql`
  query ProductDetail(
    $productID: Int!
    $withProductHistoryList: Boolean = true
    $withGeneralDetailsSection: Boolean = true
    $withDescriptionsSection: Boolean = true
    $withVariantsPricingSection: Boolean = true
    $withPromotionProductItemList: Boolean = true
  ) {
    product(productID: $productID) {
      ...ProductDetail
    }
  }
  ${ProductDetailFragmentDoc}
`;

/**
 * __useProductDetailQuery__
 *
 * To run a query within a React component, call `useProductDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailQuery({
 *   variables: {
 *      productID: // value for 'productID'
 *      withProductHistoryList: // value for 'withProductHistoryList'
 *      withGeneralDetailsSection: // value for 'withGeneralDetailsSection'
 *      withDescriptionsSection: // value for 'withDescriptionsSection'
 *      withVariantsPricingSection: // value for 'withVariantsPricingSection'
 *      withPromotionProductItemList: // value for 'withPromotionProductItemList'
 *   },
 * });
 */
export function useProductDetailQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>(
    ProductDetailDocument,
    options
  );
}
export function useProductDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProductDetailQuery,
    Types.ProductDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>(
    ProductDetailDocument,
    options
  );
}
export type ProductDetailQueryHookResult = ReturnType<typeof useProductDetailQuery>;
export type ProductDetailLazyQueryHookResult = ReturnType<typeof useProductDetailLazyQuery>;
export type ProductDetailQueryResult = Apollo.QueryResult<
  Types.ProductDetailQuery,
  Types.ProductDetailQueryVariables
>;
export const PromotionDetailDocument = gql`
  query PromotionDetail($promotionID: Int!, $input: PromotionProductItemListInput) {
    promotion(promotionID: $promotionID) {
      ...PromotionDetail
    }
  }
  ${PromotionDetailFragmentDoc}
`;

/**
 * __usePromotionDetailQuery__
 *
 * To run a query within a React component, call `usePromotionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionDetailQuery({
 *   variables: {
 *      promotionID: // value for 'promotionID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromotionDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.PromotionDetailQuery,
    Types.PromotionDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PromotionDetailQuery, Types.PromotionDetailQueryVariables>(
    PromotionDetailDocument,
    options
  );
}
export function usePromotionDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PromotionDetailQuery,
    Types.PromotionDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PromotionDetailQuery, Types.PromotionDetailQueryVariables>(
    PromotionDetailDocument,
    options
  );
}
export type PromotionDetailQueryHookResult = ReturnType<typeof usePromotionDetailQuery>;
export type PromotionDetailLazyQueryHookResult = ReturnType<typeof usePromotionDetailLazyQuery>;
export type PromotionDetailQueryResult = Apollo.QueryResult<
  Types.PromotionDetailQuery,
  Types.PromotionDetailQueryVariables
>;
export const SupplierProductHistoryDocument = gql`
  query SupplierProductHistory($productID: Int!) {
    product(productID: $productID) {
      productID
      productHistoryList {
        productHistoryID
        action
        updateDate
        updateByUserID
        updateByName
      }
    }
  }
`;

/**
 * __useSupplierProductHistoryQuery__
 *
 * To run a query within a React component, call `useSupplierProductHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierProductHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierProductHistoryQuery({
 *   variables: {
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useSupplierProductHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SupplierProductHistoryQuery,
    Types.SupplierProductHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SupplierProductHistoryQuery,
    Types.SupplierProductHistoryQueryVariables
  >(SupplierProductHistoryDocument, options);
}
export function useSupplierProductHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SupplierProductHistoryQuery,
    Types.SupplierProductHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SupplierProductHistoryQuery,
    Types.SupplierProductHistoryQueryVariables
  >(SupplierProductHistoryDocument, options);
}
export type SupplierProductHistoryQueryHookResult = ReturnType<
  typeof useSupplierProductHistoryQuery
>;
export type SupplierProductHistoryLazyQueryHookResult = ReturnType<
  typeof useSupplierProductHistoryLazyQuery
>;
export type SupplierProductHistoryQueryResult = Apollo.QueryResult<
  Types.SupplierProductHistoryQuery,
  Types.SupplierProductHistoryQueryVariables
>;
export const SupplierProductListDocument = gql`
  query SupplierProductList($input: ProductListInput!, $lang: LangType!) {
    supplierProductList(input: $input) {
      isRequiredImproveAttribute
      items {
        ...SupplierProductListItem
      }
      totalPage
      totalItem
      page
      pageSize
    }
  }
  ${SupplierProductListItemFragmentDoc}
`;

/**
 * __useSupplierProductListQuery__
 *
 * To run a query within a React component, call `useSupplierProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierProductListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSupplierProductListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SupplierProductListQuery,
    Types.SupplierProductListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SupplierProductListQuery, Types.SupplierProductListQueryVariables>(
    SupplierProductListDocument,
    options
  );
}
export function useSupplierProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SupplierProductListQuery,
    Types.SupplierProductListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SupplierProductListQuery,
    Types.SupplierProductListQueryVariables
  >(SupplierProductListDocument, options);
}
export type SupplierProductListQueryHookResult = ReturnType<typeof useSupplierProductListQuery>;
export type SupplierProductListLazyQueryHookResult = ReturnType<
  typeof useSupplierProductListLazyQuery
>;
export type SupplierProductListQueryResult = Apollo.QueryResult<
  Types.SupplierProductListQuery,
  Types.SupplierProductListQueryVariables
>;
export const SupplierProductListPromotionDocument = gql`
  query SupplierProductListPromotion(
    $input: ProductListInput!
    $lang: LangType!
    $startDateInput: Long
    $endDateInput: Long
    $promotionID: Int
  ) {
    supplierProductList(input: $input) {
      items {
        ...SupplierProductListPromotionItem
      }
      totalPage
      totalItem
      page
      pageSize
    }
  }
  ${SupplierProductListPromotionItemFragmentDoc}
`;

/**
 * __useSupplierProductListPromotionQuery__
 *
 * To run a query within a React component, call `useSupplierProductListPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierProductListPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierProductListPromotionQuery({
 *   variables: {
 *      input: // value for 'input'
 *      lang: // value for 'lang'
 *      startDateInput: // value for 'startDateInput'
 *      endDateInput: // value for 'endDateInput'
 *      promotionID: // value for 'promotionID'
 *   },
 * });
 */
export function useSupplierProductListPromotionQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SupplierProductListPromotionQuery,
    Types.SupplierProductListPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SupplierProductListPromotionQuery,
    Types.SupplierProductListPromotionQueryVariables
  >(SupplierProductListPromotionDocument, options);
}
export function useSupplierProductListPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SupplierProductListPromotionQuery,
    Types.SupplierProductListPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SupplierProductListPromotionQuery,
    Types.SupplierProductListPromotionQueryVariables
  >(SupplierProductListPromotionDocument, options);
}
export type SupplierProductListPromotionQueryHookResult = ReturnType<
  typeof useSupplierProductListPromotionQuery
>;
export type SupplierProductListPromotionLazyQueryHookResult = ReturnType<
  typeof useSupplierProductListPromotionLazyQuery
>;
export type SupplierProductListPromotionQueryResult = Apollo.QueryResult<
  Types.SupplierProductListPromotionQuery,
  Types.SupplierProductListPromotionQueryVariables
>;
export const SupplierPromotionListDocument = gql`
  query SupplierPromotionList($input: PromotionListInput!, $lang: LangType!) {
    supplierPromotionList(input: $input) {
      items {
        ...SupplierPromotionListItem
      }
      totalPage
      totalItem
      page
      pageSize
    }
  }
  ${SupplierPromotionListItemFragmentDoc}
`;

/**
 * __useSupplierPromotionListQuery__
 *
 * To run a query within a React component, call `useSupplierPromotionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPromotionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPromotionListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSupplierPromotionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SupplierPromotionListQuery,
    Types.SupplierPromotionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SupplierPromotionListQuery,
    Types.SupplierPromotionListQueryVariables
  >(SupplierPromotionListDocument, options);
}
export function useSupplierPromotionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SupplierPromotionListQuery,
    Types.SupplierPromotionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SupplierPromotionListQuery,
    Types.SupplierPromotionListQueryVariables
  >(SupplierPromotionListDocument, options);
}
export type SupplierPromotionListQueryHookResult = ReturnType<typeof useSupplierPromotionListQuery>;
export type SupplierPromotionListLazyQueryHookResult = ReturnType<
  typeof useSupplierPromotionListLazyQuery
>;
export type SupplierPromotionListQueryResult = Apollo.QueryResult<
  Types.SupplierPromotionListQuery,
  Types.SupplierPromotionListQueryVariables
>;
export const UnitOfMeasurementListDocument = gql`
  query UnitOfMeasurementList($scUOM: Boolean = true) {
    unitOfMeasurementList(scUOM: $scUOM) {
      ...UOMItem
    }
  }
  ${UomItemFragmentDoc}
`;

/**
 * __useUnitOfMeasurementListQuery__
 *
 * To run a query within a React component, call `useUnitOfMeasurementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitOfMeasurementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitOfMeasurementListQuery({
 *   variables: {
 *      scUOM: // value for 'scUOM'
 *   },
 * });
 */
export function useUnitOfMeasurementListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UnitOfMeasurementListQuery,
    Types.UnitOfMeasurementListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.UnitOfMeasurementListQuery,
    Types.UnitOfMeasurementListQueryVariables
  >(UnitOfMeasurementListDocument, options);
}
export function useUnitOfMeasurementListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UnitOfMeasurementListQuery,
    Types.UnitOfMeasurementListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.UnitOfMeasurementListQuery,
    Types.UnitOfMeasurementListQueryVariables
  >(UnitOfMeasurementListDocument, options);
}
export type UnitOfMeasurementListQueryHookResult = ReturnType<typeof useUnitOfMeasurementListQuery>;
export type UnitOfMeasurementListLazyQueryHookResult = ReturnType<
  typeof useUnitOfMeasurementListLazyQuery
>;
export type UnitOfMeasurementListQueryResult = Apollo.QueryResult<
  Types.UnitOfMeasurementListQuery,
  Types.UnitOfMeasurementListQueryVariables
>;
export const UserInformationDocument = gql`
  query UserInformation($userID: Int) {
    userInformation(userID: $userID) {
      firstName
      lastName
      firstNameEN
      lastNameEN
      email
      mobile
      mobileCallingCode
      username
      phone
      phoneCallingCode
      passwordUpdateDate
      createDate
      phoneExt
      fax
      faxCallingCode
      faxExt
      countryCode
      localeName
      timeZoneID
      registerFrom
      titleID
      otherTitleName
    }
  }
`;

/**
 * __useUserInformationQuery__
 *
 * To run a query within a React component, call `useUserInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInformationQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UserInformationQuery,
    Types.UserInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserInformationQuery, Types.UserInformationQueryVariables>(
    UserInformationDocument,
    options
  );
}
export function useUserInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UserInformationQuery,
    Types.UserInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserInformationQuery, Types.UserInformationQueryVariables>(
    UserInformationDocument,
    options
  );
}
export type UserInformationQueryHookResult = ReturnType<typeof useUserInformationQuery>;
export type UserInformationLazyQueryHookResult = ReturnType<typeof useUserInformationLazyQuery>;
export type UserInformationQueryResult = Apollo.QueryResult<
  Types.UserInformationQuery,
  Types.UserInformationQueryVariables
>;
