import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingActionEnum } from '@utils/enum/SettingEnum';
import { SettingActionType } from '@utils/constants/SettingConstants';
import { Controller, useFormContext } from 'react-hook-form';
import { AccountDetail } from '@models/AccountDetail';
import { CountryCode } from '@utils/constants/CountryConstant';
import { FormGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { useSettingContext } from '../SettingContext';
import { CountryResponse } from '@api/response/CountryResponse';
import { DropdownSelectOption, SimpleDropdownSelect } from '@components/dropdown/SimpleDropdownSelect';
import { CityResponse } from '@api/response/CityResponse';
import { CommonClient } from '@api/CommonClient';
import { Language } from '@utils/constants/CommonConstants';
import { Validate } from '../account-detail/AccountDetailsSchema';
import { DropdownCountries } from '@components/dropdown/DropdownCountries';
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead';

export interface ContactAddressProps {
    elementID: string;
    action: SettingActionType;
}

export default function ContactAddress(props: React.PropsWithChildren<ContactAddressProps>): React.FunctionComponentElement<any> {

    const { elementID, action } = props;
    const { t, i18n } = useTranslation();
    const { masterCountries } = useSettingContext();
    const currentLangItem = Language.getLangByKey(i18n.language.substring(0, 2));

    const [stateCountryStateProvince, setCountryStateProvince] = useState<CityResponse[]>();
    const [stateProvinceOption, setStateProvinceOption] = useState<DropdownSelectOption[]>([]);
    const [stateDistrictOption, setStateDistrictOption] = useState<DropdownSelectOption[]>([]);
    const [stateSubDistrictOption, setStateSubDistrictOption] = useState<DropdownSelectOption[]>([]);
    const [isInitial, setIsInitial] = useState(true);
    const [potalCodes, setPotalCodes] = useState<string[]>([]);

    const form = useFormContext<AccountDetail>();
    const {
        formState: { errors },
        trigger,
        watch,
        setValue,
        getValues,
        clearErrors,
        control
    } = form;

    useEffect(() => {
        getThaiProvinceOption();
    }, []);

    useEffect(() => {
        if (isInitial) {
            if (stateProvinceOption?.length! && !stateDistrictOption?.length!) {
                if (watch('provinceLocal')) {
                    getThaiDistrictOptionByProvince(getValues('provinceLocal'));
                } else {
                    setIsInitial(false);
                }
            }

            if (stateDistrictOption?.length! && !stateSubDistrictOption?.length!) {
                if (watch('districtLocal')) {
                    getThaiSubDistrictOptionByDistrict(getValues('districtLocal'));
                } else {
                    setIsInitial(false);
                }
            }

            if (stateSubDistrictOption?.length!) {
                if (watch('subDistrictLocal')) {
                    getThaiPostalCodeBySubDistrict(getValues('subDistrictLocal'), getValues('districtLocal'));
                } else {
                    setIsInitial(false);
                }
            }
        }
    }, [stateProvinceOption, stateDistrictOption, stateSubDistrictOption]);

    useEffect(() => {
        if (!isInitial) {
            handleChangeAddressEN();
        }
    }, [watch('provinceLocal'), watch('districtLocal'), watch('subDistrictLocal')]);

    useEffect(() => {
        if (!isInitial && potalCodes?.length == 1) {
            setValue('postalCode', potalCodes[0], { shouldValidate: true });
        }
    }, [potalCodes])

    const coutryByCountryCode = (countryCode: string): CountryResponse => {
        return masterCountries.filter(f => f.countryCode == countryCode)[0];
    }

    const getThaiProvinceOption = () => {
        return new Promise((resolve, reject) => {
            CommonClient.getProvinceOption().subscribe((response) => {
                if (response) {
                    const mapProvinceOption = response.map((province) => {
                        let ddlSelect: DropdownSelectOption = { label: '', value: '' };
                        ddlSelect.value = province.stateNameLocal;
                        if (currentLangItem === Language.en) {
                            ddlSelect.label = province.stateName + ` (${province.stateNameLocal})`;
                        } else {
                            ddlSelect.label = province.stateNameLocal + ` (${province.stateName})`;
                        }
                        return ddlSelect;
                    }).sort((left, right) => {
                        if (left.value < right.value) return -1;
                        if (left.value > right.value) return 1;
                        return 0;
                    });
                    setStateProvinceOption(
                        Object.values(
                            mapProvinceOption.reduce((acc, cur) => Object.assign(acc, { [cur.label]: cur }), {})
                        )
                    );
                    resolve(response);
                } else {
                    reject();
                }
            });
        });
    };

    const getThaiDistrictOptionByProvince = (value: string) => {
        return new Promise((resolve, reject) => {
            CommonClient.getCityInfo(value).subscribe((response) => {
                if (response) {
                    setCountryStateProvince(response);
                    const mapStateProvince = response?.map((state) => {
                        let ddlSelect: DropdownSelectOption = { label: '', value: '' };
                        ddlSelect.value = state.cityLocal;
                        if (currentLangItem === Language.en) {
                            ddlSelect.label = state.city + ` (${state.cityLocal})`;
                        } else {
                            ddlSelect.label = state.cityLocal + ` (${state.city})`;
                        }
                        return ddlSelect;
                    }).sort((left, right) => {
                        if (left.value < right.value) return -1;
                        if (left.value > right.value) return 1;
                        return 0;
                    });

                    // filter city
                    if (mapStateProvince?.length) {
                        setStateDistrictOption(
                            Object.values(
                                mapStateProvince.reduce((acc, cur) => Object.assign(acc, { [cur.label]: cur }), {})
                            )
                        );
                    } else {
                        setIsInitial(false);
                    }
                    resolve(response);
                } else {
                    reject();
                    setIsInitial(false);
                }
            });
        });
    };

    const getThaiSubDistrictOptionByDistrict = (distict: string | undefined) => {
        const mapStateSubDistrict = stateCountryStateProvince
            ?.filter((state) => state.cityLocal === distict)
            .map((state) => {
                let ddlSelect: DropdownSelectOption = { label: '', value: '' };
                ddlSelect.value = state.subDistrictLocal;
                if (currentLangItem === Language.en) {
                    ddlSelect.label = state.subDistrict + ` (${state.subDistrictLocal})`;
                } else {
                    ddlSelect.label = state.subDistrictLocal + ` (${state.subDistrict})`;
                }
                return ddlSelect;
            }).sort((left, right) => {
                if (left.value < right.value) return -1;
                if (left.value > right.value) return 1;
                return 0;
            });

        // filter sub-district
        if (mapStateSubDistrict?.length) {
            setStateSubDistrictOption(
                Object.values(
                    mapStateSubDistrict.reduce((acc, cur) => Object.assign(acc, { [cur.label]: cur }), {})
                )
            );
        } else {
            setIsInitial(false);
        }
    };

    const getThaiPostalCodeBySubDistrict = (value: string, district?: string) => {
        const mapStatePostal = stateCountryStateProvince
            ?.filter((state) => {
                if (!district) {
                    return state.subDistrictLocal === value
                }
                return state.subDistrictLocal === value && state.cityLocal === district
            })
            .map((state) => {
                return state.postalCode;
            });

        // fill postal code
        if (mapStatePostal?.length) {
            setPotalCodes(mapStatePostal);
        } else {
            setIsInitial(false);
        }
    };

    const handleChangeStateProvince = (value: string) => {
        setValue('provinceLocal', value, { shouldValidate: true, shouldDirty: true });
        setValue('districtLocal', '');
        setValue('subDistrictLocal', '');
        setValue('postalCode', '');
        setPotalCodes([]);
        clearErrors('postalCode');
        getThaiDistrictOptionByProvince(value);
    };

    const handleChangeStateDistrict = (value: string) => {
        setValue('districtLocal', value, { shouldValidate: true, shouldDirty: true });
        setValue('subDistrictLocal', '');
        setValue('postalCode', '');
        setPotalCodes([]);
        clearErrors('postalCode');
        getThaiSubDistrictOptionByDistrict(value);
    };

    const handleChangeStateSubDistrict = (value: string) => {
        setValue('subDistrictLocal', value, { shouldValidate: true, shouldDirty: true });
        setValue('postalCode', '');
        setPotalCodes([]);
        clearErrors('postalCode');
        getThaiPostalCodeBySubDistrict(value, getValues('districtLocal'));
    };

    const handleChangeAddressEN = () => {
        if (watch('countryCode') === CountryCode.TH) {
            const stateProvince = stateCountryStateProvince?.find(
                (item) => item.stateNameLocal === watch('provinceLocal')
            );
            const stateDistrict = stateCountryStateProvince?.find(
                (item) => item.cityLocal === watch('districtLocal')
            );
            const stateSubDistrict = stateCountryStateProvince?.find(
                (item) => item.subDistrictLocal === watch('subDistrictLocal')
            );
            setValue('provinceEn', stateProvince?.stateName!);
            setValue('districtEn', stateProvince?.city!);
            setValue('subDistrictEn', stateProvince?.subDistrict!);
        } else {
            setValue('provinceEn', getValues('provinceLocal')!);
            setValue('districtEn', getValues('districtLocal')!);
            setValue('subDistrictEn', getValues('subDistrictLocal')!);
        }
    }

    const clearValueAddress = () => {
        setValue('provinceLocal', '');
        setValue('districtLocal', '');
        setValue('subDistrictLocal', '');
        setValue('postalCode', '');
        setPotalCodes([]);
        clearErrors('provinceLocal');
        clearErrors('districtLocal');
        clearErrors('subDistrictLocal');
        clearErrors('postalCode');
    }

    const renderAddress = (type: 'local' | 'en') => {
        let address = '';

        if (type === 'en') {
            if (watch('addressEn')) {
                address += watch('addressEn');
            }

            if (watch('subDistrictEn')) {
                if (address) {
                    address += `, ${watch('subDistrictEn')}`;
                } else {
                    address += watch('subDistrictEn');
                }
            }

            if (watch('districtEn')) {
                if (address) {
                    address += `, ${watch('districtEn')}`;
                } else {
                    address += watch('districtEn');
                }
            }

            if (watch('provinceEn')) {
                if (address) {
                    address += `, ${watch('provinceEn')}`;
                } else {
                    address += watch('provinceEn');
                }
            }

            if (watch('postalCode')) {
                if (address) {
                    address += ` ${watch('postalCode')}`;
                } else {
                    address += watch('postalCode');
                }
            }

            if (watch('countryCode') && watch('countryNameCode')) {

                if (address) {
                    address += ` ${i18n.t(watch('countryNameCode')!, { lng: 'en' })}`;
                } else {
                    address += i18n.t(watch('countryNameCode')!, { lng: 'en' });
                }
            }
        } else {
            if (watch('addressLocal')) {
                address += watch('addressLocal');
            }

            if (watch('subDistrictLocal')) {
                if (address) {
                    address += `, ${watch('subDistrictLocal')}`;
                } else {
                    address += watch('subDistrictLocal');
                }
            }

            if (watch('districtLocal')) {
                if (address) {
                    address += `, ${watch('districtLocal')}`;
                } else {
                    address += watch('districtLocal');
                }
            }

            if (watch('provinceLocal')) {
                if (address) {
                    address += `, ${watch('provinceLocal')}`;
                } else {
                    address += watch('provinceLocal');
                }
            }

            if (watch('postalCode')) {
                if (address) {
                    address += ` ${watch('postalCode')}`;
                } else {
                    address += watch('postalCode');
                }
            }

            if (watch('countryCode') && watch('countryNameCode')) {
                if (watch('countryCode') === CountryCode.TH) {
                    if (address) {
                        address += ` ${i18n.t('Common.Label.Country', { lng: 'th' })}${i18n.t(watch('countryNameCode')!, { lng: 'th' })}`;
                    } else {
                        address += `${i18n.t('Common.Label.Country', { lng: 'th' })}${i18n.t(watch('countryNameCode')!, { lng: 'th' })}`;
                    }
                } else {
                    if (address) {
                        address += ` ${i18n.t(watch('countryNameCode')!, { lng: 'en' })}`;
                    } else {
                        address += i18n.t(watch('countryNameCode')!, { lng: 'en' });
                    }
                }
            }
        }

        return (<>{address}</>);
    }

    const view = () => {
        return (
            <div className="col-list">
                <div className="col-label label-lg">{t('AccountDetails.Label.Address')}</div>
                <div className="col-detail">
                    {watch('countryNameCode') ?
                        <p>{renderAddress('en')}
                            <small>
                                {renderAddress('local')}
                            </small>
                        </p>
                        : '-'
                    }
                </div>
            </div>)
    }

    const edit = () => {
        return (<>
            <FormGroup>
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex align-items-center">
                            <label>
                                {t('Common.Label.AddressLocal')}
                                <span className="required">*</span>
                            </label>
                        </div>
                        <Controller
                            control={control}
                            name="addressLocal"
                            render={({ field }) => (
                                <input type="text"
                                    id={`txt-${elementID}-addressLocal`}
                                    className={classNames({
                                        'form-control': true,
                                        'is-invalid': errors?.addressLocal,
                                    })}
                                    maxLength={Validate.addressLocalMaxLength}
                                    placeholder={t('RegisterService.Label.Address.AddressPlaceholder')}
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        trigger(field.name);
                                    }}
                                    onBlur={(e) => {
                                        field.onChange(e.target.value.trim());
                                        trigger(field.name);
                                    }} />
                            )}
                        />
                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'addressLocal',
                            `txt-${elementID}-addressLocal-error`
                        )}
                    </div>
                    <div className="col-6">
                        <div className="d-flex align-items-center">
                            <label>
                                {t('Common.Label.AddressEN')}
                                <span className="required">*</span>
                            </label>
                        </div>
                        <Controller
                            control={control}
                            name="addressEn"
                            render={({ field }) => (
                                <input type="text"
                                    id={`txt-${elementID}-addressEn`}
                                    className={classNames({
                                        'form-control': true,
                                        'is-invalid': errors?.addressEn,
                                    })}
                                    maxLength={Validate.addressEnMaxLength}
                                    placeholder={t('RegisterService.Label.Address.AddressPlaceholder')}
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        trigger(field.name);
                                    }}
                                    onBlur={(e) => {
                                        field.onChange(e.target.value.trim());
                                        trigger(field.name);
                                    }} />
                            )}
                        />
                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'addressEn',
                            `txt-${elementID}-addressEn-error`
                        )}
                    </div>
                </div>
            </FormGroup>
            <FormGroup>
                <div className="row">
                    <div className="col-6">
                        <label>{t('Common.Label.Country')}
                            <span className="required">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="countryCode"
                            render={({ field }) => (
                                <DropdownCountries
                                    {...field}
                                    ref={field.ref}
                                    searching={true}
                                    elementID={`${elementID}-country`}
                                    values={coutryByCountryCode(field.value || '')}
                                    countries={masterCountries}
                                    onChange={(val: any) => {
                                        field.onChange(val.countryCode);
                                        trigger(field.name);
                                        clearValueAddress();
                                    }}
                                />
                            )}
                        />
                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'countryCode',
                            `txt-${elementID}-countryCode-error`
                        )}
                    </div>
                    <div className="col-6">
                        <label>
                            {t('Common.Label.Province/State')}
                            <span className="required">*</span>
                        </label>
                        {watch('countryCode') === CountryCode.TH ?
                            <Controller
                                control={control}
                                name="provinceLocal"
                                render={({ field }) => (
                                    <SimpleDropdownSelect
                                        {...field}
                                        ref={field.ref}
                                        searchBox={true}
                                        placeholder={t('Common.Label.Province')}
                                        options={stateProvinceOption}
                                        value={field.value}
                                        disabled={!stateProvinceOption.length}
                                        id={`${elementID}-province`}
                                        onChange={(name, val) => {
                                            handleChangeStateProvince(val);
                                        }}
                                        hasError={!!errors.provinceLocal}
                                    />
                                )}
                            />
                            :
                            <Controller
                                control={control}
                                name="provinceLocal"
                                render={({ field }) => (
                                    <input type="text"
                                        id={`txt-${elementID}-provinceLocal`}
                                        className={classNames({
                                            'form-control': true,
                                            'is-invalid': errors?.provinceLocal,
                                        })}
                                        maxLength={Validate.provinceLocalMaxLength}
                                        placeholder={t('Common.Label.Province')}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            trigger(field.name);
                                        }}
                                        onBlur={(e) => {
                                            field.onChange(e.target.value.trim());
                                            trigger(field.name);
                                        }} />
                                )}
                            />
                        }

                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'provinceLocal',
                            `txt-${elementID}-provinceLocal-error`
                        )}
                    </div>
                </div>
            </FormGroup>
            <FormGroup>
                <div className="row">
                    <div className="col-6">
                        <label>
                            {t('Common.Label.District/City')}
                            <span className="required">*</span>
                        </label>
                        {watch('countryCode') === CountryCode.TH ?
                            <Controller
                                control={control}
                                name="districtLocal"
                                render={({ field }) => (
                                    <SimpleDropdownSelect
                                        {...field}
                                        ref={field.ref}
                                        searchBox={true}
                                        placeholder={t('Common.Label.District/City')}
                                        options={stateDistrictOption}
                                        value={field.value}
                                        disabled={!watch('provinceLocal')}
                                        id={`${elementID}-districtLocal`}
                                        onChange={(name, val) => {
                                            handleChangeStateDistrict(val);
                                        }}
                                        hasError={!!errors.districtLocal}
                                    />
                                )}
                            />
                            :
                            <Controller
                                control={control}
                                name="districtLocal"
                                render={({ field }) => (
                                    <input type="text"
                                        id={`txt-${elementID}-districtLocal`}
                                        className={classNames({
                                            'form-control': true,
                                            'is-invalid': errors?.districtLocal,
                                        })}
                                        maxLength={Validate.districtLocalMaxLength}
                                        placeholder={t('Common.Label.District/City')}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            trigger(field.name);
                                        }}
                                        onBlur={(e) => {
                                            field.onChange(e.target.value.trim());
                                            trigger(field.name);
                                        }} />
                                )}
                            />
                        }

                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'districtLocal',
                            `txt-${elementID}-districtLocal-error`
                        )}
                    </div>
                    <div className="col-6">
                        <label>
                            {t('Common.Label.SubDistrict')}
                            <span className="required">*</span>
                        </label>
                        {watch('countryCode') === CountryCode.TH ?
                            <Controller
                                control={control}
                                name="subDistrictLocal"
                                render={({ field }) => (
                                    <SimpleDropdownSelect
                                        {...field}
                                        ref={field.ref}
                                        searchBox={true}
                                        placeholder={t('Common.Label.SubDistrict')}
                                        options={stateSubDistrictOption}
                                        value={field.value}
                                        disabled={!watch('districtLocal')}
                                        id={`${elementID}-subDistrictLocal`}
                                        onChange={(name, val) => {
                                            handleChangeStateSubDistrict(val);
                                        }}
                                        hasError={!!errors.subDistrictLocal}
                                    />
                                )}
                            />
                            :
                            <Controller
                                control={control}
                                name="subDistrictLocal"
                                render={({ field }) => (
                                    <input type="text"
                                        id={`txt-${elementID}-subDistrictLocal`}
                                        className={classNames({
                                            'form-control': true,
                                            'is-invalid': errors?.subDistrictLocal,
                                        })}
                                        maxLength={Validate.subDistrictLocalMaxLength}
                                        placeholder={t('Common.Label.SubDistrict')}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            trigger(field.name);
                                        }}
                                        onBlur={(e) => {
                                            field.onChange(e.target.value.trim());
                                            trigger(field.name);
                                        }} />
                                )}
                            />
                        }

                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'subDistrictLocal',
                            `txt-${elementID}-subDistrictLocal-error`
                        )}
                    </div>
                </div>
            </FormGroup>
            <FormGroup>
                <div className="row">
                    <div className="col-6">
                        <label>
                            {t('Common.Label.PostalCode')}
                            <span className="required">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="postalCode"
                            render={({ field }) => (
                                <Typeahead
                                    {...field}
                                    id={`txt-${elementID}-postalCode-Typeahead`}
                                    onChange={(val) => {
                                        if (val.length) {
                                            field.onChange(val.toString());
                                            trigger(field.name);
                                        }
                                    }}
                                    onInputChange={(e) => {
                                        field.onChange(e.toString());
                                        trigger(field.name);
                                    }}
                                    options={potalCodes}
                                    placeholder={t('Common.Label.PostalCode')}
                                    selected={[field?.value || '']}
                                    isInvalid={!!errors?.postalCode!}
                                    disabled={!potalCodes?.length! && !!(watch('countryCode') === CountryCode.TH)}
                                    renderMenu={(results, menuProps) => {
                                        if (!results?.length) {
                                            return null;
                                        }
                                        return <TypeaheadMenu {...menuProps} options={results as string[]} />;
                                    }}
                                    inputProps={{
                                        maxLength: (watch('countryCode') === CountryCode.TH) ? Validate.postalCodeTHMaxLength : Validate.postalCodeMaxLength,
                                        id: `txt-${elementID}-postalCode`
                                    }}
                                />
                            )}
                        />
                        {CommonHelper.renderFieldError<AccountDetail>(
                            t,
                            errors,
                            'postalCode',
                            `txt-${elementID}-postalCode-error`
                        )}
                    </div>
                </div>
            </FormGroup>
        </>);
    }

    return (<>
        <div className="mb-4">
            <div className="title">{t('AccountDetails.Label.ContactAddress')}</div>
            {action === SettingActionEnum.VIEW ? view() : edit()}
        </div>
    </>
    )
}