import { SupplierPromotionListItemFragment } from '@graphql/autogenerate/operations';
import { PromotionDetail, PromotionImageInput, PromotionProductInput, UpsertPromotionInput } from '@graphql/autogenerate/schemas';
import { DealsAndDiscount } from '@models/DealsAndDiscount';
import { CompanyItem } from '@ptvn-react/authentication';
import { DateTimeFormat } from '@utils/constants/CommonConstants';
import dayjs from 'dayjs';
import { CommonHelper } from './CommonHelper';

export type PromotionStatus = 'pending' | 'completed' | 'cancelled' | 'deleted';
export class DealsAndDiscountHelper {
  public static getRequestObject = (isDraft: boolean, status: PromotionStatus, form: DealsAndDiscount): UpsertPromotionInput => {
    let startDate = null;
    let endDate = null;
    if (form.promotionDetail.startTime) {
      startDate = CommonHelper.composeDateTimeToTimestamp(
        CommonHelper.displayDate(Number(form?.promotionDetail?.startDate!), DateTimeFormat.DDMMYYYY_HYPHEN),
        (form?.promotionDetail?.startTime!) ? form.promotionDetail.startTime : '00:00',
        CommonHelper.getUserClientTimeZone(),
        DateTimeFormat.DDMMYYYY_HYPHEN,
        DateTimeFormat.HHmm
      );
    }

    if (form.promotionDetail.endDate) {
      endDate = CommonHelper.composeDateTimeToTimestamp(
        CommonHelper.displayDate(Number(form?.promotionDetail?.endDate!), DateTimeFormat.DDMMYYYY_HYPHEN),
        (form?.promotionDetail?.endTime!) ? form.promotionDetail.endTime : '00:00',
        CommonHelper.getUserClientTimeZone(),
        DateTimeFormat.DDMMYYYY_HYPHEN,
        DateTimeFormat.HHmm
      );
    }

    let pomotionImage: PromotionImageInput[] = [];
    if (form?.promotionDetail?.imageGlobal?.originalFileName) {
      pomotionImage.push({
        uuid: form.promotionDetail.imageGlobal?.uuid!,
        originalFileName: form.promotionDetail.imageGlobal?.originalFileName!,
        uploadDate: form.promotionDetail.imageGlobal?.uploadDate!,
        uploadByUserName: form.promotionDetail.imageGlobal?.uploadByUserName!,
        uploadByUserID: form.promotionDetail.imageGlobal?.uploadByUserID!,
        language: "en",
        order: form.promotionDetail.imageGlobal?.order!,
      });
    }

    if (form?.promotionDetail?.imageLocal?.originalFileName) {
      pomotionImage.push({
        uuid: form.promotionDetail.imageLocal?.uuid!,
        originalFileName: form.promotionDetail.imageLocal?.originalFileName!,
        uploadDate: form.promotionDetail.imageLocal?.uploadDate!,
        uploadByUserName: form.promotionDetail.imageLocal?.uploadByUserName!,
        uploadByUserID: form.promotionDetail.imageLocal?.uploadByUserID!,
        language: "local",
        order: form.promotionDetail.imageLocal?.order!,
      });
    }

    return {
      companyID: form.companyId,
      branchID: form.branchId,
      promotionID: (form.promotionId) ? Number(form.promotionId) : null,
      draft: isDraft,
      promotionTypeID: 1,
      discountType: form.promotionDetail.discountType!,
      promotionNameEN: form.promotionDetail.promotionNameEN!,
      promotionNameLocal: form.promotionDetail.promotionNameLocal!,
      products: [
        ...form.promotionProduct.filter(p => !p.promotionOverlap && !p.canNotBeApplied).map(p => {
          return {
            productID: p.productID,
            discountValue: p.discountValue
          }
        })
      ] as PromotionProductInput[],
      startDate: startDate!,
      endDate: endDate!,
      noEndDate: form.promotionDetail.isNoEndDate,
      promotionImages: pomotionImage,
      descriptionLocal: form.promotionDetail.descriptionLocal,
      descriptionEN: form.promotionDetail.descriptionEN,
      remark: null,
      discountDetail: null,
      promotionStatus: status
    };
  };

  public static getUpdateStatusRequestObject = (status: PromotionStatus, remark: string | null, form: SupplierPromotionListItemFragment): UpsertPromotionInput => {
    return {
      companyID: (form.company) ? Number(form.company?.companyID) : 1,
      branchID: (form.company?.branch) ? Number(form.company?.branch[0]?.branchID) : 1,
      promotionID: (form.promotionID) ? Number(form.promotionID) : null,
      promotionStatus: status,
      remark: remark,
      discountType: '',
      draft: false,
      promotionNameEN: form.nameEN as string,
      promotionNameLocal: form.nameLocal as string,
      promotionTypeID: 1
    };
  };

  public static getUpdatePromotionStatusRequestObject = (status: PromotionStatus, remark: string | null, form: PromotionDetail,company :CompanyItem): UpsertPromotionInput => {
    return {
      companyID: (company) ? Number(company.companyID) : 1,
      branchID: (company) ? Number(company.branchId) : 1,
      promotionID: (form.promotionID) ? Number(form.promotionID) : null,
      promotionStatus: status,
      remark: remark,
      discountType: '',
      draft: false,
      promotionNameEN: form.promotionNameEN as string,
      promotionNameLocal: form.promotionNameLocal as string,
      promotionTypeID: 1
    };
  };
}