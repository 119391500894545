export enum LanguageEnum {
  EN = 'EN',
  LOCAL = 'LOCAL',
}

export enum TimerEnum {
  THIRTY_SECONDS = 30000,
  ONE_MINUTE = 60000,
  TWO_MINUTE = 120000,
  THREE_MINUTE = 180000,
  FIVE_MINUTE = 300000
}
