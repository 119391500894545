export enum FileStatus {
  PENDING = 'pending',
  PENDING_EDIT = 'pending_edit',
  UPLOAD_FAIL = 'upload_fail',
  UPLOADED = 'uploaded',
  INVALID = 'invalid',
}

export enum FileChangedBy {
  UPLOAD,
  EDIT
}