import PageRoute from '@utils/constants/PageRoute';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer({
  children,
}: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
  const { t } = useTranslation()
  return (
    <>
      <footer className="footer white">
        <a href={PageRoute.TERMS_AND_PRIVACY.replace(':tab', 'terms')} target="_blank">{t('Common.Label.TermOfService')}</a>
        <a href={PageRoute.TERMS_AND_PRIVACY.replace(':tab', 'privacy')} target="_blank">{t('Common.Label.PrivacyPolicy')}</a>
        <span>&copy; 2021 Pantavanij Company Limited. All Right Reserved.</span>
      </footer>
    </>
  );
}
