import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FooterContact({
  children,
}: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer">
        <small className="text-footer">{t("Footer.Label.NeedHelp")}
          <br />{t("Footer.Label.Phone")}
          <br />{t("Footer.Label.Email")}
        </small>
      </footer>
    </>
  );
}
