import classNames from 'classnames';
import React from 'react';
import { AlertComponentPropsWithStyle } from 'react-alert';
import { ColorType, IconType, SCColors } from '../icon/SCIcon';
import { TypeOfTheme } from '@utils/enum/Theme';

export type ThemeSmartAlert = TypeOfTheme.ACCOUNT_SETTING | TypeOfTheme.SUPPLIER_CONNECT | TypeOfTheme.TOP_SUPPLIER_CONNECT | TypeOfTheme.DEFAULT;
export interface ISmartAlertContentProp {
  text: string;
  icon?: IconType;
  color?: ColorType;
  asBar?: boolean;
  theme?: ThemeSmartAlert;
  onClose?: () => void;
}
export const SmartAlertContent = (
  props: ISmartAlertContentProp
): React.FunctionComponentElement<ISmartAlertContentProp> => {
  const { text, color, asBar, theme, onClose } = props;

  const SmartAlertThemeContent = (text: string, color?: ColorType, asBar?: boolean, theme?: ThemeSmartAlert,
    onClose?: () => void) => {
    switch (theme) {
      case TypeOfTheme.ACCOUNT_SETTING:
        return (
          <>
            {color && <i className={classNames({
              'icon-check color-success': color === SCColors[0],
              'icon-error color-danger': color === SCColors[1]
            })}></i>}
            {text}
            <a className="undo" onClick={() => {
              onClose && onClose();
            }}>
              <i className="icon-sm-close"></i>
            </a>
          </>
        );
      case TypeOfTheme.TOP_SUPPLIER_CONNECT:
        return (
          <>
            <div
              className={classNames('alert top-alert', {
                'alert-success': color === SCColors[0],
                'alert-danger': color === SCColors[1],
                'alert-primary': color === SCColors[2],
              })}
              style={{ width: asBar ? 'unset' : '' }}
              onClick={() => {
                onClose && onClose();
              }}>{text}</div>
          </>
        );
      default:
        return (
          <div
            className={classNames('alert', {
              'alert-success': color === SCColors[0],
              'alert-danger': color === SCColors[1],
              'alert-primary': color === SCColors[2]
            })}
            style={{ width: asBar ? 'unset' : '' }}
          >
            {text}
          </div>
        )
    }
  }

  return (
    <>
      {SmartAlertThemeContent(text, color, asBar, theme, onClose)}
    </>
  );
};

export const SmartAlertTemplate: React.ComponentType<AlertComponentPropsWithStyle> = ({
  style,
  message,
}) => {
  const alertContainerStyle = { ...style };
  return (
    <div className="react-smart-alert-template">
      <div style={alertContainerStyle} className={'smart-alert'}>
        {message}
      </div>
    </div>
  );
};
