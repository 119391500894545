export enum SettingPageRoute {
  ACCOUNT_DETAIL = 'account-detail',
  COMPANY = 'company',
  NOTIFICATION = 'notifications',
  PREFERENCES = 'preferences'
}

export enum SettingActionEnum {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum AvailableServiceEnum {
  OP = 'Online Purchasing (OP)'
}