import { SCButton } from '@components/button/SCButton';
import { IconType, SCIcon } from '@components/icon/SCIcon';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalSize } from '../../utils/constants/StyleConstant';
import { ModalStackPropsBase } from './ModalStackProvider';

export interface IIconAlertModalData {
  modalSize?: ModalSize;
  title: string;
  icon: any;
  description?: string;
  submitText?: string;
  cancelBtn?: boolean;
  cancelText?: string;
  submitBtnIcon?: false | IconType;
  cancelBtnIcon?: false | IconType;
}

export interface IIconAlertModalProps extends ModalStackPropsBase<unknown, unknown> {
  data: IIconAlertModalData;
  pageElementID: string;
  onSubmit: () => void;
  elementRef?: any;
}

export function IconAlertModal(
  props: React.PropsWithChildren<IIconAlertModalProps>
): React.FunctionComponentElement<IIconAlertModalProps> {
  const { data, pageElementID, onSubmit, elementRef, show, onClose } = props;

  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <>
      {
        <Modal
          id={`modal-${pageElementID}`}
          show={show}
          size={data.modalSize === ModalSize.MD ? undefined : data.modalSize}
          dialogClassName={data.modalSize === ModalSize.MD ? 'modal-dialog modal-md' : ''}
          onHide={() => { }}
        >
          <Modal.Body ref={elementRef} id={`lbl-${pageElementID}-detail`}>
            <div className="text-center"><img src={data.icon} className="card-icon" />
              <div id={`lbl-${pageElementID}-header`} className="title">{t(data.title)}</div>
              {data?.description && <p id={`lbl-${pageElementID}-description`} >{t(data.description)}</p>}
            </div>
          </Modal.Body>

          <Modal.Footer>
            {data.cancelBtn &&
              <div className='float-left'>
                <SCButton
                  id={`btn-${pageElementID}-cancel`}
                  type="outline"
                  onClick={onClose}>
                  {typeof data.cancelBtnIcon === 'string' && <SCIcon icon={data.cancelBtnIcon} className="mr-8px" />}
                  {data.cancelText ? t(data.cancelText) : t('Common.Button.Cancel')}
                </SCButton>
              </div>}
            <div className={data.cancelBtn ? 'float-right' : 'text-center'}>
              <SCButton
                id={`btn-${pageElementID}-submit`}
                type="primary"
                onClick={handleSubmit}
              >
                {typeof data.submitBtnIcon === 'string' && <SCIcon icon={data.submitBtnIcon} className="mr-8px" />}
                {data.submitText ? t(data.submitText) : t('Common.Button.OK')}
              </SCButton>
            </div>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}
