import React, { lazy } from 'react';
import { match, useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import urljoin from 'url-join';
import { DealsAndDiscountPageRoute } from '@utils/enum/DealsAndDiscountEnum';
import DealsAndDiscountListPage from './deals-and-discount-list/DealsAndDiscountListPage';
import AddAndEditDealsAndDiscountPage from './add-edit';
import DealsAndDiscountDetailPage from './detail/DealsAndDiscountDetailPage';


export default function DealsAndDiscountPageRouting(
  props: React.PropsWithChildren<any>
): React.FunctionComponentElement<any> {
  const routeMatch: match<{ promotionID: any }> = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          exact={false}
          path={urljoin(routeMatch.url, DealsAndDiscountPageRoute.ADD)}
          component={AddAndEditDealsAndDiscountPage}
        />
        <Route
          exact={false}
          path={urljoin(routeMatch.url, DealsAndDiscountPageRoute.EDIT)}
          component={AddAndEditDealsAndDiscountPage}
        />
        <Route
          exact={false}
          path={urljoin(routeMatch.url, DealsAndDiscountPageRoute.DETAIL)}
          component={DealsAndDiscountDetailPage}
        />
        <Route
          exact={false}
          path={urljoin(routeMatch.url, DealsAndDiscountPageRoute.LIST)}
          component={DealsAndDiscountListPage}
        />
        <Redirect to={urljoin(routeMatch.url, DealsAndDiscountPageRoute.LIST)} />
      </Switch>
    </>
  );
}
