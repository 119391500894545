import Header from "@components/layout/Header";
import React from "react";
import FooterContact from "@components/layout/FooterContact";
import Img403 from '../../assets/images/frontend/403.png'
import { useTranslation } from "react-i18next";
import { MaintenanceConfigResponse } from "@api/response/ConfigResponse";

export interface IMaintenancePageProp {
    maintenanceConfig: MaintenanceConfigResponse;
}

export default function MaintenancePage(props: React.PropsWithChildren<IMaintenancePageProp>): React.FunctionComponentElement<any> {
    const { maintenanceConfig } = props;
    const { t } = useTranslation()

    return <>
        <Header
            notShowIconSideMenu={true}
            noAppSwitcher={true}
            clickLogoNoRedirect={true}
        />
        <div className="content">
            <div className="content-align-middle">
                <div className="d-flex align-items-center">
                    <div className="text-center">
                        <img src={Img403} width="240" className="mb-4" />
                        <h3 className="title-header">{t("Common.Label.Maintenance")}</h3>
                        <p
                            dangerouslySetInnerHTML={maintenanceConfig &&
                            {
                                __html: t("Common.Text.Maintenance")
                                    .replace(/\{0\}/g, maintenanceConfig?.date)
                                    .replace("{1}", maintenanceConfig?.startTime)
                                    .replace("{2}", maintenanceConfig?.endTime)
                            }
                            }>
                        </p>
                    </div>
                </div>
            </div>
            <FooterContact />
        </div>

    </>;
}