import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { CountryResponse } from '../../api/response/CountryResponse';
import { Language } from '../../utils/constants/CommonConstants';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InputToggle } from './InputToggle';

interface IProps extends DropdownProps {
  elementID?: string;
  countries: CountryResponse[];
  values?: CountryResponse;
  disabled?: boolean;
  searching?: boolean;
  searchingNotFound?: string;
  dropdownClass?: string;
  dropdownToggleClass?: string;
  dropdownMenuClass?: string;
  dropdownItemClass?: string;
  onChange: (countryResponse: CountryResponse) => void;
}

export const DropdownCountries = React.forwardRef((
  props: React.PropsWithChildren<IProps>, ref: any
) => {
  const {
    elementID,
    countries,
    values,
    disabled = false,
    searching,
    searchingNotFound,
    dropdownClass = '',
    dropdownToggleClass = '',
    dropdownMenuClass = '',
    dropdownItemClass = '',
    show,
    onChange,
    ...dropdownProps } = props;
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLElement>(null);
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValuePlaceholder, setSearchValuePlaceholder] = useState<string>('');

  useEffect(() => {
    searchInputRef?.current?.blur();
  }, [values]);

  useEffect(() => {
    if (!searchValue && !searchValuePlaceholder) {
      searchInputRef?.current?.blur();
    }
  }, [searchValue, searchValuePlaceholder]);

  const onClickCallingCodeDropdown = (country: CountryResponse) => {
    onChange({
      ...country
    });
  };

  const toggleDropdown = (e: boolean) => {
    if (e) {
      setSearchValuePlaceholder(t('Common.Label.PleaseInputCountry'));
    } else {
      setSearchValue('');
      setSearchValuePlaceholder('');
    }
  };

  const renderCountryName = (country?: CountryResponse): string => {
    if (country) {
      if (i18n.language === Language.en.key) {
        return `${country.countryName}`;
      } else {
        return `${t(country.countryNameCode)} (${country.countryName})`;
      }
    }
    return '';
  };

  const dropdownItem = countries.filter((c, i) =>
  (
    c?.countryCode?.includes(searchValue.toUpperCase())! ||
    c?.countryName?.toLowerCase().includes(searchValue.toLowerCase())! ||
    i18n.t(c?.countryNameCode, { lng: 'th' }).includes(searchValue)! ||
    i18n.t(c?.countryNameCode, { lng: 'en' }).toLowerCase().includes(searchValue.toLowerCase())!
  )).map((calling, index) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-${index}`}
        key={`calling-code-${index}`}
        className={classNames(`${dropdownItemClass}`, {
          active: `${calling.countryCode}` === values?.countryCode
        })}
        onSelect={() => {
          const contry = calling;
          onClickCallingCodeDropdown(contry)
        }}
      >
        <div className="row">
          <div className="col-12">
            <div className="dropdown-inline-icon">
              {CommonHelper.renderFlagCountry(calling?.countryCode, 'icon-flags mt-0')}
              <div className="dropdown-inline-text">{renderCountryName(calling)}</div>
            </div>
          </div>
        </div>
      </Dropdown.Item>
    );
  })

  return (
    <>
      <Dropdown className={classNames(`${dropdownClass}`, { disabled: disabled })} {...dropdownProps} ref={dropdownRef}
        onToggle={toggleDropdown}>
        {searching ?
          <>
            <Dropdown.Toggle as={InputToggle} id={`ddl-${elementID}`} variant=""
              className={dropdownToggleClass}
              ref={searchInputRef}
              elementID={elementID}
              value={searchValue}
              onChangeInput={(e: any) => { setSearchValue(e?.target?.value!) }}>
            </Dropdown.Toggle>
            {!searchValuePlaceholder ? <>
              <i data-toggle="dropdown" data-reference="parent"
                className="icon-small-dropdown dropdown-button"></i>
              <div className="dropdown-text">
                {
                  values ? (
                    <>
                      <div className="dropdown-inline-icon">
                        {CommonHelper.renderFlagCountry(values?.countryCode, 'icon-flags mt-11')}
                        <div id={`ddl-${elementID}`} className="pl-10 ellipsis">
                          {renderCountryName(values)}
                        </div>
                      </div>
                    </>
                  ) : (<><span className="placeholder">{t('Common.Label.PleaseInputCountry')}</span></>)}
              </div>
            </> :
              <>{!searchValue &&
                <div className="dropdown-text">
                  <div className="dropdown-inline-icon">
                    {searchValuePlaceholder}
                  </div>
                </div>
              }
              </>
            }
          </>
          :
          <Dropdown.Toggle className={dropdownToggleClass} id={`ddl-${elementID}`} variant="">
            {
              values ? (
                <>
                  <div className="dropdown-inline-icon">
                    {CommonHelper.renderFlagCountry(values?.countryCode, 'icon-flags')}
                    <div id={`ddl-${elementID}`} className="dropdown-inline-text">
                      {renderCountryName(values)}
                    </div>
                  </div>
                </>
              ) : (<><span className="placeholder">{t('Common.Label.Country')}</span></>)}
          </Dropdown.Toggle>
        }

        <Dropdown.Menu className={dropdownMenuClass}>
          <div className="dropdown-list">
            {dropdownItem.length > 0 ? dropdownItem :
              <div className="no-results" id={`opt-${elementID}-no-results`} style={{ display: 'block' }}>
                {searchingNotFound ? searchingNotFound : t('Common.Label.NoResultsFound')}
              </div>
            }
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
});