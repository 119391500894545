import React from 'react';

export function SkeletonLoad(
  props: React.PropsWithChildren<any>
): React.FunctionComponentElement<any> {

  return (
    <>
      <div className="content">
        <div className="card-content w-600">
          <div style={{ "width": "50%" }} className="skeleton-input mb-4"></div>
          <div className="card">
            <div className="card-body">
              <div style={{ "width": "50%" }} className="skeleton-input"></div>
              <div style={{ "width": "100%" }} className="skeleton-input"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
