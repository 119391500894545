import classNames from 'classnames';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PageRoute from '@utils/constants/PageRoute';
import { SettingPageRoute } from '@utils/enum/SettingEnum';
import useGoogleAnalytics, { IGoogleAnalytic } from "@google/useGoogleAnalytics";
import { GoogleAnalyticsConstantEvent } from "@utils/constants/GoogleAnalyticsConstant";
import { EventArgs } from "react-ga";

export interface SidebarMenuItem {
  title: string;
  icon?: string;
  to?: string;
  activePath?: string;
  gaEvent?: IGoogleAnalytic;
}

export default function SettingSideMenu({
  children,
}: React.PropsWithChildren<unknown>): React.FunctionComponentElement<unknown> {
  const { t } = useTranslation();
  const history = useHistory();
  const { setRequestGA } = useGoogleAnalytics();
  const menuConfig: SidebarMenuItem[] = [
    {
      title: 'Setting.Menu.AccountDetails',
      activePath: `${PageRoute.SETTING}/${SettingPageRoute.ACCOUNT_DETAIL}`,
      to: `${PageRoute.SETTING}/${SettingPageRoute.ACCOUNT_DETAIL}`,
      gaEvent: {
        suffixPathAction: GoogleAnalyticsConstantEvent.VIEW_ACCOUNT_DETAIL,
        event: { action: GoogleAnalyticsConstantEvent.VIEW_ACCOUNT_DETAIL } as EventArgs
      }

    }, {
      title: 'Setting.Menu.Notifications',
      activePath: `${PageRoute.SETTING}/${SettingPageRoute.NOTIFICATION}`,
      to: `${PageRoute.SETTING}/${SettingPageRoute.NOTIFICATION}`,
      gaEvent: {
        suffixPathAction: GoogleAnalyticsConstantEvent.VIEW_NOTIFICATIONS,
        event: { action: GoogleAnalyticsConstantEvent.VIEW_NOTIFICATIONS } as EventArgs
      }
    }
  ];

  const redirect = (url?: string) => {
    if (url) {
      history.push(url);
    }
  }

  return (<>
    <div className="left-menu">
      <div className="left-menu-inner">
        <div className="lfw-ml-auto">
          <div className="left-menu-title">{t("Setting.Menu.Settings")}</div>
          <div className="nav nav-tabs-1 nav-border">
            {menuConfig.map((menu, menuIndex) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const isMenuMatch = useRouteMatch({
                path: menu.activePath,
                strict: true,
                sensitive: true,
              });
              return (
                <li key={menuIndex}>
                  <a onClick={() => {
                    if (menu?.gaEvent) {
                      setRequestGA({
                        suffixPathAction: menu.gaEvent.suffixPathAction,
                        event: menu.gaEvent.event
                      });
                    }
                    redirect(menu.to)
                  }}
                    className={classNames({
                      active: !!isMenuMatch
                    })} >
                    {t(menu.title)}
                  </a>
                </li>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </>
  );
}
