export enum ModalSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}


export enum ModalBackdrop {
  STATIC = 'static'
}