import React, { useEffect, useRef, useState } from 'react';
import { useDealsAndDiscountDetailContext } from './DealsAndDiscountDetailContext';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  DealsAndDiscountAction,
  DealsAndDiscountActionType,
  DealsAndDiscountListStatusEnum,
  DealsAndDiscountPageRoute,
} from '@utils/enum/DealsAndDiscountEnum';
import PromotionProductItem from './components/PromotionProductItem';
import { DateTimeFormat, Language } from '@utils/constants/CommonConstants';
import { Link } from 'react-router-dom';
import urljoin from 'url-join';
import PageRoute from '@utils/constants/PageRoute';
import { PromotionDetail, PromotionListFilterStatusType } from '@graphql/autogenerate/schemas';
import { CancelModal } from '@components/modal/CancelModal';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { DeleteModal } from '@components/modal/DeleteModal';
import { useUpsertPromotionMutation } from '@graphql/autogenerate/hooks';
import { DealsAndDiscountHelper } from '@utils/helpers/DealsAndDiscountHelper';
import { SomethingWentWrongModal } from '@components/modal/SomethingWentWrongModal';
import { useSmartAlert } from '@components/smart-alert/SmartAlert';
import { ApolloError } from '@apollo/client';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import { CompanyItem, usePTVNAuthentication } from '@ptvn-react/authentication';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PromotionDetails from './components/PromotionDetails';
import { DescriptionWithFooterModal } from '@components/modal/DescriptionWithFooterModal';
import { GoogleAnalyticsConstantEvent } from '@utils/constants/GoogleAnalyticsConstant';
import { EventArgs } from 'react-ga';
import useGoogleAnalytics from '@google/useGoogleAnalytics';
import { Subscription } from 'rxjs';
export interface IDealsAndDiscountPageLayoutProps {
  elementID: string;
}

export type DealsAndDiscountLayoutComponent =
  React.FunctionComponent<IDealsAndDiscountPageLayoutProps>;
export interface IDealsAndDiscountPageLayoutCardProps {
  elementID?: string;
  title?: string;
}
export default function DealsAndDiscountDetailPageLayout(): React.FunctionComponentElement<IDealsAndDiscountPageLayoutCardProps> {
  const { t, i18n } = useTranslation();
  const { push } = useModalStack();
  const { requestGAObservable } = useGoogleAnalytics();
  const [elementID, setElementID] = useState<string>('DealsAndDiscount');
  const [elementTabID, setTabElementID] = useState<string>('tab-1');
  const [elementTab1, setTabElement1] = useState<string>('active');
  const [elementTab2, setTabElement2] = useState<string>('');
  const promotionRef = useRef<HTMLDivElement>(null);
  const [upsertPromotionMutation] = useUpsertPromotionMutation();
  const alert = useSmartAlert();
  const history = useHistory();
  const { promotionDetail, loadPromotionList } = useDealsAndDiscountDetailContext();
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    return () => {
      subscription && subscription.unsubscribe();
    }
  }, [subscription])

  const onChangeTab = (tabId: string) => {
    if (tabId == 'tab-1') {
      setTabElement1('active');
      setTabElement2('');
    } else {
      setTabElement1('');
      setTabElement2('active');
    }
    setTabElementID(tabId);
  };

  const handleClickEdit = (promotion: PromotionDetail) => {
    setSubscription(requestGAObservable({
      suffixPathAction: GoogleAnalyticsConstantEvent.EDIT_PROMOTION,
      event: { action: GoogleAnalyticsConstantEvent.EDIT_PROMOTION } as EventArgs
    }).subscribe(res => {
      const promotionID = promotion.promotionID!.toString();
      history.push(`${PageRoute.DEALS_AND_DISCOUNT_EDIT.replace(':promotionID', promotionID)}`);
    }));
  };

  const handleClickCancel = (promotion: PromotionDetail) => {
    push(CancelModal, {
      data: {
        title: t('DealsAndDiscount.Modal.CancelPromotion.Title'),
        description: t('DealsAndDiscount.Modal.CancelPromotion.Description'),
        submitText: t('Common.Button.YesCancel'),
        cancelText: t('Common.Button.No'),
        submitType: 'primary',
        label: t('Common.Label.Note'),
      },
      pageElementID: elementID,
      onClose: () => { },
      onSubmit: (value: string) => {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.CANCEL_PROMOTION,
          event: { action: GoogleAnalyticsConstantEvent.CANCEL_PROMOTION } as EventArgs
        }).subscribe(res => {
          cancelPromotion(promotion, value);
        }));
      }
    });
  };

  const handleClickDelete = (promotion: PromotionDetail) => {
    push(DeleteModal, {
      data: {
        title: t('DealsAndDiscount.Modal.DeletePromotion.Title'),
        description: t('DealsAndDiscount.Modal.DeletePromotion.Description'),
        submitText: t('Common.Button.YesDelete'),
        cancelText: t('Common.Button.No'),
      },
      pageElementID: elementID,
      onClose: () => { },
      onSubmit: () => {
        setSubscription(requestGAObservable({
          suffixPathAction: GoogleAnalyticsConstantEvent.DELETE_PROMOTION,
          event: { action: GoogleAnalyticsConstantEvent.DELETE_PROMOTION } as EventArgs
        }).subscribe(res => {
          deletePromotion(promotion);
        }));
      }
    });
  };

  const cancelPromotion = (promotion: PromotionDetail, remark: string) => {
    upsertPromotionMutation({
      variables: {
        input: {
          ...DealsAndDiscountHelper.getUpdatePromotionStatusRequestObject(
            'cancelled',
            remark,
            promotion,
            currentCompany
          ),
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        } else {
          alert.show(t('DealsAndDiscount.Alert.YourPromotionCancelled'), 'success', true);
          loadPromotionList();
        }
      })
      .catch((error: ApolloError) => {
        const ex = CommonHelper.transformGraphQLErrors(error);
        if (ex && ex.length > 0) {
          let errMessage = '';
          ex.forEach((e) => {
            if (e.message) {
              if (errMessage) {
                errMessage += ' ' + e.message;
              } else {
                errMessage += e.message;
              }
            }
          });
          push(SomethingWentWrongModal, {
            errMessage: errMessage,
            onSubmit: () => { },
          });
        } else {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        }
      });
  };

  const deletePromotion = (promotion: PromotionDetail) => {
    upsertPromotionMutation({
      variables: {
        input: {
          ...DealsAndDiscountHelper.getUpdatePromotionStatusRequestObject(
            'deleted',
            promotion?.remark!,
            promotion,
            currentCompany
          ),
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        } else {
          alert.show(t('DealsAndDiscount.Alert.YourPromotionDeleted'), 'success', true);
          history.push(`${urljoin(PageRoute.DEALS_AND_DISCOUNT, DealsAndDiscountPageRoute.LIST)}`);
        }
      })
      .catch((error: ApolloError) => {
        const ex = CommonHelper.transformGraphQLErrors(error);
        if (ex && ex.length > 0) {
          let errMessage = '';
          ex.forEach((e) => {
            if (e.message) {
              if (errMessage) {
                errMessage += ' ' + e.message;
              } else {
                errMessage += e.message;
              }
            }
          });
          push(SomethingWentWrongModal, {
            errMessage: errMessage,
            onSubmit: () => { },
          });
        } else {
          push(SomethingWentWrongModal, {
            onSubmit: () => { },
          });
        }
      });
  };

  const actionDelete = (promotion: PromotionDetail, index: number) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-action-delete-${index}`}
        className="dropdown-item"
        onSelect={() => handleClickDelete(promotion)}
      >
        <div className="dropdown-inline-icon">
          <i className="icon-delete">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="dropdown-inline-text">{t('DealsAndDiscount.Table.Action.Delete')}</div>
        </div>
      </Dropdown.Item>
    );
  };

  const actionCancel = (promotion: PromotionDetail, index: number) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-action-cancel-${index}`}
        className="dropdown-item"
        onSelect={() => handleClickCancel(promotion)}
      >
        <div className="dropdown-inline-icon">
          <i className="icon-error">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="dropdown-inline-text">{t('DealsAndDiscount.Table.Action.Cancel')}</div>
        </div>
      </Dropdown.Item>
    );
  };

  const actionEdit = (promotion: PromotionDetail, index: number) => {
    return (
      <Dropdown.Item
        id={`opt-${elementID}-action-edit-${index}`}
        className="dropdown-item"
        onSelect={() => handleClickEdit(promotion)}
      >
        <div className="dropdown-inline-icon">
          <i className="icon-edit">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="dropdown-inline-text">{t('DealsAndDiscount.Table.Action.Edit')}</div>
        </div>
      </Dropdown.Item>
    );
  };

  const renderAction = () => {
    let promotion = promotionDetail;
    let index = promotionDetail?.promotionID!;
    switch (promotion?.status?.toUpperCase()) {
      case DealsAndDiscountListStatusEnum.ONGOING:
        return (
          <>
            {actionEdit(promotion, index)}
            {actionCancel(promotion, index)}
          </>
        );
      case DealsAndDiscountListStatusEnum.UPCOMING:
        return (
          <>
            {actionEdit(promotion, index)}
            {actionCancel(promotion, index)}
          </>
        );
      case DealsAndDiscountListStatusEnum.DRAFT:
        return (
          <>
            {actionEdit(promotion, index)}
            {actionDelete(promotion, index)}
          </>
        );
      case DealsAndDiscountListStatusEnum.CANCELLED:
        return <></>;
      case DealsAndDiscountListStatusEnum.EXPIRED:
        return <></>;
    }
  };

  const renderStatus = () => {
    let promotion = promotionDetail;
    let index = promotionDetail?.promotionID!;
    switch (promotion?.status?.toUpperCase()) {
      case PromotionListFilterStatusType.Ongoing:
        return (
          <div className="d-flex status-flex ml-auto" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-green">{t('DealsAndDiscount.Label.Ongoing')}</div>
            </div>
          </div>
        );
      case PromotionListFilterStatusType.Upcoming:
        return (
          <div className="d-flex status-flex ml-auto" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-blue">{t('DealsAndDiscount.Label.Upcoming')}</div>
            </div>
          </div>
        );
      case PromotionListFilterStatusType.Draft:
        return (
          <div className="d-flex status-flex ml-auto" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-yellow">{t('DealsAndDiscount.Label.Draft')}</div>
            </div>
          </div>
        );

      case PromotionListFilterStatusType.Cancelled:
        return (
          <>
            <div className="d-flex status-flex ml-auto" id={`lbl-${elementID}-status-${index}`}>
              <div className="d-flex align-items-center">
                <div className="status status-dark-gray">
                  {t('DealsAndDiscount.Label.Cancelled')}
                </div>
              </div>

              {promotion && promotion?.remark ? (
                <>
                  <div className="ml-1">
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={
                        <Tooltip
                          className="m-0"
                          id={`lbl-${elementID}-note-tooltip`}
                          style={{ zIndex: 999 }}
                        >
                          {t('DealsAndDiscount.Table.Body.Note')}
                        </Tooltip>
                      }
                    >
                      <button
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Note"
                        className="btn btn-icon"
                        onClick={() => handleClickNote(promotion!)}
                      >
                        <i className="icon-note">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </button>
                    </OverlayTrigger>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        );

      case PromotionListFilterStatusType.Expired:
        return (
          <div className="d-flex status-flex ml-auto" id={`lbl-${elementID}-status-${index}`}>
            <div className="d-flex align-items-center">
              <div className="status status-light-gray">{t('DealsAndDiscount.Label.Expired')}</div>
            </div>
          </div>
        );
    }
  };

  const handleClickNote = (promotion: PromotionDetail) => {
    push(DescriptionWithFooterModal, {
      elementID: elementID,
      data: {
        title: t('DealsAndDiscount.Modal.NotePromotion.Title'),
        description: promotion.remark! || '',
        actor: promotion.updateByName! || '',
        date:
          CommonHelper.displayDate(
            Number(promotion?.updateDate!),
            DateTimeFormat.DEFAULT_DATE_TIME_FORMAT
          ) || '-',
      },
    });
  };

  return (
    <>
      <div className="card-content w-full prl-24">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link
              to={urljoin(PageRoute.DEALS_AND_DISCOUNT, DealsAndDiscountPageRoute.LIST)}
              id={`a-${elementID}-product-list`}
            >
              <i className="icon-small-left"> </i>
              {t('DealsAndDiscount.Title.DealsAndDiscount')}
            </Link>
          </li>
          <li className="breadcrumb-item active">{t('DealsAndDiscount.Title.PromotionDetails')}</li>
        </ol>
        <div className="">
          <h2 className="d-flex align-items-center">
            <span className="ellipsis">
              {i18n.language === Language.en.key
                ? promotionDetail?.promotionNameEN
                : promotionDetail?.promotionNameLocal}
            </span>
            {renderStatus()}
            {promotionDetail?.status?.toUpperCase() !== DealsAndDiscountListStatusEnum.CANCELLED &&
              promotionDetail?.status?.toUpperCase() !== DealsAndDiscountListStatusEnum.EXPIRED ? (
              <>
                <div className="border-btn"></div>
                <OverlayTrigger
                  placement="top"
                  flip={true}
                  overlay={
                    <Tooltip
                      className="m-0"
                      id={`lbl-${elementID}-action-tooltip`}
                      style={{ zIndex: 999 }}
                    >
                      {t('DealsAndDiscount.Table.Body.Action')}
                    </Tooltip>
                  }
                >
                  <Dropdown
                    as="div"
                    style={{ borderRadius: 'unset' }}
                    className="btn-dropdown"
                    bsPrefix="-"
                  >
                    <Dropdown.Toggle
                      id={`ddl-${elementID}-option-${promotionDetail?.promotionID}`}
                      as="button"
                      bsPrefix="btn"
                      className="btn-icon btn-outline"
                    >
                      <i className="icon-action"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{renderAction()}</Dropdown.Menu>
                  </Dropdown>
                </OverlayTrigger>
              </>
            ) : (
              <></>
            )}
          </h2>
          <div className="row row-card">
            <div className="col-3">
              <div className="card">
                <div className="nav nav-tabs-1">
                  <a
                    id={elementID + '-nav-tab-promotionDetail'}
                    data-toggle="tab"
                    title="Promotion Details"
                    className={elementTab1}
                    onClick={(e) => onChangeTab('tab-1')}
                  >
                    {t('DealsAndDiscount.Title.PromotionDetails')}
                  </a>
                  <a
                    id={elementID + '-nav-tab-promotionProductItem'}
                    onClick={(e) => onChangeTab('tab-2')}
                    data-toggle="tab"
                    title="Products"
                    className={elementTab2}
                  >
                    {t('DealsAndDiscount.Title.Products')}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="tab-content p-0">
                {elementTabID == 'tab-1' ? (
                  <PromotionDetails elementID={elementID} refBox={promotionRef} />
                ) : (
                  <PromotionProductItem elementID={elementID} refBox={promotionRef} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
