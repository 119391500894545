export class DisplayValueMap<Display extends string = '', Value extends string = ''> {
  display: Display;
  value: Value;
  style?: string;

  constructor(display: Display, value: Value, style?: string) {
    this.display = display;
    this.value = value;
    this.style = style;
  }
}

export interface GraphQLErrorResponse {
  code?: string;
  message: string;
}

export interface DropdownSelectOptionBase {
  label: string;
  value: any;
}
