import { GatewayGraphQLClient } from "@api/GatewayGraphQLClient";
import { SCButton } from "@components/button/SCButton";
import Pagination from "@components/pagination/Pagination";
import { useSupplierProductListPromotionLazyQuery } from "@graphql/autogenerate/hooks";
import { LangType, ProductListFilterPricingType, ProductListFilterStatusType, ProductListInput, ProductListSortByType, SortDirType } from "@graphql/autogenerate/schemas";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDealsAndDiscountContext } from "./DealsAndDiscountContext";
import { DateTimeFormat, PageSizeDefaultValues } from '@utils/constants/CommonConstants';
import EmptyProductImg from '@assets/images/frontend/save-draft.png';
import { CompanyItem, usePTVNAuthentication } from "@ptvn-react/authentication";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import { CommonHelper } from "@utils/helpers/CommonHelper";
import { DealsAndDiscountAction, DiscountTypeOptionEnum } from "@utils/enum/DealsAndDiscountEnum";
import { DealsAndDiscount, PromotionProduct } from "@models/DealsAndDiscount";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import NotFoundProductImg from "@assets/images/frontend/not-found.png";
import { useHistory } from "react-router";
import PageRoute from "@utils/constants/PageRoute";
import { CountryCode } from "@utils/constants/CountryConstant";
import emptyFile from '@assets/images/thumbnail/empty-thumbnail-64.png';
import { useFormContext } from "react-hook-form";
import { Language } from '@utils/constants/CommonConstants';
interface ISelectProductProps {
  elementID: string;
}

export default function SelectProduct(
  props: React.PropsWithChildren<ISelectProductProps>
): React.FunctionComponentElement<ISelectProductProps> {
  const { elementID } = props;
  const thisElementID = elementID + '-SelectProducts';
  const { t, i18n } = useTranslation();
  const currentCompany = usePTVNAuthentication().currentCompany as CompanyItem;
  const { action, setSelectProductsPage, selectProductsPage, productItemsInPromotion, setProductItemsInPromotion, promotionID } = useDealsAndDiscountContext();
  const history = useHistory();
  const form = useFormContext<DealsAndDiscount>();
  const {
    getValues
  } = form;

  const defaultFilter = (): ProductListInput => {
    return {
      sortBy: ProductListSortByType.ProductName,
      sortDir: SortDirType.Asc,
      sortNameBy: LangType.En,
      companyID: currentCompany?.companyID!,
      branchIDs: [currentCompany?.branchId!],
      page: 1,
      pageSize: 10,
      searches: [],
      filterBy: {
        uom: [],
        productStatus: [ProductListFilterStatusType.Active],
        productPricingType: (getValues('promotionDetail.discountType') === DiscountTypeOptionEnum.value) ? [ProductListFilterPricingType.AdjustableProductPrice] : null
      },
    };
  };

  const [filter, setFilter] = useState<ProductListInput>(defaultFilter()!);

  const [productItem, setProductItem] = useState<PromotionProduct[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');

  const [
    loadProductList,
    { data: graphQLProductList, loading: isLoadingProductList, error: errorLoadProductList },
  ] = useSupplierProductListPromotionLazyQuery({
    client: GatewayGraphQLClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: filter!,
      lang: i18n.language.startsWith('en') ? LangType.En : LangType.Local,
      startDateInput: CommonHelper.composeDateTimeToTimestamp(
        CommonHelper.displayDate(Number(getValues('promotionDetail.startDate')!), DateTimeFormat.DDMMYYYY_HYPHEN),
        (getValues('promotionDetail.startTime')!) ? getValues('promotionDetail.startTime')! : '00:00',
        CommonHelper.getUserClientTimeZone(),
        DateTimeFormat.DDMMYYYY_HYPHEN,
        DateTimeFormat.HHmm
      )!,
      endDateInput: (getValues('promotionDetail.endDate')!) ? CommonHelper.composeDateTimeToTimestamp(
        CommonHelper.displayDate(Number(getValues('promotionDetail.endDate')!), DateTimeFormat.DDMMYYYY_HYPHEN),
        (getValues('promotionDetail.endTime')!) ? getValues('promotionDetail.endTime')! : '00:00',
        CommonHelper.getUserClientTimeZone(),
        DateTimeFormat.DDMMYYYY_HYPHEN,
        DateTimeFormat.HHmm
      )! : null,
      promotionID: Number(promotionID)!
    },
  });

  useEffect(() => {
    setProductItem([...productItemsInPromotion]);
  }, [productItemsInPromotion])

  useEffect(() => {
    filter &&
      setFilter((x) => {
        return x
          ? {
            ...x,
            filterBy: {
              ...x.filterBy,
              productPricingType: (getValues('promotionDetail.discountType') === DiscountTypeOptionEnum.value) ? [ProductListFilterPricingType.AdjustableProductPrice] : null
            }
          }
          : defaultFilter();
      });
  }, [getValues('promotionDetail.discountType')])

  useEffect(() => {
    if (graphQLProductList?.supplierProductList?.items?.length!) {
      let productIDs = productItem.map((p) => p.productID);
      if (graphQLProductList?.supplierProductList?.items?.filter(i => !productIDs.includes(i?.productID!)
        && !i?.promotionOverlap?.length).length || productItem.length === 0) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
    } else {
      setSelectAll(false);
    }
  }, [productItem, graphQLProductList?.supplierProductList?.items])

  useEffect(() => {
    if (selectProductsPage) {
      setInputSearchValue('');
      setFilter(defaultFilter());
      setProductItem([...productItemsInPromotion]);
    }
  }, [selectProductsPage]);

  useEffect(() => {
    if (filter) {
      loadProductList();
    }
  }, [filter]);

  const handleSubmit = () => {
    setProductItemsInPromotion([...productItem]);
    setSelectProductsPage(false);
  }

  const handleSortChange = (by: ProductListSortByType, dir: SortDirType, sortNameBy: LangType) => {
    filter &&
      setFilter((x) => {
        return x
          ? {
            ...x,
            sortBy: by,
            sortDir: dir,
            sortNameBy: sortNameBy!
          }
          : defaultFilter();
      });
  };

  const addSearch = (value: string) => {
    const trimmedValue = CommonHelper.trimValue(value);
    filter &&
      setFilter((x) => {
        return x
          ? {
            ...x,
            searches: [trimmedValue]
          }
          : defaultFilter();
      });
  };

  const handleEnterSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addSearch(e.currentTarget.value);
    }
  };

  const onClickSelectAll = () => {
    if (graphQLProductList?.supplierProductList?.items.length) {
      let productIDs = productItem.map(pi => pi.productID);
      setProductItem([...new Set(
        [...productItem,
        ...graphQLProductList?.supplierProductList?.items
          .filter(p => !productIDs.includes(p?.productID!) && !p?.promotionOverlap?.length)
          .map(p => {
            return {
              productID: p!.productID!,
              name: p?.name,
              sku: p?.sku,
              categoryName: p?.category?.find((category) => category?.level === 3)?.name,
              productImageUrl: p?.productImageUrl,
              discountValue: '',
              unitPrice: p?.productOption?.unitPrice,
              canNotBeApplied: false
            } as PromotionProduct
          })
        ]
      )
      ]);
      setSelectAll(true);
    }
  }

  const onClickUnSelectAll = () => {
    setProductItem([]);
    setSelectAll(false);
  }

  const checkDisableProductItem = (product: PromotionProduct): boolean => {
    return !!(product.promotionOverlap || product.canNotBeApplied);
  }

  const renderSelectProduct = (product: PromotionProduct, index: number) => {
    if (productItem?.length) {
      return (<>
        <li>
          <div className="box-product">
            <div className="product-img">
              <img src={product?.productImageUrl!} onError={(ev: any) => { ev.target.src = emptyFile }} width="64" />
            </div>
            <div className="product-detail">
              <div className="product-name">
                <span>
                  {product?.name || '-'}
                </span>
              </div>
              <div className="mt-8px">
                <small className={checkDisableProductItem(product) ? 'disabled' : ''}>
                  {t('DealsAndDiscount.Label.ParentSKU', {
                    SKU: (product?.sku) ? product?.sku : '-'
                  })}
                </small>
              </div>
              {checkDisableProductItem(product) &&
                <div className="mt-8px">
                  <small className="color-danger">
                    {product.canNotBeApplied ?
                      t('DealsAndDiscount.Label.NonPriceProductCanNotBeApplied')
                      : product.promotionOverlap &&
                      t('DealsAndDiscount.Label.OverlappingPromotion', {
                        promotionName: product?.promotionOverlap!
                      })
                    }
                  </small>
                </div>
              }
            </div>
            <div className="product-remove ml-auto">
              <OverlayTrigger
                placement="top"
                flip={true}
                overlay={
                  <Tooltip
                    className="m-0"
                    id={`lbl-${elementID}-remove-tooltip`}
                    style={{ zIndex: 999 }}
                  >
                    {t('DealsAndDiscount.Tootip.Remove')}
                  </Tooltip>
                }
              >
                <button type="button" data-toggle="tooltip" data-placement="top" title="Remove" className="btn btn-icon" onClick={() => {
                  if (index >= 0) {
                    let temp = [...productItem];
                    temp.splice(index, 1);
                    setProductItem(temp);
                  }
                }}>
                  <i className="icon-cancel-circle">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </li>
      </>
      );
    }
    return <></>
  }

  const renderCurrency = () => {
    switch (currentCompany?.countryCode!) {
      case CountryCode.TH:
        return 'THB';
      case CountryCode.VN:
        return 'VND'
      default:
        return 'USD';
    }
  }

  const renderPromotionOverlapName = (promotionOverlap: any[]): string => {
    return promotionOverlap.map(ov => {
      if (i18n.language === Language.en.key) {
        if (ov.promotionNameEN) {
          return ov.promotionNameEN
        } else if (ov.promotionNameLocal) {
          return ov.promotionNameLocal
        }
      } else {
        if (ov.promotionNameLocal) {
          return ov.promotionNameLocal
        } else if (ov.promotionNameEN) {
          return ov.promotionNameEN
        }
      }
      return '';
    }).toString();
  }

  return <>
    <div className="full-modal-wrap">
      <div className="full-modal-header">
        <div className="modal-head-nav" id={`lbl-${thisElementID}-header`}>
          <a onClick={() => setSelectProductsPage(false)} className="modal-nav-item">
            <i className="icon-left">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {action === DealsAndDiscountAction.EDIT ?
              t('DealsAndDiscount.Title.EditPromotion') :
              t('DealsAndDiscount.Title.AddPromotion')}
          </a>
          <strong>{t('DealsAndDiscount.Title.SelectProducts')}</strong>
        </div>
        <button type="button" className="close" id={`btn-${thisElementID}-exit`}
          onClick={() => setSelectProductsPage(false)}>
          <i className="icon-cancel"></i>
        </button>
      </div>
      {filter &&
        graphQLProductList?.supplierProductList &&
        graphQLProductList.supplierProductList.items.length > 0 ||
        filter?.searches?.length! > 0 ?
        <div className="full-modal-body">
          <div className="template-sticky-right">
            <div className="left-content">
              <div className="card">
                <div className="card-body no-hz-space">
                  <div className="row-search p-hz-default">
                    <div className="col-lg-4 col-sm-6 text-right">
                      <div className="input-group icon">
                        <input
                          id={`txt-${thisElementID}-search`}
                          type="text"
                          placeholder={t('DealsAndDiscount.Placeholder.SearchProduct')}
                          className="form-control"
                          value={inputSearchValue}
                          onChange={(e) => setInputSearchValue(e.target.value)}
                          onKeyDown={handleEnterSearchInput}
                        />
                        <button
                          id={`btn-${thisElementID}-search`}
                          type="button"
                          className="btn"
                          onClick={() => addSearch(inputSearchValue)}
                        >
                          <i className="icon-search">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {filter &&
                    graphQLProductList?.supplierProductList &&
                    graphQLProductList.supplierProductList.items.length > 0 ? (
                    <>
                      <div className="table-wrap">
                        <table className="table-no-berder">
                          <thead>
                            <tr>
                              <th style={{ width: "60" }}>
                                <input
                                  id={`chk-${elementID}-check-all}`}
                                  checked={selectAll}
                                  type="checkbox"
                                  onChange={() => {
                                    if (selectAll) {
                                      onClickUnSelectAll()
                                    } else {
                                      onClickSelectAll()
                                    }
                                  }} />
                              </th>
                              <th>
                                <div className="d-flex align-items-center nowrap">
                                  {t('ProductList.Label.Product')}
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id={`btn-${elementID}-sort`}
                                      as="a"
                                      variant=""
                                      bsPrefix="btn-icon"
                                    >
                                      {filter?.sortBy === ProductListSortByType.ProductName ?
                                        (
                                          <i className={classNames('icon-sort', 'ml-8px', {
                                            'desc': filter?.sortDir === SortDirType.Desc,
                                            'asc': filter?.sortDir === SortDirType.Asc,
                                          })}>
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </i>
                                        ) :
                                        (
                                          <i className="icon-sort ml-8px">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </i>
                                        )
                                      }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '110px', minWidth: '110px' }}>
                                      <div className="dropdown-list">
                                        <Dropdown.Item
                                          id={`btn-${elementID}-sort-productName-en-asc`}
                                          onSelect={() => handleSortChange(ProductListSortByType.ProductName, SortDirType.Asc, LangType.En)}
                                        >
                                          {t('Product Name: A - Z')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          id={`btn-${elementID}-sort-productName-en-desc`}
                                          onSelect={() => handleSortChange(ProductListSortByType.ProductName, SortDirType.Desc, LangType.En)}
                                        >
                                          {t('Product Name: Z - A')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          id={`btn-${elementID}-sort-productName-local-asc`}
                                          onSelect={() => handleSortChange(ProductListSortByType.ProductName, SortDirType.Asc, LangType.Local)}
                                        >
                                          {t('Product Name: ก - ฮ')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          id={`btn-${elementID}-sort-productName-local-desc`}
                                          onSelect={() =>
                                            handleSortChange(ProductListSortByType.ProductName, SortDirType.Desc, LangType.Local)
                                          }
                                        >
                                          {t('Product Name: ฮ - ก')}
                                        </Dropdown.Item>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </th>
                              <th style={{ width: "230" }}>{t('ProductList.Label.Category')}</th>
                              <th style={{ width: "222" }} className="text-right">
                                {t('DealsAndDiscount.Label.UnitPriceCurrency', {
                                  currency: renderCurrency()
                                })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {graphQLProductList?.supplierProductList?.items.map((product, index) => {
                              return (
                                <tr key={"products-item" + index} className={product?.promotionOverlap?.length! > 0 ? 'disabled' : ''}>
                                  <td>
                                    <input
                                      disabled={product?.promotionOverlap?.length! > 0}
                                      id={`chk-${thisElementID}-${index}`}
                                      type="checkbox"
                                      checked={!!(productItem.filter(i => i.productID === product?.productID).length)}
                                      onChange={() => {
                                        let productChecked = productItem.find(i => i.productID === product?.productID);
                                        if (productChecked) {
                                          let temp = [...productItem];
                                          var removeIndex = temp.map((item) => item.productID).indexOf(product?.productID!);
                                          temp.splice(removeIndex, 1);
                                          setProductItem(temp);
                                        } else {
                                          let temp = [...productItem];
                                          temp.push({
                                            productID: product!.productID!,
                                            name: product?.name,
                                            sku: product?.sku,
                                            categoryName: product?.category?.find((category) => category?.level === 3)?.name,
                                            productImageUrl: product?.productImageUrl,
                                            discountValue: '',
                                            unitPrice: product?.productOption?.unitPrice,
                                            canNotBeApplied: false
                                          } as PromotionProduct);
                                          setProductItem(temp);
                                        }
                                      }} />
                                  </td>
                                  <td>
                                    <div className="box-product">
                                      <div className="product-img">
                                        <img src={product?.productImageUrl!} onError={(ev: any) => { ev.target.src = emptyFile }} width="64" />
                                      </div>
                                      <div className="product-detail">
                                        <div className="product-name"> <strong>{product?.name || '-'}</strong></div>
                                        <div className="mt-8px">
                                          <small className={product?.promotionOverlap?.length! > 0 ? 'disabled' : ''}>
                                            {t('DealsAndDiscount.Label.ParentSKU', {
                                              SKU: (product?.sku) ? product?.sku : '-'
                                            })}</small>
                                        </div>
                                        {product?.promotionOverlap?.length! > 0 &&
                                          <div className="mt-8px">
                                            <small className="color-danger"> {t('DealsAndDiscount.Label.OverlappingPromotion', {
                                              promotionName: renderPromotionOverlapName(product?.promotionOverlap!)
                                            })}</small>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="product-cat">{product?.category?.find((category) => category?.level === 3)?.name || '-'}</div>
                                  </td>
                                  <td className="text-right">
                                    {(product?.productOption?.unitPrice) ? <NumberFormat
                                      displayType="text"
                                      value={Number(product?.productOption?.unitPrice)! || '-'}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}
                                    /> :
                                      <p className="disabled">
                                        {t('DealsAndDiscount.Label.NonPriceProduct')}
                                      </p>
                                    }
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        elementIdPrefix={thisElementID}
                        elementIdSuffix="product"
                        currentPage={graphQLProductList.supplierProductList.page}
                        totalPage={graphQLProductList.supplierProductList.totalPage}
                        totalItem={graphQLProductList.supplierProductList.totalItem}
                        pageSize={graphQLProductList.supplierProductList.pageSize}
                        pageSizes={PageSizeDefaultValues}
                        onSelectPageSize={(pageSize: number) => {
                          filter && setFilter({ ...filter, page: 1, pageSize });
                        }}
                        onSelectPageNumber={(pageNo: number) => {
                          filter && setFilter({ ...filter, page: pageNo });
                        }}
                      />
                    </>
                  ) : (
                    <div className="form-group-border">
                      <div className="result-found">
                        {isLoadingProductList ? (
                          <div className="loading" style={{ position: 'absolute' }}>
                            <i className="icon-loading spinner"></i>
                          </div>
                        ) : (
                          <>
                            <img src={NotFoundProductImg} className="img-result" />
                            <div className="title">{t('Common.Label.NoResultsFound')}</div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="right-sticky">
              <div className="card">
                <div className="card-body box-select-products" style={{ overflowY: "auto" }}>
                  <div className="title mb-8px">{t('DealsAndDiscount.Label.SelectedProducts')}</div>
                  <div className={productItem.length > 0 ? 'mb-8px' : 'mb-4'}>
                    <small>
                      {t('DealsAndDiscount.Label.NumberProducts', {
                        number: productItem.length
                      })}
                    </small>
                  </div>
                  {productItem.length > 0 ?
                    <ul className="product-selected-list">
                      {
                        productItem.map((product, index) => {
                          return (
                            <div key={"selected-product" + index} className={`row${checkDisableProductItem(product) ? ' disabled' : ''}`}>
                              <div className='col'>
                                {renderSelectProduct(product, index)}
                              </div>
                            </div>
                          )
                        })
                      }
                    </ul>
                    :
                    <div className="result-found clear-min-h">
                      <img src={EmptyProductImg} className="img-result" />
                      <p>{t('DealsAndDiscount.Label.NoProductsSelected')}</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        : (
          <div className="full-modal-body">
            <div className="card">
              <div className="card-body">
                <div className="result-found">
                  {isLoadingProductList ? (
                    <div className="loading" style={{ position: 'absolute' }}>
                      <i className="icon-loading spinner"></i>
                    </div>
                  ) : (
                    <>
                      <img src={EmptyProductImg} className="img-result" />
                      <div className="title">{t('DealsAndDiscount.Label.NoProducts')}</div>
                      <p>{
                        t('DealsAndDiscount.Label.YouCanGoTo') + " "}
                        <a style={{ color: '#4b98cd' }} onClick={() => history.push(PageRoute.PRODUCT)}>{t('DealsAndDiscount.Label.ProductAndServices')}</a>
                        {" " + t('DealsAndDiscount.Label.ToAddYourProducts')}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="full-modal-footer has-3-btn">
        <div className="float-right">
          <SCButton
            type="primary"
            className="float-right"
            id={`btn-${thisElementID}-confirm`}
            onClick={() => handleSubmit()}
          >
            {t('DealsAndDiscount.Button.Confirm')}
          </SCButton>
          <SCButton
            type="outline"
            className="float-right noborder mr-2 mobile"
            id={`btn-${thisElementID}-saveDraft`}
            onClick={() => setSelectProductsPage(false)}
          >
            {t('Common.Button.Cancel')}
          </SCButton>
        </div>
      </div>
    </div>
  </>;
}
