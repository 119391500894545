export enum AutoAcceptPOPageRoute {
  AUTO_ACCEPT_PO = 'auto-accept-po'
}

export enum AutoAcceptPOActionEnum {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum AutoAcceptPOStatusEnum {
  DRAFT = "Draft",
  AWAITING_APPROVOL = "Awaiting",
  REJECTED = "Rejected",
  CANCELLED = "Cancelled",
  APPROVED = "Approved",
  APPROVED_TO_EP = "Approved_to_ep",
}

export enum AutoAcceptPOActiveStatusEnum {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  ACTIVE_ON_EP = "Active_on_ep",
  INACTIVE_ON_EP = "Inactive_on_ep"
}

export enum AutoAcceptPOErrorEnum {
  SUBMIT_BY_ANOTHER = '4047',
  UPDATE_STATUS_BY_ANOTHER = '4050',
  UPDATE_REQUEST_STATUS_BY_ANOTHER = '4051',
  CANNOT_ACTIVE_INACTIVE = '4057',
  UNABLE_TO_ACTIVE_ON_EP = '5024',
  INVALID_FILE_EXTENSION = '4020',
  INVALID_FILE_SIZE = '4021',
  EMAIL_DUPLICATE = '4060'
}
