import React, { useEffect, useState } from 'react';
import { useDealsAndDiscountContext } from './DealsAndDiscountContext';
import DealsAndDiscountPage from './DealsAndDiscountPage';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DealsAndDiscountAction, DealsAndDiscountActionType } from '@utils/enum/DealsAndDiscountEnum';
export interface IDealsAndDiscountPageLayoutProps {
  elementID: string;
}

export type DealsAndDiscountLayoutComponent = React.FunctionComponent<IDealsAndDiscountPageLayoutProps>;
export interface IDealsAndDiscountPageLayoutCardProps {
  elementID?: string;
  title?: string;
}
export default function DealsAndDiscountPageLayout(
): React.FunctionComponentElement<IDealsAndDiscountPageLayoutCardProps> {
  const { action } = useDealsAndDiscountContext();
  const [elementID, setElementID] = useState<string>('DealsAndDiscount');

  useEffect(() => {
    if (action) {
      setElementID(action + '-' + elementID);
    }
  }, [action]);

  const renderActionPage = (action: DealsAndDiscountActionType) => {
    switch (action) {
      case DealsAndDiscountAction.ADD:
      case DealsAndDiscountAction.EDIT:
        return <DealsAndDiscountPage
          elementID={elementID} />
      case DealsAndDiscountAction.NOT_FOUND:
        return <div>Notfound</div>;
      case DealsAndDiscountAction.ERROR:
        return <div>Error</div>;
    }
  }

  return (
    <>
      {action ?
        renderActionPage(action)
        :
        <><div>loading</div></>
      }
    </>
  );
}
