import React from 'react';
import classNames from 'classnames';

export const Icons = [
  'check',
  'reload',
  'edit',
  'copy',
  'toggle-inactive',
  'toggle-active',
  'delete',
  'history',
  'eye',
  'crop',
  'cancel',
  'sm-check',
  'sm-close'
] as const;
export type IconType = typeof Icons[number];

export const SCColors = ['success', 'danger', 'primary'] as const;
export type ColorType = typeof SCColors[number];

export interface PIconProps {
  icon: IconType;
  color?: ColorType;
  inactive?: boolean;
  className?: string;
}

export function SCIcon({
  icon,
  color,
  inactive,
  className = '',
}: React.PropsWithChildren<PIconProps>): React.FunctionComponentElement<PIconProps> {
  const classNameObj = { 'icon-inactive': inactive };
  switch (icon) {
    case 'reload':
      return (
        <i className={classNames('icon-reload', classNameObj, className)}>
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      );
    default:
      return (
        <i
          className={classNames(
            classNameObj,
            color ? `color-${color}` : '',
            `icon-${icon}`,
            className
          )}
        >
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      );
  }
}
