import React, { } from 'react';

type InputToggleProps = {
  children?: React.ReactNode;
  elementID?: string;
  value?: string;
  searchValue?: string;
  onChangeInput: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {};
};

export const InputToggle = React.forwardRef(
  (props: InputToggleProps, ref: React.Ref<HTMLInputElement>) => (
    <>
      <input type="text"
        id={`ddl-${props.elementID}`}
        data-toggle="dropdown"
        className="form-control"
        ref={ref}
        autoComplete="new-password"
        style={{ zIndex: 3 }}
        value={props.value}
        onClick={(e) => {
          e.preventDefault();
          props.onClick(e);
        }}
        onChange={(e) => {
          props.onChangeInput(e);
        }}
      />
      {props.children}
    </>
  )
);