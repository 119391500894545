export class GoogleAnalyticsConstantSuffixPage {
  public static readonly COMPANY_MANAGEMENTE = "Company Menagement";
  public static readonly REGISTER_COMPANY = "Register Company";
  public static readonly REQUEST_TO_JOIN_COMPANY = "Request to Join";
  public static readonly ADD_NEW_BRANCH = "Add New Branch";
  public static readonly SUMMARY_COMPANY_INFORMATION = "Summary company infomation";
  public static readonly EDIT_COMPANY_PROFILE = "Edit Company Profile";
  public static readonly REQUEST_REJECT = "Request Rejected";
  public static readonly AWITING_APPROVE = "Awaiting Approve";
  public static readonly AWITING_SAF_APPROVE = "Awaiting SAF Approve";
  public static readonly APPROVED = "Approved";
  public static readonly REQUEST_SUBMIT_SUCCESS = "Request Submit Success";
}

export class GoogleAnalyticsConstantEvent {
  public static readonly BACK_TO_REQUEST_TO_JOIN = "Back to request to join page";
  public static readonly BACK_TO_REGISTER = "Back to register page";
  public static readonly BACK_TO_EDIT = "Back to edit";
  public static readonly VERIFY_TAXID = "Verify TaxID";
  public static readonly SUBMIT_REGISTER = "Submit register";
  public static readonly SUBMIT_ADD_NEW_BRANCH = "Submit add new branch";
  public static readonly SUBMIT_REQUEST_TO_JOIN = "Submit request to join";
  public static readonly SUBMIT_GET_STARTED_USER = "Submit get started user";

  // register service page
  public static readonly CONTINUE_STEP = "Continue (Step {number})";
  public static readonly SUBMIT_STEP = "Submit (Step {number})";
  public static readonly BACK_STEP = "Back (Step {number})";
  public static readonly BACK_TO_HOME_PAGE = "Back to Homepage";
  public static readonly EDIT_COMPANY_PROFILE = "Edit Company Profile";
  public static readonly GET_DATA_FROM_RD = "Get data from RD";

  // Modal Suggest PI
  public static readonly DONT_SHOW_MODAL_PI_AGAIN = "Do not show modal PI again for the next 7 days";
  public static readonly CLOSE_MODAL_PI = "Close modal PI";
  public static readonly VIEW_DETAIL_MODAL_PI = "View Detail modal PI";

  // Right Menu
  public static readonly ACCOUNT_SETTING_MENU = "Account Setting Menu";
  public static readonly SETTING_MENU = "Setting Menu";
  public static readonly LOGOUT = "Log out"; // 

  // Switch App
  public static readonly SUPPLIER_WEBWORK = "Supplier Web Work";
  public static readonly PROCUMENT_BOARD = "Procurement Board";
  public static readonly PANTAVANIJ_INTELLIGENCE = "Pantavanij Intelligence";

  // side menu
  public static readonly COMPANY_MANAGEMENT = "Company Management"; 
  public static readonly PRODUCT_AND_SERVICE = "Product and Services"; 
  public static readonly BUYER_INVITATION_CODE = "Buyer Invitation Code"; 
  public static readonly DEAL_AND_DISCOUNT = "Deals and Discount"; 

  // company
  public static readonly COMPANY_INTRODUCTION = "Company Introduction";
  public static readonly COMPANY_ADDRESS = "Company Address";
  public static readonly BUSINESS_INFORMATION = "Business Information";
  public static readonly DOCUMENTS = "Documents";
  public static readonly COMPANY_INFORMATION_EDIT = "Edit Company Information";
  public static readonly COMPANY_INFORMATION_UPDATE = "Update Company Information";
  public static readonly COMPANY_INFORMATION_CANCEL = "Cancel Edit Company Information";
  public static readonly COMPANY_INTRODUCTION_EDIT = "Edit Company Introduction";
  public static readonly COMPANY_INTRODUCTION_UPDATE = "Update Company Introduction";
  public static readonly COMPANY_INTRODUCTION_CANCEL = "Cancel Edit Company Introduction";
  public static readonly EDIT_ADDRESS = "Edit Address";
  public static readonly UPDATE_ADDRESS = "Update Address";
  public static readonly CANCEL_ADDRESS = "Cancel Edit Address";
  public static readonly BUSINESS_INFORMATION_EDIT = "Edit Business Information";
  public static readonly BUSINESS_INFORMATION_UPDATE = "Update Business Information";
  public static readonly BUSINESS_INFORMATION_CANCEL = "Cancel Edit Business Information";
  public static readonly EDIT_DOCUMENTS = "Edit Documents";
  public static readonly UPDATE_DOCUMENTS = "Update Documents";
  public static readonly CANCEL_DOCUMENTS = "Cancel Edit Documents";

  // product
  public static readonly ADD_PRODUCT = "Add Product";
  public static readonly DOWNLOAD_TEMPLATE = "Download Template";
  public static readonly MASS_UPLOAD = "Mass Upload";
  public static readonly EXPORT_ALL_PRODUCT = "Export All Product";
  public static readonly EXPORT_CURRENT_PRODUCT = "Export Current Product";
  public static readonly EXPORT_SELECTED_PRODUCT = "Export Selected Product";
  public static readonly FIND_CATEGORY_ID = "Find Category ID";
  public static readonly NEXT_STEP = "Next (Step {number})";
  public static readonly ADD_STEP = "Add (Step {number})";
  public static readonly EDIT_PRODUCT = "Edit Product";
  public static readonly VIEW_PRODUCT = "View Product";
  public static readonly DELETE_PRODUCT = "Delete Product";
  public static readonly HISTORY_PRODUCT = "History Product";
  public static readonly SET_ACTIVE_PRODUCT = "Toggle Avtive Product";
  public static readonly PRODUCT_STATUS = "Product Status";
  public static readonly UPDATE_PRODUCT = "Update Product";
  public static readonly CANCEL_PRODUCT = "Cancel Product";

  // buyer management 
  public static readonly ADD_INVITATION_CODE = "Add Invitation Code";

  // selling tools
  public static readonly VIEW_PROMOTION = "View Promotion";
  public static readonly CREATE_PROMOTION = "Create Promotion";
  public static readonly SELECT_PRODUCT = "Select Product";
  public static readonly EDIT_PROMOTION = "Edit Promotion";
  public static readonly UPDATE_PROMOTION = "Update Promotion";
  public static readonly CANCEL_PROMOTION = "Cancel Promotion";
  public static readonly DELETE_PROMOTION = "Delete Promotion";

  // setting 
  public static readonly VIEW_ACCOUNT_DETAIL = "View Account Details"; 
  public static readonly VIEW_NOTIFICATIONS = "View Notifications"; 
  public static readonly ACCOUNT_SETTING = "Account Settings";
  public static readonly MORE_SETTING = "More Setting";
  public static readonly EDIT_ACCOUNT_DETAIL = "Edit Account Detail";
  public static readonly UPDATE_ACCOUNT_DETAIL = "Update Account Detail";
  public static readonly CANCEL_ACCOUNT_DETAIL = "Cancel Account Detail";
  public static readonly TOGGLE_NOTIFICATION = "Toggle Notification";

  // account setting
  public static readonly ACCOUNT_SETTING_PROFILE_TAB = "Acoount Setting Profile"; 
  public static readonly ACCOUNT_SETTING_PREFERENCE_TAB = "Acoount Setting Preference"; 
  public static readonly ACCOUNT_SETTING_ACCOUNT_ACCESS_TAB = "Account Access"; 
  public static readonly ACCOUNT_SETTING_EDIT_PROFILE = "Edit Profile";
  public static readonly ACCOUNT_SETTING_SAVE_PROFILE = "Save Profile";
  public static readonly ACCOUNT_SETTING_CANCEL_PROFILE = "Cancel Profile";
  public static readonly ACCOUNT_SETTING_EDIT_PREFERENCE = "Edit Preference";
  public static readonly ACCOUNT_SETTING_SAVE_PREFERENCE = "Save Preference";
  public static readonly ACCOUNT_SETTING_CANCEL_PPREFERENCE = "Cancel Preference";
  public static readonly ACCOUNT_SETTING_LOGIN_EDIT = "Edit Email Login"; 
  public static readonly ACCOUNT_SETTING_LOGIN_SAVE = "Save Email Login";
  public static readonly ACCOUNT_SETTING_LOGIN_CANCEL = "Cancel Email Login";
  public static readonly ACCOUNT_SETTING_EDIT_PASSWORD = "Edit Password";
  public static readonly ACCOUNT_SETTING_CHANGE_PASSWORD = "Change Password";
  public static readonly ACCOUNT_SETTING_CANCEL_CHANGE_PASSWORD = "Cancel Change Password";
  
  // common
  public static readonly CLOSE_PAGE = "Close Page";
  public static readonly FILTER = "Filter";
  public static readonly SEARCH = "Search";
  public static readonly SAVE_DRAFT = "Save Draft";
}