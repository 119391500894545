import SaveDraft from '@assets/images/frontend/save-draft.png';
import { SCButton } from '@components/button/SCButton';
import classNames from 'classnames';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalStackPropsBase } from './ModalStackProvider';

interface IProps extends ModalStackPropsBase<'do_not_save' | 'save_and_leave', unknown> {
  show?: boolean;
  disabledSaveAndLeave?: boolean;
  elementID: string;
  isLoading?: boolean;
}

export function LeaveThisPageModal({
  onClose,
  onSubmit,
  show,
  elementID,
  disabledSaveAndLeave,
  isLoading
}: IProps): React.FunctionComponentElement<IProps> {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        size={undefined}
        dialogClassName={classNames('modal-dialog', { 'modal-600': !disabledSaveAndLeave })}
        id={`modal-${elementID}-leaveThisPage`}
      >
        {disabledSaveAndLeave ? (
          <>
            <Modal.Body className="text-center">
              <img src={SaveDraft} className="card-icon" />
              <div className="title">{t('Common.Modal.LeaveThisPage.Title')}</div>
              <p>{t('Common.Modal.LeaveThisPage.DescriptionLeaveOnly')}</p>
            </Modal.Body>
            <Modal.Footer>
              <div className="float-left">
                <Button
                  className="btn-outline"
                  id={`btn-${elementID}-leaveThisPage-cancel`}
                  onClick={onClose}
                >
                  {t('Common.Modal.LeaveThisPage.StayOnPage')}
                </Button>
              </div>
              <div className="float-right">
                <Button
                  variant="primary"
                  id={`btn-${elementID}-leaveThisPage-saveAndLeave`}
                  onClick={() => onSubmit && onSubmit('do_not_save')}
                >
                  {t('Common.Modal.LeaveThisPage.LeavePage')}
                </Button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body className="text-center">
              <img src={SaveDraft} className="card-icon" />
              <div className="title">{t('Common.Modal.LeaveThisPage.Title')}</div>
              <p>{t('Common.Modal.LeaveThisPage.Description')}</p>
            </Modal.Body>
            <Modal.Footer>
              <div className="float-left">
                <Button
                  className="btn-outline"
                  id={`btn-${elementID}-leaveThisPage-cancel`}
                  onClick={onClose}
                >
                  {t('Common.Modal.LeaveThisPage.Cancel')}
                </Button>
              </div>
              <div className="float-right">
                <Button
                  variant="outline"
                  className="noborder"
                  id={`btn-${elementID}-leaveThisPage-dontSave`}
                  onClick={() => onSubmit && onSubmit('do_not_save')}
                >
                  {t('Common.Modal.LeaveThisPage.DoNotSave')}
                </Button>
                <SCButton
                  id={`btn-${elementID}-leaveThisPage-saveAndLeave`}
                  type="primary"
                  isLoading={isLoading}
                  onClick={() => onSubmit && onSubmit('save_and_leave')}
                >
                  {t('Common.Modal.LeaveThisPage.SaveAndLeave')}
                </SCButton>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}
