import { IconAlertModal, IIconAlertModalProps } from '@components/modal/IconAlertModal';
import { useModalStack } from '@components/modal/ModalStackProvider';
import { IFileDetail } from '@models/File';
import { KB } from '@utils/constants/CommonConstants';
import { FileStatus } from '@utils/constants/FileConstant';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { PreviewImagesModal } from '@components/modal/PreviewImagesModal';
import ImageCard from './ImageCard';
import { FileChangedBy } from '@utils/constants/FileConstant';
import UploadFileIcon from '@assets/images/frontend/not-found.png';

interface IMaxFilesProps {
  max: number;
  modalData: IIconAlertModalProps;
}

interface IUploadImageProps {
  label: string;
  fileDescription: string;
  existFiles?: IFileDetail[];
  isRequired?: boolean;
  acceptanceExtension: string[];
  acceptancefileType: string;
  maxFileMBSize: number;
  maxFiles?: IMaxFilesProps;
  elementID: string;
  confirmDeleteImag?: boolean;
  showIconProfile?: boolean;
  onChange?: (data: IFileDetail[]) => void;
  onError?: () => void;
}

export default function UploadImage(
  props: React.PropsWithChildren<IUploadImageProps>
): React.FunctionComponentElement<IUploadImageProps> {
  const {
    existFiles = [],
    label,
    fileDescription,
    isRequired,
    acceptanceExtension,
    acceptancefileType,
    maxFiles,
    maxFileMBSize,
    elementID,
    confirmDeleteImag,
    showIconProfile,
    onChange,
    onError
  } = props;

  const { t } = useTranslation();
  const { push } = useModalStack();

  const [fileList, setFileList] = useState<IFileDetail[]>(existFiles);
  const [uploadedFileList, setUploadedFileList] = useState<IFileDetail[]>(existFiles);
  const [fileThumbnail, setFileThumbnail] = useState<string | undefined>();
  const [dragID, setDragID] = useState<string>();

  useEffect(() => {
    if (existFiles) {
      setFileList(existFiles);
    }
  }, [existFiles]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {

      const numOfUploadFile = acceptedFiles.length + fileRejections.length;
      if (maxFiles && numOfUploadFile > maxFiles.max - uploadedFileList.length) {
        push(IconAlertModal, { ...maxFiles.modalData });
      } else if (fileRejections && fileRejections.length > 0) {
        push(IconAlertModal, {
          data: {
            title: t('Common.Modal.UploadFile.Title.Error'),
            description: t('Common.Modal.UploadFile.Description.InvalidTypeOrSize', {
              maxImageMBSize: maxFileMBSize,
              fileType: acceptancefileType
            }),
            icon: UploadFileIcon,
            submitText: t('Common.Modal.UploadFile.Button.OK'),
          },
          pageElementID: elementID,
          onClose: () => {
          },
          onSubmit: () => {
          },
        });
      }
      else {
        setFileList((fl) => {
          let newFileList: IFileDetail[] = [...fl];
          let pendingFileList: IFileDetail[] = [];

          acceptedFiles.forEach((af) => {
            const newFile: File = CommonHelper.changeDuplicateFilename(newFileList, af);
            const fileDetail: IFileDetail = {
              originalFileName: newFile.name,
              status: FileStatus.PENDING,
              file: newFile,
              dropDate: CommonHelper.getTimestampFormUnix(dayjs().unix()),
              originalFileUrl: null,
            };
            pendingFileList.push(fileDetail);
          });

          pendingFileList.sort(sortFiles);
          newFileList.splice(
            newFileList.filter((f) => f.status === FileStatus.UPLOADED).length > 0 ? 1 : 0,
            0,
            ...pendingFileList
          );

          newFileList = newFileList.map((file, index) => {
            return { ...file, order: index + 1 };
          });

          return newFileList;
        });
      }
    },
    [setFileList, uploadedFileList]
  );

  const sortFiles = useCallback((n1: IFileDetail, n2: IFileDetail) => {
    switch (n1.status) {
      case FileStatus.PENDING:
        if (n2.status === FileStatus.PENDING) {
          return n2.dropDate! - n1.dropDate!;
        } else {
          return -1;
        }
      default:
        if (n2.status === FileStatus.UPLOADED || n2.status === FileStatus.PENDING) {
          return 1;
        } else {
          return n2.dropDate! - n1.dropDate!;
        }
    }
  }, []);

  const shiftFirstUploaded = useCallback((fileList: IFileDetail[]) => {
    let newFileList = [...fileList];
    const uploadedList = newFileList.filter((f) => f.status === FileStatus.UPLOADED);
    if (uploadedList.length > 0) {
      const firstUploadedName = uploadedList.pop()?.originalFileName;
      const index = fileList.findIndex((f) => f.originalFileName === firstUploadedName);
      newFileList = [newFileList[index], ...newFileList.filter((f, i) => i !== index)];
    }
    return newFileList;
  }, []);

  const shiftAllUploadedAndPending = useCallback((fileList: IFileDetail[]) => {
    let newFileList = [...fileList];
    const uploadedList = fileList.filter(
      (f) => f.status === FileStatus.UPLOADED || f.status === FileStatus.PENDING
    );
    if (uploadedList.length > 0) {
      newFileList = [
        ...uploadedList,
        ...fileList.filter(
          (f) => f.status === FileStatus.INVALID || f.status === FileStatus.UPLOAD_FAIL
        ),
      ];
    }
    return newFileList;
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptanceExtension,
    maxSize: maxFileMBSize * KB * KB,
    onDrop,
    disabled: false,
  });

  const includeFileStatus = (status: FileStatus, validStatusList: FileStatus[]) => {
    return validStatusList.includes(status);
  };

  const onImageDelete = useCallback(
    (filename: string) => {
      setFileList((fileList) => {
        let newFileList = [...fileList];
        newFileList = newFileList.filter((f, i) => f.originalFileName !== filename);
        onChange &&
          onChange(
            newFileList.filter((f) =>
              includeFileStatus(f.status, [FileStatus.UPLOADED, FileStatus.PENDING])
            )
          );

        return newFileList;
      });
    },
    [setFileList]
  );

  const onImageChange = useCallback(
    (changedBy: FileChangedBy, filename: string, data: IFileDetail) => {
      if (changedBy === FileChangedBy.UPLOAD) {
        setFileList((fl) => {
          let newFileList = [...fl];
          if (data.status === FileStatus.PENDING) {
            newFileList = newFileList.filter((f) => f.originalFileName !== filename);
            newFileList.splice(newFileList.length > 0 ? 1 : 0, 0, data);
          } else {
            newFileList = newFileList.map((f) => {
              if (f.originalFileName === filename) {
                f = data;
              }
              return f;
            });
          }

          if (
            newFileList.length > 1 &&
            newFileList.filter((file) => file.status === FileStatus.UPLOADED).length === 1
          ) {
            newFileList = shiftFirstUploaded(newFileList);
          }
          newFileList = shiftAllUploadedAndPending(newFileList);

          newFileList = newFileList.map((file, index) => {
            return { ...file, order: index + 1 };
          });

          onChange &&
            onChange(
              newFileList.filter((f) =>
                includeFileStatus(f.status, [FileStatus.UPLOADED, FileStatus.PENDING])
              )
            );

          return newFileList;
        });
      } else {
        setFileList((fl) => {
          let newFileList = [...fl];
          newFileList = newFileList.map((f) => {
            if (f.originalFileName === filename) {
              f = data;
            }
            return f;
          });
          onChange && onChange(newFileList);

          return newFileList;
        });
      }
    },
    [setFileList]
  );

  const onImagePreview = useCallback(
    (filename: string, previewImages: IFileDetail[]) => {
      const index = previewImages.findIndex((f) => f.originalFileName === filename);
      push(PreviewImagesModal, {
        initIndex: index,
        title: label,
        data: previewImages,
        pageElementID: elementID,
        onClose: () => { },
      });
    },
    [elementID]
  );

  const isMaximumFiles = useCallback(
    (uploadedFileList: IFileDetail[], maxFiles?: number): boolean => {
      return maxFiles !== undefined && uploadedFileList.length >= maxFiles;
    },
    []
  );

  useEffect(() => {
    const fIndex: number = fileList.findIndex((f) => f.status === FileStatus.UPLOADED);
    setFileThumbnail(fileList[fIndex]?.originalFileName!);
    setUploadedFileList(
      fileList.filter((f) => includeFileStatus(f.status, [FileStatus.UPLOADED, FileStatus.PENDING]))
    );
  }, [fileList]);

  const renderDropZone = (
    <div
      {...getRootProps({
        className: classNames('upload-banner default'),
      })}
    >
      <div className="box-action">
        <i className="icon-large-upload mb-2 icon-gray">
          <span className="path1"></span>
          <span className="path2"></span>
          <input {...getInputProps()} />
        </i>
      </div>
    </div>
  );

  const handleDrag = (id: string) => {
    setDragID(id);
  };

  const handleDrop = (ev: DragEvent) => {
    if (ev && (ev.currentTarget as HTMLDivElement).draggable) {
      const targetID = ev.currentTarget.id;
      setFileList((fl) => {
        const dragBox = fl.find((file) => `div-${elementID}-box-${file.uuid}` === dragID);
        const dropBox = fl.find((file) => `div-${elementID}-box-${file.uuid}` === targetID);

        const dragBoxOrder = dragBox?.order;
        const dropBoxOrder = dropBox?.order;

        const newFileList = fl.map((file) => {
          if (`div-${elementID}-box-${file.uuid}` === dragID) {
            file.order = dropBoxOrder;
          }
          if (`div-${elementID}-box-${file.uuid}` === targetID) {
            file.order = dragBoxOrder;
          }
          return file;
        });

        newFileList.sort((a, b) => a.order! - b.order!);
        onChange &&
          onChange(
            newFileList.filter((f) =>
              includeFileStatus(f.status, [FileStatus.UPLOADED, FileStatus.PENDING])
            )
          );

        return newFileList;
      });
      setDragID(undefined);
    }
  };

  return (
    <>
      {fileList.length > 0 ? (
        <>
          {fileList.map((f, i) => (
            <ImageCard
              key={`${f.originalFileName}`}
              file={f}
              index={i}
              confirmDeleteImag={confirmDeleteImag}
              showIconProfile={showIconProfile}
              fileThumbnail={fileThumbnail!}
              isMaximumFiles={isMaximumFiles(uploadedFileList, maxFiles?.max)}
              previewImages={uploadedFileList}
              elementID={elementID}
              enableEdit={true}
              onDelete={onImageDelete}
              onChange={onImageChange}
              onPreview={onImagePreview}
              draggable={!!f.order && FileStatus.UPLOADED === f.status}
              onDragStart={handleDrag}
              onDrop={handleDrop}
              onError={onError}
            />
          ))}
        </>
      ) : (
        <>
          <div className="mt-8px">{renderDropZone}</div>
        </>
      )}
    </>
  );
}
