import React, { ComponentType, FC, Fragment } from "react";

type Components = ComponentType<any> | [ComponentType<any>, { [key: string]: any }];

interface Props {
  components: Components[];
}

export const Compose: FC<Props> = ({ components, children }) => (
  <Fragment>
    {components.reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </Fragment>
);

export function withProvider<P>(component: ComponentType<P>, args?: Omit<P,'children'>): [ComponentType<P>, P | {}] {
  return [component, args || {}];
}
