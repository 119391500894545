import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import UserDefaultImg from '@assets/images/header/profile-empty.png';
import { useTranslation } from 'react-i18next';
import { Language, REGEX } from '@utils/constants/CommonConstants';
import { usePTVNAuthentication } from '@ptvn-react/authentication';
import { ExtendedUserInformation } from '@models/User';
import init from '@assets/js/common';
import { Link } from 'react-router-dom';
import PageRoute from '@utils/constants/PageRoute';
import { useHistory } from 'react-router';
import SWWImg from '@assets/images/header/sww.png';
import PTVNImg from '@assets/images/header/PTVN.png';
import SCPTVNImg from '@assets/images/header/SCPTVN.png';
import useOnClickOutside from '@components/hook/OnClickOutside';
import PTVNINTELLIGENCEImg from '@assets/images/header/Pantavanij Intelligence.svg';
import useGoogleAnalytics from "@google/useGoogleAnalytics";
import { GoogleAnalyticsConstantEvent } from "@utils/constants/GoogleAnalyticsConstant";
import { EventArgs } from "react-ga";
import { Subscription } from 'rxjs';

interface IProps {
  notShowIconSideMenu?: boolean;
  noAppSwitcher?: boolean;
  noProcurementBoard?: boolean;
  showSCCompanyManageMent?: boolean;
  clickLogoNoRedirect?: boolean;
  notShowUserMenu?: boolean;
  notShowCurrentCompany?: boolean;
}

export default function Header(props: React.PropsWithChildren<IProps>): React.FunctionComponentElement<unknown> {
  const { notShowIconSideMenu, noAppSwitcher, showSCCompanyManageMent, clickLogoNoRedirect, noProcurementBoard, notShowUserMenu, notShowCurrentCompany } = props;
  const { i18n, t } = useTranslation();
  const currentLangItem = Language.getLangByKey(i18n.language.substring(0, 2));
  const languages = [Language.en, Language.th];
  const { currentCompany, userInformation, extendedUserInformation } = usePTVNAuthentication<ExtendedUserInformation>();
  const [fullName, setFullName] = useState<string>();
  const history = useHistory();
  const [isShowAccountSetting, setIsShowAccountSetting] = useState<boolean>(false);
  const ref = useRef(null);
  const { setRequestGA, requestGAObservable } = useGoogleAnalytics();
  const [subscription, setSubscription] = useState<Subscription>();
  useEffect(() => {
    init && init.initFunctionReady();

    window.addEventListener('resize', () => {
      init && init.initFunctionResize();
    });
  }, []);

  useEffect(() => {
    if (extendedUserInformation?.userInformation) {
      if (
        i18n.language === Language.en.key &&
        extendedUserInformation?.userInformation?.firstNameEN &&
        extendedUserInformation?.userInformation?.lastNameEN
      ) {
        setFullName(
          `${extendedUserInformation.userInformation.firstNameEN} ${extendedUserInformation.userInformation.lastNameEN}`
        );
      } else {
        setFullName(
          `${extendedUserInformation.userInformation.firstName} ${extendedUserInformation.userInformation.lastName}`
        );
      }
      if (!extendedUserInformation?.userInformation?.username?.match(REGEX.EMAIL)) {
        setIsShowAccountSetting(true);
      }
    } else {
      setFullName(userInformation?.fullName)
    }
  }, [i18n.language, extendedUserInformation?.userInformation, userInformation]);

  const redirectTo = (url: string) => {
    document.body.classList.remove('user-nav-open');
    history.push(url);
  };

  const handleClickOutsideUserMenu = () => {
    document.body.classList.remove('user-nav-open');
  };

  useOnClickOutside(ref, handleClickOutsideUserMenu);

  const renderCompanyName = () => {
    let companyName = '';
    let branchName = '';
    if (currentCompany) {
      if (i18n.language === Language.en.key && currentCompany.companyNameEN) {
        companyName = currentCompany.companyNameEN;
      } else {
        companyName = currentCompany.companyNameLocal;
      }

      if (currentCompany.branchNumber) {
        branchName += companyName ? `- ${currentCompany.branchNumber}` : currentCompany.branchNumber;
      }

      if (i18n.language === Language.en.key && currentCompany.branchNameEN) {
        branchName += branchName ? `: ${currentCompany.branchNameEN}` : `- ${currentCompany.branchNameEN}`;
      } else {
        branchName += branchName ? `: ${currentCompany.branchNameLocal}` : `- ${currentCompany.branchNameLocal}`;
      }
    }
    return (<>
      {currentCompany && !notShowCurrentCompany && (
        <div className="sub-branch">
          {currentCompany.companyNameLocal && (
            <strong id={`lbl-header-companyName`}>{companyName}</strong>
          )}
          {branchName && (
            <span id={`lbl-header-branchName`}>
              {branchName}
            </span>
          )}
        </div>)}
    </>);
  }

  return (
    <>
      <header className="headbar white">
        <div className="float-left">
          {!notShowIconSideMenu &&
            <div className="nav-toggle">
              <i id={`btn-header-logo`} className="icon-menu">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          }
          <a onClick={() => {
            !clickLogoNoRedirect &&
              redirectTo(PageRoute.COMPANY_MANAGEMENT)
          }} className="logo">
            <span>{t('Common.Title.Supplier')}</span>
          </a>

          {renderCompanyName()}

        </div>
        <div className="float-right">
          <Dropdown id={`ddl-header-changeLanguage`} className="lang position-static">
            <i className="icon-globe" style={{ zIndex: 1 }}>
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <Dropdown.Toggle id={`btn-header-changeLanguage`} variant="" data-toggle="dropdown" style={{ maxWidth: 'unset' }}>
              {currentLangItem?.title}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
              {languages.map((lang, i) => {
                return (
                  <Dropdown.Item
                    id={`opt-header-changeLanguage-${i}`}
                    onSelect={() => i18n.changeLanguage(lang.key)}
                    key={'switch-lang-' + lang.key}
                  >
                    {lang.title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {!notShowUserMenu &&
            <>
              <div className="user-dropdown">
                <button id={`btn-header-profile`} type="button" data-toggle="dropdown" className="btn">
                  <img src={UserDefaultImg} />
                  <span>{fullName}</span>
                </button>
              </div>
              <div className="user-menu" ref={ref}>
                <div className="user-menu-scroll">
                  <div className="user-info media">
                    <div className="float-left">
                      <div className="user-avatar">
                        <img id={`img-header-profile`} src={UserDefaultImg} />
                      </div>
                    </div>
                    <div className="media-body ellipsis">
                      <div id={`lbl-header-profile-name`} className="title ellipsis">{fullName}</div>
                      <small id={`lbl-header-profile-email`} className="d-block ellipsis">
                        {extendedUserInformation?.userInformation?.email || userInformation?.email}
                      </small>
                    </div>
                  </div>
                  {!noAppSwitcher && isShowAccountSetting &&
                  <ul className="list-user-menu">
                    <li className="menu-title"><strong>{t('MyAccount.UserProfile')}</strong></li>
                    <li className="mb-1">
                      <a className="menu-icon"
                        href={PageRoute.ACCOUNT_SETTING}
                        onClick={
                          () => {
                            setSubscription(requestGAObservable({
                              suffixPathAction: GoogleAnalyticsConstantEvent.ACCOUNT_SETTING_MENU,
                              event: { action: GoogleAnalyticsConstantEvent.ACCOUNT_SETTING_MENU } as EventArgs
                            }).subscribe(() => {
                              redirectTo(PageRoute.ACCOUNT_SETTING)
                            }));
                          }
                        }>
                        <span>{t('MyAccount.Title.AccountSettings')}</span>
                        <i className="icon-launch-custom ml-auto"></i>
                      </a>
                    </li>
                    <li className="menu-title"><strong>{t('Common.Title.Supplier')}</strong></li>
                    <li>
                      <a className="menu-icon"
                        href={PageRoute.SETTING}
                        onClick={() => {
                          setSubscription(requestGAObservable({
                            suffixPathAction: GoogleAnalyticsConstantEvent.SETTING_MENU,
                            event: { action: GoogleAnalyticsConstantEvent.SETTING_MENU } as EventArgs
                          }).subscribe(() => {
                            redirectTo(PageRoute.SETTING)
                          }));
                        }}>
                        <span>{t('Setting.Menu.Settings')}</span>
                      </a>
                    </li>
                  </ul>}
                </div>
                <div className="user-menu-footer">
                  <ul className="list-user-menu">
                    <li>
                      <Link id={`btn-header-profile-logout`}
                        to={() => {
                          return PageRoute.LOGOUT
                        }
                        }>{t('Common.Label.LogOut')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          }

          {!noAppSwitcher && <div className="apps">
            <Dropdown id={`ddl-header-app-switcher`}>
              <Dropdown.Toggle
                as="button"
                bsPrefix="btn">
                <i className="icon-apps"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-right" style={{ zIndex: 100, minWidth: 'max-content' }}>
                {showSCCompanyManageMent && <Dropdown.Item
                  id={`ddl-item1-app-switcher`}
                  className="dropdown-item d-flex align-items-center"
                  onSelect={() => {
                    setSubscription(requestGAObservable({
                      suffixPathAction: GoogleAnalyticsConstantEvent.COMPANY_MANAGEMENT,
                      event: { action: GoogleAnalyticsConstantEvent.COMPANY_MANAGEMENT } as EventArgs
                    }).subscribe(() => {
                      redirectTo(PageRoute.COMPANY_MANAGEMENT)
                    }));
                  }}
                >
                  <img src={SCPTVNImg} className="img-item" />
                  {t('Common.Title.Supplier')}
                </Dropdown.Item>
                }
                <Dropdown.Item
                  id={`ddl-item2-app-switcher`}
                  className="dropdown-item d-flex align-items-center"
                  onSelect={() => {
                    setSubscription(requestGAObservable({
                      suffixPathAction: GoogleAnalyticsConstantEvent.SUPPLIER_WEBWORK,
                      event: { action: GoogleAnalyticsConstantEvent.SUPPLIER_WEBWORK } as EventArgs
                    }).subscribe(() => {
                      redirectTo(PageRoute.PORTAL)
                    }));
                  }}
                >
                  <img src={SWWImg} className="img-item" />{t('Common.Title.SupplierWebWork')}
                </Dropdown.Item>
                {!noProcurementBoard && <><Dropdown.Item
                  id={`ddl-item3-app-switcher`}
                  className="dropdown-item d-flex align-items-center"
                  onSelect={() => {
                    setSubscription(requestGAObservable({
                      suffixPathAction: GoogleAnalyticsConstantEvent.PROCUMENT_BOARD,
                      event: { action: GoogleAnalyticsConstantEvent.PROCUMENT_BOARD } as EventArgs
                    }).subscribe(() => {
                      window.location.href = PageRoute.PROCUREMENT_BOARD
                    }));
                  }}
                >
                  <img src={PTVNImg} className="img-item" />{t('Common.Title.ProcurementBoard')}
                </Dropdown.Item>
                  <Dropdown.Item
                    id={`ddl-item3-app-switcher`}
                    className="dropdown-item d-flex align-items-center"
                    onSelect={() => {
                      setSubscription(requestGAObservable({
                        suffixPathAction: GoogleAnalyticsConstantEvent.PANTAVANIJ_INTELLIGENCE,
                        event: { action: GoogleAnalyticsConstantEvent.PANTAVANIJ_INTELLIGENCE } as EventArgs
                      }).subscribe(() => {
                        window.location.href = PageRoute.PANTAVANIJ_INTELLIGENCE
                      }));
                    }}
                  >
                    <img src={PTVNINTELLIGENCEImg} className="img-item" />{t('Common.Title.PantavanijIntelligence')}
                  </Dropdown.Item>
                </>}
              </Dropdown.Menu>
            </Dropdown>
          </div>}
        </div>
      </header>
    </>
  );
}
