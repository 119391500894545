import { GatewayGraphQLClient } from '@api/GatewayGraphQLClient';
import { usePromotionDetailLazyQuery } from '@graphql/autogenerate/hooks';
import { PromotionDetail, PromotionProductItemListInput, PromotionProductItemSortByType, SortDirType } from '@graphql/autogenerate/schemas';
import { PromotionProduct } from '@models/DealsAndDiscount';
import {
  DealsAndDiscountAction,
  DealsAndDiscountActionType,
  ErrorCodeEnum,
  PromotionStatusEnum,
} from '@utils/enum/DealsAndDiscountEnum';
import { CommonHelper } from '@utils/helpers/CommonHelper';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { match, useRouteMatch } from 'react-router';

export interface DealsAndDiscountDetailContextType {
  setFilter: Dispatch<SetStateAction<PromotionProductItemListInput>>;
  filter: PromotionProductItemListInput;
  promotionDetail?: PromotionDetail;
  defaultProductFilter: PromotionProductItemListInput;
  isLoadingPromotion: boolean;
  loadPromotionList: () => void;
}

const DealsAndDiscountDetailContext = React.createContext<
  DealsAndDiscountDetailContextType | undefined
>(undefined);

export const DealsAndDiscountProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const routeMatch: match<{ promotionID: any; tab: string }> = useRouteMatch();

  const [action, setAction] = useState<DealsAndDiscountActionType>();
  const [promotionID, setPromotionID] = useState<number>();
  const [promotionDetail, setPromotionDetail] = useState<PromotionDetail>();
  const defaultFilter = (): PromotionProductItemListInput => {
    return {
      sortBy: PromotionProductItemSortByType.ProductName,
      sortDir: SortDirType.Asc,
      page: 1,
      pageSize: 10,
      searches: [],
    } as PromotionProductItemListInput;
  };
  const [defaultProductFilter, setDefaultProductFilter] = useState<PromotionProductItemListInput>(defaultFilter());
  const [filter, setFilter] = useState<PromotionProductItemListInput>(defaultFilter());

  const [
    loadPromotionList,
    { data: graphQLPromotion, loading: isLoadingPromotion, error: errorLoadPromotion },
  ] = usePromotionDetailLazyQuery({
    client: GatewayGraphQLClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      promotionID: Number(promotionID)!,
      input: filter!,
    },
  });

  useEffect(() => {
    if (filter) {
      loadPromotionList();
    }
  }, [filter]);

  useEffect(() => {
    if (graphQLPromotion?.promotion) {
      setPromotionDetail(graphQLPromotion.promotion as PromotionDetail);
    }
  }, [graphQLPromotion]);

  useEffect(() => {
    if (errorLoadPromotion) {
      const ex = CommonHelper.transformGraphQLErrors(errorLoadPromotion);
      if (ex && ex.length) {
        if (
          ex.find((e) => e.code === ErrorCodeEnum.NOT_FOUND) ||
          ex.find((e) => e.code === ErrorCodeEnum.PROMOTION_DELETED)
        ) {
          setAction(DealsAndDiscountAction.NOT_FOUND);
        } else {
          setAction(DealsAndDiscountAction.ERROR);
        }
      } else {
        setAction(DealsAndDiscountAction.ERROR);
      }
    }
  }, [errorLoadPromotion]);

  useEffect(() => {
    if (promotionDetail) {
      if (promotionDetail.promotionStatus === PromotionStatusEnum.PENDING) {
        setAction(DealsAndDiscountAction.ADD);
      } else {
        setAction(DealsAndDiscountAction.EDIT);
      }
    }
  }, [promotionDetail]);

  useEffect(() => {
    if (routeMatch?.params?.promotionID) {
      setPromotionID(routeMatch?.params?.promotionID);
    } else {
      setAction(DealsAndDiscountAction.ADD);
    }
  }, [routeMatch.params.promotionID]);

  return (
    <DealsAndDiscountDetailContext.Provider
      value={{
        setFilter,
        filter,
        promotionDetail,
        defaultProductFilter,
        isLoadingPromotion,
        loadPromotionList
      }}
    >
      {children}
    </DealsAndDiscountDetailContext.Provider>
  );
};

export const useDealsAndDiscountDetailContext = () => {
  return useContext(DealsAndDiscountDetailContext) as DealsAndDiscountDetailContextType;
};
